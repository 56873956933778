import {
    GET_ALL_ARTICLE_TYPES,
    GET_ALL_ARTICLE_TYPES_ERROR,
} from "../actions/types";

const initialState = {
    articleTypes: [],
    isLoading: true,
    success: false,
    count: null,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_ALL_ARTICLE_TYPES:
            return {
                ...state,
                articleTypes: payload,
                isLoading: false,
                success: true,
            };

        case GET_ALL_ARTICLE_TYPES_ERROR:
            return {
                ...state,
                articles: payload,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}
