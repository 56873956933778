export function getBuyersGuideStageUrl(num) {
    let url = ''
    switch (num) {
        case 1:
            url = 'pdf-upload'
            break;

        case 2:
            url = 'image-review'
            break;

        // case 3:
        //     url = 'initial-review'
        //     break;

        case 3:
            url = 'new-content'
            break;

        case 4:
            url = 'review'
            break;

        // case 5:
        //     url = 'email-prep'
        //     break;

        case 5:
            url = 'client-activity'
            break;

        case 6:
            url = 'final-review'
            break;

        default:
            url = 'pdf-upload'
            break;
    }

    return url
}