import {
    GET_ALL_404_URLS,
    ALL_404_URLS_ERROR,
    GET_ALL_301_REDIRECTS,
    ALL_301_REDIRECTS_ERROR,
    ADD_301_REDIRECTS_BULK,
    ADD_301_REDIRECTS_BULK_ERROR,
    ADD_301_REDIRECT,
    ADD_301_REDIRECT_ERROR
} from "../actions/types";

const initialState = {
    Page404urls: [],
    Page301redirects: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_ALL_404_URLS:
            return {
                ...state,
                Page404urls: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_301_REDIRECTS:
            return {
                ...state,
                Page301redirects: payload,
                isLoading: false,
                success: true,
            };
        case ADD_301_REDIRECTS_BULK:
        case ADD_301_REDIRECT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case ADD_301_REDIRECTS_BULK_ERROR:
        case ADD_301_REDIRECT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case ALL_404_URLS_ERROR:
            return {
                ...state,
                Page404urls: payload,
                isLoading: false,
                success: false,
            };
        case ALL_301_REDIRECTS_ERROR:
            return {
                ...state,
                Page301redirects: payload,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
