import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.css';

import { getBuyersGuideManufacturers, getBuyersGuideCategories, bulkUpdateManufacturer, bulkUpdateCategory } from '../../redux/actions/buyersGuide';

registerAllModules();

const SettingsModal = ({ open, closeModal, bulkUpdateManufacturer, bulkUpdateCategory, getBuyersGuideManufacturers, getBuyersGuideCategories, buyersGuide: { isLoading, success, specificBuyersGuideData, categories, manufacturers } }) => {
    const catTable = useRef(null);
    const manuTable = useRef(null);


    const [categoryData, setCategoryData] = useState([])
    const [manufacturerData, setManufacturerData] = useState([])


    useEffect(() => {
        getBuyersGuideManufacturers(specificBuyersGuideData.buyers_guide_id)
        getBuyersGuideCategories(specificBuyersGuideData.buyers_guide_id)
    }, [])

    useEffect(() => {
        if (categories && categories.length > 0) {
            let arr = []
            categories.forEach(e => {
                let newArr = {
                    'Category Id': e.staged_category_id,
                    'Category Name': e.category_name
                }
                arr.push(newArr)
            })

            setCategoryData(arr)
        }
    }, [categories])

    useEffect(() => {
        if (manufacturers && manufacturers.length > 0) {
            let arr = []
            manufacturers.forEach(e => {
                let newArr = {
                    'Manufacturer Id': e.staged_manufacturer_id,
                    'Name': e.manufacturer_name,
                    'Phone': e.phone,
                    'Website': e.website,
                    'Contact Email': e.contact_email,
                }
                arr.push(newArr)
            })

            setManufacturerData(arr)
        }
    }, [manufacturers])


    const handleEditCat = () => {
        let data = catTable.current.hotInstance.getData()

        bulkUpdateCategory(data.map(e => {
            return ({
                staged_category_id: e[0],
                category_name: e[1],
            })
        }), specificBuyersGuideData.buyers_guide_id)
    };

    const handleEditManu = () => {
        let data = manuTable.current.hotInstance.getData()

        bulkUpdateManufacturer(data.map(e => {
            return ({
                staged_manufacturer_id: e[0],
                manufacturer_name: e[1],
                phone: e[2],
                website: e[3],
                contact_email: e[4],
            })
        }), specificBuyersGuideData.buyers_guide_id)
    };

    return (
        <Dialog
            open={open}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
            style={{ width: '800px', margin: 'auto' }}
        >
            <DialogContent>
                <div id="hot-app">
                    <div style={{ marginBottom: 20 }}>
                        <h3>Buyers Guide Category Data</h3>
                        <HotTable
                            colHeaders={['Category Id', 'Category Name']}
                            data={categoryData}
                            columnSorting={true}
                            height="250"
                            colWidths='280'
                            width={'100%'}
                            ref={catTable}
                            licenseKey='non-commercial-and-evaluation'
                            columns={[
                                {
                                    data: 'Category Id',
                                    readOnly: true
                                },
                                {
                                    data: 'Category Name'
                                }
                            ]}
                        />

                        <button style={{ marginTop: 20 }} onClick={handleEditCat}>Edit Categories</button>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <h3>Buyers Guide Manufacturer Data</h3>
                        <HotTable
                            colHeaders={['Manufacturer Id', 'Name', 'Phone', 'Website', 'Contact Email']}
                            data={manufacturerData}
                            columnSorting={true}
                            height="250"
                            colWidths='112'
                            width={'100%'}
                            ref={manuTable}
                            licenseKey='non-commercial-and-evaluation'
                            columns={[
                                {
                                    data: 'Manufacturer Id',
                                    readOnly: true
                                },
                                {
                                    data: 'Name'
                                },
                                {
                                    data: 'Phone'
                                },
                                {
                                    data: 'Website'
                                },
                                {
                                    data: 'Contact Email'
                                }
                            ]}
                        />
                        <button style={{ marginTop: 20 }} onClick={handleEditManu}>Edit Manufacturers</button>
                    </div>
                </div>
            </ DialogContent>
        </Dialog>
    )
};

const mapStateToProps = state => ({
    buyersGuide: state.buyersGuide
});
export default connect(
    mapStateToProps, {
    getBuyersGuideManufacturers,
    getBuyersGuideCategories,
    bulkUpdateManufacturer,
    bulkUpdateCategory
})(SettingsModal);