import {
    DXP_CATEGORY_GET_FEATURED_COURSE,
    DXP_CATEGORY_GET_FEATURED_COURSE_ERROR,
    DXP_CATEGORY_GET_CONTENT,
    DXP_CATEGORY_GET_CONTENT_ERROR,
    DXP_CATEGORY_GET_FEATURED_NEW,
    DXP_CATEGORY_GET_FEATURED_NEW_ERROR,
    DXP_CATEGORY_SELECT_CATEGORY,
    DXP_CATEGORY_SELECT_COURSE,
    DXP_CATEGORY_SELECT_CONTENT,
    DXP_CATEGORY_SELECT_NEW_CONTENT,
    DXP_CATEGORY_ADD_FEATURED_CONTENT,
    DXP_CATEGORY_ADD_FEATURED_CONTENT_ERROR,
    DXP_CATEGORY_UPDATE_FEATURED_CONTENT,
    DXP_CATEGORY_UPDATE_FEATURED_CONTENT_ERROR,
    DXP_CATEGORY_DELETE_CONTENT,
    DXP_CATEGORY_DELETE_CONTENT_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    selectedCategory: null,
    featuredCourse: [],
    selectedCourse: null,
    featuredContent: [],
    selectedContent: null,
    featuredNew: [],
    selectedNew: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_CATEGORY_GET_FEATURED_COURSE:
            return {
                ...state,
                featuredCourse: payload,
                isLoading: false,
                success: true,
            };
        case DXP_CATEGORY_GET_CONTENT:
            return {
                ...state,
                featuredContent: payload,
                isLoading: false,
                success: true,
            };
        case DXP_CATEGORY_GET_FEATURED_NEW:
            return {
                ...state,
                featuredNew: payload,
                isLoading: false,
                success: true,
            };

        case DXP_CATEGORY_SELECT_CATEGORY:
            return {
                ...state,
                selectedCategory: payload,
                isLoading: false,
                success: true,
            };
        case DXP_CATEGORY_SELECT_COURSE:
            return {
                ...state,
                selectedCourse: payload,
                isLoading: false,
                success: true,
            };
        case DXP_CATEGORY_SELECT_CONTENT:
            return {
                ...state,
                selectedContent: payload,
                isLoading: false,
                success: true,
            };
        case DXP_CATEGORY_SELECT_NEW_CONTENT:
            return {
                ...state,
                selectedNew: payload,
                isLoading: false,
                success: true,
            };
        case DXP_CATEGORY_ADD_FEATURED_CONTENT:
        case DXP_CATEGORY_UPDATE_FEATURED_CONTENT:
        case DXP_CATEGORY_DELETE_CONTENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };


        case DXP_CATEGORY_GET_FEATURED_COURSE_ERROR:
            return {
                ...state,
                featuredCourses: payload,
                isLoading: false,
                success: true
            }
        case DXP_CATEGORY_GET_CONTENT_ERROR:
            return {
                ...state,
                featuredContent: payload,
                isLoading: false,
                success: true
            }
        case DXP_CATEGORY_GET_FEATURED_NEW_ERROR:
            return {
                ...state,
                featuredNew: payload,
                isLoading: false,
                success: true
            }
        case DXP_CATEGORY_ADD_FEATURED_CONTENT_ERROR:
        case DXP_CATEGORY_UPDATE_FEATURED_CONTENT_ERROR:
        case DXP_CATEGORY_DELETE_CONTENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: true
            }
        default:
            return state;
    }
}
