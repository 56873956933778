import {
    DXP_FREE_GET_FEATURED_COURSE,
    DXP_FREE_GET_FEATURED_COURSE_ERROR,
    DXP_FREE_GET_FREE_CONTENT,
    DXP_FREE_GET_FREE_CONTENT_ERROR,
    DXP_FREE_GET_FEATURED_CATEGORIES,
    DXP_FREE_GET_FEATURED_CATEGORIES_ERROR,
    DXP_FREE_GET_FEATURED_NEW_ALL,
    DXP_FREE_GET_FEATURED_NEW_ALL_ERROR,
    DXP_FREE_SELECT_COURSE,
    DXP_FREE_SELECT_FREE_CONTENT,
    DXP_FREE_SELECT_NEW_CONTENT,
    DXP_FREE_ADD_FEATURED_CONTENT,
    DXP_FREE_ADD_FEATURED_CONTENT_ERROR,
    DXP_FREE_UPDATE_FEATURED_CONTENT,
    DXP_FREE_UPDATE_FEATURED_CONTENT_ERROR,
    DXP_FREE_DELETE_CONTENT,
    DXP_FREE_DELETE_CONTENT_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    featuredCourses: [],
    selectedCourse: null,
    featuredFree: [],
    selectedFree: null,
    featuredCategories: [],
    featuredNew: [],
    selectedNew: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_FREE_GET_FEATURED_COURSE:
            return {
                ...state,
                featuredCourses: payload,
                isLoading: false,
                success: true,
            };
        case DXP_FREE_GET_FREE_CONTENT:
            return {
                ...state,
                featuredFree: payload,
                isLoading: false,
                success: true,
            };
        case DXP_FREE_GET_FEATURED_CATEGORIES:
            return {
                ...state,
                featuredCategories: payload,
                isLoading: false,
                success: true,
            };
        case DXP_FREE_GET_FEATURED_NEW_ALL:
            return {
                ...state,
                featuredNew: payload,
                isLoading: false,
                success: true,
            };

        case DXP_FREE_SELECT_COURSE:
            return {
                ...state,
                selectedCourse: payload,
                isLoading: false,
                success: true,
            };
        case DXP_FREE_SELECT_FREE_CONTENT:
            return {
                ...state,
                selectedFree: payload,
                isLoading: false,
                success: true,
            };
        case DXP_FREE_SELECT_NEW_CONTENT:
            return {
                ...state,
                selectedNew: payload,
                isLoading: false,
                success: true,
            };
        case DXP_FREE_ADD_FEATURED_CONTENT:
        case DXP_FREE_UPDATE_FEATURED_CONTENT:
        case DXP_FREE_DELETE_CONTENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };


        case DXP_FREE_GET_FEATURED_COURSE_ERROR:
            return {
                ...state,
                featuredCourses: payload,
                isLoading: false,
                success: true
            }
        case DXP_FREE_GET_FREE_CONTENT_ERROR:
            return {
                ...state,
                featuredFree: payload,
                isLoading: false,
                success: true
            }
        case DXP_FREE_GET_FEATURED_CATEGORIES_ERROR:
            return {
                ...state,
                featuredCategories: payload,
                isLoading: false,
                success: true
            }
        case DXP_FREE_GET_FEATURED_NEW_ALL_ERROR:
            return {
                ...state,
                featuredNew: payload,
                isLoading: false,
                success: true
            }
        case DXP_FREE_ADD_FEATURED_CONTENT_ERROR:
        case DXP_FREE_UPDATE_FEATURED_CONTENT_ERROR:
        case DXP_FREE_DELETE_CONTENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: true
            }
        default:
            return state;
    }
}
