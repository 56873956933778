import {
    DL_GET_ALL_TRANSACTIONS_PAGINATED,
    DL_GET_ALL_TRANSACTIONS_PAGINATED_ERROR,
    DL_GET_ALL_TRANSACTIONS_PAGINATED_BY_SEARCH_TERM,
    DL_GET_ALL_TRANSACTIONS_PAGINATED_BY_SEARCH_TERM_ERROR,
    DL_GET_TRANSACTION_RECEIPT,
    DL_GET_TRANSACTION_RECEIPT_ERROR,
    DL_REFUND_TRANSACTION,
    DL_REFUND_TRANSACTION_ERROR,
    DL_GET_ALL_USER_TRANSACTIONS_BY_USER_ID,
    DL_GET_ALL_USER_TRANSACTIONS_BY_USER_ID_ERROR,
    DL_GET_TRANSACTION_COUPONS,
    DL_GET_TRANSACTION_COUPONS_ERROR,
} from "../actions/types";

const initialState = {
    allTransactionsPaginatedCount: null,
    allTransactionsPaginated: [],
    allSearchTransactionsPaginated: [],
    allSearchTransactionsPaginatedCount: null,
    transactionReceiptUrl: null,
    allTransactionsByUserId: [],
    transactionCoupons: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_ALL_USER_TRANSACTIONS_BY_USER_ID:
            return {
                ...state,
                allTransactionsByUserId: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_TRANSACTION_RECEIPT:
            return {
                ...state,
                transactionReceiptUrl: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_TRANSACTIONS_PAGINATED_BY_SEARCH_TERM:
            return {
                ...state,
                allSearchTransactionsPaginated: payload.rows,
                allSearchTransactionsPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_TRANSACTIONS_PAGINATED:
            return {
                ...state,
                allTransactionsPaginated: payload.rows,
                allTransactionsPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DL_GET_TRANSACTION_COUPONS:
            return {
                ...state,
                transactionCoupons: payload,
                isLoading: false,
                success: true,
            };
        case DL_REFUND_TRANSACTION:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_TRANSACTIONS_PAGINATED_ERROR:
        case DL_GET_ALL_TRANSACTIONS_PAGINATED_BY_SEARCH_TERM_ERROR:
        case DL_GET_TRANSACTION_RECEIPT_ERROR:
        case DL_REFUND_TRANSACTION_ERROR:
        case DL_GET_ALL_USER_TRANSACTIONS_BY_USER_ID_ERROR:
        case DL_GET_TRANSACTION_COUPONS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
