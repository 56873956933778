import {
    DXP_GET_FREE_PROMOS_BY_USER,
    DXP_GET_FREE_PROMOS_BY_USER_ERROR,
    DXP_ADD_FREE_PROMOS,
    DXP_ADD_FREE_PROMOS_ERROR,
    DXP_GET_FREE_MEMBERSHIPS,
    DXP_GET_FREE_MEMBERSHIPS_ERROR,
    DXP_GET_FREE_MEMBERSHIP_COUNT,
    DXP_GET_FREE_MEMBERSHIP_COUNT_ERROR,
    DXP_SEARCH_FREE_MEMBERSHIPS,
    DXP_SEARCH_FREE_MEMBERSHIPS_ERROR,
    DXP_SEARCH_FREE_MEMBERSHIP_COUNT,
    DXP_SEARCH_FREE_MEMBERSHIP_COUNT_ERROR,
} from "../actions/types";


const initialState = {
    isLoading: true,
    success: false,
    freeOffers: [],
    freeTrials: [],
    count: null,
    searchCount: null,
    freeMemberships: [],
    searchedFreeMembership: [],
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_FREE_PROMOS_BY_USER:
            return {
                ...state,
                freeOffers: payload.freeOffers,
                freeTrials: payload.trials,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FREE_MEMBERSHIPS:
            return {
                ...state,
                freeMemberships: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FREE_MEMBERSHIP_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_FREE_MEMBERSHIPS:
            return {
                ...state,
                searchedFreeMembership: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_FREE_MEMBERSHIP_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_FREE_PROMOS:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case DXP_GET_FREE_PROMOS_BY_USER_ERROR:
            return {
                ...state,
                freeOffers: [],
                freeTrials: [],
                isLoading: false,
                success: false,
            };
        case DXP_GET_FREE_MEMBERSHIPS_ERROR:
            return {
                ...state,
                freeMemberships: [],
                isLoading: false,
                success: false
            }
        case DXP_GET_FREE_MEMBERSHIP_COUNT_ERROR:
            return {
                ...state,
                count: null,
                isLoading: false,
                success: false
            }
        case DXP_SEARCH_FREE_MEMBERSHIPS_ERROR:
            return {
                ...state,
                searchedFreeMembership: [],
                isLoading: false,
                success: false
            }
        case DXP_SEARCH_FREE_MEMBERSHIP_COUNT_ERROR:
            return {
                ...state,
                searchCount: null,
                isLoading: false,
                success: false
            }
        case DXP_ADD_FREE_PROMOS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}