import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setAlert } from "../redux/actions/alerts";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

import styles from './DropzoneArea.module.css';

const Dropzone = ({
    name = 'dropzone',
    acceptedFiles,
    dropzoneText = 'Drag and drop a file here or click',
    showPreviewsInDropzone,
    filesLimit,
    onChange,
    onDelete,
    setAlert,
    dropzoneClass,
    dropzoneParagraphClass,
    maxFileSize,
    showFileNames,
    initialFiles,
    showPreviews,
    showDelete,
    showFileNamesInPreview,
    hideBorder
}) => {
    const [images, setImages] = useState([]);
    const [clear, setClear] = useState(0);

    useEffect(() => {
        if (initialFiles?.length > 0 && images?.length === 0) {
            setImages([...initialFiles])
        }
    }, [initialFiles])

    const handleAddImages = (files) => {

        if (Object.keys(files)?.length > filesLimit || (filesLimit > 1 && Object.keys(files)?.length + images.length > filesLimit)) {
            setAlert('The number of files exceeds the limit', 'danger')
            return
        } else {
            let fileValues = Object.values(files)

            if (maxFileSize) {
                for (let file of fileValues) {
                    if (file.size > maxFileSize) {
                        setAlert('The file size exceeds the limit', 'danger')
                        return
                    }
                }
            }
            if (!fileValues) {
                return
            }

            fileValues = fileValues.map(file => {
                file.path = file.name
                return file
            })

            if (filesLimit > 1) {
                onChange(fileValues)
                setImages(prevImages => [
                    ...prevImages,
                    ...fileValues
                ])
            } else {
                setImages(fileValues)
                onChange(fileValues)
            }
        }

    }

    const handleDeleteImage = (e, file, index) => {

        let currentImages = [...images]

        currentImages.splice(index, 1)

        setImages([...currentImages])

        onDelete(file)
        setClear(clear + 1)
    }

    return (
        <>
            <div name={name}
                className={styles.dropzone_container}
                style={{ ...dropzoneClass, width: dropzoneClass?.width ? dropzoneClass.width : '100%' }}
            >
                <label
                    className={styles.dropzone_label}
                    style={hideBorder ? { ...dropzoneClass, borderColor: 'transparent', width: '100%', height: '100%', position: 'relative' } : { ...dropzoneClass, width: '100%', height: '100%', position: 'relative' }}
                >
                    <p
                        className={styles.dropzone_text}
                        style={{ ...dropzoneParagraphClass }}
                    >
                        {dropzoneText}
                    </p>
                    <div className={styles.dropzone_icon}>
                        <CloudUploadIcon sx={{ fontSize: 60 }} />
                    </div>
                    <input
                        key={clear}
                        className={styles.dropzone_input}
                        type='file'
                        name={name}
                        multiple={filesLimit > 0 ? true : false}
                        onChange={(e) => handleAddImages(e.target.files)}
                        accept={acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0]}
                    />
                </label>
                {onDelete && showDelete && images.length > 0 ?
                    <button
                        name={name}
                        className={styles.dropzone_image_delete_no_image}
                        onClick={() => handleDeleteImage()}
                    >
                        <DeleteIcon sx={{ fontSize: 20 }} />
                    </button>

                    : null}

                {/* PREVIEW IN CONTAINER */}
                {showPreviewsInDropzone !== false && images?.length > 0 ?
                    images.map((image, idx) => (
                        <div
                            key={`dropzone_${idx}_${image?.name}`}
                            className={styles.dropzone_image_container}
                            style={showFileNames ? { height: '85px' } : {}}
                        >
                            {image.type?.startsWith('image') ?
                                <img
                                    className={styles.dropzone_image}
                                    src={URL.createObjectURL(image)}
                                    alt={image.name}
                                    key={idx}
                                />
                                : <div className={styles.dropzone_image_placeholder}></div>}

                            {onDelete ?
                                <button
                                    name={name}
                                    className={styles.dropzone_image_delete}
                                    onClick={(e) => handleDeleteImage(e, image, idx)}
                                >
                                    <DeleteIcon sx={{ fontSize: 20 }} />
                                </button>

                                : null}

                            <div className={styles.dropzone_image_fade}></div>

                            {showFileNames ?
                                <p className={styles.dropzone_image_name}>{image.name}</p>
                                : null}
                        </div>
                    ))
                    : null
                }
            </div>

            {/* PREVIEW NEXT TO CONTAINER */}
            {showPreviewsInDropzone === false && showPreviews === true && images?.length > 0 ?

                <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '70%' }}>

                    {images.map((image, idx) => (
                        <div
                            key={`dropzone_${idx}_${image?.name}`}
                            className={styles.dropzone_image_external_container}
                            style={showFileNamesInPreview ? { height: '125px' } : {}}
                        >
                            <img
                                className={styles.dropzone_image}
                                src={URL.createObjectURL(image)}
                                alt={image.name}
                                key={idx}
                            />

                            {onDelete ?
                                <button
                                    className={styles.dropzone_image_delete}
                                    onClick={(e) => handleDeleteImage(e, image, idx)}
                                >
                                    <DeleteIcon sx={{ fontSize: 20 }} />
                                </button>

                                : null}

                            <div className={styles.dropzone_image_fade}></div>

                            {showFileNamesInPreview ?
                                <p className={styles.dropzone_image_name}>{image.name}</p>
                                : null}
                        </div>
                    ))
                    }
                </div >
                : null
            }
        </>
    );
};

Dropzone.propTypes = {
    name: PropTypes.string,
    acceptedFiles: PropTypes.array,
    dropzoneText: PropTypes.string,
    showPreviewsInDropzone: PropTypes.bool,
    filesLimit: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    setAlert: PropTypes.func.isRequired,
    dropzoneClass: PropTypes.object,
    dropzoneParagraphClass: PropTypes.object,
    maxFileSize: PropTypes.number,
    showFileNames: PropTypes.bool,
    initialFiles: PropTypes.array,
    showPreviews: PropTypes.bool,
    showDelete: PropTypes.bool,
    showFileNamesInPreview: PropTypes.bool,
    hideBorder: PropTypes.bool,
}


const mapStateToProps = (state) => ({
});

export const DropzoneArea = connect(mapStateToProps, {
    setAlert,
})(Dropzone);