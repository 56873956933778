import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import { logout } from "../redux/actions/auth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown({ logout }) {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await logout();
    navigate("/");
  };

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          aria-owns={anchorMenu ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <Link
          to="/admin/profile"
          style={{ textDecoration: "none", color: "black" }}
        >
          <MenuItem>Profile</MenuItem>
        </Link>


        {auth.user.hasAccess.includes('addUsers') ?
          <Link
            to="/admin/users"
            style={{ textDecoration: "none", color: "black" }}
          >
            <MenuItem>Manage Users</MenuItem>
          </Link>
          :
          <></>
        }


        {auth.user.hasAccess.includes('manageRoles') ?
          <Link
            to="/admin/roles"
            style={{ textDecoration: "none", color: "black" }}
          >
            <MenuItem>Manage Roles</MenuItem>
          </Link>
          :
          <></>
        }

        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
      </Menu>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(UserDropdown);
