import axios from "axios";
import {
    ADD_BUYERS_GUIDE,
    ADD_BUYERS_GUIDE_ERROR,
    GET_ALL_BUYERS_GUIDE,
    ALL_BUYERS_GUIDE_ERROR,
    GET_SPECIFIC_BUYERS_GUIDE,
    GET_SPECIFIC_BUYERS_GUIDE_ERROR,
    GET_ALL_BUYERS_GUIDE_CATEGORIES,
    GET_ALL_BUYERS_GUIDE_CATEGORIES_ERROR,
    GET_ALL_BUYERS_GUIDE_MANUFACTURERS,
    GET_ALL_BUYERS_GUIDE_MANUFACTURERS_ERROR,
    GET_ALL_BUYERS_GUIDE_PRODUCTS,
    GET_ALL_BUYERS_GUIDE_PRODUCTS_ERROR,
    GET_BUYERS_GUIDE_DROPBOX_IMAGES,
    GET_BUYERS_GUIDE_DROPBOX_IMAGES_ERROR,
    DROPBOX_IMAGE_LOADED,
    // DROPBOX_IMAGE_LOADED_ERROR,
    DROPBOX_IMAGE_LOADING_DONE,
    GET_DROPBOX_AUTH,
    GET_DROPBOX_AUTH_ERROR,
    // SAVE_DROPBOX_IMAGE,
    DELETE_BUYERS_GUIDE,
    DELETE_BUYERS_GUIDE_ERROR,
    SELECT_BUYERS_GUIDE,
    GET_BUYERS_GUIDE_PAGINATED,
    BUYERS_GUIDE_PAGINATED_ERROR,
    GET_BUYERS_GUIDE_COUNT,
    GET_BUYERS_GUIDE_COUNT_ERROR
} from "./types";

import { setAlert } from "./alerts";
// import { da } from "date-fns/locale";

import { uploadFile } from "../../lib/ReactS3";
import config from "../../utils/awsConfig";
import { UseUrlHandler } from "../../helpers/handleUrlImage";

const baseUrl = process.env.REACT_APP_BASE_URL;
const teamMemberId = "dbmid:AABL510y36XB5OmgtMp7E5k_eLg_PdMWJjk"

//gets all buyers guide
export const getBuyersGuide = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/buyersGuide`);

        dispatch({
            type: GET_ALL_BUYERS_GUIDE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: ALL_BUYERS_GUIDE_ERROR,
            payload: { msg: "Could not fetch all buyers guide" },
        });
    }
};

//gets specific buyers guide
export const getBuyersGuideByID = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/buyersGuide/${id}`);

        dispatch({
            type: GET_SPECIFIC_BUYERS_GUIDE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_SPECIFIC_BUYERS_GUIDE_ERROR,
            payload: { msg: "Could not fetch buyers guide" },
        });
    }
};

//gets buyers guide count
export const getBuyersGuideCount = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/buyersGuide/count`);

        dispatch({
            type: GET_BUYERS_GUIDE_COUNT,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_BUYERS_GUIDE_COUNT_ERROR,
            payload: { msg: "Could not fetch the buyers guide count" },
        });
    }
};

//gets all buyers guide paginated
export const getBuyersGuidePaginated = (page, pageSize) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/buyersGuide/page/${page}/100`);

        dispatch({
            type: GET_BUYERS_GUIDE_PAGINATED,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: BUYERS_GUIDE_PAGINATED_ERROR,
            payload: { msg: "Could not fetch paginated buyers guide" },
        });
    }
};



//gets all buyers guide categories
export const getBuyersGuideCategories = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/buyersGuide/categories/${id}`);

        dispatch({
            type: GET_ALL_BUYERS_GUIDE_CATEGORIES,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ALL_BUYERS_GUIDE_CATEGORIES_ERROR,
            payload: { msg: "Could not fetch paginated buyers guide" },
        });
    }
};

export const createNewCategory = (data) => async (dispatch) => {
    try {
        await axios.post(`${baseUrl}/api/buyersGuide/category`, {
            data
        });

        dispatch(setAlert("category has been created successfully!", "success"));
        dispatch(getBuyersGuideCategories(data.buyers_guide_id));
    } catch (err) {
        dispatch(setAlert("category has not been created", "danger"));
        dispatch({
            type: GET_ALL_BUYERS_GUIDE_CATEGORIES_ERROR,
            payload: { msg: "Could not add category" },
        });
    }
}

export const bulkUpdateCategory = (data, buyers_guide_id) => async (dispatch) => {
    try {
        for (let i = 0; i < data.length; i++) {
            const newData = { ...data[i] };
            delete newData['staged_category_id']

            await axios.put(`${baseUrl}/api/buyersGuide/category/edit/${data[i].staged_category_id}`, {
                newData
            });

        }

        dispatch(setAlert("Categories have been updated", "success"));
        dispatch(getBuyersGuideCategories(buyers_guide_id));
    } catch (err) {
        dispatch(setAlert("Categories could not be updated", "danger"));
    }
}

//gets all buyers guide products
export const getBuyersGuideProducts = (id, stage) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/buyersGuide/products/${id}/${stage}`);

        dispatch({
            type: GET_ALL_BUYERS_GUIDE_PRODUCTS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ALL_BUYERS_GUIDE_PRODUCTS_ERROR,
            payload: { msg: "Could not fetch paginated buyers guide" },
        });
    }
};

export const createNewProduct = (data) => async (dispatch) => {
    try {
        await axios.post(`${baseUrl}/api/buyersGuide/product`, {
            data
        });

        dispatch(setAlert("product has been created successfully!", "success"));
        dispatch(getBuyersGuideProducts(data.buyers_guide_id, data.stage_added));
    } catch (err) {
        dispatch(setAlert("product has not been created", "danger"));
        dispatch({
            type: GET_ALL_BUYERS_GUIDE_PRODUCTS_ERROR,
            payload: { msg: "Could not add product" },
        });
    }
}

export const editProduct = (id, data) => async (dispatch) => {
    try {
        await axios.put(`${baseUrl}/api/buyersGuide/product/edit/${id}`, {
            data
        });

        dispatch(setAlert("product has been edited successfully!", "success"));
        dispatch(getBuyersGuideProducts(data.buyers_guide_id, data.stage_added));
    } catch (err) {
        dispatch(setAlert("product has not been edited", "danger"));
        dispatch({
            type: GET_ALL_BUYERS_GUIDE_PRODUCTS_ERROR,
            payload: { msg: "Could not add product" },
        });
    }
}

export const deleteProduct = (id, data) => async (dispatch) => {
    try {
        await axios.delete(`${baseUrl}/api/buyersGuide/product/delete/${id}`);

        dispatch(setAlert("product has been deleted successfully!", "success"));
        dispatch(getBuyersGuideProducts(data.buyers_guide_id, data.stage_added));
    } catch (err) {
        dispatch(setAlert("product could not be deleted", "danger"));
        dispatch({
            type: GET_ALL_BUYERS_GUIDE_PRODUCTS_ERROR,
            payload: { msg: "Could not add product" },
        });
    }
}


//gets all buyers guide Manufacturers
export const getBuyersGuideManufacturers = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/buyersGuide/Manufacturers/${id}`);
        dispatch({
            type: GET_ALL_BUYERS_GUIDE_MANUFACTURERS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ALL_BUYERS_GUIDE_MANUFACTURERS_ERROR,
            payload: { msg: "Could not fetch paginated buyers guide" },
        });
    }
};

export const createNewManufacturer = (data) => async (dispatch) => {
    try {
        await axios.post(`${baseUrl}/api/buyersGuide/manufacturer`, {
            data
        });

        dispatch(setAlert("manufacturer has been created successfully!", "success"));
        dispatch(getBuyersGuideManufacturers(data.buyers_guide_id));
    } catch (err) {
        dispatch(setAlert("manufacturer has not been created", "danger"));
        dispatch({
            type: GET_ALL_BUYERS_GUIDE_MANUFACTURERS_ERROR,
            payload: { msg: "Could not add manufacturer" },
        });
    }
}

export const bulkUpdateManufacturer = (data, buyers_guide_id) => async (dispatch) => {
    try {
        for (let i = 0; i < data.length; i++) {
            const newData = { ...data[i] };
            delete newData['staged_manufacturer_id']

            await axios.put(`${baseUrl}/api/buyersGuide/manufacturer/edit/${data[i].staged_manufacturer_id}`, {
                newData
            });

        }

        dispatch(setAlert("manufacturers have been updated", "success"));
        dispatch(getBuyersGuideManufacturers(buyers_guide_id));
    } catch (err) {
        dispatch(setAlert("manufacturer could not be updated", "danger"));
    }
}

export const updateManufacturer = (data, staged_manufacturer_id, buyers_guide_id) => async (dispatch) => {
    try {
        await axios.put(`${baseUrl}/api/buyersGuide/manufacturer/edit/${staged_manufacturer_id}`, {
            data
        });

        dispatch(setAlert("manufacturers have been updated", "success"));
        dispatch(getBuyersGuideManufacturers(buyers_guide_id));
    } catch (err) {
        dispatch(setAlert("manufacturer could not be updated", "danger"));
    }
}

export const getDropBoxAuth = (token) => async (dispatch) => {
    try {

        const response = await axios.put(`${baseUrl}/api/buyersGuide/dropbox-oath/${token}`);

        dispatch({
            type: GET_DROPBOX_AUTH,
            payload: response.data.result,
        });
    } catch (err) {
        dispatch({
            type: GET_DROPBOX_AUTH_ERROR,
            payload: { msg: "Could not access dropbox" },
        });
    }
};


//get dropbox images for buyers guide products
export const getDropBoxImages = (products) => async (dispatch) => {
    try {
        dispatch({
            type: GET_BUYERS_GUIDE_DROPBOX_IMAGES,
            payload: products.length,
        });
    } catch (err) {
        dispatch({
            type: GET_BUYERS_GUIDE_DROPBOX_IMAGES_ERROR,
            payload: { msg: "Could not fetch all buyers guide" },
        });
    }
};

function getSafeUnicode(c) {
    const unicode = `000${c.charCodeAt(0).toString(16)}`.slice(-4);
    return `\\u${unicode}`;
}

export const searchDropBoxImage = (index, name, token) => async (dispatch) => {

    try {
        const response = await fetch(`https://api.dropboxapi.com/2/files/search_v2`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Dropbox-API-Select-User": teamMemberId
            },
            body: JSON.stringify({
                match_field_options: {
                    include_highlights: false
                },
                options: {
                    filename_only: true,
                },
                query: name.split(' ')[0]
            }),
        })

        const data = await response.json()



        let imageData = []

        if (data.matches?.length > 0) {
            let currentImageData = data.matches.map(e => ({
                path: e.metadata.metadata.path_lower,
                id: e.metadata.metadata.id
            }))


            for (let image of currentImageData) {

                const thumbnail = await fetch(`https://content.dropboxapi.com/2/files/get_thumbnail_v2`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Dropbox-API-Select-User": teamMemberId,
                        "Dropbox-API-Arg": JSON.stringify(
                            {
                                "resource": {
                                    ".tag": "path",
                                    "path": image.path
                                },
                            }).replace(/[\u007f-\uffff]/g, getSafeUnicode),
                    }
                }
                )
                const blob = await thumbnail.blob()

                imageData = [
                    ...imageData,
                    {
                        path: image.path,
                        id: image.id,
                        imageUrl: URL.createObjectURL(blob)
                    }
                ]
            }
        }

        // CHECK DB FOR EXISTING IMAGES
        const res = await axios.get(`${baseUrl}/api/buyersGuide/product/images/${name}`);

        if (res?.data?.length > 0) {
            imageData = [
                ...res.data.map(e => ({
                    path: e.aws_image_link,
                    id: null,
                    imageUrl: UseUrlHandler(e.aws_image_link),
                    is_aws_image: true

                })),
                ...imageData,
            ]
        }

        dispatch({
            type: DROPBOX_IMAGE_LOADED,
            payload: { length: index + 1, image: { product: name, images: imageData } },
        });

    } catch (err) {
        console.error(err)
    }

}


export const dropBoxImageDone = () => async (dispatch) => {
    dispatch({
        type: DROPBOX_IMAGE_LOADING_DONE,
    });
}

export const saveDropBoxImage = (product, image, index, token) => async (dispatch) => {
    try {
        let data
        const isAWSImage = image.is_aws_image
        if (!isAWSImage) {
            const thumbnail = await fetch(`https://content.dropboxapi.com/2/files/download`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Dropbox-API-Select-User": teamMemberId,
                    "Dropbox-API-Arg": JSON.stringify(
                        {
                            "path": image.path

                        })
                }
            }
            )

            const blob = await thumbnail.blob()
            const file = new File([blob], product.title)

            data = await uploadFile(file, config)
        }

        if (data && data.key || isAWSImage) {
            let newProdData = {
                aws_image_link: isAWSImage ? image.path : data.key,
                dropbox_image_link: isAWSImage ? null : image.path,
                buyers_guide_id: product.buyers_guide_id,
                stage_added: product.stage_added
            }

            dispatch(editProduct(product.staged_product_id, newProdData));
        }

    } catch (err) {
        console.log(err)
    }
};


export const createBuyersGuide = (buyersGuide) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/api/buyersGuide/`, {
            buyersGuide
        });

        dispatch({
            type: ADD_BUYERS_GUIDE,
            payload: response.data,
        });
        dispatch(setAlert("buyers guide has been created successfully!", "success"));
        dispatch(getBuyersGuide());
    } catch (err) {
        dispatch(setAlert("buyers guide has not been created", "danger"));
        dispatch({
            type: ADD_BUYERS_GUIDE_ERROR,
            payload: { msg: "Could not add buyers guide" },
        });
    }
}

export const updateBuyersGuide = (data, buyers_guide_id) => async (dispatch) => {
    try {
        await axios.put(`${baseUrl}/api/buyersGuide/${buyers_guide_id}`, {
            data
        });

        dispatch(setAlert("buyers guide has been updated", "success"));
        dispatch(getBuyersGuideByID(buyers_guide_id));
    } catch (err) {
        dispatch(setAlert("buyers guide could not be updated", "danger"));
    }
}

export const deleteBuyersGuide = (id) => async (dispatch) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/buyersGuide/${id}`);

        dispatch({
            type: DELETE_BUYERS_GUIDE,
            payload: response.data,
        });
        dispatch(setAlert("buyers guide has been created deleted!", "success"));
        dispatch(getBuyersGuidePaginated(0));
    } catch (err) {
        dispatch(setAlert("buyers guide has not been deleted", "danger"));
        dispatch({
            type: DELETE_BUYERS_GUIDE_ERROR,
            payload: { msg: "Could not delete buyers guide" },
        });
    }
}

//selects a single evaluation
export const selectBuyersGuide = (buyersGuide) => (dispatch) => {
    dispatch({
        type: SELECT_BUYERS_GUIDE,
        payload: buyersGuide,
    });
};


//change section
export const handleSectionChange = (newStage, data, newStageData) => async (dispatch) => {

    let id = data.buyers_guide_id


    try {
        await axios.put(`${baseUrl}/api/buyersGuide/updateStage/${id}/${newStage}`);
        if (newStageData.loadNew) {
            dispatch(loadNewSectionData(newStageData))
        }
        else {
            dispatch(getBuyersGuideByID(id));
        }

    } catch (err) {
        dispatch(setAlert("buyers guide coudl not be updated", "danger"));
    }
};

export const loadNewSectionData = (newStageData) => async (dispatch) => {
    try {
        if (newStageData.stage === 3) {
            await axios.put(`${baseUrl}/api/buyersGuide/manufacturer/close-all-forms/${newStageData.buyers_guide_id}`);
        }

        const response = await axios.post(`${baseUrl}/api/buyersGuide/duplicate-stage-data/${newStageData.buyers_guide_id}/${newStageData.stage - 1}`);

        dispatch({
            type: GET_ALL_BUYERS_GUIDE_PRODUCTS,
            payload: response.data,
        });
        dispatch(getBuyersGuideByID(newStageData.buyers_guide_id));
    } catch (err) {
        dispatch({
            type: GET_ALL_BUYERS_GUIDE_PRODUCTS_ERROR,
            payload: { msg: "Could not fetch buyers guide products" },
        });
    }
};