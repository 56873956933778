import {
    ADD_EVENT,
    ADD_EVENT_ERROR,
    GET_ALL_EVENTS,
    GET_ALL_EVENTS_ERROR,
    GET_ALL_PUBLISHED_EVENTS,
    GET_ALL_PUBLISHED_EVENTS_ERROR,
    GET_EVENTS_PAGE,
    GET_EVENTS_PAGE_ERROR,
    SEARCH_EVENTS_PAGE,
    SEARCH_EVENTS_PAGE_ERROR,
    SELECT_EVENT,
    GET_ALL_EVENT_COUNT,
    GET_ALL_EVENT_COUNT_ERROR,
    UPDATE_EVENT,
    UPDATE_EVENT_ERROR,
    DELETE_EVENT,
    DELETE_EVENT_ERROR,
    GET_FEATURED_CONTENT_HOME,
    GET_FEATURED_CONTENT_HOME_ERROR,
    SET_FEATURED_CONTENT_FALSE,
    SET_FEATURED_CONTENT_FALSE_ERROR,
    SET_FEATURED_EVENT,
    SET_FEATURED_EVENT_ERROR
} from "../actions/types";

const initialState = {
    count: null,
    events: [],
    publishedEvents: [],
    eventsSearchPage: [],
    featuredHomeContent: [],
    selectedEvent: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_EVENTS:
        case GET_EVENTS_PAGE:
            return {
                ...state,
                events: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_PUBLISHED_EVENTS:
            return {
                ...state,
                publishedEvents: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_EVENT_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case GET_ALL_EVENT_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case SELECT_EVENT:
            return {
                ...state,
                selectedEvent: payload,
            };
        case SEARCH_EVENTS_PAGE:
            return {
                ...state,
                eventsSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_EVENTS_PAGE_ERROR:
            return {
                ...state,
                eventsSearchPage: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_EVENTS_ERROR:
        case GET_EVENTS_PAGE_ERROR:
            return {
                ...state,
                events: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_PUBLISHED_EVENTS_ERROR:
            return {
                ...state,
                publishedEvents: payload,
                isLoading: false,
                success: false,
            };
        case SET_FEATURED_CONTENT_FALSE:
        case GET_FEATURED_CONTENT_HOME:
            return {
                ...state,
                featuredHomeContent: payload,
                isLoading: false,
                success: true,
            };
        case SET_FEATURED_CONTENT_FALSE_ERROR:
        case GET_FEATURED_CONTENT_HOME_ERROR:
            return {
                ...state,
                featuredHomeContent: payload,
                isLoading: false,
                success: false,
            };
        case SET_FEATURED_EVENT:
        case ADD_EVENT:
        case UPDATE_EVENT:
        case DELETE_EVENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case SET_FEATURED_EVENT_ERROR:
        case ADD_EVENT_ERROR:
        case UPDATE_EVENT_ERROR:
        case DELETE_EVENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
