import React from "react";
import { useEffect, useState } from "react";

import {
  CardContent,
  Grid,
  Breadcrumbs,
  Card,
  FormControl,
  Divider,
  Paper,
  TextField,
  Typography,
  Select,
  MenuItem,
  Button,
  InputLabel,
} from "@mui/material";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getManufacturer2 } from "../../redux/actions/manufacturers";
import { addFeaturedMan } from "../../redux/actions/featuredManufacturers";
import ReplaceOrCancelDialog from "./Dialogs/ReplaceOrCancelDialog";

const FeaturedManufacturerForm = ({
  addFeaturedMan,
  getManufacturer2,
  manufacturer: { manufacturers, manufacturer, isLoading, success },
  featuredManufacturer: { featuredManufacturers },
}) => {
  const [newManu, setNewManu] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [replaceDialog, openReplaceDialog] = useState(false);
  const [replacementManufacturer, setReplacementManufacturer] = useState(null);

  const handleManufacturerSelect = async (e) => {
    const { value } = e.target;
    try {
      getManufacturer2(value);
      setDisabled(false);
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleProductSelect = (e) => {
    const { name, value } = e.target;
    setNewManu({
      ...newManu,
      manufacturerId: manufacturer.manufacturer_id,
      [name]: value,
    });
  };

  const handleDateSelect = (e) => {
    const { name, value } = e.target;
    setNewManu({
      ...newManu,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    //checks to see if it already exists and attemps to replace it in db
    e.preventDefault();
    if (
      featuredManufacturers.some(
        (manu) => manu.manufacturer_id === newManu.manufacturerId
      )
    ) {
      setReplacementManufacturer(
        featuredManufacturers.filter(
          (manu) => manu.manufacturer_id === newManu.manufacturerId
        )
      );
      openReplaceDialog(true);
    } else {
      //just adds if it doesn't
      addFeaturedMan(newManu);
      setNewManu({});
    }
  };

  const handleClose = (e) => {
    e.preventDefault();

    openReplaceDialog(false);
  };

  return (
    !isLoading &&
    success && (
      <Card mb={6} variant="outlined" style={{ boxShadow: "5px 5px 5px grey" }}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            Add Featured Manufacturer
          </Typography>
          <Grid>
            <Divider style={{ marginBottom: "20px" }} />
            <Paper style={{ padding: "2rem" }}>
              <form>
                <FormControl style={{ marginLeft: "2rem", display: 'flex', flexDirection: 'row' }}>
                  <InputLabel variant='standard' id="manufacturer-select">
                    Select Manufacturer
                  </InputLabel>
                  <Select
                    variant='standard'
                    name="manufacturer"
                    defaultValue="Manufacturers"
                    onChange={handleManufacturerSelect}
                    style={{
                      marginTop: "25px",
                      marginRight: "10px",
                      width: "600px",
                    }}
                  >
                    <MenuItem value="Manufacturers" disabled>
                      Select Manufacturer First
                    </MenuItem>
                    {manufacturers &&
                      manufacturers.map((manufacturer, idx) => (
                        <MenuItem
                          key={idx}
                          value={`${manufacturer.manufacturer_id}`}
                          style={{ padding: "5px", cursor: "pointer" }}
                        >
                          {manufacturer.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl style={{ marginLeft: "2rem", display: 'flex', flexDirection: 'row' }}>
                  <InputLabel variant='standard' id="product-select">Select Product</InputLabel>
                  <Select
                    variant='standard'
                    disabled={disabled}
                    name="product1"
                    defaultValue="Products"
                    onChange={handleProductSelect}
                    style={{
                      marginTop: "25px",
                      marginRight: "10px",
                      width: "600px",
                    }}
                  >
                    <MenuItem value="Products" disabled>
                      Select Product
                    </MenuItem>
                    {manufacturer &&
                      manufacturer.Products.map((product, idx) => (
                        <MenuItem
                          key={idx}
                          value={`${product.product_id}`}
                          style={{ padding: "5px", cursor: "pointer" }}
                        >
                          {product.name}
                        </MenuItem>
                      ))}
                  </Select>

                  <TextField
                    variant='standard'
                    id="date1"
                    label="Expiration Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="expiration1"
                    onChange={handleDateSelect}
                    style={{ marginTop: "8px" }}
                  />
                </FormControl>
                <FormControl style={{ marginLeft: "2rem", display: 'flex', flexDirection: 'row' }}>
                  <InputLabel variant='standard' id="product-select">Select Product</InputLabel>
                  <Select
                    variant='standard'
                    disabled={disabled}
                    name="product2"
                    defaultValue="Products"
                    onChange={handleProductSelect}
                    style={{
                      marginTop: "25px",
                      marginRight: "10px",
                      width: "600px",
                    }}
                  >
                    <MenuItem value="Products" disabled>
                      Select Product
                    </MenuItem>
                    {manufacturer &&
                      manufacturer.Products.map((product, idx) => (
                        <MenuItem
                          key={idx}
                          value={`${product.product_id}`}
                          style={{ padding: "5px", cursor: "pointer" }}
                        >
                          {product.name}
                        </MenuItem>
                      ))}
                  </Select>

                  <TextField
                    variant='standard'
                    id="date2"
                    label="Expiration Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="expiration2"
                    onChange={handleDateSelect}
                    style={{ marginTop: "8px" }}
                  />
                </FormControl>
                <FormControl style={{ marginLeft: "2rem", display: 'flex', flexDirection: 'row' }}>
                  <InputLabel variant='standard' id="product-select">Select Product</InputLabel>
                  <Select
                    variant='standard'
                    disabled={disabled}
                    name="product3"
                    defaultValue="Products"
                    onChange={handleProductSelect}
                    style={{
                      marginTop: "25px",
                      marginRight: "10px",
                      width: "600px",
                    }}
                  >
                    <MenuItem value="Products" disabled>
                      Select Product
                    </MenuItem>
                    {manufacturer &&
                      manufacturer.Products.map((product, idx) => (
                        <MenuItem
                          key={idx}
                          value={`${product.product_id}`}
                          style={{ padding: "5px", cursor: "pointer" }}
                        >
                          {product.name}
                        </MenuItem>
                      ))}
                  </Select>

                  <TextField
                    variant='standard'
                    id="date3"
                    label="Expiration Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="expiration3"
                    onChange={handleDateSelect}
                    style={{ marginTop: "8px" }}
                  />
                </FormControl>
              </form>
            </Paper>{" "}
            <Divider style={{ marginTop: "20px" }} />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
              onClick={(e) => handleSubmit(e)}
              disabled={
                !(
                  newManu.manufacturerId &&
                  (newManu.product1 && newManu.expiration1) ||
                  (newManu.product2 && newManu.expiration2) ||
                  (newManu.product3 && newManu.expiration3)
                )
              }
            >
              Submit
            </Button>
          </Grid>
        </CardContent>
        <ReplaceOrCancelDialog
          open={replaceDialog}
          handleClose={handleClose}
          newManu={newManu}
          replacementManufacturer={replacementManufacturer}
        />
      </Card>
    )
  );
};

const mapStateToProps = (state) => ({
  manufacturer: state.manufacturer,
  featuredManufacturer: state.featuredManufacturer,
});

export default connect(mapStateToProps, { getManufacturer2, addFeaturedMan })(
  FeaturedManufacturerForm
);
