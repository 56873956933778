import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Dialog } from "@mui/material";

import DeleteForm from "./DeleteForm";
import EditForm from "./EditForm";

const OptionsDialog = ({
  open,
  handleClose,
  page,
  selectedAction,
  article: { selectedArticle },
}) => {
  useEffect(() => {
    if (!selectedArticle.length) {
      handleClose();
    }
  }, []);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
    >
      {selectedAction === "edit"
        ? selectedArticle.length > 0 && (
          <EditForm handleClose={handleClose} page={page} />
          // <></>
        )
        : selectedAction === "delete"
          ? selectedArticle.length > 0 && (
            <DeleteForm handleClose={handleClose} page={page} />
          )
          : handleClose()}
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  article: state.article,
});

export default connect(mapStateToProps, {})(OptionsDialog);
