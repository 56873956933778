import {
    SELECT_SITE,
} from "./types";


//selects a site
export const selectSite = (site) => (dispatch) => {
    dispatch({
        type: SELECT_SITE,
        payload: site,
    });
};
