import {
  GET_ALL_USERS,
  GET_ALL_USERS_ERROR,
  SELECT_USER,
  GET_ALL_ROLES,
  GET_ROLE,
  ADD_ROLE,
  GET_PERMISSIONS,
  GET_ALL_ROLES_ERROR,
  GET_ROLE_ERROR,
  GET_PERMISSIONS_ERROR,
  ADD_ROLE_ERROR,
} from "../actions/types";

const initialState = {
  active: null,
  inactive: null,
  roles: null,
  permissions: null,
  selectedUser: null,
  selectedRole: null,
  newRole: null,
  isLoading: true,
  success: false,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_USERS:
      return {
        ...state,
        active: payload.filter((user) => user.status === true),
        inactive: payload.filter((user) => user.status === false),
        isLoading: false,
        success: true,
      };
    case GET_ROLE:
      return {
        ...state,
        selectedRole: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_ROLES:
      return {
        ...state,
        roles: payload,
        isLoading: false,
        success: true,
      };
    case GET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
        isLoading: false,
        success: true,
      };
    case SELECT_USER:
      return {
        ...state,
        selectedUser: payload,
      };
    case ADD_ROLE:
      return {
        ...state,
        newRole: payload,
      };
    case GET_ALL_USERS_ERROR:
      return {
        ...state,
        users: payload,
        isLoading: false,
        success: false,
      };
    case GET_ROLE_ERROR:
      return {
        ...state,
        selectedRole: payload,
        isLoading: false,
        success: false,
      };
    case GET_PERMISSIONS_ERROR:
      return {
        ...state,
        permissions: payload,
        isLoading: false,
        success: false,
      };
    case GET_ALL_ROLES_ERROR:
      return {
        ...state,
        roles: payload,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
}
