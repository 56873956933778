import React from "react";
import styled from "styled-components";
// import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "../../vendor/perfect-scrollbar.css";

import { changeInputData } from "../../redux/actions/articleTemplates";

import {
    ListItemText,
    TextField as MuiTextField,
    MenuItem,
    InputLabel
} from "@mui/material";


const TextField = styled(MuiTextField)`
    margin: 0 16px;
    border-radius: 5px;
    
    input {
        height: 20px;
        color: #000;
        padding: 3px;
        border-bottom: none;
        &::before {
            border: none;
        }
    }
   
`;

const TextFieldColor = styled(MuiTextField)`
    margin: 0 16px;
    
    input {
        height: 30px;
        padding: 3px;
        margin: 0;
    }
   
`;

const TemplateText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const TemplateStyles = ({
    changeInputData,
    articleTemplate: { inputtedData, hasTemplate, selectedSection, selectedInput },
}) => {


    const handleAddStyles = (style) => {
        if (!selectedSection) {
            return
        }

        let copiedInputData = inputtedData
        let currentInput = copiedInputData[selectedInput?.component][selectedInput?.index]

        if (!currentInput) {
            return
        }
        let currentStyle
        if (selectedInput?.childIndex !== null) {

            currentStyle = currentInput?.children_styles[selectedInput.childIndex].styles ? JSON.parse(currentInput.children_styles[selectedInput.childIndex].styles) : null
        } else {
            currentStyle = currentInput.styles ? JSON.parse(currentInput.styles) : null
        }

        let newStyle
        if (currentStyle) {
            const existingProp = currentStyle[Object.keys(style)[0]]
            if (existingProp) {
                currentStyle[Object.keys(style)[0]] = Object.values(style)[0]
                newStyle = { ...currentStyle }
            } else {

                newStyle = { ...currentStyle, ...style }
            }

        } else {
            newStyle = { ...style }
        }
        newStyle = newStyle ? newStyle : { ...style }

        if (selectedInput?.childIndex !== null) {
            if (style?.height !== undefined && selectedInput.component?.startsWith('TwoImageI4V')) {
                const otherChild = selectedInput?.childIndex === '0' ? '1' : '0'
                currentInput.children_styles[otherChild] = { name: selectedInput.childName, styles: JSON.stringify(newStyle) }
            }
            currentInput.children_styles[selectedInput?.childIndex] = { name: selectedInput.childName, styles: JSON.stringify(newStyle) }
        } else {
            currentInput.styles = JSON.stringify(newStyle)
        }
        copiedInputData[selectedInput?.component][selectedInput?.index] = currentInput
        changeInputData(copiedInputData)
    }

    const getType = (property) => {
        if (property?.includes('olor')) {
            return 'color'
        } else {
            return 'number'
        }

    }

    let currentStyleOptions = []
    if (selectedInput?.childIndex !== null) {
        currentStyleOptions = selectedSection?.children_styles_options[selectedInput?.childIndex]?.styles
    } else if (selectedInput?.index !== undefined) {
        currentStyleOptions = selectedSection?.style_options
    }

    const getCurrentStyle = (style, is_placeholder) => {
        if (!inputtedData[selectedInput.component]) {
            return ''
        }
        const currentInput = inputtedData[selectedInput.component][selectedInput.index]

        if (!currentInput) {
            return ''
        }

        let currentStyle
        if (selectedInput?.childIndex !== null) {
            currentStyle = currentInput.children_styles[selectedInput.childIndex].styles
        } else if (selectedInput?.index !== undefined) {
            currentStyle = currentInput.styles

        }
        currentStyle = currentStyle ? JSON.parse(currentStyle)[style] : null
        return (style?.includes('olor') || is_placeholder) && currentStyle ? currentStyle : is_placeholder ? '' : undefined
    }

    return (
        <>
            <hr style={{ color: '#eeeeee', width: 'calc(100% - 16px)' }} />

            <TemplateText>Section Edit: </TemplateText>
            <div style={{ margin: '10px 20px' }}>

                {currentStyleOptions?.length > 0 && currentStyleOptions[0] && currentStyleOptions.map((style, i) => {
                    const inputType = getType(style)

                    return style === 'textAlign' ?
                        (
                            <div key={`${style}-${i}`} style={{ color: '#000', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                <InputLabel sx={{ color: '#eeeeee', width: '100%', marginBottom: '2px' }}>
                                    {style + ':'}

                                </InputLabel>
                                <TextField
                                    select
                                    value={selectedInput?.styles ? getCurrentStyle(style, true) : null}
                                    sx={{ width: '250px', backgroundColor: '#eeeeee', color: 'black' }}
                                    onChange={(e) => handleAddStyles({ textAlign: e.target.value })}
                                >
                                    <MenuItem value={'left'}>Left</MenuItem>
                                    <MenuItem value={'center'}>Center</MenuItem>
                                    <MenuItem value={'right'}>Right</MenuItem>
                                </TextField>
                            </div>
                        )

                        : style === 'justifyContent' ?
                            (
                                <div key={`${style}-${i}`} style={{ color: '#000', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                    <InputLabel sx={{ color: '#eeeeee', width: '100%', marginBottom: '2px' }}>
                                        {style + ':'}

                                    </InputLabel>
                                    <TextField
                                        select
                                        value={selectedInput?.styles ? getCurrentStyle(style, true) : null}
                                        sx={{ width: '180px', backgroundColor: '#eeeeee', color: 'black' }}
                                        onChange={(e) => handleAddStyles({ justifyContent: e.target.value })}
                                    >
                                        <MenuItem value={'flex-start'}>Left</MenuItem>
                                        <MenuItem value={'center'}>Center</MenuItem>
                                        <MenuItem value={'flex-end'}>Right</MenuItem>
                                        <MenuItem value={'space-between'}>Separate</MenuItem>
                                    </TextField>
                                </div>
                            )

                            : style === 'objectFit' ?
                                (
                                    <div key={`${style}-${i}`} style={{ color: '#000', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                        <InputLabel style={{ color: '#eeeeee', width: '100%', marginBottom: '2px' }}>
                                            {style + ':'}

                                        </InputLabel>
                                        <TextField
                                            select
                                            value={selectedInput?.styles ? getCurrentStyle(style, true) : null}
                                            sx={{ width: '250px', backgroundColor: '#eeeeee', color: 'black' }}
                                            onChange={(e) => handleAddStyles({ objectFit: e.target.value })}
                                        >
                                            <MenuItem value={'cover'}>Cover</MenuItem>
                                            <MenuItem value={'contain'}>Contain</MenuItem>
                                        </TextField>
                                    </div>
                                )
                                : style === 'fontWeight' ?
                                    (
                                        <div key={`${style}-${i}`} style={{ color: '#000', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                            <InputLabel style={{ color: '#eeeeee', width: '100%', marginBottom: '2px' }}>
                                                {style + ':'}

                                            </InputLabel>
                                            <TextField
                                                select
                                                value={selectedInput?.styles ? getCurrentStyle(style, true) : null}
                                                sx={{ width: '200px', backgroundColor: '#eeeeee', color: 'black' }}
                                                onChange={(e) => handleAddStyles({ fontWeight: e.target.value })}
                                            >
                                                <MenuItem value={'bold'}>Bold</MenuItem>
                                                <MenuItem value={'bolder'}>Bolder</MenuItem>
                                                <MenuItem value={'lighter'}>Lighter</MenuItem>
                                                <MenuItem value={'normal'}>Normal</MenuItem>
                                                <MenuItem value={'100'}>100</MenuItem>
                                                <MenuItem value={'200'}>200</MenuItem>
                                                <MenuItem value={'300'}>300</MenuItem>
                                                <MenuItem value={'400'}>400</MenuItem>
                                                <MenuItem value={'500'}>500</MenuItem>
                                                <MenuItem value={'600'}>600</MenuItem>
                                                <MenuItem value={'700'}>700</MenuItem>
                                                <MenuItem value={'800'}>800</MenuItem>
                                                <MenuItem value={'900'}>900</MenuItem>
                                            </TextField>
                                        </div>
                                    )

                                    :
                                    (
                                        <div key={`${style}-${i}`} style={{ color: '#000', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                            <InputLabel sx={{ color: '#eeeeee', width: '100%', marginBottom: '2px' }}>
                                                {style + ':'}

                                            </InputLabel>
                                            <TextFieldColor

                                                type={inputType}
                                                sx={{
                                                    width: inputType === 'color' ? '50px' : '100px', backgroundColor: inputType === 'color' ? 'transparent' : '#eeeeee', borderRadius: '5px', margin: 0
                                                }}
                                                placeholder={`${selectedInput?.styles ? getCurrentStyle(style, true) : '0px'}`}
                                                value={selectedInput?.styles ? getCurrentStyle(style) : ''}
                                                onChange={(e) => handleAddStyles({ [style]: `${e.target.value}${inputType === 'number' ? 'px' : ''}` })}
                                            />
                                        </div>
                                    )

                })}
            </div>


        </>


    );
};

const mapStateToProps = (state) => ({
    articleTemplate: state.articleTemplate
});

// export default withRouter(connect(mapStateToProps, {
//     changeInputData,
// })(TemplateStyles));
export default connect(mapStateToProps, {
    changeInputData,
})(TemplateStyles);