import {
    ADD_ADVERTISEMENT,
    ADD_ADVERTISEMENT_ERROR,
    GET_ALL_ADVERTISEMENTS,
    GET_ALL_ADVERTISEMENTS_ERROR,
    GET_ADVERTISEMENTS_PAGE,
    GET_ADVERTISEMENTS_PAGE_ERROR,
    SEARCH_ADVERTISEMENTS_PAGE,
    SEARCH_ADVERTISEMENTS_PAGE_ERROR,
    SELECT_ADVERTISEMENT,
    GET_ALL_ADVERTISEMENT_COUNT,
    GET_ALL_ADVERTISEMENT_COUNT_ERROR,
    UPDATE_ADVERTISEMENT,
    UPDATE_ADVERTISEMENT_ERROR,
    DELETE_ADVERTISEMENT,
    DELETE_ADVERTISEMENT_ERROR,
    SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME,
    SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME_ERROR,
    GET_ALL_MANUFACTURER_ADVERTISEMENTS,
    GET_ALL_MANUFACTURER_ADVERTISEMENTS_ERROR,
    GET_MANUFACTURER_ADVERTISEMENTS,
    GET_MANUFACTURER_ADVERTISEMENTS_ERROR,
    ADD_ADVERTISEMENTS_BULK,
    ADD_ADVERTISEMENTS_BULK_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    advertisements: [],
    manufacturerAdvertisements: [],
    specificManufacturerAds: [],
    advertisementsSearchPage: [],
    advertisementsSearchPageByPageName: [],
    selectedAdvertisement: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_ADVERTISEMENTS:
        case GET_ADVERTISEMENTS_PAGE:
            return {
                ...state,
                advertisements: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_MANUFACTURER_ADVERTISEMENTS:
            return {
                ...state,
                manufacturerAdvertisements: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_MANUFACTURER_ADVERTISEMENTS_ERROR:
            return {
                ...state,
                manufacturerAdvertisements: payload,
                isLoading: false,
                success: false,
            };
        case GET_MANUFACTURER_ADVERTISEMENTS:
            return {
                ...state,
                specificManufacturerAds: payload,
                isLoading: false,
                success: true,
            };
        case GET_MANUFACTURER_ADVERTISEMENTS_ERROR:
            return {
                ...state,
                specificManufacturerAds: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_ADVERTISEMENT_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case GET_ALL_ADVERTISEMENT_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case SELECT_ADVERTISEMENT:
            return {
                ...state,
                selectedAdvertisement: payload,
            };
        case SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME:
            return {
                ...state,
                advertisementsSearchPageByPageName: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME_ERROR:
            return {
                ...state,
                advertisementsSearchPageByPageName: payload,
                isLoading: false,
                success: false,
            };
        case SEARCH_ADVERTISEMENTS_PAGE:
            return {
                ...state,
                advertisementsSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_ADVERTISEMENTS_PAGE_ERROR:
            return {
                ...state,
                advertisementsSearchPage: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_ADVERTISEMENTS_ERROR:
        case GET_ADVERTISEMENTS_PAGE_ERROR:
            return {
                ...state,
                advertisements: payload,
                isLoading: false,
                success: false,
            };
        case ADD_ADVERTISEMENT:
        case ADD_ADVERTISEMENTS_BULK:
        case UPDATE_ADVERTISEMENT:
        case DELETE_ADVERTISEMENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case ADD_ADVERTISEMENT_ERROR:
        case ADD_ADVERTISEMENTS_BULK_ERROR:
        case UPDATE_ADVERTISEMENT_ERROR:
        case DELETE_ADVERTISEMENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
