import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";

import {
    getOther,
    selectArticle,
    searchOther,
    getOtherCount,
} from "../redux/actions/articles";
import { setAlert } from "../redux/actions/alerts";
import {
    selectArticleForTemplate,
    selectEvaluationForTemplate,
    selectTemplate
} from "../redux/actions/articleTemplates";

import OptionsDialog from "../components/OnsiteContentForms/OptionsDialog";
import NewDialog from "../components/OnsiteContentForms/NewDialog";
import Loader from "../components/Loader";
import {
    Card,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    MenuItem,
    Menu,
    IconButton,
    Button,
} from "@mui/material";
import Pagination from '@mui/material/Pagination';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchContainer from "../components/StyledComponents/SearchContainer";




const OtherArticlePage = ({
    getOther,
    selectArticle,
    searchOther,
    getOtherCount,
    setAlert,
    selectArticleForTemplate,
    selectEvaluationForTemplate,
    selectTemplate,
    article: {
        other,
        isLoading,
        success,
        otherSearch,
        otherSearchPageCount,
        count,
        selectedArticle,
    },
}) => {
    // const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [selectedAction, setSelectedAction] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [newDialogOpen, setNewDialogOpen] = useState(false);

    useEffect(() => {
        getOther(0);
        getOtherCount();
    }, [getOther, getOtherCount]);

    useEffect(() => {
        selectArticleForTemplate(null)
        selectEvaluationForTemplate(null);
        selectTemplate(null)
    }, [selectArticleForTemplate, selectEvaluationForTemplate, selectTemplate]);

    const searchForCase = (e) => {
        setTimeout(() => {
            if (e.target.value.length < 2) {
                setAlert("Query must be 2 characters or longer", "danger");
                setSearchString(false);
            } else {
                searchOther(e.target.value, 0);
                setSearchString(e.target.value);
            }
        }, 1000);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (e, article) => {
        setAnchorEl(e.currentTarget);
        selectArticle(article);
    };

    const handleDialogOpen = (e) => {
        setSelectedAction(e.target.getAttribute("name"));
        setDialogOpen(true);
        setAnchorEl(null);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handlePageChange = (e, count) => {
        getOther(count - 1);
        setPage(count - 1);
    };

    const handleSearchPageChange = (e, count) => {
        searchOther(searchString, count - 1);
        setPage(count - 1);
    };

    const handleNewDialogClose = () => {
        setNewDialogOpen(false);
    };

    return isLoading && success && !other.length ? (
        <Loader />
    ) : (
        <>
            <Card style={{ boxShadow: "5px 5px 5px grey", display: "flex" }}>
                <SearchContainer
                    placeholder="Search Case Study Title"
                    inputProps={{ "aria-label": "search" }}
                    onKeyUp={(e) => searchForCase(e)}
                />

                <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "200px", margin: "4px" }}
                    onClick={(e) => setNewDialogOpen(true)}
                >
                    Add New
                </Button>
            </Card>
            <br />
            <Card mb={6} variant="outlined" style={{ boxShadow: "5px 5px 5px grey" }}>
                <CardContent pb={1}>
                    <Typography variant="h3" gutterBottom>
                        All Other Articles
                    </Typography>
                </CardContent>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell align="left">Author</TableCell>
                                <TableCell align="left">Manufacturer</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {otherSearch.length > 0 && searchString
                                ? otherSearch.map((filteredArticle, idx) => (
                                    <TableRow key={idx} hover>
                                        <TableCell scope="row">{filteredArticle.title}</TableCell>
                                        <TableCell align="left">
                                            {filteredArticle.OldUser || filteredArticle.User
                                                ? filteredArticle.OldUser
                                                    ? filteredArticle.OldUser.first_name
                                                    : filteredArticle.User.firstName
                                                : null}{" "}
                                            {filteredArticle.OldUser || filteredArticle.User
                                                ? filteredArticle.OldUser
                                                    ? filteredArticle.OldUser.last_name
                                                    : filteredArticle.User.lastName
                                                : null}
                                        </TableCell>
                                        <TableCell align="left">
                                            {" "}
                                            {filteredArticle.ArticleMap
                                                ? filteredArticle.ArticleMap.Product.Manufacturer.name
                                                : "No manufacturer"}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Moment format="MM/DD/YYYY">
                                                {filteredArticle.published_at}
                                            </Moment>
                                        </TableCell>
                                        <TableCell
                                            style={{ fontWeight: "bold", cursor: "pointer" }}
                                        >
                                            <IconButton
                                                onClick={(e) => handleClick(e, filteredArticle)}
                                                id={filteredArticle.article_id}
                                            >
                                                <MoreVertIcon id={filteredArticle.article_id} />
                                            </IconButton>
                                        </TableCell>
                                        <Menu
                                            p={2}
                                            onClose={handleClose}
                                            id="case-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClick={(e) => handleDialogOpen(e)}
                                        >
                                            <MenuItem
                                                name="edit"
                                                style={{
                                                    border: "1px solid lightgrey",
                                                    borderRadius: "5px",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                name="delete"
                                                style={{
                                                    border: "1px solid lightgrey",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </TableRow>
                                ))
                                : other.length > 0 &&
                                !searchString &&
                                other.map((article, idx) => (
                                    <TableRow key={idx} hover>
                                        <TableCell scope="row">{article.title}</TableCell>
                                        <TableCell align="left">
                                            {article.OldUser || article.User
                                                ? article.OldUser
                                                    ? article.OldUser.first_name
                                                    : article.User.firstName
                                                : null}{" "}
                                            {article.OldUser || article.User
                                                ? article.OldUser
                                                    ? article.OldUser.last_name
                                                    : article.User.lastName
                                                : null}
                                        </TableCell>
                                        <TableCell align="left">
                                            {article.ArticleMap
                                                ? article.ArticleMap.Product.Manufacturer.name
                                                : "No manufacturer"}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Moment format="MM/DD/YYYY">
                                                {article.published_at}
                                            </Moment>
                                        </TableCell>
                                        <TableCell
                                            style={{ fontWeight: "bold", cursor: "pointer" }}
                                        >
                                            <IconButton
                                                onClick={(e) => handleClick(e, article)}
                                                id={article.article_id}
                                            >
                                                <MoreVertIcon id={article.article_id} />
                                            </IconButton>
                                        </TableCell>
                                        <Menu
                                            p={2}
                                            onClose={handleClose}
                                            id="case-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClick={(e) => handleDialogOpen(e)}
                                        >
                                            <MenuItem
                                                name="edit"
                                                style={{
                                                    border: "1px solid lightgrey",
                                                    borderRadius: "5px",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                Edit{" "}
                                            </MenuItem>
                                            <MenuItem
                                                name="delete"
                                                style={{
                                                    border: "1px solid lightgrey",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Paper>
                {count && !searchString ? (
                    <Pagination
                        count={Math.ceil(count / 25)}
                        onChange={(e, count) => handlePageChange(e, count)}
                        color="primary"
                        style={{
                            padding: "25px 0",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    />
                ) : (
                    <Pagination
                        count={Math.ceil(otherSearchPageCount / 25)}
                        onChange={(e, count) => handleSearchPageChange(e, count)}
                        color="primary"
                        style={{
                            padding: "25px 0",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    />
                )}
            </Card>
            {other.length > 0 && (
                <NewDialog
                    page={page}
                    handleClose={handleNewDialogClose}
                    open={newDialogOpen}
                    type="other"
                    type_id={0}
                />
            )}
            <OptionsDialog
                selectedAction={selectedAction}
                open={dialogOpen}
                handleClose={handleDialogClose}
                page={page}
                type="other"

            />
        </>
    );
};

const mapStateToProps = (state) => ({
    article: state.article,
});

export default connect(mapStateToProps, {
    getOther,
    selectArticle,
    searchOther,
    getOtherCount,
    setAlert,
    selectArticleForTemplate,
    selectEvaluationForTemplate,
    selectTemplate
})(OtherArticlePage);
