import {
    GET_PRODUCT_SKUS,
    GET_PRODUCT_SKUS_ERROR,
    DELETE_PRODUCT_SKUS,
    DELETE_PRODUCT_SKUS_ERROR,
    ADD_PRODUCT_SKUS,
    ADD_PRODUCT_SKUS_ERROR,
    ADD_SKU_DISTRIBUTORS,
    ADD_SKU_DISTRIBUTORS_ERROR,
    SELECT_SKU,
    UPDATE_PRODUCT_SKU,
    UPDATE_PRODUCT_SKU_ERROR,
    UPDATE_SKU_DISTRIBUTOR,
    UPDATE_SKU_DISTRIBUTOR_ERROR
} from "../actions/types";

const initialState = {
    productSkus: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_PRODUCT_SKUS:
            return {
                ...state,
                productSkus: payload,
                isLoading: false,
                success: true,
            };
        case SELECT_SKU:
            return {
                ...state,
                selectedSku: payload,
            };
        case DELETE_PRODUCT_SKUS:
        case ADD_PRODUCT_SKUS:
        case ADD_SKU_DISTRIBUTORS:
        case UPDATE_PRODUCT_SKU:
        case UPDATE_SKU_DISTRIBUTOR:
            return {
                ...state,
                isLoading: false,
                success: true
            };
        case DELETE_PRODUCT_SKUS_ERROR:
        case ADD_PRODUCT_SKUS_ERROR:
        case ADD_SKU_DISTRIBUTORS_ERROR:
        case UPDATE_PRODUCT_SKU_ERROR:
        case UPDATE_SKU_DISTRIBUTOR_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false
            };
        case GET_PRODUCT_SKUS_ERROR:
            return {
                ...state,
                productSkus: payload,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
