import {
    DXP_ADD_NEW_BLOG_POST,
    DXP_ADD_NEW_BLOG_POST_ERROR,
    DXP_SELECT_SINGLE_BLOG_POST,
    DXP_GET_ALL_BLOG_POSTS_PAGINATED,
    DXP_GET_ALL_BLOG_POSTS_PAGINATED_ERROR,
    DXP_GET_ALL_BLOG_POSTS_COUNT,
    DXP_GET_ALL_BLOG_POSTS_COUNT_ERROR,
    DXP_UPDATE_BLOG_POST,
    DXP_UPDATE_BLOG_POST_ERROR,
    DXP_DELETE_BLOG_POST,
    DXP_DELETE_BLOG_POST_ERROR,
    DXP_SEARCH_BLOG_POSTS_PAGINATED,
    DXP_SEARCH_BLOG_POSTS_PAGINATED_ERROR,
    DXP_SEARCH_BLOG_POSTS_COUNT,
    DXP_SEARCH_BLOG_POSTS_COUNT_ERROR

} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    blogPosts: [],
    publishedBlogPosts: [],
    blogPostsSearchPaginated: [],


    selectedBlogPost: null,
    isLoading: true,
    success: false,
};




export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case DXP_SEARCH_BLOG_POSTS_PAGINATED:
            return {
                ...state,
                blogPostsSearchPaginated: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_BLOG_POSTS_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_ALL_BLOG_POSTS_PAGINATED:
            return {
                ...state,
                blogPosts: payload,
                publishedBlogPosts: payload.filter(post => post.published),
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_BLOG_POSTS_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case DXP_ADD_NEW_BLOG_POST:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_SINGLE_BLOG_POST:
            return {
                ...state,
                selectedBlogPost: payload,
            };
        case DXP_UPDATE_BLOG_POST:
        case DXP_DELETE_BLOG_POST:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_NEW_BLOG_POST_ERROR:
        case DXP_GET_ALL_BLOG_POSTS_COUNT_ERROR:
        case DXP_GET_ALL_BLOG_POSTS_PAGINATED_ERROR:
        case DXP_UPDATE_BLOG_POST_ERROR:
        case DXP_DELETE_BLOG_POST_ERROR:
        case DXP_SEARCH_BLOG_POSTS_COUNT_ERROR:
        case DXP_SEARCH_BLOG_POSTS_PAGINATED_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
