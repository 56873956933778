import {
    ADD_CRITERIA,
    ADD_CRITERIA_ERROR,
    GET_ALL_CRITERIA,
    ALL_CRITERIA_ERROR,
    DELETE_CRITERIA,
    DELETE_CRITERIA_ERROR,
    SELECT_CRITERIA,
    GET_CRITERIA_PAGINATED,
    CRITERIA_PAGINATED_ERROR,
    GET_CRITERIA_COUNT,
    GET_CRITERIA_COUNT_ERROR
} from "../actions/types";

const initialState = {
    criteria: [],
    criteriaCount: null,
    selectedCriteria: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_ALL_CRITERIA:
        case GET_CRITERIA_PAGINATED:
            return {
                ...state,
                criteria: payload,
                isLoading: false,
                success: true,
            };
        case ADD_CRITERIA:
        case DELETE_CRITERIA:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case GET_CRITERIA_COUNT:
            return {
                ...state,
                criteriaCount: payload,
                isLoading: false,
                success: true,
            };
        case SELECT_CRITERIA:
            return {
                ...state,
                selectedCriteria: payload,
            };
        case ALL_CRITERIA_ERROR:
        case CRITERIA_PAGINATED_ERROR:
            return {
                ...state,
                criteria: payload,
                isLoading: false,
                success: false,
            };
        case GET_CRITERIA_COUNT_ERROR:
            return {
                ...state,
                criteriaCount: payload,
                isLoading: false,
                success: false,
            };
        case ADD_CRITERIA_ERROR:
        case DELETE_CRITERIA_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
