import {
    DL_GRANT_COURSE_LICENSE,
    DL_GRANT_COURSE_LICENSE_ERROR,
    DL_GET_COURSE_LICENSES_PAGINATED,
    DL_GET_COURSE_LICENSES_PAGINATED_ERROR,
    DL_GET_COURSE_LICENSES_COUNT,
    DL_GET_COURSE_LICENSES_COUNT_ERROR,
    DL_SEARCH_COURSE_LICENSES_PAGINATED,
    DL_SEARCH_COURSE_LICENSES_PAGINATED_ERROR,
    DL_SEARCH_COURSE_LICENSE_COUNT,
    DL_SEARCH_COURSE_LICENSE_COUNT_ERROR,
    DL_SELECT_COURSE_LICENSE,
    DL_DELETE_COURSE_LICENSE,
    DL_DELETE_COURSE_LICENSE_ERROR,
    DL_GET_COURSE_LICENSE_USERS,
    DL_GET_COURSE_LICENSE_USERS_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    licenses: [],
    searchLicenses: [],
    selectedLicense: null,
    courseLicenses: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_COURSE_LICENSES_PAGINATED:
            return {
                ...state,
                licenses: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_COURSE_LICENSES_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_COURSE_LICENSES_PAGINATED:
            return {
                ...state,
                searchLicenses: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_COURSE_LICENSE_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_COURSE_LICENSE_USERS:
            return {
                ...state,
                courseLicenses: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_COURSE_LICENSE:
            return {
                ...state,
                selectedLicense: payload,
                isLoading: false,
                success: true,
            };
        case DL_GRANT_COURSE_LICENSE:
        case DL_DELETE_COURSE_LICENSE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case DL_GET_COURSE_LICENSES_PAGINATED_ERROR:
            return {
                ...state,
                licenses: [],
                isLoading: false,
                success: false
            }
        case DL_GET_COURSE_LICENSES_COUNT_ERROR:
            return {
                ...state,
                count: null,
                isLoading: false,
                success: false
            }
        case DL_SEARCH_COURSE_LICENSES_PAGINATED_ERROR:
            return {
                ...state,
                searchLicenses: [],
                isLoading: false,
                success: false
            }
        case DL_SEARCH_COURSE_LICENSE_COUNT_ERROR:
            return {
                ...state,
                searchCount: null,
                isLoading: false,
                success: false
            }
        case DL_GET_COURSE_LICENSE_USERS_ERROR:
            return {
                ...state,
                courseLicenses: [],
                isLoading: false,
                success: false
            }
        case DL_GRANT_COURSE_LICENSE_ERROR:
        case DL_DELETE_COURSE_LICENSE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false
            }

        default:
            return state;
    }
}
