import {
    DL_GET_ALL_USERS_PAGINATED,
    DL_GET_ALL_USERS_PAGINATED_ERROR,
    DL_GET_USERS_SEARCH_PAGINATED,
    DL_GET_USERS_SEARCH_PAGINATED_ERROR,
    DL_GET_USER_BY_ID,
    DL_GET_USER_BY_ID_ERROR,
    DL_UPDATE_USER,
    DL_UPDATE_USER_ERROR,
    DL_GET_ALL_USERS,
    DL_GET_ALL_USERS_ERROR,
    DL_SEARCH_USER_EMAILS,
    DL_SEARCH_USER_EMAILS_ERROR,
    DL_BULK_USER,
    DL_BULK_USER_ERROR,
    DL_GET_DASHBOARD_USER_STATS_LOADING,
    DL_GET_DASHBOARD_USER_STATS,
    DL_GET_DASHBOARD_USER_STATS_ERROR,
    DL_GET_USER_TOTAL_STATS,
    DL_GET_USER_TOTAL_STATS_ERROR,
    DL_GET_REVENUE_STATS,
    DL_GET_REVENUE_STATS_ERROR,
} from "../actions/types";

const initialState = {
    allUsersCount: null,
    allUsersPaginated: [],
    searchUsersCount: null,
    searchUsersPaginated: [],
    userEmails: [],
    userById: null,
    allUsers: [],
    isLoading: true,
    success: false,

    dashUserStatsLoading: true,
    dashUserStatsData: null,
    dashUserTotalStatsData: null,
    dashRevenueStatsData: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_REVENUE_STATS:
            return {
                ...state,
                dashRevenueStatsData: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_REVENUE_STATS_ERROR:
            return {
                ...state,
                dashRevenueStatsData: null,
                isLoading: false,
                success: false,
            };
        case DL_GET_USER_TOTAL_STATS:
            return {
                ...state,
                dashUserTotalStatsData: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_USER_TOTAL_STATS_ERROR:
            return {
                ...state,
                dashUserTotalStatsData: null,
                isLoading: false,
                success: false,
            };
        case DL_GET_DASHBOARD_USER_STATS:
            return {
                ...state,
                dashUserStatsData: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_DASHBOARD_USER_STATS_LOADING:
            return {
                ...state,
                dashUserStatsLoading: payload,
            };
        case DL_GET_ALL_USERS:
            return {
                ...state,
                allUsers: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_USERS_PAGINATED:
            return {
                ...state,
                allUsersPaginated: payload.rows,
                allUsersCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DL_GET_USER_BY_ID:
            return {
                ...state,
                userById: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_USERS_SEARCH_PAGINATED:
            return {
                ...state,
                searchUsersPaginated: payload.rows,
                searchUsersCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_USER_EMAILS:
            return {
                ...state,
                userEmails: payload,
                isLoading: false,
                success: true,
            };
        case DL_UPDATE_USER:
        case DL_BULK_USER:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_USER_EMAILS_ERROR:
            return {
                ...state,
                userEmails: [],
                isLoading: false,
                success: false,
            };
        case DL_GET_ALL_USERS_PAGINATED_ERROR:
        case DL_GET_USERS_SEARCH_PAGINATED_ERROR:
        case DL_GET_USER_BY_ID_ERROR:
        case DL_UPDATE_USER_ERROR:
        case DL_BULK_USER_ERROR:
        case DL_GET_DASHBOARD_USER_STATS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
