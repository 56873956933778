import {
    GET_DL_COURSE_SUPPORTERS,
    GET_DL_COURSE_SUPPORTERS_ERROR,
    GET_DL_MANUFACTURERS,
    GET_DL_MANUFACTURERS_ERROR,
    DL_SELECT_MANUFACTURER,
    DL_DELETE_MANUFACTURER,
    DL_DELETE_MANUFACTURER_ERROR,
    DL_ADD_MANUFACTURER,
    DL_ADD_MANUFACTURER_ERROR,
    DL_UPDATE_MANUFACTURER,
    DL_UPDATE_MANUFACTURER_ERROR,
    DL_SEARCH_MANUFACTURERS,
    DL_SEARCH_MANUFACTURERS_ERROR,
    GET_DL_MANUFACTURERS_OPTIONS,
    GET_DL_MANUFACTURERS_OPTIONS_ERROR,
} from "../actions/types";

const initialState = {
    manufacturers: [],
    manufacturerOptions: [],
    manufacturerSearch: [],
    allManufacturers: [],
    selectedManufacturer: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_DL_COURSE_SUPPORTERS:
            return {
                ...state,
                manufacturers: payload,
                isLoading: false,
                success: true,
            };

        case GET_DL_MANUFACTURERS:
            return {
                ...state,
                allManufacturers: payload,
                isLoading: false,
                success: true,
            };
        case GET_DL_MANUFACTURERS_OPTIONS:
            return {
                ...state,
                manufacturerOptions: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_MANUFACTURERS:
            return {
                ...state,
                manufacturerSearch: payload,
                isLoading: false,
                success: true,
            };

        case DL_SELECT_MANUFACTURER:
            return {
                ...state,
                selectedManufacturer: payload,
            };

        case DL_ADD_MANUFACTURER:
        case DL_UPDATE_MANUFACTURER:
        case DL_DELETE_MANUFACTURER:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case GET_DL_COURSE_SUPPORTERS_ERROR:

            return {
                ...state,
                manufacturers: payload,
                isLoading: false,
                success: false,
            };

        case GET_DL_MANUFACTURERS_ERROR:

            return {
                ...state,
                allManufacturers: payload,
                isLoading: false,
                success: false,
            };
        case GET_DL_MANUFACTURERS_OPTIONS_ERROR:

            return {
                ...state,
                manufacturerOptions: [],
                isLoading: false,
                success: false,
            };
        case DL_SEARCH_MANUFACTURERS_ERROR:

            return {
                ...state,
                manufacturerSearch: payload,
                isLoading: false,
                success: false,
            };
        case DL_ADD_MANUFACTURER_ERROR:
        case DL_UPDATE_MANUFACTURER_ERROR:
        case DL_DELETE_MANUFACTURER_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}
