import axios from "axios";
import {
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_UPDATED,
  GET_ALL_PRODUCTS_PAGE,
  ALL_PRODUCTS_ERROR,
  GET_PRODUCT,
  SELECT_PRODUCT,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_PAGE,
  GET_PRODUCT_ERROR,
  GET_FEATURED_PRODUCTS,
  GET_FEATURED_PRODUCTS_ERROR,
  GET_NEW_DEFAULT_PRODUCTS,
  GET_NEW_DEFAULT_PRODUCTS_ERROR,
  SET_NEW_DEFAULT_PRODUCT,
  SET_NEW_DEFAULT_PRODUCT_ERROR,
  SET_NEW_DEFAULT_FALSE,
  SET_NEW_DEFAULT_FALSE_ERROR,
  SET_FEATURED_FALSE,
  SET_FEATURED_FALSE_ERROR,
  SET_FEATURED_PRODUCT,
  GET_NEW_FEATURED_PRODUCTS,
  SET_NEW_FEATURED_PRODUCT,
  SET_NEW_FEATURED_FALSE,
  SET_FEATURED_PRODUCT_ERROR,
  GET_PRODUCT_COUNT,
  GET_PRODUCT_COUNT_ERROR,
  ADD_PRODUCT,
  ADD_PRODUCT_ERROR,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_TAG,
  DELETE_PRODUCT,
  UPDATE_PRODUCT_ERROR,
  DELETE_PRODUCT_ERROR,
  GET_ALL_PRODUCTS_PAGE_ERROR,
  SEARCH_PRODUCTS_ERROR,
  SEARCH_PRODUCTS_PAGE_ERROR,
  GET_NEW_FEATURED_PRODUCTS_ERROR,
  SET_NEW_FEATURED_FALSE_ERROR,
  SET_NEW_FEATURED_PRODUCT_ERROR,
} from "./types";
import { setAlert } from "./alerts";

const baseUrl = process.env.REACT_APP_BASE_URL;

//gets all products
export const getProducts = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/products`);

    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: ALL_PRODUCTS_ERROR,
      payload: { msg: "Could not fetch all products" },
    });
  }
};

//gets all updated Products
export const getProductsUpdated = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/products/recent`);

    dispatch({
      type: GET_ALL_PRODUCTS_UPDATED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: ALL_PRODUCTS_ERROR,
      payload: { msg: "Could not fetch all products" },
    });
  }
};

//gets the total count of products
export const getProductCount = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/products/count`);

    dispatch({
      type: GET_PRODUCT_COUNT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_COUNT_ERROR,
      payload: { msg: "Could not fetch the product count" },
    });
  }
};

//gets all products paginated
export const getProductsPage = (page) => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/products/page/${page}/25`);

    dispatch({
      type: GET_ALL_PRODUCTS_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_PRODUCTS_PAGE_ERROR,
      payload: { msg: "Could not fetch all products" },
    });
  }
};

//gets product by id
export const getProduct = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/products/product/${id}`);

    dispatch({
      type: GET_PRODUCT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_ERROR,
      payload: { msg: "Could not fetch product" },
    });
  }
};

//gets product by id
export const getProduct2 = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/products/product2/${id}`);

    dispatch({
      type: GET_PRODUCT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_ERROR,
      payload: { msg: "Could not fetch product" },
    });
  }
};

//Add new product
export const addProduct = (product, images, newProd, page, distributors, skus) => async (
  dispatch
) => {
  try {
    const response = await axios.post(`${baseUrl}/api/products/`, {
      product,
      images,
      newProd,
    });

    if (response.data.product_id && Object.keys(distributors).length > 0) {
      distributors.forEach(disty => {
        disty.product_id = response.data.product_id
      })
      const productDistributor = await axios.post(`${baseUrl}/api/productDistributor/bulk`, {
        distributors
      });
    }

    if (response.data.product_id && Object.keys(skus).length > 0) {
      skus.forEach(sku => {
        sku.product_id = response.data.product_id
      })
      const productSku = await axios.post(`${baseUrl}/api/productSku/bulk`, {
        skus
      });
    }

    dispatch({
      type: ADD_PRODUCT,
      payload: response.data,
    });
    dispatch(setAlert("Product added successfully!", "success"));
    dispatch(getProductsPage(page));
  } catch (err) {
    dispatch(setAlert("Could not add product", "danger"));
    dispatch({
      type: ADD_PRODUCT_ERROR,
      payload: { msg: "Could not add product" },
    });
  }
};

//update product by id
export const updateProduct = (id, product, page) => async (dispatch) => {
  try {
    const response = await axios.put(`${baseUrl}/api/products/product/${id}`, {
      product,
    });

    if (product.published === false) {
      const unpublishRelatedVideos = await axios.put(`${baseUrl}/api/productvideos/product-cascade/${id}`);
      const unpublishRelatedEvaluation = await axios.put(`${baseUrl}/api/productevaluations/product-cascade/${id}`);
    }

    dispatch({
      type: UPDATE_PRODUCT,
      payload: response.data,
    });
    dispatch(setAlert("Product updated successfully!", "success"));
    dispatch(getProductsPage(page));
  } catch (err) {
    dispatch(setAlert("Could not update product", "danger"));
    dispatch({
      type: UPDATE_PRODUCT_ERROR,
      payload: { msg: "Could not update product" },
    });
  }
};

//update product tag by id
export const updateProductTag = (id, product) => async (dispatch) => {
  try {
    const response = await axios.put(`${baseUrl}/api/products/product/${id}`, {
      product,
    });

    dispatch({
      type: UPDATE_PRODUCT_TAG,
      payload: response.data,
    });
    dispatch(setAlert("Product updated successfully!", "success"));
    dispatch(getProducts());
  } catch (err) {
    dispatch(setAlert("Could not update product", "danger"));
    dispatch({
      type: UPDATE_PRODUCT_ERROR,
      payload: { msg: "Could not update product" },
    });
  }
};

//delete product by id
export const deleteProduct = (id, page) => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/products/${id}`);

    dispatch({
      type: DELETE_PRODUCT,
      payload: response.data,
    });
    dispatch(setAlert("Product deleted successfully!", "success"));
    dispatch(getProductsPage(page));
  } catch (err) {
    dispatch(setAlert("Could not delete product", "danger"));
    dispatch({
      type: DELETE_PRODUCT_ERROR,
      payload: { msg: "Could not delete product" },
    });
  }
};

//search products
export const searchProducts = (product) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/products/productsearch/${product}`
    );

    dispatch({
      type: SEARCH_PRODUCTS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCTS_ERROR,
      payload: [],
    });
  }
};

//search products
export const searchPublishedProducts = (product) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/products/productpublishedsearch/${product}`
    );

    dispatch({
      type: SEARCH_PRODUCTS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCTS_ERROR,
      payload: [],
    });
  }
};

//search products with pagination and count
export const searchProductsPage = (product, page, radioValue) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/products/productsearchpage/${product}/${page}/25/${radioValue}`);
    dispatch({
      type: SEARCH_PRODUCTS_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCTS_PAGE_ERROR,
      payload: [],
    });
  }
};

//selects a single product
export const selectProduct = (product) => (dispatch) => {
  dispatch({
    type: SELECT_PRODUCT,
    payload: product,
  });
};

//gets all featured products
export const getFeaturedProducts = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/products/featured`);

    dispatch({
      type: GET_FEATURED_PRODUCTS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FEATURED_PRODUCTS_ERROR,
      payload: { msg: "Could not fetch all featured products" },
    });
  }
};

//set product as featured for homepage
export const setFeaturedProduct = (id, featuredNewProduct) => async (
  dispatch
) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/products/set-new-featured/${id}`,
      {
        featuredNewProduct,
      }
    );

    dispatch({
      type: SET_FEATURED_PRODUCT,
      payload: response.data,
    });
    dispatch(setAlert("Products added as featured succesfully!", "success"));
    dispatch(getFeaturedProducts());
  } catch (err) {
    dispatch(setAlert("Could not set product as featured", "danger"));
    dispatch({
      type: SET_FEATURED_PRODUCT_ERROR,
      payload: { msg: "Could not set product as featured" },
    });
  }
};

//set all previously featured products as false
export const setFeaturedFalse = () => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/products/set-featured-false`
    );

    dispatch({
      type: SET_FEATURED_FALSE,
      payload: response.data,
    });
    dispatch(getFeaturedProducts());
  } catch (err) {
    dispatch({
      type: SET_FEATURED_FALSE_ERROR,
      payload: { msg: "Could not remove products as featured" },
    });
  }
};

//gets all featured new products
export const getFeaturedNewProducts = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/products/new-product-featured`
    );

    dispatch({
      type: GET_NEW_FEATURED_PRODUCTS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_NEW_FEATURED_PRODUCTS_ERROR,
      payload: { msg: "Could not fetch all featured new products" },
    });
  }
};

//set product as featured for new product page
export const setFeaturedNewProduct = (id, featuredNewProduct) => async (
  dispatch
) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/products/set-new-product-featured/${id}`,
      {
        featuredNewProduct,
      }
    );

    dispatch({
      type: SET_NEW_FEATURED_PRODUCT,
      payload: response.data,
    });
    dispatch(setAlert("Products added as featured succesfully!", "success"));
    dispatch(getFeaturedNewProducts());
  } catch (err) {
    dispatch(setAlert("Could not set product as featured", "danger"));
    dispatch({
      type: SET_NEW_FEATURED_PRODUCT_ERROR,
      payload: { msg: "Could not set product as featured" },
    });
  }
};

//set all previously featured products as false
export const setFeaturedNewFalse = () => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/products/set-new-product-featured-false`
    );

    dispatch({
      type: SET_NEW_FEATURED_FALSE,
      payload: response.data,
    });
    dispatch(getFeaturedNewProducts());
  } catch (err) {
    dispatch({
      type: SET_NEW_FEATURED_FALSE_ERROR,
      payload: { msg: "Could not remove products as featured" },
    });
  }
};

//gets all default new products
export const getNewDefaultProducts = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/products/default-new-products`
    );

    dispatch({
      type: GET_NEW_DEFAULT_PRODUCTS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_NEW_DEFAULT_PRODUCTS_ERROR,
      payload: { msg: "Could not fetch all default new products" },
    });
  }
};

//set product as default for new product page
export const setFeaturedDefaultProduct = (id) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/products/set-new-product-default/${id}`
    );

    dispatch({
      type: SET_NEW_DEFAULT_PRODUCT,
      payload: response.data,
    });
    dispatch(setAlert("Products added as default succesfully!", "success"));
    dispatch(getNewDefaultProducts());
  } catch (err) {
    dispatch(setAlert("Could not set product as default", "danger"));
    dispatch({
      type: SET_NEW_DEFAULT_PRODUCT_ERROR,
      payload: { msg: "Could not set product as default" },
    });
  }
};

//set all previously default products as false
export const setFeaturedDefaultFalse = () => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/products/set-new-product-default-false`
    );

    dispatch({
      type: SET_NEW_DEFAULT_FALSE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: SET_NEW_DEFAULT_FALSE_ERROR,
      payload: { msg: "Could not remove products as default" },
    });
  }
};
