import {
    DL_GET_BANNERS,
    DL_GET_BANNERS_ERROR,
    DL_ADD_BANNERS,
    DL_ADD_BANNERS_ERROR,
    DL_UPDATE_BANNERS,
    DL_UPDATE_BANNERS_ERROR,
    DL_DELETE_BANNERS,
    DL_DELETE_BANNERS_ERROR,
    DL_SELECT_BANNER,
} from "../actions/types";

const initialState = {
    banners: [],
    selectedBanner: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_BANNERS:
            return {
                ...state,
                banners: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_BANNER:
            return {
                ...state,
                selectedBanner: payload,
            };

        case DL_ADD_BANNERS:
        case DL_UPDATE_BANNERS:
        case DL_DELETE_BANNERS:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case DL_GET_BANNERS_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };

        case DL_ADD_BANNERS_ERROR:
        case DL_UPDATE_BANNERS_ERROR:
        case DL_DELETE_BANNERS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
