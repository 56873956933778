import {
    DXP_ADD_CONTACTS,
    DXP_ADD_CONTACTS_ERROR,
    DXP_GET_MAILING_LIST,
    DXP_GET_MAILING_LIST_ERROR,
    DXP_SELECT_MAILING_CONTACT,
    DXP_MAILING_LIST_DELETE_CONTACT,
    DXP_MAILING_LIST_DELETE_CONTACT_ERROR,
    DXP_GET_MAILING_LIST_PAGINATED,
    DXP_GET_MAILING_LIST_PAGINATED_ERROR
} from "../actions/types";

const initialState = {
    count: null,
    mailingList: [],
    mailingListPaginated: [],
    mailingListPaginatedCount: null,
    selectedContact: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_MAILING_LIST_PAGINATED:
            return {
                ...state,
                mailingListPaginated: payload.rows,
                mailingListPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        
        
        case DXP_GET_MAILING_LIST:
            return {
                ...state,
                mailingList: payload,
                isLoading: false,
                success: true,
            };


        case DXP_ADD_CONTACTS:
        case DXP_MAILING_LIST_DELETE_CONTACT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_MAILING_CONTACT:
            return {
                ...state,
                selectedContact: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_MAILING_LIST_ERROR:
            return {
                ...state,
                mailingList: payload,
                isLoading: false,
                success: false,
            };

        case DXP_ADD_CONTACTS_ERROR:
        case DXP_MAILING_LIST_DELETE_CONTACT_ERROR:
        case DXP_GET_MAILING_LIST_PAGINATED_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
