import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Sidebar from "../components/Sidebar";
import Header from "../components/AppBar";
import TemplateSidebar from "../components/Templates/TemplateSidebar";

import { spacing } from '@mui/system';
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  useTheme,
  useMediaQuery
  // withWidth,
} from "@mui/material";

// import { useIsWidthUp } from "@mui/material/withWidth";

const drawerWidth = 258;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const theme = useTheme()

  const useIsWidthUp = (breakpoint) => {
    return useMediaQuery(theme.breakpoints.up(breakpoint))
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const pathname = window?.location?.pathname

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden mdUp={true} implementation="js">
          {pathname === '/onsite-content/template' ?
            <TemplateSidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
            :
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />}
        </Hidden>
        <Hidden mdDown={true} implementation="css">
          {pathname === '/onsite-content/template' ?
            <TemplateSidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
            />
            :
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
            />}
        </Hidden>

      </Drawer>
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent sx={{ padding: useIsWidthUp('lg') ? 12 : 5 }}>
          {children}
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default (Dashboard);
