import {
  GET_ALL_EMBED_FORMS,
  ALL_EMBED_FORMS_ERROR,
  GET_ALL_EMBED_FORMS_PAGE,
  ALL_EMBED_FORMS_PAGE_ERROR,
  GET_EMBED_FORM,
  GET_EMBED_FORM_ERROR,
  SELECT_EMBED_FORM,
  SEARCH_EMBED_FORMS,
  GET_EMBED_FORM_COUNT,
  GET_EMBED_FORM_COUNT_ERROR,
  ADD_EMBED_FORM,
  ADD_EMBED_FORM_ERROR,
  UPDATE_EMBED_FORM,
  DELETE_EMBED_FORM,
  UPDATE_EMBED_FORM_ERROR,
  DELETE_EMBED_FORM_ERROR,
  SEARCH_EMBED_FORMS_ERROR,
  GET_EMBED_FORM_PAGE_DEFAULT,
  GET_EMBED_FORM_PAGE_FEATURED_ERROR,
  GET_EMBED_FORM_PAGE_DEFAULT_ERROR
} from "../actions/types";

const initialState = {
  embedForms: [],
  embedFormSearchPage: [],
  embedFormPageFeatured: [],
  embedFormPageDefault: [],
  associatedEmbedForms: [],
  embedFormSearchPageCount: null,
  embedForm: null,
  newEmbedForm: null,
  isLoading: true,
  success: false,
  count: null,
  selectedEmbedForm: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_EMBED_FORMS:
    case GET_ALL_EMBED_FORMS_PAGE:
      return {
        ...state,
        embedForms: payload,
        isLoading: false,
        success: true,
      };
    case GET_EMBED_FORM:
      return {
        ...state,
        embedForm: payload,
        isLoading: false,
        success: true,
      };
    case SELECT_EMBED_FORM:
      return {
        ...state,
        selectedEmbedForm: payload,
        isLoading: false,
        success: true,
      };
    case SEARCH_EMBED_FORMS:
      return {
        ...state,
        embedFormSearchPage: payload,
        isLoading: false,
        success: true,
      };
    case GET_EMBED_FORM_COUNT:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      };
    case GET_EMBED_FORM_PAGE_DEFAULT:
      return {
        ...state,
        embedFormPageDefault: payload,
        isLoading: false,
        success: true,
      };
    case UPDATE_EMBED_FORM:
    case DELETE_EMBED_FORM:
    case ADD_EMBED_FORM:
    case ALL_EMBED_FORMS_ERROR:
    case ALL_EMBED_FORMS_PAGE_ERROR:
      return {
        ...state,
        embedForms: payload,
        isLoading: false,
        success: false,
      };
    case DELETE_EMBED_FORM_ERROR:
    case UPDATE_EMBED_FORM_ERROR:
    case ADD_EMBED_FORM_ERROR:
    case GET_EMBED_FORM_ERROR:
      return {
        ...state,
        embedForm: payload,
        isLoading: false,
        success: false,
      };
    case GET_EMBED_FORM_COUNT_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: false,
      };
    case SEARCH_EMBED_FORMS_ERROR:
      return {
        ...state,
        embedFormSearchPage: payload,
        isLoading: false,
        success: false,
      };
    case GET_EMBED_FORM_PAGE_FEATURED_ERROR:
      return {
        ...state,
        embedFormPageFeatured: payload,
        isLoading: false,
        success: false,
      };
    case GET_EMBED_FORM_PAGE_DEFAULT_ERROR:
      return {
        ...state,
        embedFormPageDefault: payload,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
}
