import {
    DXP_GET_SYMPOSIUMS,
    DXP_GET_SYMPOSIUMS_ERROR,
    DXP_GET_SELECT_SYMPOSIUM_REGISTRANT,
    DXP_GET_SYMPOSIUM_REGISTRANT_COUNT,
    DXP_GET_SYMPOSIUM_REGISTRANT_COUNT_ERROR,
    DXP_GET_SYMPOSIUM_PAGINATED,
    DXP_GET_SYMPOSIUM_PAGINATED_ERROR,
    DXP_SEARCH_SYMPOSIUM_REGISTRANT_COUNT,
    DXP_SEARCH_SYMPOSIUM_REGISTRANT_COUNT_ERROR,
    DXP_SEARCH_SYMPOSIUM_REGISTRANT_PAGINATED,
    DXP_SEARCH_SYMPOSIUM_REGISTRANT_PAGINATED_ERROR,
    DXP_SYMPOSIUM_MAIL_CERTS,
    DXP_SYMPOSIUM_MAIL_CERTS_ERROR,
    DXP_GET_SYMPOSIUM_COURSES,
    DXP_GET_SYMPOSIUM_COURSES_ERROR,
    DXP_ADD_SYMPOSIUM_CHECKIN,
    DXP_ADD_SYMPOSIUM_CHECKIN_ERROR,
} from "../actions/types";

const initialState = {
    symposiums: [],
    registrants: [],
    searchRegistrants: [],
    symposiumCourses: [],
    nonUsers: [],
    added: false,
    count: null,
    searchCount: null,
    selectedRegistrant: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_SYMPOSIUMS:
            return {
                ...state,
                symposiums: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_SYMPOSIUM_PAGINATED:
            return {
                ...state,
                registrants: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_SYMPOSIUM_REGISTRANT_PAGINATED:
            return {
                ...state,
                searchRegistrants: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_SYMPOSIUM_REGISTRANT_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_SYMPOSIUM_REGISTRANT_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_SELECT_SYMPOSIUM_REGISTRANT:
            return {
                ...state,
                selectedRegistrant: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SYMPOSIUM_MAIL_CERTS:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_SYMPOSIUM_CHECKIN:
            return {
                ...state,
                nonUsers: payload.nonUsers,
                added: payload.added,
                isLoading: false,
                success: true,
            };
        case DXP_GET_SYMPOSIUM_COURSES:
            return {
                ...state,
                symposiumCourses: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_SYMPOSIUMS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_GET_SYMPOSIUM_PAGINATED_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_SEARCH_SYMPOSIUM_REGISTRANT_PAGINATED_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_GET_SYMPOSIUM_REGISTRANT_COUNT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_SEARCH_SYMPOSIUM_REGISTRANT_COUNT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_SYMPOSIUM_MAIL_CERTS_ERROR:
        case DXP_ADD_SYMPOSIUM_CHECKIN_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_GET_SYMPOSIUM_COURSES_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
