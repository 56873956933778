import {
    ADD_BUYERS_GUIDE,
    ADD_BUYERS_GUIDE_ERROR,
    GET_ALL_BUYERS_GUIDE,
    ALL_BUYERS_GUIDE_ERROR,
    GET_SPECIFIC_BUYERS_GUIDE,
    GET_SPECIFIC_BUYERS_GUIDE_ERROR,
    GET_ALL_BUYERS_GUIDE_CATEGORIES,
    GET_ALL_BUYERS_GUIDE_CATEGORIES_ERROR,
    GET_ALL_BUYERS_GUIDE_MANUFACTURERS,
    GET_ALL_BUYERS_GUIDE_MANUFACTURERS_ERROR,
    GET_ALL_BUYERS_GUIDE_PRODUCTS,
    GET_ALL_BUYERS_GUIDE_PRODUCTS_ERROR,
    GET_BUYERS_GUIDE_DROPBOX_IMAGES,
    GET_BUYERS_GUIDE_DROPBOX_IMAGES_ERROR,
    DROPBOX_IMAGE_LOADED,
    DROPBOX_IMAGE_LOADED_ERROR,
    DROPBOX_IMAGE_LOADING_DONE,
    GET_DROPBOX_AUTH,
    GET_DROPBOX_AUTH_ERROR,
    SAVE_DROPBOX_IMAGE,
    DELETE_BUYERS_GUIDE,
    DELETE_BUYERS_GUIDE_ERROR,
    SELECT_BUYERS_GUIDE,
    GET_BUYERS_GUIDE_PAGINATED,
    BUYERS_GUIDE_PAGINATED_ERROR,
    GET_BUYERS_GUIDE_COUNT,
    GET_BUYERS_GUIDE_COUNT_ERROR
} from "../actions/types";

const initialState = {
    buyersGuide: [],
    specificBuyersGuideData: {},
    dataStage: 1,
    buyersGuideCount: null,
    categories: null,
    dropBoxAuthToken: {},
    dropBoxImage: {
        loading: false,
        length: 0,
        processed: 0,
        images: []
    },
    dropBoxImageFound: [],
    manufacturers: null,
    products: null,
    selectedBuyersGuide: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_ALL_BUYERS_GUIDE:
        case GET_BUYERS_GUIDE_PAGINATED:
            return {
                ...state,
                buyersGuide: payload,
                isLoading: false,
                success: true,
            };
        case GET_SPECIFIC_BUYERS_GUIDE:
            return {
                ...state,
                specificBuyersGuideData: payload,
                dataStage: payload.curr_stage < 5 ? 1 : payload.curr_stage === 5 ? 2 : 3,
                categories: null,
                manufacturers: null,
                products: null,
                isLoading: false,
                success: true,
            };
        case ADD_BUYERS_GUIDE:
        case DELETE_BUYERS_GUIDE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case GET_BUYERS_GUIDE_COUNT:
            return {
                ...state,
                buyersGuideCount: payload,
                isLoading: false,
                success: true,
            };
        case SELECT_BUYERS_GUIDE:
            return {
                ...state,
                selectedBuyersGuide: payload,
            };
        case ALL_BUYERS_GUIDE_ERROR:
        case BUYERS_GUIDE_PAGINATED_ERROR:
            return {
                ...state,
                buyersGuide: payload,
                isLoading: false,
                success: false,
            };
        case GET_BUYERS_GUIDE_COUNT_ERROR:
            return {
                ...state,
                buyersGuideCount: payload,
                isLoading: false,
                success: false,
            };
        case GET_SPECIFIC_BUYERS_GUIDE_ERROR:
            return {
                ...state,
                specificBuyersGuideData: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_BUYERS_GUIDE_CATEGORIES:
            return {
                ...state,
                categories: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_BUYERS_GUIDE_CATEGORIES_ERROR:
            return {
                ...state,
                categories: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_BUYERS_GUIDE_MANUFACTURERS:
            return {
                ...state,
                manufacturers: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_BUYERS_GUIDE_MANUFACTURERS_ERROR:
            return {
                ...state,
                manufacturers: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_BUYERS_GUIDE_PRODUCTS:
            return {
                ...state,
                products: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_BUYERS_GUIDE_PRODUCTS_ERROR:
            return {
                ...state,
                products: payload,
                isLoading: false,
                success: false,
            };
        case ADD_BUYERS_GUIDE_ERROR:
        case DELETE_BUYERS_GUIDE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case GET_BUYERS_GUIDE_DROPBOX_IMAGES:
            return {
                ...state,
                dropBoxImage: {
                    ...state.dropBoxImage,
                    loading: true,
                    length: payload
                },
                isLoading: true,
                success: true,
            };
        case GET_BUYERS_GUIDE_DROPBOX_IMAGES_ERROR:
            return {
                ...state,
                dropBoxImage: {
                    ...state.dropBoxImage,
                    loading: false,
                    length: 0,
                    processed: 0,
                    images: []
                },
                isLoading: false,
                success: false,
            };
        case DROPBOX_IMAGE_LOADED:
            return {
                ...state,
                dropBoxImage: {
                    ...state.dropBoxImage,
                    processed: payload.length,
                    images: [
                        ...state.dropBoxImage.images,
                        payload.image
                    ]
                }
            };
        case DROPBOX_IMAGE_LOADED_ERROR:
            return {
                ...state,
                dropBoxImage: {
                    ...state.dropBoxImage,
                    processed: payload.length,
                    images: [
                        ...state.dropBoxImage.images,
                        null
                    ]
                },
            };
        case DROPBOX_IMAGE_LOADING_DONE:
            return {
                ...state,
                dropBoxImageFound: [...state.dropBoxImage.images],
                dropBoxImage: {
                    loading: false,
                    length: 0,
                    processed: 0,
                    images: []
                },
                isLoading: false,
                success: true,
            };
        case GET_DROPBOX_AUTH:
            return {
                ...state,
                dropBoxAuthToken: { ...payload },
                isLoading: false,
                success: true,
            };
        case GET_DROPBOX_AUTH_ERROR:
            return {
                ...state,
                dropBoxAuthToken: {},
                isLoading: false,
                success: false,
            };
        case SAVE_DROPBOX_IMAGE:
            return {
                ...state,
                dropBoxImageFound: [state.dropBoxImage.images.filter((e, i) => i !== payload)],
            };
        default:
            return state;
    }
}
