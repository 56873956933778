import {
    GET_FEATURED_NEWSFEED,
    GET_FEATURED_NEWSFEED_ERROR,
    ADD_FEATURED_NEWSFEED,
    ADD_FEATURED_NEWSFEED_ERROR,
} from "../actions/types";

const initialState = {
    featuredNewsfeed: [],
    isLoading: true,
    success: false,
    count: null,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_FEATURED_NEWSFEED:
            return {
                ...state,
                featuredNewsfeed: payload,
                isLoading: false,
                success: true,
            };

        case ADD_FEATURED_NEWSFEED:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case GET_FEATURED_NEWSFEED_ERROR:
            return {
                ...state,
                featuredNewsfeed: [],
                isLoading: false,
                success: false,
            };
        case ADD_FEATURED_NEWSFEED_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}
