import {
    UPDATE_PRODUCT_IMAGES,
    UPDATE_PRODUCT_IMAGES_ERROR,
    GET_PRODUCT_IMAGES_ERROR,
    GET_PRODUCT_IMAGES,
    DELETE_PRODUCT_IMAGES,
    DELETE_PRODUCT_IMAGES_ERROR,
    ADD_PRODUCT_IMAGES,
    ADD_PRODUCT_IMAGES_ERROR
} from "../actions/types";

const initialState = {
    productImages: [],
    isLoading: true,
    success: false
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_PRODUCT_IMAGES:
            return {
                ...state,
                productImages: payload,
                isLoading: false,
                success: true
            }
        case ADD_PRODUCT_IMAGES:
        case UPDATE_PRODUCT_IMAGES:
        case DELETE_PRODUCT_IMAGES:
            return {
                ...state,
                isLoading: false,
                success: true
            }
        case GET_PRODUCT_IMAGES_ERROR:
            return {
                ...state,
                productImages: payload,
                isLoading: false,
                success: false
            }
        case ADD_PRODUCT_IMAGES_ERROR:
        case UPDATE_PRODUCT_IMAGES_ERROR:
        case DELETE_PRODUCT_IMAGES_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false
            }
        default:
            return state;
    }
}