import axios from "axios";
import {
    GET_TEMPLATES,
    GET_TEMPLATES_ERROR,
    SET_HAS_TEMPLATE,
    SET_HAS_TEMPLATE_ERROR,
    SELECT_ARTICLE_TEMPLATES,
    SELECT_ARTICLE_FOR_TEMPLATE,
    SELECT_EVALUATION_FOR_TEMPLATE,
    SELECT_BLOG_FOR_TEMPLATE,
    SET_HAS_TEMPLATE_SECTION,
    SELECT_ARTICLE_TEMPLATE_SECTION,
    CHANGE_INPUT_DATA,
    GET_ARTICLE_TITLE,
    GET_ARTICLE_TITLE_ERROR,
    SAVE_ARTICLE,
    SAVE_ARTICLE_ERROR,
    SAVE_TEMPLATE,
    SAVE_TEMPLATE_ERROR,
    SET_TEMPLATE_RETURN_URL,
    DELETE_TEMPLATE,
    DELETE_TEMPLATE_ERROR,
    SELECT_ARTICLE_TEMPLATE_INPUT,
    CHANGE_TEMPLATE_HISTORY,
    CLEAR_TEMPLATE_HISTORY,
    ADD_TEMPLATE_HISTORY,
    CLEAR_TEMPLATE,
    SHOW_PREVIEW,
    SHOW_PREVIEW_ERROR,
} from "./types";

import config from "../../utils/templateConfig";
import { setAlert } from "./alerts";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getTemplates = (templateType) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/templates/${templateType}`);
        let newTemplates = []

        // Updates old template for new inputs and styles
        for (let template of response.data) {

            let updatedJSON = []
            for (let element of template.json) {
                let component = config.element_key.find(e => e.component_id === element.component_id && e.component_version === element.component_version)
                updatedJSON = [
                    ...updatedJSON,
                    {
                        ...element,
                        children: component.children,
                        children_styles_options: component.children_styles_options,
                        style_options: component.style_options,
                        templates_categories_available: component.templates_categories_available
                    }
                ]
            }
            newTemplates = [...newTemplates, { ...template, json: updatedJSON }]
        }
        dispatch({
            type: GET_TEMPLATES,
            payload: newTemplates,
        });
    } catch (err) {
        dispatch({
            type: GET_TEMPLATES_ERROR,
            payload: { msg: "Could not fetch templates" },
        });
    }
};

export const getTemplateContent = (id, contentType) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/templates/${contentType}/${id}`);
        let inputData = {}
        let sectionData = {}

        if (response?.data) {
            const template = response?.data?.json ? response.data.json : {}
            if (template?.elements?.length > 0) {
                for (let element of template.elements) {
                    let component = config.element_key.find(e => e.component_id === element[0].component_id && e.component_version === element[0].component_version)
                    inputData = { ...inputData, [component.component]: [] }

                    for (let section of element) {

                        sectionData = { ...sectionData, [section.order]: component }

                        let updatedChildren = []
                        let updatedChildrenStyles = []

                        // Adds missing fields if existing data does not have an input
                        for (let i = 0; i < component.children.length; i++) {

                            if (section.children[i]) {
                                updatedChildren = [
                                    ...updatedChildren,
                                    {
                                        ...component.children[i],
                                        ...section.children[i]
                                    }
                                ]
                                updatedChildrenStyles = [
                                    ...updatedChildrenStyles,
                                    {
                                        ...section.children_styles[i]
                                    }
                                ]

                            } else {
                                updatedChildren = [
                                    ...updatedChildren,
                                    {
                                        ...component.children[i]
                                    }
                                ]
                                updatedChildrenStyles = [
                                    ...updatedChildrenStyles,
                                    {
                                        name: component.children_styles_options[i].name,
                                        styles: '',
                                    }
                                ]
                            }
                        }
                        inputData[component.component] = [...inputData[component.component], {
                            ...section,
                            children: updatedChildren,
                            children_styles: updatedChildrenStyles
                        }]
                    }
                }

                const sections = Object.values(sectionData)
                dispatch({
                    type: SELECT_ARTICLE_TEMPLATES,
                    payload: true,
                });
                dispatch(addNewSection(inputData, sections))
                // dispatch(setSections(sections))
                // dispatch(changeInputData(inputData))
                // dispatch(selectTemplate({ template_id: template.template_id, template_name: template.template_type, json: sections }))
            }
            if (template) {
                dispatch({
                    type: SET_HAS_TEMPLATE,
                    payload: true,
                });
            }
        } else {
            dispatch({
                type: SELECT_ARTICLE_TEMPLATES,
                payload: false,
            });
            dispatch({
                type: CLEAR_TEMPLATE
            });
        }
    } catch (err) {
        dispatch({
            type: SET_HAS_TEMPLATE_ERROR,
            payload: { msg: "Could not fetch templates" },
        });
    }
};

export const getArticleTitle = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articles/articleTitle/${id}`);

        dispatch({
            type: GET_ARTICLE_TITLE,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ARTICLE_TITLE_ERROR,
            payload: { msg: "Could not fetch article title" },
        });
    }
};

export const saveArticle = (id, json, publish) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/api/templates/article`, {
            id,
            json,
            publish
        });

        dispatch({
            type: SAVE_ARTICLE,
            payload: response.data,
        });
        dispatch(setAlert('Article template saved', 'success'))
    } catch (err) {
        dispatch({
            type: SAVE_ARTICLE_ERROR,
            payload: { msg: "Could not fetch templates" },
        });
        dispatch(setAlert('Article template was not saved', 'danger'))

    }
};

export const updateArticle = (id, json, publish) => async (dispatch) => {
    try {
        const response = await axios.put(`${baseUrl}/api/templates/article`, {
            id,
            json,
            publish
        });

        dispatch({
            type: SAVE_ARTICLE,
            payload: response.data,
        });
        dispatch(setAlert('Article template updated', 'success'))
    } catch (err) {
        dispatch({
            type: SAVE_ARTICLE_ERROR,
            payload: { msg: "Could not update templates" },
        });
        dispatch(setAlert('Article template was not updated', 'danger'))

    }
};

export const saveEvaluation = (id, json, publish) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/api/templates/evaluation`, {
            id,
            json,
            publish
        });

        dispatch({
            type: SAVE_ARTICLE,
            payload: response.data,
        });
        dispatch(setAlert('Evaluation template saved', 'success'))
    } catch (err) {
        dispatch({
            type: SAVE_ARTICLE_ERROR,
            payload: { msg: "Could not fetch templates" },
        });
        dispatch(setAlert('Evaluation template was not saved', 'danger'))

    }
};

export const updateEvaluation = (id, json, publish) => async (dispatch) => {
    try {
        const response = await axios.put(`${baseUrl}/api/templates/evaluation`, {
            id,
            json,
            publish
        });

        dispatch({
            type: SAVE_ARTICLE,
            payload: response.data,
        });
        dispatch(setAlert('Evaluation template updated', 'success'))
    } catch (err) {
        dispatch({
            type: SAVE_ARTICLE_ERROR,
            payload: { msg: "Could not update templates" },
        });
        dispatch(setAlert('Evaluation template was not updated', 'danger'))

    }
};

export const saveBlog = (id, json, publish) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/api/templates/blog`, {
            id,
            json,
            publish
        });

        dispatch({
            type: SAVE_ARTICLE,
            payload: response.data,
        });
        dispatch(setAlert('Blog template saved', 'success'))
    } catch (err) {
        dispatch({
            type: SAVE_ARTICLE_ERROR,
            payload: { msg: "Could not fetch templates" },
        });
        dispatch(setAlert('Blog template was not saved', 'danger'))

    }
};

export const updateBlog = (id, json, publish) => async (dispatch) => {
    try {
        const response = await axios.put(`${baseUrl}/api/templates/blog`, {
            id,
            json,
            publish
        });

        dispatch({
            type: SAVE_ARTICLE,
            payload: response.data,
        });
        dispatch(setAlert('Blog template updated', 'success'))
    } catch (err) {
        dispatch({
            type: SAVE_ARTICLE_ERROR,
            payload: { msg: "Could not update templates" },
        });
        dispatch(setAlert('Blog template was not updated', 'danger'))

    }
};

export const saveTemplate = (id, templateType, json, data, publish) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/api/templates/template`, {
            id,
            templateType,
            json,
            data,
            publish
        });

        dispatch({
            type: SAVE_TEMPLATE,
            payload: response.data,
        });
        dispatch(setAlert('Article template saved', 'success'))
    } catch (err) {
        dispatch({
            type: SAVE_TEMPLATE_ERROR,
            payload: { msg: "Could not fetch templates" },
        });
        dispatch(setAlert('Article template was not saved', 'danger'))
    }
};

export const savePreview = (id, json, templateType) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/api/templates/preview`, {
            id,
            json,
            templateType
        });

        dispatch({
            type: SHOW_PREVIEW,
            payload: response.data,
        });
        dispatch(setAlert('Article preview saved', 'success'))
    } catch (err) {
        dispatch({
            type: SHOW_PREVIEW_ERROR,
            payload: { msg: "Could not fetch preview" },
        });
        dispatch(setAlert('Article preview was not saved', 'danger'))
    }
};

export const updatePreview = (id, json, templateType) => async (dispatch) => {
    try {
        const response = await axios.put(`${baseUrl}/api/templates/preview`, {
            id,
            json,
            templateType
        });

        dispatch({
            type: SHOW_PREVIEW,
            payload: response.data,
        });
        dispatch(setAlert('Article preview saved', 'success'))
    } catch (err) {
        dispatch({
            type: SHOW_PREVIEW_ERROR,
            payload: { msg: "Could not fetch preview" },
        });
        dispatch(setAlert('Article preview was not saved', 'danger'))
    }
};

export const clearPreview = (id, json) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_PREVIEW,
            payload: null,
        });
        dispatch(setAlert('Article preview cleared', 'success'))
    } catch (err) {
        dispatch({
            type: SHOW_PREVIEW_ERROR,
            payload: { msg: "Could not clear preview" },
        });
        dispatch(setAlert('Article preview was not cleared', 'danger'))
    }
};

export const deleteTemplate = (id, templateType) => async (dispatch) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/templates/template/${id}`);

        dispatch({
            type: DELETE_TEMPLATE,
            payload: response.data,
        });
        dispatch(setAlert('Template deleted', 'success'))
        dispatch(getTemplates(templateType))
    } catch (err) {
        dispatch({
            type: DELETE_TEMPLATE_ERROR,
            payload: { msg: "Could not fetch templates" },
        });
        dispatch(setAlert('Template was not deleted', 'danger'))
    }
};

export const addNewSection = (data, section) => (dispatch, getState) => {
    const { historyIndex, templateHistory } = getState().articleTemplate

    let currentSectionHistory = historyIndex && historyIndex !== templateHistory?.length - 1 ? templateHistory.slice(0, historyIndex + 1) : historyIndex === 0 ? [templateHistory[0]] : templateHistory

    if (currentSectionHistory?.length > 200) {
        currentSectionHistory = currentSectionHistory.slice(1)
    }
    const newHistory = [...currentSectionHistory, { sections: section, inputData: data }]

    dispatch({
        type: ADD_TEMPLATE_HISTORY,
        payload: {
            data,
            section,
            newHistory
        },
    });
};

export const changeInputData = (data) => (dispatch, getState) => {
    const { historyIndex, templateHistory } = getState().articleTemplate

    let currentInputHistory = historyIndex && historyIndex !== templateHistory?.length - 1 ? templateHistory.slice(0, historyIndex + 1) : historyIndex === 0 ? [templateHistory[0]] : templateHistory
    if (currentInputHistory?.length > 200) {
        currentInputHistory = currentInputHistory.slice(1)
    }
    const newHistory = [...currentInputHistory, { inputData: JSON.parse(JSON.stringify(data)), sections: currentInputHistory?.length > 0 ? currentInputHistory.at(-1)?.sections : [] }]

    dispatch({
        type: CHANGE_INPUT_DATA,
        payload: {
            data,
            newHistory
        },
    });
};

export const selectTemplate = (template) => (dispatch) => {

    dispatch(clearHistory())
    if (template?.json) {
        dispatch(setSections(template.json))
    } else {
        dispatch(clearTemplate())
    }
    dispatch({
        type: SELECT_ARTICLE_TEMPLATES,
        payload: template ? true : false,
    });
};

export const selectArticleForTemplate = (article) => (dispatch) => {

    dispatch({
        type: SELECT_ARTICLE_FOR_TEMPLATE,
        payload: article,
    });
};

export const selectBlogForTemplate = (blog) => (dispatch) => {

    dispatch({
        type: SELECT_BLOG_FOR_TEMPLATE,
        payload: blog,
    });
};

export const selectEvaluationForTemplate = (evaluation) => (dispatch) => {

    dispatch({
        type: SELECT_EVALUATION_FOR_TEMPLATE,
        payload: evaluation,
    });
};

export const setSections = (section) => (dispatch, getState) => {
    const { historyIndex, templateHistory } = getState().articleTemplate
    let currentSectionHistory = (historyIndex && historyIndex !== templateHistory?.length - 1) ? templateHistory.slice(0, historyIndex + 1) : historyIndex === 0 ? [templateHistory[0]] : templateHistory

    if (currentSectionHistory?.length > 200) {
        currentSectionHistory = currentSectionHistory.slice(1)
    }
    const newHistory = [...currentSectionHistory, { sections: section, inputData: currentSectionHistory?.length > 0 ? currentSectionHistory.at(-1)?.inputData : {} }]
    dispatch({
        type: SET_HAS_TEMPLATE_SECTION,
        payload: {
            section,
            newHistory
        },
    });
}

export const selectSection = (section) => (dispatch) => {
    dispatch({
        type: SELECT_ARTICLE_TEMPLATE_SECTION,
        payload: section,
    });
}

export const selectInput = (section) => (dispatch) => {
    dispatch({
        type: SELECT_ARTICLE_TEMPLATE_INPUT,
        payload: section,
    });
}


export const setReturnUrl = (url) => (dispatch) => {
    dispatch({
        type: SET_TEMPLATE_RETURN_URL,
        payload: url,
    });
}

export const clearArticleTemplate = () => (dispatch) => {
    dispatch({
        type: SET_HAS_TEMPLATE,
        payload: null,
    });
}

export const changeTemplateHistory = (index) => (dispatch) => {
    dispatch({
        type: CHANGE_TEMPLATE_HISTORY,
        payload: index
    });
}

export const clearTemplate = () => (dispatch) => {
    dispatch({
        type: CLEAR_TEMPLATE,
    });
}

export const clearHistory = (index) => (dispatch) => {
    dispatch({
        type: CLEAR_TEMPLATE_HISTORY,
        payload: index
    });
}

export const clearArticleTitle = () => (dispatch) => {
    dispatch({
        type: GET_ARTICLE_TITLE,
        payload: null
    });
}


