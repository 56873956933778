import axios from "axios";
import {
  GET_ALL_FEATURED,
  GET_ALL_FEATURED_PAGE,
  ALL_FEATURED_ERROR,
  GET_FEATURED,
  GET_FEATURED_ERROR,
  GET_FEATURED_COUNT,
  GET_FEATURED_COUNT_ERROR,
  ADD_FEATURED,
  ADD_FEATURED_ERROR,
  UPDATE_FEATURED,
  DELETE_FEATURED,
  UPDATE_FEATURED_ERROR,
  DELETE_FEATURED_ERROR,
  GET_ALL_FEATURED_PAGE_ERROR,
  SELECT_FEATURED,
  SELECT_FEATURED_ERROR,
} from "./types";
import { setAlert } from "./alerts";

const baseUrl = process.env.REACT_APP_BASE_URL;

//gets all featured products
export const getFeatured = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/featuredproducts`);

    dispatch({
      type: GET_ALL_FEATURED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: ALL_FEATURED_ERROR,
      payload: { msg: "Could not fetch all featured products" },
    });
  }
};

//gets the total count of featured products
export const getFeaturedCount = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/featuredproduct/count`);

    dispatch({
      type: GET_FEATURED_COUNT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FEATURED_COUNT_ERROR,
      payload: { msg: "Could not fetch the featured product count" },
    });
  }
};

//gets all featured products paginated
export const getFeaturedPage = (page) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/featuredproducts/page/${page}/25`
    );

    dispatch({
      type: GET_ALL_FEATURED_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_FEATURED_PAGE_ERROR,
      payload: { msg: "Could not fetch all featured products" },
    });
  }
};

//gets featured product by id
export const getFeaturedProduct = (categoryId) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/featuredproducts/featuredproduct/${categoryId}`
    );

    dispatch({
      type: GET_FEATURED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FEATURED_ERROR,
      payload: { msg: "Could not fetch featured product" },
    });
  }
};

//Add new featured product
export const addProduct = (featured) => async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/api/featuredproducts/`, {
      featured,
    });

    dispatch({
      type: ADD_FEATURED,
      payload: response.data,
    });
    dispatch(getFeatured());
    dispatch(setAlert("Featured product added successfully!", "success"));
  } catch (err) {
    dispatch(setAlert("Failed to add featured product", "danger"));
    dispatch({
      type: ADD_FEATURED_ERROR,
      payload: { msg: "Could not add featured product" },
    });
  }
};

//update featured product by id
export const updateFeatured = (id, featured) => async (dispatch) => {
  try {
    const response = await axios.put(`${baseUrl}/api/featuredproducts/${id}`, {
      featured,
    });

    dispatch({
      type: UPDATE_FEATURED,
      payload: response.data,
    });
    dispatch(getFeatured());
    dispatch(setAlert("Featured product successfully updated!", "success"));
  } catch (err) {
    dispatch(setAlert("Could not update featured product", "danger"));
    dispatch({
      type: UPDATE_FEATURED_ERROR,
      payload: { msg: "Could not update featured product" },
    });
  }
};

//delete featured product by id
export const deleteFeatured = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/featuredproducts/${id}`
    );

    dispatch({
      type: DELETE_FEATURED,
      payload: response.data,
    });
    dispatch(getFeatured());
    dispatch(setAlert("Featured product successfully deleted!", "success"));
  } catch (err) {
    dispatch(setAlert("Could not delete featured product", "danger"));
    dispatch({
      type: DELETE_FEATURED_ERROR,
      payload: { msg: "Could not delete featured product" },
    });
  }
};

//select a featured product from table
export const selectFeatured = (product) => (dispatch) => {
  dispatch({
    type: SELECT_FEATURED,
    payload: product,
  });
};
