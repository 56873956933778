import {
    DL_GET_COMPLETED_COURSE_PAGINATED,
    DL_GET_COMPLETED_COURSE_PAGINATED_ERROR,
    DL_GET_COMPLETED_COURSE_COUNT,
    DL_GET_COMPLETED_COURSE_COUNT_ERROR

} from "../actions/types";

const initialState = {
    completedCoursesPaginated: [],
    completedCoursesPaginatedCount: null,
    completedCourseTotalCount: {},
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_COMPLETED_COURSE_PAGINATED:
            return {
                ...state,
                completedCoursesPaginated: payload?.rows,
                completedCoursesPaginatedCount: payload?.count,
                isLoading: false,
                success: true,
            };
        
        case DL_GET_COMPLETED_COURSE_COUNT:
            return {
                ...state,
                completedCourseTotalCount: payload,
                isLoading: false,
                success: true,
            };

        case DL_GET_COMPLETED_COURSE_PAGINATED_ERROR:
        case DL_GET_COMPLETED_COURSE_COUNT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}