import {
    GET_ALL_PRODUCT_DISTRIBUTORS,
    ALL_PRODUCT_DISTRIBUTORS_ERROR,
    DELETE_PRODUCT_DISTRIBUTORS,
    DELETE_PRODUCT_DISTRIBUTORS_ERROR,
    ADD_PRODUCT_DISTRIBUTORS,
    ADD_PRODUCT_DISTRIBUTORS_ERROR
} from "../actions/types";

const initialState = {
    productDistributor: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_ALL_PRODUCT_DISTRIBUTORS:
            return {
                ...state,
                productDistributor: payload,
                isLoading: false,
                success: true,
            };
        case DELETE_PRODUCT_DISTRIBUTORS:
        case ADD_PRODUCT_DISTRIBUTORS:
            return {
                ...state,
                isLoading: false,
                success: true
            };
        case DELETE_PRODUCT_DISTRIBUTORS_ERROR:
        case ADD_PRODUCT_DISTRIBUTORS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false
            };
        case ALL_PRODUCT_DISTRIBUTORS_ERROR:
            return {
                ...state,
                productDistributor: payload,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
