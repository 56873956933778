import {
    DL_ADD_ARTICLE,
    DL_ADD_ARTICLE_ERROR,
    DL_GET_ARTICLES_PAGE,
    DL_GET_ARTICLES_PAGE_ERROR,
    DL_SEARCH_ARTICLES_PAGE,
    DL_SEARCH_ARTICLES_PAGE_ERROR,
    DL_SELECT_ARTICLE,
    DL_GET_ALL_ARTICLE_COUNT,
    DL_GET_ALL_ARTICLE_COUNT_ERROR,
    DL_GET_ARTICLE_SEARCH_COUNT,
    DL_GET_ARTICLE_SEARCH_COUNT_ERROR,
    DL_UPDATE_ARTICLE,
    DL_UPDATE_ARTICLE_ERROR,
    DL_DELETE_ARTICLE,
    DL_DELETE_ARTICLE_ERROR,
    DL_GET_ALL_ARTICLES,
    DL_GET_ALL_ARTICLES_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    allArticles: [],
    articles: [],
    articleSearchPage: [],
    selectedArticle: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_ALL_ARTICLES:
            return {
                ...state,
                allArticles: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_ARTICLES_PAGE:
            return {
                ...state,
                articles: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_ARTICLES_PAGE:
            return {
                ...state,
                articleSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_ARTICLE_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_ARTICLE_SEARCH_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_ARTICLE:
            return {
                ...state,
                selectedArticle: payload,
            }
        case DL_ADD_ARTICLE:
        case DL_UPDATE_ARTICLE:
        case DL_DELETE_ARTICLE:
            return {
                ...state,
                isLoading: false,
                success: true
            }
        case DL_GET_ALL_ARTICLES_ERROR:
            return {
                ...state,
                allArticles: [],
                isLoading: false,
                success: false,
            };
        case DL_GET_ARTICLES_PAGE_ERROR:
            return {
                ...state,
                articles: [],
                isLoading: false,
                success: false,
            };
        case DL_SEARCH_ARTICLES_PAGE_ERROR:
            return {
                ...state,
                articleSearchPage: [],
                isLoading: false,
                success: false,
            };
        case DL_GET_ALL_ARTICLE_COUNT_ERROR:
            return {
                ...state,
                searchCount: null,
                isLoading: false,
                success: false,
            };
        case DL_GET_ARTICLE_SEARCH_COUNT_ERROR:
            return {
                ...state,
                searchCount: null,
                isLoading: false,
                success: false,
            };
        case DL_ADD_ARTICLE_ERROR:
        case DL_UPDATE_ARTICLE_ERROR:
        case DL_DELETE_ARTICLE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}
