import {
    DXP_ADD_ADVERTISEMENT,
    DXP_ADD_ADVERTISEMENT_ERROR,
    DXP_GET_ALL_ADVERTISEMENTS,
    DXP_GET_ALL_ADVERTISEMENTS_ERROR,
    DXP_GET_ADVERTISEMENTS_PAGE,
    DXP_GET_ADVERTISEMENTS_PAGE_ERROR,
    DXP_SEARCH_ADVERTISEMENTS_PAGE,
    DXP_SEARCH_ADVERTISEMENTS_PAGE_ERROR,
    DXP_SELECT_ADVERTISEMENT,
    DXP_GET_ALL_ADVERTISEMENT_COUNT,
    DXP_GET_ALL_ADVERTISEMENT_COUNT_ERROR,
    DXP_UPDATE_ADVERTISEMENT,
    DXP_UPDATE_ADVERTISEMENT_ERROR,
    DXP_DELETE_ADVERTISEMENT,
    DXP_DELETE_ADVERTISEMENT_ERROR,
    DXP_SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME,
    DXP_SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME_ERROR,
    DXP_GET_ALL_MANUFACTURER_ADVERTISEMENTS,
    DXP_GET_ALL_MANUFACTURER_ADVERTISEMENTS_ERROR,
    DXP_GET_MANUFACTURER_ADVERTISEMENTS,
    DXP_GET_MANUFACTURER_ADVERTISEMENTS_ERROR,
    DXP_ADD_ADVERTISEMENTS_BULK,
    DXP_ADD_ADVERTISEMENTS_BULK_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    advertisements: [],
    manufacturerAdvertisements: [],
    specificManufacturerAds: [],
    advertisementsSearchPage: [],
    advertisementsSearchPageByPageName: [],
    selectedAdvertisement: null,
    pagePlacement: { "All Content": 0, "Free to View": 2, "Xperts": 3, "Category": 4, "All Category": 5, "Everywhere": 6, "Forum": 7, "Manufacturer List": 8, "Event List": 9, "Course": 10, "Article": 11, "Webinar": 12, "Event": 13, "Video": 14 },
    allAds: ["All Content", "Free to View", "Category", "All Category", "Everywhere", "Forum", "Course", "Article", "Webinar", "Video"],
    someAds: ["Manufacturer List"],
    eventAds: ["Event List"],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_ADVERTISEMENTS:
        case DXP_GET_ADVERTISEMENTS_PAGE:
            return {
                ...state,
                advertisements: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_MANUFACTURER_ADVERTISEMENTS:
            return {
                ...state,
                manufacturerAdvertisements: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_MANUFACTURER_ADVERTISEMENTS_ERROR:
            return {
                ...state,
                manufacturerAdvertisements: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_MANUFACTURER_ADVERTISEMENTS:
            return {
                ...state,
                specificManufacturerAds: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_MANUFACTURER_ADVERTISEMENTS_ERROR:
            return {
                ...state,
                specificManufacturerAds: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_ALL_ADVERTISEMENT_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_ALL_ADVERTISEMENT_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case DXP_SELECT_ADVERTISEMENT:
            return {
                ...state,
                selectedAdvertisement: payload,
            };
        case DXP_SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME:
            return {
                ...state,
                advertisementsSearchPageByPageName: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME_ERROR:
            return {
                ...state,
                advertisementsSearchPageByPageName: payload,
                isLoading: false,
                success: false,
            };
        case DXP_SEARCH_ADVERTISEMENTS_PAGE:
            return {
                ...state,
                advertisementsSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_ADVERTISEMENTS_PAGE_ERROR:
            return {
                ...state,
                advertisementsSearchPage: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_ALL_ADVERTISEMENTS_ERROR:
        case DXP_GET_ADVERTISEMENTS_PAGE_ERROR:
            return {
                ...state,
                advertisements: payload,
                isLoading: false,
                success: false,
            };
        case DXP_ADD_ADVERTISEMENT:
        case DXP_ADD_ADVERTISEMENTS_BULK:
        case DXP_UPDATE_ADVERTISEMENT:
        case DXP_DELETE_ADVERTISEMENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_ADVERTISEMENT_ERROR:
        case DXP_ADD_ADVERTISEMENTS_BULK_ERROR:
        case DXP_UPDATE_ADVERTISEMENT_ERROR:
        case DXP_DELETE_ADVERTISEMENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}

