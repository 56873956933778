import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Dialog } from "@mui/material";

import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";

const OptionsDialog = ({
  open,
  handleClose,
  selectedAction,
  featuredManufacturer: { selectedManufacturer },
}) => {
  //leaving edit functionality here but don't plan on using as of right now.
  //The edit component will have to be changed from featured product to manufacuturer if it is to be used in the future
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
    >
      {selectedAction === "edit" && selectedManufacturer ? <EditDialog handleClose={handleClose} />
        : selectedAction === "delete" && selectedManufacturer ? <DeleteDialog handleClose={handleClose} />
          : handleClose()}
    </Dialog>
  );
};

OptionsDialog.propTypes = {};

const mapStateToProps = (state) => ({
  featuredManufacturer: state.featuredManufacturer,
});

export default connect(mapStateToProps, {})(OptionsDialog);
