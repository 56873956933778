import {
    DXP_ADD_FAQ,
    DXP_ADD_FAQ_ERROR,
    DXP_GET_FAQS,
    DXP_GET_FAQS_ERROR,
    DXP_GET_FAQS_PAGE,
    DXP_GET_FAQS_PAGE_ERROR,
    DXP_SELECT_FAQ,
    DXP_GET_ALL_FAQ_COUNT,
    DXP_GET_ALL_FAQ_COUNT_ERROR,
    DXP_UPDATE_FAQ,
    DXP_UPDATE_FAQ_ERROR,
    DXP_DELETE_FAQ,
    DXP_DELETE_FAQ_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    faqs: [],
    selectedFAQ: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_FAQS:
        case DXP_GET_FAQS_PAGE:
            return {
                ...state,
                faqs: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_FAQ_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }

        case DXP_SELECT_FAQ:
            return {
                ...state,
                selectedFAQ: payload,
            };

        case DXP_ADD_FAQ:
        case DXP_UPDATE_FAQ:
        case DXP_DELETE_FAQ:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FAQS_ERROR:
        case DXP_GET_FAQS_PAGE_ERROR:
            return {
                ...state,
                faqs: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_ALL_FAQ_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }

        case DXP_ADD_FAQ_ERROR:
        case DXP_UPDATE_FAQ_ERROR:
        case DXP_DELETE_FAQ_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
