import {
    ADD_INSTRUCTOR,
    ADD_INSTRUCTOR_ERROR,
    GET_ALL_INSTRUCTORS,
    GET_ALL_INSTRUCTORS_ERROR,
    GET_INSTRUCTORS_PAGE,
    GET_INSTRUCTORS_PAGE_ERROR,
    SEARCH_INSTRUCTORS_PAGE,
    SEARCH_INSTRUCTORS_PAGE_ERROR,
    SELECT_INSTRUCTOR,
    GET_ALL_INSTRUCTOR_COUNT,
    GET_ALL_INSTRUCTOR_COUNT_ERROR,
    UPDATE_INSTRUCTOR,
    UPDATE_INSTRUCTOR_ERROR,
    DELETE_INSTRUCTOR,
    DELETE_INSTRUCTOR_ERROR,
    ADD_INSTRUCTOR_ASSOCIATION,
    ADD_INSTRUCTOR_ASSOCIATION_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    instructors: [],
    instructorsSearchPage: [],
    selectedInstructor: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_INSTRUCTORS:
        case GET_INSTRUCTORS_PAGE:
            return {
                ...state,
                instructors: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_INSTRUCTOR_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case GET_ALL_INSTRUCTOR_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case SELECT_INSTRUCTOR:
            return {
                ...state,
                selectedInstructor: payload,
            };
        case SEARCH_INSTRUCTORS_PAGE:
            return {
                ...state,
                instructorsSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_INSTRUCTORS_PAGE_ERROR:
            return {
                ...state,
                instructorsSearchPage: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_INSTRUCTORS_ERROR:
        case GET_INSTRUCTORS_PAGE_ERROR:
            return {
                ...state,
                instructors: payload,
                isLoading: false,
                success: false,
            };
        case ADD_INSTRUCTOR:
        case UPDATE_INSTRUCTOR:
        case DELETE_INSTRUCTOR:
        case ADD_INSTRUCTOR_ASSOCIATION:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case ADD_INSTRUCTOR_ERROR:
        case UPDATE_INSTRUCTOR_ERROR:
        case DELETE_INSTRUCTOR_ERROR:
        case ADD_INSTRUCTOR_ASSOCIATION_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
