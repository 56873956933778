import {
  DL_GET_ALL_EMBED_FORMS,
  DL_ALL_EMBED_FORMS_ERROR,
  DL_GET_ALL_EMBED_FORMS_PAGE,
  DL_ALL_EMBED_FORMS_PAGE_ERROR,
  DL_GET_EMBED_FORM,
  DL_GET_EMBED_FORM_ERROR,
  DL_SELECT_EMBED_FORM,
  DL_SEARCH_EMBED_FORMS,
  DL_GET_EMBED_FORM_COUNT,
  DL_GET_EMBED_FORM_COUNT_ERROR,
  DL_ADD_EMBED_FORM,
  DL_ADD_EMBED_FORM_ERROR,
  DL_UPDATE_EMBED_FORM,
  DL_DELETE_EMBED_FORM,
  DL_UPDATE_EMBED_FORM_ERROR,
  DL_DELETE_EMBED_FORM_ERROR,
  DL_SEARCH_EMBED_FORMS_ERROR,
} from "../actions/types";

const initialState = {
  embedForms: [],
  embedFormSearchPage: [],
  embedFormPageFeatured: [],
  embedFormPageDefault: [],
  associatedEmbedForms: [],
  embedFormSearchPageCount: null,
  embedForm: null,
  newEmbedForm: null,
  isLoading: true,
  success: false,
  count: null,
  selectedEmbedForm: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case DL_GET_ALL_EMBED_FORMS:
    case DL_GET_ALL_EMBED_FORMS_PAGE:
      return {
        ...state,
        embedForms: payload,
        isLoading: false,
        success: true,
      };
    case DL_GET_EMBED_FORM:
      return {
        ...state,
        embedForm: payload,
        isLoading: false,
        success: true,
      };
    case DL_SELECT_EMBED_FORM:
      return {
        ...state,
        selectedEmbedForm: payload,
        isLoading: false,
        success: true,
      };
    case DL_SEARCH_EMBED_FORMS:
      return {
        ...state,
        embedFormSearchPage: payload,
        isLoading: false,
        success: true,
      };
    case DL_GET_EMBED_FORM_COUNT:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      };
    case DL_UPDATE_EMBED_FORM:
    case DL_DELETE_EMBED_FORM:
    case DL_ADD_EMBED_FORM:
    case DL_ALL_EMBED_FORMS_ERROR:
    case DL_ALL_EMBED_FORMS_PAGE_ERROR:
      return {
        ...state,
        embedForms: payload,
        isLoading: false,
        success: false,
      };
    case DL_DELETE_EMBED_FORM_ERROR:
    case DL_UPDATE_EMBED_FORM_ERROR:
    case DL_ADD_EMBED_FORM_ERROR:
    case DL_GET_EMBED_FORM_ERROR:
      return {
        ...state,
        embedForm: payload,
        isLoading: false,
        success: false,
      };
    case DL_GET_EMBED_FORM_COUNT_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: false,
      };
    case DL_SEARCH_EMBED_FORMS_ERROR:
      return {
        ...state,
        embedFormSearchPage: payload,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
}
