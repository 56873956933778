import {
    DXP_GET_ACCOUNT_REGISTRANTS,
    DXP_GET_ACCOUNT_REGISTRANTS_ERROR,
    DXP_GET_REGISTRANT_ENROLLMENT,
    DXP_GET_REGISTRANT_ENROLLMENT_ERROR,
    DXP_GET_FILTERED_ACCOUNT_REGISTRANTS,
    DXP_GET_FILTERED_ACCOUNT_REGISTRANTS_ERROR,
    DXP_SELECT_REGISTRANT,
    DXP_REASSOCIATE_REGISTRANT_ENROLLMENT,
    DXP_REASSOCIATE_REGISTRANT_ENROLLMENT_ERROR,
    DXP_REGISTRANTS_LOADING
} from "../actions/types";

const initialState = {
    registrants: [],
    filteredRegistrants: [],
    registrantEnrollment: {},
    selectedRegistrant: null,
    count: null,
    filteredRegistrantsCount: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ACCOUNT_REGISTRANTS:
            return {
                ...state,
                registrants: payload?.registrants,
                count: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_REGISTRANT_ENROLLMENT:
            return {
                ...state,
                registrantEnrollment: {
                    ...state.registrantEnrollment,
                    [payload.id]: payload.enrollment
                },
                isLoading: false,
                success: true,
            };
        case DXP_GET_FILTERED_ACCOUNT_REGISTRANTS:
            return {
                ...state,
                filteredRegistrants: payload?.registrants ? payload?.registrants : [],
                filteredRegistrantsCount: payload?.count ? payload?.count : 0,
                isLoading: false,
                success: true,
            };
        case DXP_REASSOCIATE_REGISTRANT_ENROLLMENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_REGISTRANT:
            return {
                ...state,
                selectedRegistrant: payload,
            };
        case DXP_REGISTRANTS_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        case DXP_GET_ACCOUNT_REGISTRANTS_ERROR:
            return {
                ...state,
                registrants: [],
                isLoading: false,
                success: false,
            };

        case DXP_GET_REGISTRANT_ENROLLMENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_GET_FILTERED_ACCOUNT_REGISTRANTS_ERROR:
            return {
                ...state,
                filteredRegistrants: [],
                isLoading: false,
                success: false,
            };
        case DXP_REASSOCIATE_REGISTRANT_ENROLLMENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}