import React from "react";

import async from "../components/Async";

import {
    BookOpen,
    Briefcase,
    Calendar as CalendarIcon,
    CheckSquare,
    CreditCard,
    Grid,
    ShoppingCart,
    PieChart,
    Search,
    Sliders,
    Users,
    Award,
    Crosshair,
    Truck,
    Activity,
    Book,
    List,
    Edit,
    Columns,
} from "react-feather";
import PodcastPage from "../views/PodcastPage";
import CEPage from "../views/CEPage";
import InformationPage from "../views/InformationPage";
import OtherArticlePage from "../views/OtherArticlePage";
import NewProductsPageContent from "../views/NewProductsPageContent";
import PromotionsPageContent from "../views/PromotionsPageContent";
import FeaturedManufacturerPage from "../views/FeaturedManufacturerPage";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

//Admin components
const AdminUsers = async(() => import("../views/Administration/AdminUsers"));
const AdminRoles = async(() => import("../views/Administration/AdminRoles"));
const Profile = async(() => import("../components/Administration/Profile"));

//This governs routes for the app. If a route is private, add it to the protected page routes object below and give it the guard:authGuard key value pair
//Anything that isn't login or reset password related will need to get put in that object. Eventually, we'll get rid of anything else and we'll have to login
//to make things work permanently.

//DPS - Dental Product Shopper Pages
const ManageAdsPage = async(() => import("../views/ManageAdsPage"));
const ManufacturerAdsPage = async(() => import("../views/ManufacturerAdsPage"));
const HomePageContent = async(() => import("../views/HomePageContent"));
const ProductCategoryPage = async(() => import("../views/ProductCategoryPage"));
const AddAdvertisementsPage = async(() => import("../views/AddAdvertisementsPage"));
const ArticleTemplatePage = async(() => import("../views/ArticleTemplatePage"));
const BlogsPage = async(() => import("../views/BlogsPage"));
const CaseStudyPage = async(() => import("../views/CaseStudyPage"));
const ResearchPage = async(() => import("../views/ResearchPage"));
const MarketingMaterialsPage = async(() => import("../views/MarketingMaterialsPage"));
const VideoMaterialPage = async(() => import("../views/VideoMaterialPage"));
const EditCategoriesPage = async(() => import("../views/EditCategoriesPage"));
const OnsiteSEOPage = async(() => import("../views/OnsiteSEOPage"));
const DistributorPage = async(() => import("../views/DistributorPage"));
const ManufacturersPage = async(() => import("../views/ManufacturersPage"));
const ProductsPage = async(() => import("../views/ProductsPage"));
const ReviewsPage = async(() => import("../views/Reviews/ReviewsPage"));
const PromotionsPage = async(() => import("../views/PromotionsPage"));
const EvaluationsPage = async(() => import("../views/EvaluationsPage"));
const CriteriaPage = async(() => import("../views/CriteriaPage"));
const PublishedOutlierPage = async(() => import("../views/PublishedOutlierPage"));
const SkusPage = async(() => import("../views/SkusPage"));
const PlacementCheckerPage = async(() => import("../views/PlacementCheckerPage"));
const SitemapPage = async(() => import("../views/SitemapPage"));
const IpUploadPage = async(() => import("../views/IpUploadPage"));
const EmbedFormPage = async(() => import("../views/EmbedFormPage"));
const Page301Redirects = async(() => import("../views/Page301Redirects"));
const FeaturedRecentArticlesPage = async(() => import("../views/FeaturedRecentArticlesPage"));
const FeaturedCategoryContentPage = async(() => import("../views/FeaturedCategoryContentPage"));
const FeaturedNewsfeedPage = async(() => import("../views/FeaturedNewsfeedPage"));
const DynamicPersonasPage = async(() => import("../views/DynamicPersonasPage"))
const TeamMembersPage = async(() => import("../views/TeamMembersPage"))
const FeaturedPopUpPage = async(() => import("../views/FeaturedPopUpPage"))
const BuyersGuidePage = async(() => import("../views/BuyersGuidePage"));
const BuyersGuidePDFView = async(() => import("../views/BuyersGuide/BuyersGuidePDFView"));
const BuyersGuideDropBoxView = async(() => import("../views/BuyersGuide/BuyersGuideDropBoxView"))
const BuyersGuideOldContentView = async(() => import("../views/BuyersGuide/BuyersGuideOldContentView"))
const BuyersGuideNewProductView = async(() => import("../views/BuyersGuide/BuyersGuideNewProductView"))
const BuyersGuideReviewView = async(() => import("../views/BuyersGuide/BuyersGuideReviewView"))
const BuyersGuideClientEmailsView = async(() => import("../views/BuyersGuide/BuyersGuideClientEmailsView"))
const BuyersGuideFinalReviewView = async(() => import("../views/BuyersGuide/BuyersGuideFinalReviewView"))

//DL - Dental Learning Pages
const DashboardDL = async(() => import("../views/DentalLearning/DashboardDL"));
const AdvertisementsPageDL = async(() => import("../views/DentalLearning/AdvertisementsPageDL.jsx"));
const CategoryPageFeatured = async(() => import("../views/DentalLearning/CategoryPageFeatured"));
const CoursesPageDL = async(() => import("../views/DentalLearning/CoursesPageDL"));
const EventsPageDL = async(() => import("../views/DentalLearning/EventsPageDL"));
const BlogsPageDL = async(() => import("../views/DentalLearning/BlogsPageDL"));
const InstructorsPageDL = async(() => import("../views/DentalLearning/InstructorsPageDL"));
const CategoriesPageDL = async(() => import("../views/DentalLearning/CategoriesPageDL"));
const EventLocationsPageDL = async(() => import("../views/DentalLearning/EventLocationsPageDL"));
const TargetAudiencePageDL = async(() => import("../views/DentalLearning/TargetAudiencePageDL"));
const CourseTypesPageDL = async(() => import("../views/DentalLearning/CourseTypesPageDL"));
const AdvertisementsManufacturerPageDL = async(() => import("../views/DentalLearning/AdvertisementsManufacturerPageDL"));
const HomePageFeaturedDL = async(() => import("../views/DentalLearning/HomePageFeaturedDL"));
const EmbedFormPageDL = async(() => import("../views/DentalLearning/EmbedFormPageDL"));
const TakeFivePageDL = async(() => import("../views/DentalLearning/TakeFivePageDL"));
const UsersPageDL = async(() => import("../views/DentalLearning/UsersPageDL"));
const ManufacturerPageDL = async(() => import("../views/DentalLearning/ManufacturerPageDL"));
const ContentFeedPageDL = async(() => import("../views/DentalLearning/ContentFeedPageDL"));
const PopUpPageDL = async(() => import("../views/DentalLearning/PopUpPageDL"));
const FeaturedHomeLoggedDL = async(() => import("../views/DentalLearning/FeaturedHomeLoggedDL"));
const BundlePageDL = async(() => import("../views/DentalLearning/BundlePageDL"));
const TransactionsPageDL = async(() => import("../views/DentalLearning/TransactionsPageDL"));
const SubscriptionsPageDL = async(() => import("../views/DentalLearning/SubscriptionsPageDL"));
const RegistrationsPageDL = async(() => import("../views/DentalLearning/RegistrationsPageDL"));
const SiteBannerDL = async(() => import("../views/DentalLearning/SiteBannerDL"));
const ArticlePageDL = async(() => import("../views/DentalLearning/ArticlePageDL"));
const CourseLicensePageDL = async(() => import("../views/DentalLearning/CourseLicensePageDL"));
const FreeMembershipPageDL = async(() => import("../views/DentalLearning/FreeMembershipPageDL"));
const CompetitorExclusionPageDL = async(() => import("../views/DentalLearning/CompetitorExclusionPageDL"));
const WebhookEventsPageDL = async(() => import("../views/DentalLearning/WebhookEventsPageDL"));
const PodcastPageDL = async(() => import("../views/DentalLearning/PodcastPageDL"));
const PodcastEpisodePageDL = async(() => import("../views/DentalLearning/PodcastEpisodePageDL"));

//DXP - Dental XP
const Dashboard = async(() => import("../views/DentalXP/Dashboard"));
const AdvertisementsPageDXP = async(() => import("../views/DentalXP/AdvertisementsPageDXP"));
const AdvertisementsManufacturerPageDXP = async(() => import("../views/DentalXP/AdvertisementsManufacturerPageDXP"));
const ManufacturersPageDXP = async(() => import("../views/DentalXP/ManufacturersPageDXP"));
const ProductManufacturerDXP = async(() => import("../views/DentalXP/ProductManufacturerDXP"));
const CategoriesPageDXP = async(() => import("../views/DentalXP/CategoriesPageDXP"));
const CoursesPageDXP = async(() => import("../views/DentalXP/CoursesPageDXP"));
const UsersPageDXP = async(() => import("../views/DentalXP/UsersPageDXP"));
const TransactionsPageDXP = async(() => import("../views/DentalXP/TransactionsPageDXP"));
const SubscriptionsPageDXP = async(() => import("../views/DentalXP/SubscriptionsPageDXP"));
const XpertsPageDXP = async(() => import("../views/DentalXP/XpertsPageDxp.jsx"));
const PendingStudentsPageDXP = async(() => import("../views/DentalXP/PendingStudentsPageDXP.jsx"));
const PendingPostsCommentsPageDXP = async(() => import("../views/DentalXP/PendingPostsCommentsPageDXP.jsx"));
const UserGroupsPageDXP = async(() => import("../views/DentalXP/UserGroupsPageDXP.jsx"));
const GroupUsersDXP = async(() => import("../views/DentalXP/GroupUsersDXP.jsx"));
const VideosPageDXP = async(() => import("../views/DentalXP/VideosPageDXP"));
const ArticlesPageDXP = async(() => import("../views/DentalXP/ArticlesPageDXP"));
const BlogsPageDXP = async(() => import("../views/DentalXP/BlogPageDXP"));
const WebinarsPageDXP = async(() => import("../views/DentalXP/WebinarsPageDXP"));
const EventsPageDXP = async(() => import("../views/DentalXP/EventsPageDXP"));
const ExternalEventsPageDXP = async(() => import("../views/DentalXP/ExternalEventsPageDXP"));
const UserRolesPageDXP = async(() => import("../views/DentalXP/UserRolesPageDXP"));
const LanguagesPageDXP = async(() => import("../views/DentalXP/LanguagesPageDXP"));
const HomePageFeaturedDXP = async(() => import("../views/DentalXP/HomePageFeaturedDXP"));
const FeaturedPopUpPageDXP = async(() => import("../views/DentalXP/FeaturedPopUpPageDXP"));
const FeaturedEventDXP = async(() => import("../views/DentalXP/FeaturedEventDXP"));
const FeaturedAllContentDXP = async(() => import("../views/DentalXP/FeaturedAllContentDXP"));
const FeaturedFreePageDXP = async(() => import("../views/DentalXP/FeaturedFreePageDXP"));
const FeaturedXpertListDXP = async(() => import("../views/DentalXP/FeaturedXpertListDXP"));
const FeaturedExternalEventsDXP = async(() => import("../views/DentalXP/FeaturedExternalEventsDXP"));
const FeaturedManufacturerPageDXP = async(() => import("../views/DentalXP/FeaturedManufacturerPageDXP"));
const FeaturedForumPageDXP = async(() => import("../views/DentalXP/FeaturedForumPageDXP"));
const FeaturedCategoryPageDXP = async(() => import("../views/DentalXP/FeaturedCategoryPageDXP"));
const FeaturedSearchPageDXP = async(() => import("../views/DentalXP/FeaturedSearchPageDXP"));
const FeaturedForumCategoryDXP = async(() => import("../views/DentalXP/FeaturedForumCategoryDXP"));
const FeaturedMainBannerDXP = async(() => import("../views/DentalXP/FeaturedMainBannerDXP"));
const FAQPageDXP = async(() => import("../views/DentalXP/FAQPageDXP"));
const CourseHistoryDXP = async(() => import("../views/DentalXP/CourseHistoryDXP"));
const ExpiringContentPageDXP = async(() => import("../views/DentalXP/ExpiringContentPageDXP"));
const Page404DXP = async(() => import("../views/DentalXP/404PageDXP"));
const MailingListDXP = async(() => import("../views/DentalXP/MailingListDXP"));
const SymposiumBillingPageDXP = async(() => import("../views/DentalXP/SymposiumBillingPageDXP"));
const AffiliatePageDXP = async(() => import("../views/DentalXP/AffiliatePageDXP"));
const FeaturedAffiliatePageDXP = async(() => import("../views/DentalXP/FeaturedAffiliatePageDXP"));
const FellowshipsPageDXP = async(() => import("../views/DentalXP/FellowshipsPageDXP"));
const FellowshipTestsPageDXP = async(() => import("../views/DentalXP/FellowshipTestsPageDXP"));
const FellowshipSessionsPageDXP = async(() => import("../views/DentalXP/FellowshipSessionsPageDXP"));
const FellowshipAdditionalProgramsPageDXP = async(() => import("../views/DentalXP/FellowshipAdditionalProgramsPageDXP"));
const FellowshipUserCompletionsPageDXP = async(() => import("../views/DentalXP/FellowshipUserCompletionsPageDXP"));
const FellowshipUserAccessPageDXP = async(() => import("../views/DentalXP/FellowshipUserAccessPageDXP"));
const FellowshipMaterialsPageDXP = async(() => import("../views/DentalXP/FellowshipMaterialsPageDXP"));
const FellowshipTransactionsPageDXP = async(() => import("../views/DentalXP/FellowshipTransactionsPageDXP"));
const RegistrationEventsPageDXP = async(() => import("../views/DentalXP/RegistrationEventsPageDXP"));
const RegistrationUsersPageDXP = async(() => import("../views/DentalXP/RegistrationUsersPageDXP"));
const AuditPageDXP = async(() => import("../views/DentalXP/AuditPageDXP"));
const TrialCodePageDXP = async(() => import("../views/DentalXP/TrialCodePageDXP"));
const FreeMembershipPageDXP = async(() => import("../views/DentalXP/FreeMembershipPageDXP"));
const SentEmailsPageDXP = async(() => import("../views/DentalXP/SentEmailsPageDXP"));

// DPS - dental product shopper page LAYOUTS
const dashboardsRoutes = {
    id: "Featured Content",
    path: "/featured-content",
    icon: <Sliders />,
    // containsHome: true,
    guard: AuthGuard,
    accessParam: 'featuredContent',
    children: [
        {
            path: "/featured-content/home-page",
            name: "Home Page",
            component: HomePageContent,
            guard: AuthGuard,
        },
        {
            path: "/featured-content/product-category-page",
            name: "Product Category Page",
            component: ProductCategoryPage,
            guard: AuthGuard,
        },
        {
            path: "/featured-content/featured-manufacturer-page",
            name: "Manufacturer Page",
            component: FeaturedManufacturerPage,
            guard: AuthGuard,
        },
        {
            path: "/featured-content/promotions-page",
            name: "New Product Page",
            component: NewProductsPageContent,
            guard: AuthGuard,
        },
        {
            path: "/featured-content/new-product-page",
            name: "Promotion Listing Page",
            component: PromotionsPageContent,
            guard: AuthGuard,
        },
        {
            path: "/featured-content/featured-recent-articles",
            name: "Recent Articles",
            component: FeaturedRecentArticlesPage,
            guard: AuthGuard,
        },
        {
            path: "/featured-content/featured-category-content",
            name: "Category Page Content",
            component: FeaturedCategoryContentPage,
            guard: AuthGuard,
        },
        {
            path: "/featured-content/featured-newsfeed",
            name: "Newsfeed",
            component: FeaturedNewsfeedPage,
            guard: AuthGuard,
        },
        {
            path: "/featured-content/featured-popup",
            name: "Pop-ups",
            component: FeaturedPopUpPage,
            guard: AuthGuard,
        },
    ],
    component: null,
};

const AdPlacementRoutes = {
    id: "Ad Placement",
    path: "/manage-ads-page",
    header: "Website Services",
    component: ManageAdsPage,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'adPlacement',
};

const PlacementCheckerRoutes = {
    id: "Placement Checker",
    path: "/placement-checker",
    // header: "Website Services",
    component: PlacementCheckerPage,
    children: null,
    icon: <Activity />,
    guard: AuthGuard,
    accessParam: 'placementChecker',
};

const EditCategoriesPageRoutes = {
    id: "Edit Categories",
    path: "/edit-categories",
    header: "Other Resources",
    component: EditCategoriesPage,
    children: null,
    icon: <Columns />,
    guard: AuthGuard,
    accessParam: 'editCategories',
};

const SitemapPageRoute = {
    id: "Sitemaps",
    path: "/sitemaps",
    component: SitemapPage,
    children: null,
    icon: <Crosshair />,
    guard: AuthGuard,
    accessParam: 'sitemaps',
};

const IpUploadPageRoute = {
    id: "Ip Upload",
    path: "/ip-upload",
    component: IpUploadPage,
    children: null,
    icon: <Crosshair />,
    guard: AuthGuard,
    // accessParam: 'ip-upload',
    accessParam: '',
};


const Page301RedirectsRoute = {
    id: "301 Redirects",
    path: "/redirects",
    component: Page301Redirects,
    children: null,
    icon: <Crosshair />,
    guard: AuthGuard,
    accessParam: 'redirects',
};

const OnsiteSEOPageRoutes = {
    id: "Onsite SEO",
    path: "/onsite-seo",
    component: OnsiteSEOPage,
    children: null,
    icon: <Search />,
    guard: AuthGuard,
    accessParam: 'onsiteSEO',
}

const DistributorPageRoutes = {
    id: "Distributors",
    path: "/distributor",
    component: DistributorPage,
    children: null,
    icon: <Search />,
    guard: AuthGuard,
    accessParam: 'distributor',
}

const EmbedFormRoutes = {
    id: "Embed Forms",
    path: "/embed-form",
    component: EmbedFormPage,
    children: null,
    icon: <Search />,
    guard: AuthGuard,
    accessParam: 'embedForms',
}

const PublishedOutlierPageRoutes = {
    id: "Published Outlier Page",
    path: "/published-outlier-page",
    header: "Miscellaneous",
    component: PublishedOutlierPage,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'publishedOutlier',
};


// Not used at the moment
const AddAdvertisementsRoutes = {
    id: "Add Advertisement",
    path: "/add-advertisement",
    component: AddAdvertisementsPage,
    children: null,
    // icon: <Grid />,
    guard: AuthGuard,
};

const ManufacturerAdsRoutes = {
    id: "ManufacturerAds",
    path: "/manage-ads-page/:id",
    component: ManufacturerAdsPage,
    guard: AuthGuard,
    accessParam: 'adPlacement',
};

const ManufacturersRoutes = {
    id: "Manufacturers",
    icon: <Truck />,
    path: "/manufacturers",
    header: "Manage Onsite Data",
    children: null,
    component: ManufacturersPage,
    guard: AuthGuard,
    accessParam: 'manufacturers',
};

const CriteriaRoutes = {
    id: "Criterion",
    icon: <List />,
    path: "/criteria",
    children: null,
    component: CriteriaPage,
    guard: AuthGuard,
    accessParam: 'criterion',
};

const SkusRoutes = {
    id: "Edit Product Skus",
    icon: <Edit />,
    path: "/product-skus",
    children: null,
    component: SkusPage,
    guard: AuthGuard,
    accessParam: 'editProductSkus',
};

const EvaluationRoutes = {
    id: "Evaluations",
    icon: <Activity />,
    path: "/evaluations",
    children: null,
    component: EvaluationsPage,
    guard: AuthGuard,
    accessParam: 'evaluations',
};

const ProductsRoutes = {
    id: "Products",
    icon: <ShoppingCart />,
    path: "/products",
    children: null,
    component: ProductsPage,
    guard: AuthGuard,
    accessParam: 'products',
};

const PromotionsRoutes = {
    id: "Promotions",
    icon: <Crosshair />,
    path: "/promotions",
    children: null,
    component: PromotionsPage,
    guard: AuthGuard,
    accessParam: 'promotions',
};

const ReviewsRoutes = {
    id: "Reviews",
    icon: <Award />,
    path: "/reviews",
    children: null,
    component: ReviewsPage,
    guard: AuthGuard,
    accessParam: 'reviews',
};

// const BackRoute = {
//   id: "Back to CMS",
//   icon: <ArrowBack />,
//   path: "/",
// }

const ArticleTemplateRoute = {
    id: "Templates",
    path: "/onsite-content/template",
    icon: <BookOpen />,
    children: null,
    component: ArticleTemplatePage,
    accessParam: 'blogsAndArticles',
    guard: AuthGuard,
}

const OnsiteContentRoutes = {
    id: "Blogs And Articles",
    path: "/onsite-content",
    header: "Manage Onsite Content",
    component: ManageAdsPage,
    icon: <BookOpen />,
    guard: AuthGuard,
    accessParam: 'blogsAndArticles',
    children: [
        {
            path: "/onsite-content/blogs",
            name: "Blogs",
            component: BlogsPage,
            guard: AuthGuard,
        },
        {
            path: "/onsite-content/case-study",
            name: "Case Study",
            component: CaseStudyPage,
            guard: AuthGuard,
        },
        {
            path: "/onsite-content/research",
            name: "Research",
            component: ResearchPage,
            guard: AuthGuard,
        },
        {
            path: "/onsite-content/marketing-materials",
            name: "Marketing Materials",
            component: MarketingMaterialsPage,
            guard: AuthGuard,
        },
        {
            path: "/onsite-content/videos",
            name: "Product Videos",
            component: VideoMaterialPage,
            guard: AuthGuard,
        },
        {
            path: "/onsite-content/podcasts",
            name: "Podcasts",
            component: PodcastPage,
            guard: AuthGuard,
        },
        {
            path: "/onsite-content/ce",
            name: "Continuing Education",
            component: CEPage,
            guard: AuthGuard,
        },
        {
            path: "/onsite-content/information",
            name: "Information",
            component: InformationPage,
            guard: AuthGuard,
        },
        {
            path: "/onsite-content/other",
            name: "Other Articles",
            component: OtherArticlePage,
            guard: AuthGuard,
        },
    ],
};

const authRoutes = {
    id: "Auth",
    path: "/auth",
    icon: <Users />,
    children: [
        {
            path: "/",
            name: "Sign In",
            component: SignIn,
        },
        {
            path: "/auth/sign-up",
            name: "Sign Up",
            component: SignUp,
        },
        {
            path: "/auth/reset-password",
            name: "Reset Password",
            component: ResetPassword,
        },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404,
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500,
        },
    ],
    component: null,
};

const adminRoutes = {
    id: "Administration",
    path: "/admin",
    icon: <Users />,
    guard: AuthGuard,
    accessParam: 'siteAdmin',
    children: [
        {
            path: "/admin/users",
            name: "Manage Users",
            component: AdminUsers,
            guard: AuthGuard,
            accessParam: 'addUsers',
        },
        {
            path: "/admin/roles",
            name: "Manage Roles",
            component: AdminRoles,
            guard: AuthGuard,
            accessParam: 'manageRoles',
        },
        {
            path: "/admin/profile",
            name: "Profile",
            component: Profile,
            guard: AuthGuard,
        },
    ],
    component: null,
};




// Dental Learning Routes
const DashboardSectionDL = {
    id: "Dashboard",
    path: "/dl/dashboard",
    component: DashboardDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};


// Dental Learning Page Layouts
const AdPlacementRoutesDL = {
    id: "Ad Placement",
    path: "/manage-ads-dl",
    header: "Website Services",
    component: AdvertisementsPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'adPlacementDL',
};

const FeaturedContentRoutesDL = {
    id: "Featured Content",
    path: "/featured-content-dl",
    icon: <Sliders />,
    // containsHome: true,
    guard: AuthGuard,
    accessParam: 'featuredContentDL',
    children: [
        {
            path: "/featured-content-dl/home-page",
            name: "Home Page",
            component: HomePageFeaturedDL,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dl/home-page-logged",
            name: "Home (Logged In)",
            component: FeaturedHomeLoggedDL,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dl/category-page",
            name: "Category Page",
            component: CategoryPageFeatured,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dl/content-feed",
            name: "Content Feed",
            component: ContentFeedPageDL,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dl/site-banner",
            name: "Site Wide Banner",
            component: SiteBannerDL,
            guard: AuthGuard,
        },
    ],
    component: null,
};

const PopUpRoutesDL = {
    id: "Pop Up",
    path: "/pop-ups-dl",
    component: PopUpPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'adPlacementDL',
};

const CompetitorExclusionRoutesDL = {
    id: "Competitor Exclusions",
    path: "/competitor-exclusion-dl",
    component: CompetitorExclusionPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'manufacturerDL',
};

const OnsiteContentRoutesDL = {
    id: "Courses",
    path: "/courses-dl",
    header: "Manage Onsite Content",
    component: CoursesPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'courses',
};

const EventsRouteDL = {
    id: "Events",
    path: "/events-dl",
    // header: "Manage Onsite Content",
    component: EventsPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'events',
};

const ArticlesRouteDL = {
    id: "Articles",
    path: "/articles-dl",
    component: ArticlePageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};

const BlogsRouteDL = {
    id: "Blogs",
    path: "/blogs-dl",
    // header: "Manage Onsite Content",
    component: BlogsPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'blogsDL',
};

const PodcastsRouteDL = {
    id: "Podcasts",
    path: "/podcasts-dl",
    // header: "Manage Onsite Content",
    component: PodcastPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'blogsDL',
};

const PodcastEpisodesRouteDL = {
    id: "Podcast Episodes",
    path: "/podcast-episodes-dl",
    // header: "Manage Onsite Content",
    component: PodcastEpisodePageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'blogsDL',
};

const InstructorsRouteDL = {
    id: "Instructors",
    path: "/instructors-dl",
    // header: "Manage Onsite Content",
    component: InstructorsPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'instructors',
};

const CategoriesRouteDL = {
    id: "Categories",
    path: "/categories-dl",
    // header: "Manage Onsite Content",
    component: CategoriesPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'categories',
};

const ManufacturersRouteDL = {
    id: "Manufacturers",
    path: "/manufacturers-dl",
    // header: "Manage Onsite Content",
    component: ManufacturerPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'manufacturerDL',
};

const LocationsRouteDL = {
    id: "Event Locations",
    path: "/locations-dl",
    // header: "Manage Onsite Content",
    component: EventLocationsPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'eventLocations',
};

const EmbedFormRouteDL = {
    id: "Embed Form",
    path: "/embed-form-dl",
    component: EmbedFormPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: '',
};

const TargetAudiencesRouteDL = {
    id: "Target Audiences",
    path: "/target-audience-dl",
    // header: "Manage Onsite Content",
    component: TargetAudiencePageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'targetAudiences',
};

const CourseTypesRouteDL = {
    id: "Course Types",
    path: "/course-types-dl",
    // header: "Manage Onsite Content",
    component: CourseTypesPageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'courseTypes',
};

const BundleRouteDL = {
    id: "Bundles",
    path: "/bundles-dl",
    // header: "Manage Onsite Content",
    component: BundlePageDL,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    accessParam: 'bundles',
};


const AdsManufacturerRouteDL = {
    id: "Manufacturers Ads",
    path: "/manage-ads-page-dl/:id",
    component: AdvertisementsManufacturerPageDL,
    guard: AuthGuard,
};

const TakeFiveRouteDL = {
    id: "Take 5 Videos",
    path: "/take-five-videos",
    children: null,
    icon: <Grid />,
    component: TakeFivePageDL,
    guard: AuthGuard,
};

const UsersDL = {
    id: "Users",
    path: "/dl-users",
    children: null,
    icon: <Users />,
    component: UsersPageDL,
    guard: AuthGuard,
};

const CourseLicensesDL = {
    id: "User Course Licenses",
    path: "/dl-user-course-licenses",
    children: null,
    icon: <Users />,
    component: CourseLicensePageDL,
    guard: AuthGuard,
};

const TransactionsDL = {
    id: "Transactions",
    path: "/dl-transactions",
    children: null,
    icon: <Users />,
    component: TransactionsPageDL,
    guard: AuthGuard,
};

const SubscriptionsDL = {
    id: "Subscriptions",
    path: "/dl-subscriptions",
    children: null,
    icon: <Users />,
    component: SubscriptionsPageDL,
    guard: AuthGuard,
};

const FreeMembershipsDL = {
    id: "Free Memberships",
    path: "/dl-free-membership",
    children: null,
    icon: <Users />,
    component: FreeMembershipPageDL,
    guard: AuthGuard,
};

const RegistrationsDL = {
    id: "Registrations",
    path: "/dl-registrations",
    children: null,
    icon: <Users />,
    component: RegistrationsPageDL,
    guard: AuthGuard,
};

const WebhookEventsRouteDL = {
    id: "Webhook Events",
    path: "/dl-webhook-events",
    children: null,
    icon: <Users />,
    component: WebhookEventsPageDL,
    guard: AuthGuard,
};



// Buyers Guide Routes

const BuyersGuide = {
    id: "Buyers Guide",
    icon: <List />,
    path: "/buyers-guide",
    children: null,
    component: BuyersGuidePage,
    guard: AuthGuard,
    accessParam: 'buyersGuide',
};

const BuyersGuidePDF = {
    id: "PDF Upload",
    icon: <List />,
    path: "/buyers-guide/pdf-upload",
    children: null,
    component: BuyersGuidePDFView,
    guard: AuthGuard,
};

const BuyersGuideImageReview = {
    id: "Dropbox Image Review",
    path: "/buyers-guide/image-review",
    children: null,
    component: BuyersGuideDropBoxView,
    guard: AuthGuard,
};

const BuyersGuideInitialReview = {
    id: "Old Content Review",
    path: "/buyers-guide/initial-review",
    children: null,
    component: BuyersGuideOldContentView,
    guard: AuthGuard,
};

const BuyersGuideNewContent = {
    id: "Add New Content",
    path: "/buyers-guide/new-content",
    children: null,
    component: BuyersGuideNewProductView,
    guard: AuthGuard,

};

const BuyersGuideReview = {
    id: "Pre Client Review",
    path: "/buyers-guide/review",
    children: null,
    component: BuyersGuideReviewView,
    guard: AuthGuard,

};

const BuyersGuideEmailPrep = {
    id: "Client Email Prep",
    path: "/buyers-guide/email-prep",
    children: null,
    component: BuyersGuidePDFView,
    guard: AuthGuard,

};

const BuyersGuideClientActivity = {
    id: "Client Activity",
    path: "/buyers-guide/client-activity",
    children: null,
    component: BuyersGuideClientEmailsView,
    guard: AuthGuard,

};

const BuyersGuideFinal = {
    id: "Final Review",
    path: "/buyers-guide/final-review",
    children: null,
    component: BuyersGuideFinalReviewView,
    guard: AuthGuard,

};

const DynamicPersonasRoute = {
    id: "Dynamic Personas",
    path: "/dynamic-personas",
    children: null,
    component: DynamicPersonasPage,
    guard: AuthGuard,
    icon: <Grid />,

};

const TeamMembersRoute = {
    id: "Team Members",
    path: "/ims-team-members",
    children: null,
    component: TeamMembersPage,
    guard: AuthGuard,
    icon: <Grid />,

};




// Dental XP Routes
const DashboardDXP = {
    id: "Dashboard",
    path: "/dxp/dashboard",
    component: Dashboard,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};

const AdPlacementRoutesDXP = {
    id: "Ad Placement",
    path: "/dxp/manage-ads",
    header: "Website Services",
    component: AdvertisementsPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};

const FeaturedContentRoutesDXP = {
    id: "Featured Content",
    path: "/featured-content-dxp",
    icon: <Sliders />,
    // containsHome: true,
    guard: AuthGuard,
    // accessParam: 'featuredContentDXP',
    children: [
        {
            path: "/featured-content-dxp/home-page",
            name: "Home Page",
            component: HomePageFeaturedDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/pop-up",
            name: "Pop-ups",
            component: FeaturedPopUpPageDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/featured-event",
            name: "Featured Event",
            component: FeaturedEventDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/all-content",
            name: "All Content",
            component: FeaturedAllContentDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/free-to-view",
            name: "Free to View",
            component: FeaturedFreePageDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/xperts",
            name: "Xpert List",
            component: FeaturedXpertListDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/external-events",
            name: "External Events",
            component: FeaturedExternalEventsDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/forum-home",
            name: "Forum Home",
            component: FeaturedForumPageDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/category-content",
            name: "Content Category",
            component: FeaturedCategoryPageDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/forum-category",
            name: "Forum Category",
            component: FeaturedForumCategoryDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/manufacturers",
            name: "Manufacturer",
            component: FeaturedManufacturerPageDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/search",
            name: "Search",
            component: FeaturedSearchPageDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/main-banner",
            name: "Main Banner",
            component: FeaturedMainBannerDXP,
            guard: AuthGuard,
        },
        {
            path: "/featured-content-dxp/affilaite-event",
            name: "Affiliate Event",
            component: FeaturedAffiliatePageDXP,
            guard: AuthGuard,
        },
    ],
    component: null,
};

const ExpiringContentRouteDXP = {
    id: "Expiring Content",
    path: "/expiring-content-dxp",
    component: ExpiringContentPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'videos',  Add DXP accessParam
};

const AdsManufacturerRouteDXP = {
    id: "Manufacturers Ads",
    path: "/manage-ads-page-dxp/:id",
    component: AdvertisementsManufacturerPageDXP,
    guard: AuthGuard,
};

const AffiliateRoutesDXP = {
    id: "Affiliates",
    path: "/affiliates-dxp",
    header: "Affiliate Resources",
    component: AffiliatePageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
};

const FellowshipRoutesDXP = {
    id: "Fellowships",
    path: "/fellowships-dxp",
    header: "Fellowships Resources",
    component: FellowshipsPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
};

const FellowshipContentDXP = {
    id: "Content",
    // component: FellowshipsPageDXP,
    children: [
        {
            name: "Sessions",
            path: "/fellowship-sessions-dxp",
            component: FellowshipSessionsPageDXP,
            children: null,
            icon: <Users />,
            guard: AuthGuard,
        },
        {
            name: "Additional Programs",
            path: "/fellowship-additional-programs-dxp",
            component: FellowshipAdditionalProgramsPageDXP,
            children: null,
            icon: <Users />,
            guard: AuthGuard,
        },
        {
            name: "Tests",
            path: "/fellowship-tests-dxp",
            component: FellowshipTestsPageDXP,
            children: null,
            icon: <Users />,
            guard: AuthGuard,
        }
    ],
    icon: <Users />,
    guard: AuthGuard,
};

// const FellowshipSessionRoutesDXP = {
//     id: "Sessions",
//     path: "/fellowship-sessions-dxp",
//     component: FellowshipSessionsPageDXP,
//     children: null,
//     icon: <Users />,
//     guard: AuthGuard,
// };

// const FellowshipAdditionalProgramsRoutesDXP = {
//     id: "Additional Programs",
//     path: "/fellowship-additional-programs-dxp",
//     component: FellowshipAdditionalProgramsPageDXP,
//     children: null,
//     icon: <Users />,
//     guard: AuthGuard,
// };

// const FellowshipTestRoutesDXP = {
//     id: "Tests",
//     path: "/fellowship-tests-dxp",
//     component: FellowshipTestsPageDXP,
//     children: null,
//     icon: <Users />,
//     guard: AuthGuard,
// };

const FellowshipUserCompletionsRoutesDXP = {
    id: "User Completions",
    path: "/fellowship-user-completions-dxp",
    component: FellowshipUserCompletionsPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
};

const FellowshipUserAccessRoutesDXP = {
    id: "User Access",
    path: "/fellowship-user-access-dxp",
    component: FellowshipUserAccessPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
};

const FellowshipMaterialsRoutesDXP = {
    id: "Suplemental Materials",
    path: "/fellowship-materials-dxp",
    component: FellowshipMaterialsPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
};

const FellowshipTransactionsRoutesDXP = {
    id: "Fellowship Transactions",
    path: "/fellowship-transactions-dxp",
    component: FellowshipTransactionsPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
};


const ManufacturersRoutesDXP = {
    id: "Manufacturers",
    path: "/dxp/manage-manufacturers",
    header: "Other Resources",
    component: ManufacturersPageDXP,
    children: null,
    icon: <Truck />,
    guard: AuthGuard,
};

const CategoriesRouteDXP = {
    id: "Categories",
    path: "/categories-dxp",
    component: CategoriesPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'categories',  Add DXP accessParam
};

const OnsiteContentRoutesDXP = {
    id: "Courses",
    path: "/courses-dxp",
    header: "Manage Onsite Content",
    component: CoursesPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'courses',  Add DXP accessParam
};

const VideosDXP = {
    id: "Videos",
    path: "/videos-dxp",
    component: VideosPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'videos',  Add DXP accessParam
};

const ArticlesDXP = {
    id: "Articles",
    path: "/articles-dxp",
    component: ArticlesPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'articles',  Add DXP accessParam
};

const BlogsDXP = {
    id: "Blogs",
    path: "/blogs-dxp",
    component: BlogsPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'articles',  Add DXP accessParam
};

const WebinarsDXP = {
    id: "Webinars",
    path: "/webinars-dxp",
    component: WebinarsPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'webinars',  Add DXP accessParam
};

const EventsDXP = {
    id: "Events",
    path: "/events-dxp",
    component: EventsPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'events',  Add DXP accessParam
};
const ExternalEventsDXP = {
    id: "External Events",
    path: "/external-events-dxp",
    component: ExternalEventsPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'events',  Add DXP accessParam
};

const UserRolesDXP = {
    id: "User Roles",
    path: "/roles-dxp",
    component: UserRolesPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'roles',  Add DXP accessParam
};

const UsersRoutesDXP = {
    id: "Users",
    path: "/users-dxp",
    header: "Manage Users & Xperts",
    component: UsersPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
    // accessParam: 'users',
};

const UserGroupsRoutesDXP = {
    id: "User Groups",
    path: "/user-groups-dxp",
    //header: "Manage Users & Xperts",
    component: UserGroupsPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
    // accessParam: 'users',
};
const GroupUsersRouteDXP = {
    id: "Group Users",
    path: "/group-users-dxp",
    //header: "Manage Users & Xperts",
    component: GroupUsersDXP,
    children: null,
    // icon: <Users />,
    guard: AuthGuard,
    // accessParam: 'users',
};


const CourseHistoryRouteDXP = {
    id: "Course History",
    path: "/course-history-dxp",
    component: CourseHistoryDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'users',
};


const TransactionsRoutesDXP = {
    id: "Transactions",
    path: "/stripe-transactions-dxp",
    header: "Billing",
    component: TransactionsPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
};

const SubscriptionsRoutesDXP = {
    id: "Subscriptions",
    path: "/stripe-subscriptions-dxp",
    //header: "Manage Users & Xperts",
    component: SubscriptionsPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,

};

const AuditSubscriptionsRoutesDXP = {
    id: "Audit",
    path: "/audit-subscriptions-dxp",
    component: AuditPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,

};

const TrialCodeRoutesDXP = {
    id: "Trial Code",
    path: "/trial-code-dxp",
    component: TrialCodePageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,

};

const FreeMembershipRouteDXP = {
    id: "Free Membership",
    path: "/free-membership-dxp",
    component: FreeMembershipPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'users',
};

const XpertsRoutesDXP = {
    id: "Xperts Management",
    path: "/xperts-dxp",
    //header: "Manage Users & Xperts",
    component: XpertsPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
    // accessParam: 'users',
};

const PendingStudentsRoutesDXP = {
    id: "Pending Students",
    path: "/pending-students-dxp",
    //header: "Manage Users & Xperts",
    component: PendingStudentsPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
    // accessParam: 'users',
};

const PendingPostsComments = {
    id: "Pending Posts & Comments",
    path: "/pending-posts-comments-dxp",
    //header: "Manage Users & Xperts",
    component: PendingPostsCommentsPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
    // accessParam: 'users',
};

const LanguagesRouteDXP = {
    id: "Language Management",
    path: "/language-dxp",
    component: LanguagesPageDXP,
    children: null,
    icon: <Users />,
    guard: AuthGuard,
};

const FAQRouteDXP = {
    id: "FAQ",
    path: "/faq-dxp",
    component: FAQPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};

const ProductTableRoutesDXP = {
    id: "Product Manufacturer",
    path: "/product-manufacturer-dxp",
    header: "Product Table",
    component: ProductManufacturerDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};


const Page404RouteDXP = {
    id: "404 Page",
    path: "/404-page-dxp",
    header: "Miscellaneous",
    component: Page404DXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};

const MailingListRouteDXP = {
    id: "Pending Contacts",
    path: "/mailing-list-dxp",
    component: MailingListDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};

const SymposiumBillingRouteDXP = {
    id: "Symposium Billing",
    path: "/symposium-billing-dxp",
    component: SymposiumBillingPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};

const RegistrationEventsRouteDXP = {
    id: "Registration Events",
    path: "/registation-events-dxp",
    component: RegistrationEventsPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};

const RegistrationUsersRouteDXP = {
    id: "Registration Users",
    path: "/registation-users-dxp",
    component: RegistrationUsersPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};

const SentEmailsRouteDXP = {
    id: "Sent Emails",
    path: "/sent-emails-dxp",
    component: SentEmailsPageDXP,
    children: null,
    icon: <Grid />,
    guard: AuthGuard,
};

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
export const protectedRoutes = [
    AdPlacementRoutes,
    dashboardsRoutes,
    adminRoutes,
    AddAdvertisementsRoutes,
    ManufacturerAdsRoutes,
    ArticleTemplateRoute,
    OnsiteContentRoutes,
    EvaluationRoutes,
    EditCategoriesPageRoutes,
    PublishedOutlierPageRoutes,
    ManufacturersRoutes,
    ProductsRoutes,
    PromotionsRoutes,
    CriteriaRoutes,
    SkusRoutes,
    EvaluationRoutes,
    ReviewsRoutes,
    OnsiteSEOPageRoutes,
    DistributorPageRoutes,
    PlacementCheckerRoutes,
    SitemapPageRoute,
    IpUploadPageRoute,
    EmbedFormRoutes,
    Page301RedirectsRoute,
    DynamicPersonasRoute,
    TeamMembersRoute,
    //DL protected routes
    DashboardSectionDL,
    AdPlacementRoutesDL,
    FeaturedContentRoutesDL,
    PopUpRoutesDL,
    CompetitorExclusionRoutesDL,
    OnsiteContentRoutesDL,
    EventsRouteDL,
    ArticlesRouteDL,
    BlogsRouteDL,
    PodcastsRouteDL,
    PodcastEpisodesRouteDL,
    InstructorsRouteDL,
    CategoriesRouteDL,
    ManufacturersRouteDL,
    LocationsRouteDL,
    TargetAudiencesRouteDL,
    CourseTypesRouteDL,
    AdsManufacturerRouteDL,
    EmbedFormRouteDL,
    BundleRouteDL,
    TakeFiveRouteDL,
    UsersDL,
    CourseLicensesDL,
    TransactionsDL,
    SubscriptionsDL,
    FreeMembershipsDL,
    RegistrationsDL,
    WebhookEventsRouteDL,
    //Buyers Guide Routes
    BuyersGuide,
    //DXP protected routes
    DashboardDXP,
    AdPlacementRoutesDXP,
    FeaturedContentRoutesDXP,
    ExpiringContentRouteDXP,
    AdsManufacturerRouteDXP,
    ManufacturersRoutesDXP,
    CategoriesRouteDXP,
    OnsiteContentRoutesDXP,
    UsersRoutesDXP,
    UserGroupsRoutesDXP,
    GroupUsersRouteDXP,
    CourseHistoryRouteDXP,
    XpertsRoutesDXP,
    PendingStudentsRoutesDXP,
    PendingPostsComments,
    VideosDXP,
    ArticlesDXP,
    BlogsDXP,
    WebinarsDXP,
    EventsDXP,
    ExternalEventsDXP,
    LanguagesRouteDXP,
    FAQRouteDXP,
    UserRolesDXP,
    ProductTableRoutesDXP,
    TransactionsRoutesDXP,
    SubscriptionsRoutesDXP,
    AuditSubscriptionsRoutesDXP,
    TrialCodeRoutesDXP,
    FreeMembershipRouteDXP,
    Page404RouteDXP,
    MailingListRouteDXP,
    SymposiumBillingRouteDXP,
    RegistrationEventsRouteDXP,
    RegistrationUsersRouteDXP,
    SentEmailsRouteDXP,
    AffiliateRoutesDXP,
    FellowshipRoutesDXP,
    FellowshipContentDXP,
    // FellowshipSessionRoutesDXP,
    // FellowshipAdditionalProgramsRoutesDXP,
    // FellowshipTestRoutesDXP,
    FellowshipUserCompletionsRoutesDXP,
    FellowshipUserAccessRoutesDXP,
    FellowshipMaterialsRoutesDXP,
    FellowshipTransactionsRoutesDXP,
];

export const buyersGuideRoutes = [
    BuyersGuidePDF,
    BuyersGuideImageReview,
    // BuyersGuideInitialReview,
    BuyersGuideNewContent,
    BuyersGuideReview,
    BuyersGuideClientActivity,
    BuyersGuideFinal
]

export const buyersGuideSidebarRoutes = [
    BuyersGuidePDF,
    BuyersGuideImageReview,
    // BuyersGuideInitialReview,
    BuyersGuideNewContent,
    BuyersGuideReview,
    BuyersGuideClientActivity,
    BuyersGuideFinal
]

// Routes visible in the sidebar
export const sidebarRoutes = [
    AdPlacementRoutes,
    PlacementCheckerRoutes,
    dashboardsRoutes,
    OnsiteContentRoutes,
    EvaluationRoutes,
    ArticleTemplateRoute,
    ManufacturersRoutes,
    ProductsRoutes,
    PromotionsRoutes,
    ReviewsRoutes,
    CriteriaRoutes,
    SkusRoutes,
    EditCategoriesPageRoutes,
    SitemapPageRoute,
    IpUploadPageRoute,
    EmbedFormRoutes,
    Page301RedirectsRoute,
    OnsiteSEOPageRoutes,
    DistributorPageRoutes,
    PublishedOutlierPageRoutes,
    BuyersGuide,
    DynamicPersonasRoute,
    TeamMembersRoute,
];

// Dental Learning sidebar routes - visible in sidebar
export const sidebarRoutesDL = [
    DashboardSectionDL,
    AdPlacementRoutesDL,
    FeaturedContentRoutesDL,
    PopUpRoutesDL,
    CompetitorExclusionRoutesDL,
    OnsiteContentRoutesDL,
    EventsRouteDL,
    ArticlesRouteDL,
    BlogsRouteDL,
    PodcastsRouteDL,
    PodcastEpisodesRouteDL,
    InstructorsRouteDL,
    CategoriesRouteDL,
    ManufacturersRouteDL,
    LocationsRouteDL,
    TargetAudiencesRouteDL,
    CourseTypesRouteDL,
    EmbedFormRouteDL,
    BundleRouteDL,
    TakeFiveRouteDL,
    UsersDL,
    CourseLicensesDL,
    TransactionsDL,
    SubscriptionsDL,
    FreeMembershipsDL,
    RegistrationsDL,
    WebhookEventsRouteDL,
    // AdsManufacturerRouteDL
];


// Dental XP sidebar routes - visible in sidebar
export const sidebarRoutesDXP = [
    DashboardDXP,
    AdPlacementRoutesDXP,
    FeaturedContentRoutesDXP,
    ExpiringContentRouteDXP,
    AdsManufacturerRouteDXP,
    OnsiteContentRoutesDXP,
    VideosDXP,
    ArticlesDXP,
    BlogsDXP,
    WebinarsDXP,
    EventsDXP,
    ExternalEventsDXP,
    TransactionsRoutesDXP,
    SubscriptionsRoutesDXP,
    AuditSubscriptionsRoutesDXP,
    TrialCodeRoutesDXP,
    FreeMembershipRouteDXP,
    UsersRoutesDXP,
    XpertsRoutesDXP,
    PendingStudentsRoutesDXP,
    PendingPostsComments,
    UserRolesDXP,
    UserGroupsRoutesDXP,
    CourseHistoryRouteDXP,
    AffiliateRoutesDXP,
    FellowshipRoutesDXP,
    FellowshipContentDXP,
    // FellowshipSessionRoutesDXP,
    // FellowshipAdditionalProgramsRoutesDXP,
    // FellowshipTestRoutesDXP,
    FellowshipUserCompletionsRoutesDXP,
    FellowshipUserAccessRoutesDXP,
    FellowshipMaterialsRoutesDXP,
    FellowshipTransactionsRoutesDXP,
    ManufacturersRoutesDXP,
    CategoriesRouteDXP,
    LanguagesRouteDXP,
    FAQRouteDXP,
    ProductTableRoutesDXP,
    Page404RouteDXP,
    MailingListRouteDXP,
    SymposiumBillingRouteDXP,
    RegistrationEventsRouteDXP,
    RegistrationUsersRouteDXP,
    SentEmailsRouteDXP
]

// DPS Article Template Routes
// export const templateSidebar = [
//   BackRoute,
//   ArticleTemplateRoute
// ]