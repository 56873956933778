import React, { useEffect } from "react";
import { connect } from "react-redux";

import FeaturedManufacturerForm from "../components/FeaturedManufacturerPage/FeaturedManufacturerForm";
import FeaturedManufacturerTable from "../components/FeaturedManufacturerPage/FeaturedManufacturerTable";
import { getManufacturersPublished } from "../redux/actions/manufacturers";
import { getFeatured } from "../redux/actions/featuredManufacturers";

import Loader from "../components/Loader";

const FeaturedManufacturerPage = ({
  getFeatured,
  getManufacturersPublished,
  manufacturer: { isLoading },
}) => {
  useEffect(() => {
    getManufacturersPublished();
    getFeatured();
  }, [getManufacturersPublished, getFeatured]);

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <FeaturedManufacturerForm />
      <FeaturedManufacturerTable />
    </div>
  );
};

const mapStateToProps = (state) => ({
  manufacturer: state.manufacturer,
});

export default connect(mapStateToProps, { getManufacturersPublished, getFeatured })(
  FeaturedManufacturerPage
);
