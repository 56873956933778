import {
    DXP_GET_SENT_EMAILS,
    DXP_GET_SENT_EMAILS_ERROR,
    DXP_GET_SENT_EMAILS_COUNT,
    DXP_GET_SENT_EMAILS_COUNT_ERROR,
    DXP_SEARCH_SENT_EMAILS,
    DXP_SEARCH_SENT_EMAILS_ERROR,
    DXP_GET_SEARCH_EMAILS_COUNT,
    DXP_GET_SEARCH_EMAILS_COUNT_ERROR,
} from "../actions/types";

const initialState = {
    sentEmails: [],
    searchedEmails: [],
    count: null,
    searchCount: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_SENT_EMAILS:
            return {
                ...state,
                sentEmails: payload,
                isLoading: false,
                success: true
            }

        case DXP_GET_SENT_EMAILS_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }

        case DXP_SEARCH_SENT_EMAILS:
            return {
                ...state,
                searchedEmails: payload,
                isLoading: false,
                success: true
            }

        case DXP_GET_SEARCH_EMAILS_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true
            }

        case DXP_GET_SENT_EMAILS_ERROR:
            return {
                ...state,
                sentEmails: [],
                isLoading: false,
                success: false
            }

        case DXP_GET_SENT_EMAILS_COUNT_ERROR:
            return {
                ...state,
                count: null,
                isLoading: false,
                success: false
            }

        case DXP_SEARCH_SENT_EMAILS_ERROR:
            return {
                ...state,
                searchedEmails: [],
                isLoading: false,
                success: false
            }

        case DXP_GET_SEARCH_EMAILS_COUNT_ERROR:
            return {
                ...state,
                searchCount: null,
                isLoading: false,
                success: false
            }

        default:
            return state;
    }
}
