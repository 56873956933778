import {
    SELECT_SITE,
} from "../actions/types";

const initialState = {
    site: 'DPS'
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case SELECT_SITE:
            return {
                ...state,
                site: payload,
            };
        default:
            return state;
    }
}
