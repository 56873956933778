import {
    DXP_GET_ALL_SUBSCRIPTIONS_PAGINATED,
    DXP_GET_ALL_SUBSCRIPTIONS_PAGINATED_ERROR,
    DXP_GET_ALL_SUBSCRIPTIONS_BY_USERID,
    DXP_GET_ALL_SUBSCRIPTIONS_BY_USERID_ERROR,
    DXP_GET_ALL_SUBSCRIPTIONS_BY_STATUS_PAGINATED,
    DXP_GET_ALL_SUBSCRIPTIONS_BY_STATUS_PAGINATED_ERROR,
    DXP_GET_ALL_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED,
    DXP_GET_ALL_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED_ERROR,
    DXP_SUBSCRIPTION_CANCEL_AUTO_RENEW,
    DXP_SUBSCRIPTION_CANCEL_AUTO_RENEW_ERROR,
    DXP_ADD_NEW_SUBSCRIPTION_ENTRY,
    DXP_ADD_NEW_SUBSCRIPTION_ENTRY_ERROR,
    DXP_GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID,
    DXP_GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_ERROR,
    DXP_UPDATE_SUBSCRIPTION_BY_ENTRY_ID,
    DXP_UPDATE_SUBSCRIPTION_BY_ENTRY_ID_ERROR,
    DXP_GET_SUBSCRIPTION_COUPONS,
    DXP_GET_SUBSCRIPTION_COUPONS_ERROR,
    DXP_GET_ALL_SUBSCRIPTIONS_BY_FILTER_STATUS,
    DXP_GET_ALL_SUBSCRIPTIONS_BY_FILTER_STATUS_ERROR
} from "../actions/types";

const initialState = {

    isLoadingSubscriptions: true,
    successSubscriptions: false,
    allSubscriptionsPaginated: [],
    allSubscriptionsPaginatedCount: null,
    allSubscriptionsByUserId: [],
    allSubscriptionsByStatusPaginated: [],
    allSubscriptionsByStatusPaginatedCount: null,
    allSubscriptionsBySearchTermPaginated: [],
    allSubscriptionsBySearchTermPaginatedCount: null,
    subscriptionBySubscriptionId: null,
    subscriptionCoupons: [],
    allSubscriptionsFiltered: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_SUBSCRIPTIONS_BY_FILTER_STATUS:
            return {
                ...state,
                isLoadingSubscriptions: false,
                successSubscriptions: true,
                allSubscriptionsFiltered: payload,
            };
        case DXP_GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID:
            return {
                ...state,
                isLoadingSubscriptions: false,
                successSubscriptions: true,
                subscriptionBySubscriptionId: payload,
            };
        case DXP_GET_ALL_SUBSCRIPTIONS_PAGINATED:
            return {
                ...state,
                isLoadingSubscriptions: false,
                successSubscriptions: true,
                allSubscriptionsPaginated: payload.rows,
                allSubscriptionsPaginatedCount: payload.count
            };
        case DXP_GET_ALL_SUBSCRIPTIONS_BY_USERID:
            return {
                ...state,
                isLoadingSubscriptions: false,
                successSubscriptions: true,
                allSubscriptionsByUserId: payload,
            };
        case DXP_GET_ALL_SUBSCRIPTIONS_BY_STATUS_PAGINATED:
            return {
                ...state,
                isLoadingSubscriptions: false,
                successSubscriptions: true,
                allSubscriptionsByStatusPaginated: payload.rows,
                allSubscriptionsByStatusPaginatedCount: payload.count
            };
        case DXP_GET_ALL_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED:
            return {
                ...state,
                isLoadingSubscriptions: false,
                successSubscriptions: true,
                allSubscriptionsBySearchTermPaginated: payload.rows,
                allSubscriptionsBySearchTermPaginatedCount: payload.count
            };
        case DXP_GET_SUBSCRIPTION_COUPONS:
            return {
                ...state,
                isLoadingSubscriptions: false,
                successSubscriptions: true,
                subscriptionCoupons: payload
            };


        case DXP_SUBSCRIPTION_CANCEL_AUTO_RENEW:
        case DXP_ADD_NEW_SUBSCRIPTION_ENTRY:
        case DXP_UPDATE_SUBSCRIPTION_BY_ENTRY_ID:
            return {
                ...state,
                isLoadingSubscriptions: false,
                successSubscriptions: true,
            };

        case DXP_GET_ALL_SUBSCRIPTIONS_PAGINATED_ERROR:
        case DXP_GET_ALL_SUBSCRIPTIONS_BY_USERID_ERROR:
        case DXP_GET_ALL_SUBSCRIPTIONS_BY_STATUS_PAGINATED_ERROR:
        case DXP_GET_ALL_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED_ERROR:
        case DXP_SUBSCRIPTION_CANCEL_AUTO_RENEW_ERROR:
        case DXP_ADD_NEW_SUBSCRIPTION_ENTRY_ERROR:
        case DXP_GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_ERROR:
        case DXP_UPDATE_SUBSCRIPTION_BY_ENTRY_ID_ERROR:
        case DXP_GET_SUBSCRIPTION_COUPONS_ERROR:
        case DXP_GET_ALL_SUBSCRIPTIONS_BY_FILTER_STATUS_ERROR:
            return {
                ...state,
                isLoadingSubscriptions: false,
                successSubscriptions: false,
            };
        default:
            return state;
    }
}
