import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import {
    Avatar as MuiAvatar,
    Badge,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    // Popover as MuiPopover,
    SvgIcon,
    Tooltip,
    Typography,
} from "@mui/material";
import { Bell, Home, UserPlus, Server, ChevronDown, FileText, ShoppingCart } from "react-feather";

import {
    selectSite
} from "../redux/actions/siteToggle";

// const Popover = styled(MuiPopover)`
//   .MuiPaper-root {
//     width: 300px;
//     ${(props) => props.theme.shadows[1]};
//     border: 1px solid ${(props) => props.theme.palette.divider};
//   }
// `;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ title, description, Icon }) {
    return (
        <ListItem divider component={Link} to="#">
            <ListItemAvatar>
                <Avatar>
                    <SvgIcon fontSize="small">
                        <Icon />
                    </SvgIcon>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                primaryTypographyProps={{
                    variant: "subtitle2",
                    color: "textPrimary",
                }}
                secondary={description}
            />
        </ListItem>
    );
}

function ChooseSiteDropdown({ selectSite }) {
    const navigate = useNavigate();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggleSiteClick = (site) => {
        selectSite(site)

        setOpen(false);
        if (site === 'DL') {
            navigate('/manage-ads-dl')
        } else if (site === 'DPS') {
            navigate('/featured-content/home-page')
        } else if (site === 'DXP') {
            navigate('/dxp/manage-ads')
        }
    };

    return (
        <React.Fragment>
            <Tooltip title="Choose Site">
                <IconButton color="inherit" ref={ref} onClick={handleOpen}>
                    <Box style={{ border: '1px solid #a9a9a9', borderRadius: '5px', padding: 6, display: 'flex' }}>Choose Site<ChevronDown style={{ paddingLeft: 3, marginLeft: 4 }} /></Box>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <React.Fragment>
                    <List disablePadding>
                        <div
                            onClick={() => handleToggleSiteClick('DPS')}
                        >
                            <Notification
                                title="DPS"
                                description="Dental Product Shopper"
                                Icon={ShoppingCart}
                            />
                        </div>
                        <div
                            onClick={() => handleToggleSiteClick('DL')}
                        >
                            <Notification
                                title="DL"
                                description="Dental Learning"
                                Icon={FileText}
                            />
                        </div>
                        <div
                            onClick={() => handleToggleSiteClick('DXP')}
                        >
                            <Notification
                                title="DXP"
                                description="Dental XP"
                                Icon={FileText}
                            />
                        </div>
                    </List>
                </React.Fragment>
            </Popover>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    siteToggle: state.siteToggle,
});

export default connect(mapStateToProps, {
    selectSite
})(ChooseSiteDropdown);
