import { configureStore } from '@reduxjs/toolkit'
// import { composeWithDevTools } from "redux-devtools-extension";
// import thunk from "redux-thunk";

import { rootReducer } from "../reducers/index";

// const composeEnhancers = composeWithDevTools({});

// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(thunk))
// );

const store = configureStore({
  reducer: {
    ...rootReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })
})
export default store;