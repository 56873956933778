export function UseUrlHandler(url, site) {
    const baseUrl = site === 'dxp' ? 'https://d2n5cavkgy7na3.cloudfront.net' : 'https://dh1bsjhakq06i.cloudfront.net'

    if (!url) {
        return ''
        // } else if (url.length < 10 || url.slice(0, 10) === '/documents') {
    } else if (url.slice(0, 10) === '/documents') {
        return `https://old-site.dentalproductshopper.com/${url}`
    } else {
        if (url[0] === '/') {
            return `${baseUrl}${url}`
        } else {
            return `${baseUrl}/${url}`
        }
    }
}