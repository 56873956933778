import {
    GET_PLACEMENT_ADS,
    GET_PLACEMENT_ADS_ERROR,
} from "../actions/types";

const initialState = {
    ads: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_PLACEMENT_ADS:
            return {
                ...state,
                ads: payload,
                isLoading: false,
                success: true,
            };
        case GET_PLACEMENT_ADS_ERROR:
            return {
                ...state,
                ads: payload,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
