import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import config from "../../utils/awsConfig";
import { uploadFile } from "./../../lib/ReactS3";
import { Editor } from '@tinymce/tinymce-react';

import {
    Button,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Dialog,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    InputLabel,
    Checkbox,
} from "@mui/material";
import { DropzoneArea } from '../DropzoneArea';
import Autocomplete from '@mui/material/Autocomplete';

import { updateArticle, getArticleProducts, addArticleProductAssociation, deleteArticleProductAssociation } from "../../redux/actions/articles";
import { searchProducts } from "../../redux/actions/products";
import { getAllUsers } from "../../redux/actions/auth";
import { setAlert } from "../../redux/actions/alerts";
import "./NewDialog.css";
import { selectArticleForTemplate, setReturnUrl } from "../../redux/actions/articleTemplates";
import { getArticleTypes } from "../../redux/actions/articleTypes";

const editorApiKey = process.env.REACT_APP_TINY_KEY

const custom_image_upload_handler = (file, success) => {
    uploadFile(file.blob(), config)
        .then((data) => {
            success('https://dh1bsjhakq06i.cloudfront.net/' + file.filename())
        })
        .catch((err) => console.error(err));
}

const classes = {
    dropZone: {
        height: "232px",
        minHeight: "68px",
        width: '20%',
        marginRight: "10px",
        padding: "0 5px",
    },
    dropZoneImage: {
        height: "232px",
        minHeight: "68px",
        width: '200px',
        marginRight: "10px",
        marginBottom: '10px',
        padding: "0 5px",
    },
};

const EditForm = ({
    type,
    updateArticle,
    getArticleProducts,
    handleClose,
    searchProducts,
    getAllUsers,
    addArticleProductAssociation,
    deleteArticleProductAssociation,
    page,
    setAlert,
    selectArticleForTemplate,
    setReturnUrl,
    getArticleTypes,
    article: { selectedArticle, articleProducts },
    articleType: { articleTypes },
    auth: { user, users },
    product: { productSearch },
}) => {
    const editorRef = useRef(null);
    const prodName = useRef();
    // const classes = useStyles();
    const [post, setPost] = useState({
        article_id: "",
        article_type: "",
        article_type_id: "",
        content: "",
        create_date: "",
        modified_date: new Date(),
        publish: "",
        published_at: "",
        short_description: "",
        thumbnail: null,
        thumbnail_path: null,
        title: "",
        author: "",
        date: "",
        urlTitle: "",
        call_to_action: "",
        pdf_link: "",
        embed_form_title: "",
        hubspot_form_id: "",
        is_templated: false,
        exclude_newsfeed: false
    });
    const [editPage, setPage] = useState(0);
    const [productList, setProductList] = useState([]);
    const [currentArticleProducts, setCurrentArticleProducts] = useState([])
    const [impersonate, setImpersonate] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setPost({
            article_id: selectedArticle.article_id,
            article_type: selectedArticle.article_type,
            article_type_id: selectedArticle.article_type_id,
            content: selectedArticle.content,
            publish: selectedArticle.publish,
            create_date: selectedArticle.create_date,
            short_description: selectedArticle.short_description,
            thumbnail: selectedArticle.thumbnail,
            thumbnail_path: selectedArticle.thumbnail_path,
            title: selectedArticle.title,
            author: selectedArticle.author,
            date: selectedArticle.published_at,
            urlTitle: selectedArticle.urlTitle,
            ebookExternalLink: selectedArticle.ebookExternalLink,
            call_to_action: selectedArticle.call_to_action,
            pdf_link: selectedArticle.pdf_link,
            embed_form_title: selectedArticle.embed_form_title,
            hubspot_form_id: selectedArticle.hubspot_form_id,
            is_templated: selectedArticle.is_templated,
            exclude_newsfeed: selectedArticle.exclude_newsfeed ? true : false,
        });
        getArticleProducts(selectedArticle.article_id)
    }, [selectedArticle, getArticleProducts]);

    useEffect(() => {
        if (articleProducts.length > 0) {
            articleProducts.forEach(product => {
                product.deleted = false;
            })
            setCurrentArticleProducts([...articleProducts])
        }
    }, [articleProducts])

    useEffect(() => {
        if (user.permissions.length > 0) {
            user.permissions.forEach(permission => {
                if (permission.permission === 'impersonate') {
                    setImpersonate(true)
                }
            })
        }
        getAllUsers()
    }, [user, getAllUsers])

    useEffect(() => {
        getArticleTypes()
    }, [getArticleTypes])

    const handleSubmit = (e, cb) => {
        e.preventDefault();

        if ((productList.length > 0 || currentArticleProducts.length > 0) && title && urlTitle && (content || is_templated) && short_description) {
            updateArticle(post.article_id, post, page);
            if (productList.length > 0) {
                addArticleProductAssociation(productList)
            }
            if (currentArticleProducts.length > 0) {
                currentArticleProducts.forEach(association => {
                    if (association.deleted === true) {
                        deleteArticleProductAssociation(association.article_id, association.product_id)
                    }
                })
            }
            setPost({
                article_id: "",
                article_type: "",
                article_type_id: "",
                content: "",
                create_date: "",
                modified_date: new Date(),
                published_at: "",
                short_description: "",
                thumbnail: null,
                thumbnail_path: null,
                title: "",
                author: "",
                date: "",
                urlTitle: "",
                ebookExternalLink: "",
                call_to_action: "",
                pdf_link: "",
                embed_form_title: "",
                hubspot_form_id: "",
                is_templated: false,
                exclude_newsfeed: false,
            });

            if (cb) {
                cb()
            }

            handleClose();
        } else {
            setAlert(`Please fill out required fields: ${!title ? 'Title, ' : ''} ${!urlTitle ? 'Url Title, ' : ''} ${!content && !is_templated ? 'Article Body, ' : ''} ${!short_description ? 'Short Description, ' : ''}& pick at least 1 product`, 'danger')
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPost({
            ...post,
            [name]: value,
        });
    };

    const handleEditorChange = (data) => {
        setPost({
            ...post,
            content: editorRef.current.getContent()
        })
    };

    //handles the drop event, sets the returned value from s3 bucket to the dropName value in state
    const handleDrop = (acceptedFiles, property) => {
        acceptedFiles.forEach((file) => {
            uploadFile(file, config)
                .then((data) => {
                    if (property === 'thumbnail') {
                        setPost({
                            ...post,
                            [property]: data.location,
                            thumbnail_path: data.filename
                        });

                    } else {
                        setPost({
                            ...post,
                            [property]: data.location,
                        });

                    }
                })
                .catch((err) => console.error(err));
        });
    };

    const handleDelete = (property) => {
        if (property === 'thumbnail') {
            setPost({
                ...post,
                [property]: null,
                thumbnail_path: null
            });

        } else {
            setPost({
                ...post,
                [property]: '',
            });

        }
    };

    const searchForProducts = (e) => {
        setTimeout(() => {
            if (e.target.value.length < 2) {
                setAlert("Query must be 2 characters or longer", "danger");
            } else {
                searchProducts(e.target.value);
            }
        }, 1000);
    };

    const handleProductSelect = (e, product) => {
        let updatedProductList = [...productList];
        if (product) {
            updatedProductList.push({ product_id: product.product_id, productName: product.name, article_id: post.article_id })
            setProductList(updatedProductList)
        }
    };

    const handleDeleteCheckboxes = (e) => {
        let updatedArticleProds = [...currentArticleProducts]
        updatedArticleProds.forEach(product => {
            if (product.product_id === parseInt(e.target.name)) {
                if (e.target.value === 'false') {
                    product.deleted = true
                } else {
                    product.deleted = false
                }
            }
        })
        setCurrentArticleProducts([...updatedArticleProds])
    };

    const viewTemplate = (e) => {
        setPost(prevPost => ({
            ...prevPost,
            is_templated: true
        }))
        selectArticleForTemplate(selectedArticle)
        setReturnUrl(`/onsite-content/${type ? type?.replaceAll(' ', '-') : article_type?.replaceAll(' ', '-')}`)
        handleSubmit(e, () => navigate(`/onsite-content/template`))

    }

    const toggleEditor = () => {
        setPost(prevPost => ({
            ...prevPost,
            is_templated: !is_templated
        }))
    }
    const {
        title,
        author,
        date,
        content,
        article_type,
        publish,
        urlTitle,
        ebookExternalLink,
        call_to_action,
        pdf_link,
        embed_form_title,
        hubspot_form_id,
        short_description,
        thumbnail,
        thumbnail_path,
        is_templated,
        exclude_newsfeed
    } = post;

    return (
        <Dialog
            open
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            disableEnforceFocus={true}
        >
            <DialogTitle id="form-dialog-title">Edit Post</DialogTitle>
            {editPage === 0 &&
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        name="title"
                        type="text"
                        label="Title"
                        placeholder="Title"
                        value={title ? title : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                    />
                    {
                        impersonate === true ?
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%", paddingTop: ".5rem", paddingBottom: ".5rem", marginTop: 12 }}
                            >
                                <InputLabel id="demo-simple-select-helper-label">Author</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={author ? author : ''}
                                    name="author"
                                    onChange={(e) => handleChange(e)}
                                >
                                    {users && users.length > 0 &&
                                        users.map((user, i) =>
                                            <MenuItem key={`user-${i}`} value={user.userId}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                            :
                            <TextField
                                autoFocus
                                margin="dense"
                                variant="outlined"
                                name="author"
                                label="Author ID"
                                placeholder={author}
                                value={author ? author : ''}
                                onChange={(e) => handleChange(e)}
                                fullWidth
                                disabled
                                style={{ marginBottom: "10px" }}
                            />
                    }
                    <FormControl>
                        <InputLabel
                            id="demo-simple-select-label"
                            style={{ marginLeft: ".5rem" }}
                        >
                            Article Type
                        </InputLabel>
                        <Select
                            variant="outlined"
                            label="Article Type"
                            name="article_type"
                            value={article_type ? article_type : ''}
                            onChange={(e) => handleChange(e)}
                            fullWidth
                            sx={{ width: '250px' }}
                        >
                            {articleTypes?.length > 0 ?
                                articleTypes.map((type, i) => (
                                    <MenuItem key={`article-type-${i}`} value={type.name}>{type.name === 'CE' ? 'Continuing Ed. (Dental Learning)' : type.name === 'Viva' ? 'Continuing Ed. (Viva Learning)' : type.name}</MenuItem>
                                ))
                                : null}

                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        variant="outlined"
                        placeholder="Url Title"
                        label="Url Title"
                        name="urlTitle"
                        value={urlTitle ? urlTitle && urlTitle.toLowerCase().replace(/[^a-zA-Z0-9-_]/g, '-') : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        style={{ marginBottom: 10 }}
                    />
                    <FormControl style={{ width: '20%' }}>
                        <InputLabel
                            id="demo-simple-select-label"
                            style={{ marginLeft: ".5rem" }}
                        >
                            Published
                        </InputLabel>
                        <Select
                            variant="outlined"
                            label="Published"
                            name="publish"
                            value={publish ? publish : false}
                            onChange={(e) => handleChange(e)}
                            fullWidth
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        style={{ marginLeft: '10px', width: '20%' }}
                    >
                        <InputLabel id="exclude-newsfeed-label">Hide From Newsfeed</InputLabel>
                        <Select
                            labelId="exclude-newsfeed-label"
                            id="exclude-newsfeed"
                            label="Hide From Newsfeed"
                            value={exclude_newsfeed ? exclude_newsfeed : false}
                            name="exclude_newsfeed"
                            onChange={(e) => handleChange(e)}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        variant="outlined"
                        placeholder="Date"
                        label="Published At"
                        name="date"
                        value={date ? date : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                    />

                    <h4 style={{ textAlign: "left" }}>Image Upload</h4>
                    <DropzoneArea
                        acceptedFiles={['image/*']}
                        dropzoneClass={classes.dropZoneImage}
                        dropzoneText={thumbnail_path ? thumbnail_path : 'Add Image'}
                        showPreviewsInDropzone={true}
                        initialFiles={thumbnail_path ? [thumbnail_path] : null}
                        filesLimit={1}
                        onChange={(e) => handleDrop(e, 'thumbnail')}
                        onDelete={() => handleDelete('thumbnail')}
                    />

                    <div>
                        <h4 style={{ marginBottom: 5 }}>Associated Products:</h4>
                        {
                            articleProducts.length > 0 &&
                            articleProducts.map((article, i) =>
                                <div key={`article-${i}`} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginRight: 15 }}>{article.Product.name}</div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={`${article.product_id}`}
                                                checked={article.deleted ? true : false}
                                                value={article.deleted ? true : false}
                                                onChange={handleDeleteCheckboxes}
                                            />
                                        }
                                        label="Check box to delete product association when submitted"
                                    />
                                </div>
                            )
                        }
                    </div>
                    <form noValidate autoComplete="off" style={{ width: "100%", marginTop: 20 }}>
                        <Autocomplete
                            options={productSearch ? productSearch : []}
                            ref={prodName}
                            getOptionLabel={(option) => option.name}
                            onKeyUp={(e) => searchForProducts(e)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search More Products To Associate with Article"
                                    variant="outlined"
                                />
                            )}
                            onChange={(e, prod) => handleProductSelect(e, prod)}
                        />
                    </form>
                    {
                        productList.length > 0 &&
                        <div>
                            <h4 style={{ marginBottom: 5 }}>Additional Products to Associate:</h4>
                            {productList.map((product, i) =>
                                <div key={`product-${i}`}>{product.productName}</div>
                            )
                            }
                        </div>
                    }
                </DialogContent>
            }
            {editPage === 1 &&
                < DialogContent >
                    <DialogContentText>Add New Article</DialogContentText>

                    {is_templated ?
                        <>
                            <Button onClick={(e) => viewTemplate(e)} variant='contained' color='primary' style={{ margin: '0 10px 10px 0' }}>View Template</Button>
                            <Button onClick={() => toggleEditor()} variant='outlined' color='primary' style={{ marginBottom: '10px' }}>Switch to Editor</Button>
                        </>
                        :
                        <>
                            <Button onClick={(e) => viewTemplate(e)} variant='contained' color='primary' style={{ margin: '0 10px 10px 0' }} disabled>View Template</Button>
                            <Button onClick={() => toggleEditor()} variant='outlined' color='primary' style={{ marginBottom: '10px' }}>Switch to Template</Button>
                            <Editor
                                value={content ? content : ''}
                                onInit={(evt, editor) => editorRef.current = editor}
                                apiKey={editorApiKey}
                                onEditorChange={(newText) => handleEditorChange(newText)}
                                init={{
                                    height: 500,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste imagetools code help wordcount'
                                    ],
                                    paste_as_text: true,
                                    menubar: 'file edit view insert format tools table tc help',
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | link image | code | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    images_upload_handler: custom_image_upload_handler,
                                }}
                            />
                        </>
                    }
                    <TextField
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        name="ebookExternalLink"
                        label="eBook External Link"
                        placeholder={ebookExternalLink}
                        value={ebookExternalLink ? ebookExternalLink : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        style={{ marginTop: 20 }}
                        margin="dense"
                        variant="outlined"
                        placeholder="Short Description"
                        label="Short Description"
                        name="short_description"
                        value={short_description ? short_description : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                    />
                    <h4 style={{ marginBottom: 8 }}>For Ebooks - Hubspot Form Id</h4>
                    <TextField
                        style={{ marginTop: 0 }}
                        margin="dense"
                        variant="outlined"
                        placeholder="Embed Form Title"
                        label="Embed Form Title"
                        name="embed_form_title"
                        value={embed_form_title ? embed_form_title : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                    />
                    <TextField
                        style={{ marginTop: '5px' }}
                        margin="dense"
                        variant="outlined"
                        placeholder="Hubspot Form Id"
                        label="Hubspot Form Id"
                        name="hubspot_form_id"
                        value={hubspot_form_id ? hubspot_form_id : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        name="call_to_action"
                        label='Call to Action Ex.) "Download PDF Here!"'
                        placeholder={call_to_action}
                        value={call_to_action ? call_to_action : ''}
                        onChange={(e) => handleChange(e)}
                        multiline
                        rows={2}
                        fullWidth
                        style={{ marginBottom: "10px", marginTop: "20px" }}
                    />
                    <h3>Upload PDF Below:</h3>
                    <div style={{ display: 'flex' }}>
                        <DropzoneArea
                            acceptedFiles={['application/pdf']}
                            dropzoneClass={classes.dropZone}
                            showPreviewsInDropzone={true}
                            filesLimit={1}
                            onChange={(e) => handleDrop(e, 'pdf_link')}
                            onDelete={() => handleDelete('pdf_link')}
                        />
                        <TextField
                            id="outlined-multiline-static"
                            // label="PDF Link"
                            multiline
                            // name="topArchBannerLink"
                            placeholder={pdf_link ? pdf_link : "Add PDF"}
                            value={pdf_link ? pdf_link : "Add PDF"}
                            rows={13}
                            variant="outlined"
                            // style={{ width: "100%" }}
                            disabled
                        />
                    </div>
                    <h4>OR paste in PDF link below</h4>
                    <div>
                        <TextField
                            autoFocus
                            margin="dense"
                            variant="outlined"
                            name="pdf_link"
                            label='PDF Url'
                            placeholder={pdf_link}
                            value={pdf_link ? pdf_link : ''}
                            onChange={(e) => handleChange(e)}
                            multiline
                            rows={2}
                            fullWidth
                            style={{ marginBottom: "10px", marginTop: "5px" }}
                        />
                    </div>
                </DialogContent>
            }
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                {editPage === 0 &&
                    <Button color="primary" onClick={(e) => setPage(1)}>
                        Next
                    </Button>
                }
                {editPage === 1 &&
                    <Button color="primary" onClick={(e) => setPage(0)}>
                        Back
                    </Button>
                }
                {editPage === 1 &&
                    <Button color="primary" onClick={(e) => handleSubmit(e)}>
                        Submit
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

EditForm.propTypes = {
    updateArticle: PropTypes.func.isRequired,
    article: PropTypes.object.isRequired,
    articleType: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    article: state.article,
    articleType: state.articleType,
    product: state.product,
    auth: state.auth
});

export default connect(mapStateToProps, { updateArticle, getArticleProducts, searchProducts, addArticleProductAssociation, deleteArticleProductAssociation, getAllUsers, setAlert, selectArticleForTemplate, setReturnUrl, getArticleTypes })(EditForm);
