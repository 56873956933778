import axios from "axios";
import {
  LOGIN,
  LOGOUT,
  GET_USER,
  GET_ALL_USERS,
  GET_ALL_USERS_PAGE,
  GET_ALL_ROLES,
  GET_ROLE,
  GET_PERMISSIONS,
  REGISTER,
  ADD_ROLE,
  DELETE_ROLE,
  UPDATE_ROLE,
  UPDATE_STATUS,
  ADD_PERMISSION,
  DELETE_PERMISSIONS,
  DELETE_USER,
  SELECT_USER,
  UPDATE_USER,
  UPDATE_PASSWORD,
  UPDATE_ROLE_NAME,
  UPDATE_IMAGE,
  LOGIN_ERROR,
  LOGOUT_ERROR,
  REGISTER_ERROR,
  ADD_ROLE_ERROR,
  DELETE_ROLE_ERROR,
  ADD_PERMISSION_ERROR,
  DELETE_PERMISSIONS_ERROR,
  GET_USER_ERROR,
  GET_ALL_USERS_ERROR,
  GET_ALL_ROLES_ERROR,
  GET_ROLE_ERROR,
  UPDATE_ROLE_ERROR,
  UPDATE_STATUS_ERROR,
  DELETE_USER_ERROR,
  UPDATE_USER_ERROR,
  UPDATE_PASSWORD_ERROR,
  GET_ALL_USERS_PAGE_ERROR,
  GET_PERMISSIONS_ERROR,
  UPDATE_ROLE_NAME_ERROR,
  UPDATE_IMAGE_ERROR,
} from "./types";
import { setAlert } from "./alerts";
import setAuthToken from "../../utils/setAuthToken";

const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl = 'http://localhost:3001';

//gets user by token
export const getUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const response = await axios.get(`${baseUrl}/api/auth`);
    dispatch({
      type: GET_USER,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_ERROR,
      payload: { msg: "Failed to get user" },
    });
  }
};

//gets all users
export const getAllUsers = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/auth/users`);

    dispatch({
      type: GET_ALL_USERS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_USERS_ERROR,
      payload: { msg: "Failed to get users" },
    });
  }
};

//gets all users paginated
export const getAllUsersPage = (page) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/auth/users/page/${page}/10`
    );

    dispatch({
      type: GET_ALL_USERS_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_USERS_PAGE_ERROR,
      payload: { msg: "Failed to get users" },
    });
  }
};

//gets all roles
export const getAllRoles = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/auth/role`);

    dispatch({
      type: GET_ALL_ROLES,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_ROLES_ERROR,
      payload: { msg: "Failed to get roles" },
    });
  }
};

//gets role
export const getRole = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/auth/role/${id}`);

    dispatch({
      type: GET_ROLE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ROLE_ERROR,
      payload: { msg: "Failed to get role" },
    });
  }
};

//gets permissions
export const getPermissions = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/auth/permissions`);

    dispatch({
      type: GET_PERMISSIONS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PERMISSIONS_ERROR,
      payload: { msg: "Failed to get permissions" },
    });
  }
};

//updates role by user id
export const updateRole = (id, role) => async (dispatch) => {
  try {
    const response = await axios.put(`${baseUrl}/api/auth/role/${id}`, {
      role,
    });

    dispatch({
      type: UPDATE_ROLE,
      payload: response.data,
    });
    dispatch(getAllUsers());
    dispatch(setAlert("User role updated successfully!", "success"));
  } catch (err) {
    dispatch(setAlert("Unable to update role", "danger"));
    dispatch({
      type: UPDATE_ROLE_ERROR,
      payload: { msg: "Failed to update role" },
    });
  }
};

//updates status by user id
export const updateStatus = (id, status) => async (dispatch) => {
  try {
    const response = await axios.put(`${baseUrl}/api/auth/status/${id}`, {
      status,
    });

    dispatch({
      type: UPDATE_STATUS,
      payload: response.data,
    });
    dispatch(getAllUsers());
    dispatch(setAlert("User role updated successfully!", "success"));
  } catch (err) {
    dispatch(setAlert("Unable to update role", "danger"));
    dispatch({
      type: UPDATE_STATUS_ERROR,
      payload: { msg: "Failed to update status" },
    });
  }
};

//login
export const login = (user) => async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/api/auth/login`, {
      user,
    });
    dispatch({
      type: LOGIN,
      payload: response.data,
    });
    dispatch(getUser());
    dispatch(setAlert("Login Successful!", "success"));
  } catch (err) {
    dispatch(setAlert("Failed to login", "danger"));
    dispatch({
      type: LOGIN_ERROR,
      payload: { msg: "Login failure" },
    });
  }
};

//register
export const register = (user) => async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/api/auth/register`, {
      user,
    });

    dispatch({
      type: REGISTER,
      payload: response.data,
    });
    dispatch(setAlert("User created successfully!", "success"));
    dispatch(getAllUsers());
  } catch (err) {
    dispatch(setAlert("Failed to create user", "danger"));
    dispatch(getAllUsers);
    dispatch({
      type: REGISTER_ERROR,
      payload: { msg: "Login failure" },
    });
  }
};

//logout
export const logout = () => (dispatch) => {
  try {
    dispatch({
      type: LOGOUT,
    });
    dispatch(setAlert("Logout Successful!", "success"));
  } catch (err) {
    dispatch({
      type: LOGOUT_ERROR,
      payload: { msg: "Logout failure" },
    });
  }
};

//Add role
export const addRole = (name) => async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/api/auth/role`, {
      name,
    });

    dispatch({
      type: ADD_ROLE,
      payload: response.data,
    });
    dispatch(getAllRoles());
    dispatch(setAlert("Role added successfully!", "success"));
  } catch (err) {
    dispatch(setAlert("Could not add role", "danger"));
    dispatch({
      type: ADD_ROLE_ERROR,
      payload: { msg: "Failed to add role" },
    });
  }
};

//Add permissions
export const addPermission = (permission, role_id) => async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/api/auth/permission`, {
      permission,
      role_id,
    });

    dispatch({
      type: ADD_PERMISSION,
      payload: {
        permission,
        role_id,
      },
    });
  } catch (err) {
    dispatch({
      type: ADD_PERMISSION_ERROR,
      payload: { msg: "Failed to add permission" },
    });
  }
};

//deletes user
export const deleteUser = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/auth/user/${id}`);

    dispatch({
      type: DELETE_USER,
      payload: response.data,
    });
    dispatch(getAllUsers());
    dispatch(setAlert("User successfully deleted!", "success"));
  } catch {
    dispatch(setAlert("Could not delete user", "danger"));
    dispatch({
      type: DELETE_USER_ERROR,
      payload: { msg: "Failed to delete user" },
    });
  }
};

//deletes role
export const deleteRole = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/auth/role/${id}`);

    dispatch({
      type: DELETE_ROLE,
      payload: response.data,
    });
    dispatch(getAllRoles());
    dispatch(setAlert("Role successfully deleted!", "success"));
  } catch {
    dispatch(setAlert("Could not delete role", "danger"));
    dispatch({
      type: DELETE_ROLE_ERROR,
      payload: { msg: "Failed to delete role" },
    });
  }
};

//select a user
export const selectUser = (user) => (dispatch) => {
  dispatch({
    type: SELECT_USER,
    payload: user,
  });
};

//update user
export const updateUser = (user) => async (dispatch) => {
  try {
    const response = await axios.put(`${baseUrl}/api/auth/user/${user.id}`, {
      user,
    });

    dispatch({
      type: UPDATE_USER,
      payload: response.data,
    });
    dispatch(getAllUsers());
    dispatch(getUser());
    dispatch(setAlert("User successfully updated!", "success"));
  } catch (err) {
    dispatch(setAlert("Could not update user", "danger"));
    dispatch({
      type: UPDATE_USER_ERROR,
      payload: { msg: "Failed to update user" },
    });
  }
};

//update password
export const updatePassword = (id, password) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/auth/user/password/${id}`,
      {
        password,
      }
    );

    dispatch({
      type: UPDATE_PASSWORD,
      payload: response.data,
    });
    dispatch(getAllUsers());
    dispatch(setAlert("Password successfully updated!", "success"));
  } catch (err) {
    dispatch(setAlert("Could not update password", "danger"));
    dispatch({
      type: UPDATE_PASSWORD_ERROR,
      payload: { msg: "Failed to update password" },
    });
  }
};

//delete permissions
export const deletePermissions = (roleId) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/auth/permissions/${roleId}`
    );

    dispatch({
      type: DELETE_PERMISSIONS,
      payload: response.data,
    });
  } catch (err) {
    dispatch(setAlert("Could not delete permissions", "danger"));
    dispatch({
      type: DELETE_PERMISSIONS_ERROR,
      payload: { msg: "Failed to delete persmissions" },
    });
  }
};

//update role name
export const updateRoleName = (roleId, roleName) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/auth/role-name/${roleId}`,
      {
        roleName,
      }
    );

    dispatch({
      type: UPDATE_ROLE_NAME,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ROLE_NAME_ERROR,
      payload: { msg: "Failed to update role name" },
    });
  }
};

//sets/updates profile image
export const updateProfileImage = (image, id) => async (dispatch) => {
  try {
    const response = await axios.put(`${baseUrl}/api/auth/image-upload/${id}`, {
      image,
    });

    dispatch({
      type: UPDATE_IMAGE,
      payload: response.data,
    });
    dispatch(getUser(id));
    dispatch(setAlert("Image uploaded successfully!", "success"));
  } catch (err) {
    dispatch(setAlert("Failed to upload image", "danger"));
    dispatch({
      type: UPDATE_IMAGE_ERROR,
      payload: { msg: "Failed to update image" },
    });
  }
};
