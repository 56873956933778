import {
    DXP_ADD_EVENT,
    DXP_ADD_EVENT_ERROR,
    DXP_GET_EVENT_TYPES,
    DXP_GET_EVENT_TYPES_ERROR,
    DXP_GET_ALL_EVENTS,
    DXP_GET_ALL_EVENTS_ERROR,
    DXP_GET_EVENTS_PAGE,
    DXP_GET_EVENTS_PAGE_ERROR,
    DXP_SEARCH_EVENTS_PAGE,
    DXP_SEARCH_EVENTS_PAGE_ERROR,
    DXP_SELECT_EVENT,
    DXP_GET_ALL_EVENT_COUNT,
    DXP_GET_ALL_EVENT_COUNT_ERROR,
    DXP_UPDATE_EVENT,
    DXP_UPDATE_EVENT_ERROR,
    DXP_DELETE_EVENT,
    DXP_DELETE_EVENT_ERROR,
    DXP_SEARCH_EVENTS_COUNT,
    DXP_SEARCH_EVENTS_COUNT_ERROR,
    DXP_GET_ALL_EVENTS_BY_LANGUAGE,
    DXP_GET_ALL_EVENTS_BY_LANGUAGE_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    events: [],
    eventTypes: [],
    publishedEvents: [],
    eventsSearchPage: [],
    eventsByLanguage: [],
    selectedEvent: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_EVENTS:
        case DXP_GET_EVENTS_PAGE:
            return {
                ...state,
                events: payload,
                publishedEvents: payload.filter(event => event.published),
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_EVENT_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case DXP_SEARCH_EVENTS_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_EVENT_TYPES:
            return {
                ...state,
                eventTypes: payload,
                isLoading: false,
                success: true
            }
        case DXP_SELECT_EVENT:
            return {
                ...state,
                selectedEvent: payload,
            };
        case DXP_SEARCH_EVENTS_PAGE:
            return {
                ...state,
                eventsSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_EVENTS_BY_LANGUAGE:
            return {
                ...state,
                eventsByLanguage: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_EVENT:
        case DXP_UPDATE_EVENT:
        case DXP_DELETE_EVENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_EVENTS_ERROR:
        case DXP_GET_EVENTS_PAGE_ERROR:
            return {
                ...state,
                events: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_ALL_EVENT_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case DXP_SEARCH_EVENTS_COUNT_ERROR:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: false
            }
        case DXP_GET_EVENT_TYPES_ERROR:
            return {
                ...state,
                eventTypes: payload,
                isLoading: false,
                success: false
            }

        case DXP_SEARCH_EVENTS_PAGE_ERROR:
            return {
                ...state,
                eventsSearchPage: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_ALL_EVENTS_BY_LANGUAGE_ERROR:
            return {
                ...state,
                eventsByLanguage: [],
                isLoading: false,
                success: false,
            };

        case DXP_ADD_EVENT_ERROR:
        case DXP_UPDATE_EVENT_ERROR:
        case DXP_DELETE_EVENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
