import {
    DXP_GET_XPERTS,
    DXP_GET_XPERTS_ERROR,
    DXP_GET_ALL_USERS,
    DXP_GET_ALL_USERS_ERROR,
    GET_USERS_COUNT,
    GET_USERS_COUNT_ERROR,
    DXP_ADD_USER,
    DXP_ADD_USER_ERROR,
    DXP_GET_USER,
    DXP_GET_USER_ERROR,
    DXP_UPDATE_USER,
    DXP_UPDATE_USER_ERROR,
    DXP_UPDATE_USER_PASS,
    DXP_UPDATE_USER_PASS_ERROR,
    DXP_GET_USER_BY_MEMBERSHIP,
    DXP_GET_USER_BY_MEMBERSHIP_ERROR,
    DXP_GET_USER_BY_GROUP,
    DXP_GET_USER_BY_GROUP_ERROR,
    DELETE_USER,
    DELETE_USER_ERROR,
    DPX_GET_ALL_USERS_PAGE,
    DPX_GET_ALL_USERS_PAGE_ERROR,
    DXP_SEARCH_USERS,
    DXP_SEARCH_USERS_ERROR,
    DXP_SEARCH_USERS_DASHBOARD,
    DXP_SEARCH_USERS_DASHBOARD_ERROR,
    DXP_SEARCH_USER_OPTIONS,
    DXP_SEARCH_USER_OPTIONS_ERROR,
    DXP_GET_All_XPERTS_PAGINATED,
    DXP_GET_All_XPERTS_PAGINATED_ERROR,
    DXP_GET_ALL_XPERTS_BY_GROUP_PAGINATED,
    DXP_GET_ALL_XPERTS_BY_GROUP_PAGINATED_ERROR,
    DXP_GET_ALL_XPERTS_BY_SPECIALTY_PAGINATED,
    DXP_GET_ALL_XPERTS_BY_SPECIALTY_PAGINATED_ERROR,
    DXP_SEARCH_XPERTS_PAGINATED,
    DXP_SEARCH_XPERTS_PAGINATED_ERROR,
    DXP_GET_ALL_USERS_ID_NAME_ONLY,
    DXP_GET_ALL_USERS_ID_NAME_ONLY_ERROR,
    DXP_GET_ALL_PENDING_STUDENTS_PAGINATED,
    DXP_GET_ALL_PENDING_STUDENTS_PAGINATED_ERROR,
    DXP_UPDATE_APPROVE_STUDENT,
    DXP_UPDATE_APPROVE_STUDENT_ERROR,
    DXP_UPDATE_REJECT_STUDENT,
    DXP_UPDATE_REJECT_STUDENT_ERROR,
    DXP_SEARCH_PENDING_STUDENTS_PAGINATED,
    DXP_SEARCH_PENDING_STUDENTS_PAGINATED_ERROR,
    DXP_ADD_BULK_USERS,
    DXP_ADD_BULK_USERS_ERROR,
    DXP_DELETE_BULK_USERS,
    DXP_DELETE_BULK_USERS_ERROR,
    DXP_GET_COMPLIANCE_DOCS,
    DXP_GET_COMPLIANCE_DOCS_ERROR,
    DXP_UPDATE_USER_MEMBERSHIP_USERTYPEID,
    DXP_UPDATE_USER_MEMBERSHIP_USERTYPEID_ERROR,
    DXP_UNDO_DOWNGRADE_MEMBERSHIP,
    DXP_UNDO_DOWNGRADE_MEMBERSHIP_ERROR,
    DXP_UPDATE_USER_SUBSCRIPTION_STATUS,
    DXP_UPDATE_USER_SUBSCRIPTION_STATUS_ERROR,
    DXP_UPDATE_ONLY_USER_USERTYPEID,
    DXP_UPDATE_ONLY_USER_USERTYPEID_ERROR,
    DXP_UPDATE_ONLY_USER_NEXT_BILLING_DATE,
    DXP_UPDATE_ONLY_USER_NEXT_BILLING_DATE_ERROR
} from "../actions/types";
import { searchUserOptions } from "../actions/users";

const initialState = {
    xperts: [],
    allPendingStudentsPaginated: [],
    allPendingStudentsPaginatedCount: null,
    allXpertsPaginated: [],
    allXpertsCount: null,
    XpertsByGroupPaginated: [],
    XpertsByGroupPaginatedCount: null,
    XpertsBySpecialtyPaginated: [],
    XpertsBySpecialtyPaginatedCount: null,
    SearchXpertsPaginated: [],
    SearchXpertsPaginatedCount: null,
    SearchPendingStudentsPaginated: [],
    SearchPendingStudentsPaginatedCount: null,
    allUsers: [],
    allUsersPaginatedCount: null,
    allUsersNameIdOnly: [],
    usersCount: null,
    userById: [],
    usersByMembership: [],
    usersByMembershipPageCount: null,
    usersByGroups: [],
    usersByGroupsPageCount: null,
    searchUsersPaginated: [],
    searchUsersPaginatedCount: null,
    searchUsersDashboard: [],
    searchUsersDashboardCount: null,
    complianceDocs: [],
    userOptions: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_PENDING_STUDENTS_PAGINATED:
            return {
                ...state,
                allPendingStudentsPaginated: payload.rows,
                allPendingStudentsPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_XPERTS:
            return {
                ...state,
                xperts: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_USERS:
        case DPX_GET_ALL_USERS_PAGE:
            return {
                ...state,
                allUsers: payload.rows,
                allUsersPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_USERS_ID_NAME_ONLY:
            return {
                ...state,
                allUsersNameIdOnly: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_All_XPERTS_PAGINATED:
            return {
                ...state,
                allXpertsPaginated: payload.rows,
                allXpertsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_XPERTS_BY_GROUP_PAGINATED:
            return {
                ...state,
                XpertsByGroupPaginated: payload.rows,
                XpertsByGroupPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_XPERTS_BY_SPECIALTY_PAGINATED:
            return {
                ...state,
                XpertsBySpecialtyPaginated: payload.rows,
                XpertsBySpecialtyPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_XPERTS_PAGINATED:
            return {
                ...state,
                SearchXpertsPaginated: payload.rows,
                SearchXpertsPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_PENDING_STUDENTS_PAGINATED:
            return {
                ...state,
                SearchPendingStudentsPaginated: payload.rows,
                SearchPendingStudentsPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_USERS:
            return {
                ...state,
                searchUsersPaginated: payload.rows,
                searchUsersPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_USERS_DASHBOARD:
            return {
                ...state,
                searchUsersDashboard: payload.rows,
                searchUsersDashboardCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_USER_OPTIONS:
            return {
                ...state,
                userOptions: payload,
                isLoading: false,
                success: true,
            };
        case GET_USERS_COUNT:
            return {
                ...state,
                usersCount: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_USER_BY_MEMBERSHIP:
            return {
                ...state,
                usersByMembership: payload.rows,
                usersByMembershipPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_USER_BY_GROUP:
            return {
                ...state,
                usersByGroups: payload.rows,
                usersByGroupsPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_USER:
            return {
                ...state,
                userById: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_COMPLIANCE_DOCS:
            return {
                ...state,
                complianceDocs: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_USER:
        case DXP_ADD_BULK_USERS:
        case DXP_UPDATE_USER:
        case DXP_UPDATE_USER_PASS:
        case DXP_UPDATE_APPROVE_STUDENT:
        case DXP_UPDATE_REJECT_STUDENT:
        case DELETE_USER:
        case DXP_DELETE_BULK_USERS:
        case DXP_UPDATE_USER_MEMBERSHIP_USERTYPEID:
        case DXP_UNDO_DOWNGRADE_MEMBERSHIP:
        case DXP_UPDATE_USER_SUBSCRIPTION_STATUS:
        case DXP_UPDATE_ONLY_USER_USERTYPEID:
        case DXP_UPDATE_ONLY_USER_NEXT_BILLING_DATE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_XPERTS_ERROR:
            return {
                ...state,
                xperts: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_ALL_USERS_ERROR:
        case DPX_GET_ALL_USERS_PAGE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_SEARCH_USERS_ERROR:
            return {
                ...state,
                searchUsersPaginated: payload,
                isLoading: false,
                success: false,
            };
        case DXP_SEARCH_USERS_DASHBOARD_ERROR:
            return {
                ...state,
                searchUsersDashboard: payload,
                isLoading: false,
                success: false,
            };
        case DXP_SEARCH_USER_OPTIONS_ERROR:
            return {
                ...state,
                userOptions: [],
                isLoading: false,
                success: false,
            };
        case GET_USERS_COUNT_ERROR:
            return {
                ...state,
                usersCount: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_USER_ERROR:
            return {
                ...state,
                userById: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_COMPLIANCE_DOCS_ERROR:
            return {
                ...state,
                complianceDocs: payload,
                isLoading: false,
                success: false,
            };
        case DXP_ADD_USER_ERROR:
        case DXP_ADD_BULK_USERS_ERROR:
        case DXP_UPDATE_USER_ERROR:
        case DXP_UPDATE_APPROVE_STUDENT_ERROR:
        case DXP_UPDATE_REJECT_STUDENT_ERROR:
        case DXP_UPDATE_USER_PASS_ERROR:
        case DXP_GET_USER_BY_MEMBERSHIP_ERROR:
        case DXP_GET_USER_BY_GROUP_ERROR:
        case DELETE_USER_ERROR:
        case DXP_GET_All_XPERTS_PAGINATED_ERROR:
        case DXP_GET_ALL_XPERTS_BY_GROUP_PAGINATED_ERROR:
        case DXP_GET_ALL_XPERTS_BY_SPECIALTY_PAGINATED_ERROR:
        case DXP_SEARCH_XPERTS_PAGINATED_ERROR:
        case DXP_SEARCH_PENDING_STUDENTS_PAGINATED_ERROR:
        case DXP_GET_ALL_USERS_ID_NAME_ONLY_ERROR:
        case DXP_GET_ALL_PENDING_STUDENTS_PAGINATED_ERROR:
        case DXP_DELETE_BULK_USERS_ERROR:
        case DXP_UPDATE_USER_MEMBERSHIP_USERTYPEID_ERROR:
        case DXP_UNDO_DOWNGRADE_MEMBERSHIP_ERROR:
        case DXP_UPDATE_USER_SUBSCRIPTION_STATUS_ERROR:
        case DXP_UPDATE_ONLY_USER_USERTYPEID_ERROR:
        case DXP_UPDATE_ONLY_USER_NEXT_BILLING_DATE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
