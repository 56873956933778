import {
    Card,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    MenuItem,
    InputBase,
    Menu,
    IconButton,
    Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from '@mui/material';

const SearchContainer = ({ placeholder, inputProps, onKeyUp, disabled, clear }) => {

    const theme = useTheme();

    const search = {
        width: "100%",
        height: "100%",
        marginLeft: '25px',
        position: 'relative',
        [theme.breakpoints.up("sm").replace('min-width', 'minWidth')]: {
            marginLeft: theme.spacing(5),
        },
    }
    const searchIcon = {
        padding: theme.spacing(4, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center'
    }
    const inputInput = {
        height: '100%',
        padding: theme.spacing(4, 1, 4, 10),
        paddingLeft: `calc(1em + ${theme.spacing(7)}px)`,
        margin: 0,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "90ch",
        },
    }
    const inputRoot = {
        color: "inherit",
    }


    return (
        <div style={search}>

            <div style={searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                key={clear}
                placeholder={placeholder}
                sx={{ input: inputInput, ...inputRoot }}
                inputProps={{ "aria-label": "search" }}
                onKeyUp={(e) => onKeyUp(e)}
                disabled={disabled ? disabled : false}
            />
        </div>
    )
}

export default SearchContainer;