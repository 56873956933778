import {
  GET_ALL_PRODUCT_VIDEOS,
  GET_ALL_PRODUCT_VIDEOS_TAG,
  GET_ALL_PRODUCT_VIDEOS_UPDATED,
  GET_ALL_PRODUCT_VIDEOS_PAGE,
  GET_PRODUCT_VIDEO,
  GET_PRODUCT_VIDEOS_COUNT,
  SELECT_PRODUCT_VIDEO,
  SEARCH_PRODUCT_VIDEOS_PAGE,
  ADD_PRODUCT_VIDEOS,
  UPDATE_PRODUCT_VIDEOS,
  DELETE_PRODUCT_VIDEOS,
  ALL_PRODUCT_VIDEOS_ERROR,
  GET_PRODUCT_VIDEOS_ERROR,
  GET_PRODUCT_VIDEOS_COUNT_ERROR,
  ADD_PRODUCT_VIDEOS_ERROR,
  UPDATE_PRODUCT_VIDEOS_ERROR,
  DELETE_PRODUCT_VIDEOS_ERROR,
  GET_ALL_PRODUCT_VIDEOS_ERROR,
  GET_ALL_PRODUCT_VIDEOS_PAGE_ERROR,
  SEARCH_PRODUCT_VIDEOS_ERROR,
  SEARCH_PRODUCT_VIDEOS_PAGE_ERROR,
  SEARCH_PRODUCT_VIDEOS,
  UNPUBLISH_VIDEOS,
  UNPUBLISH_VIDEOS_ERROR,
  UPDATE_VIDEO_PRODUCT_MAP,
  DELETE_VIDEO_PRODUCT_ASSOCIATION,
  UPDATE_VIDEO_PRODUCT_MAP_ERROR,
  DELETE_VIDEO_PRODUCT_ASSOCIATION_ERROR,
  GET_VIDEO_PRODUCTS,
  GET_VIDEO_PRODUCTS_ERROR
} from "../actions/types";

const initialState = {
  productVideos: [],
  productVideosProducts: [],
  productVideosUpdated: [],
  productVideoSearch: [],
  productVideoSearchPage: [],
  productVideoSearchPageCount: null,
  productVideo: null,
  selectedProductVideo: [],
  isLoading: true,
  success: false,
  count: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_PRODUCT_VIDEOS_PAGE:
    case GET_ALL_PRODUCT_VIDEOS:
    case GET_ALL_PRODUCT_VIDEOS_TAG:
      return {
        ...state,
        productVideos: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_PRODUCT_VIDEOS_UPDATED:
      return {
        ...state,
        productVideosUpdated: payload,
        isLoading: false,
        success: true,
      }
    case GET_PRODUCT_VIDEO:
      return {
        ...state,
        productVideo: payload,
        isLoading: false,
        success: true,
      };
    case GET_VIDEO_PRODUCTS_ERROR:
      return {
        ...state,
        productVideosProducts: payload,
        isLoading: false,
        success: false,
      };
    case GET_VIDEO_PRODUCTS:
      return {
        ...state,
        productVideosProducts: payload,
        isLoading: false,
        success: true,
      };
    case SELECT_PRODUCT_VIDEO:
      return {
        ...state,
        selectedProductVideo: payload,
      };
    case GET_PRODUCT_VIDEOS_COUNT:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      };
    case SEARCH_PRODUCT_VIDEOS:
      return {
        ...state,
        productVideoSearch: payload,
        isLoading: false,
        success: true,
      };
    case SEARCH_PRODUCT_VIDEOS_PAGE:
      return {
        ...state,
        productVideoSearchPage: payload.rows,
        productVideoSearchPageCount: payload.count,
        isLoading: false,
        success: true,
      };
    case UNPUBLISH_VIDEOS:
    case UPDATE_PRODUCT_VIDEOS:
    case DELETE_PRODUCT_VIDEOS:
    case ADD_PRODUCT_VIDEOS:
    case ALL_PRODUCT_VIDEOS_ERROR:
      return {
        ...state,
        products: payload,
        isLoading: false,
        success: false,
      };
    case UNPUBLISH_VIDEOS_ERROR:
    case DELETE_PRODUCT_VIDEOS_ERROR:
    case UPDATE_PRODUCT_VIDEOS_ERROR:
    case ADD_PRODUCT_VIDEOS_ERROR:
    case UPDATE_VIDEO_PRODUCT_MAP_ERROR:
    case DELETE_VIDEO_PRODUCT_ASSOCIATION_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case GET_ALL_PRODUCT_VIDEOS_ERROR:
      return {
        ...state,
        productVideo: payload,
        isLoading: false,
        success: false,
      };
    case GET_PRODUCT_VIDEOS_COUNT_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: false,
      };
    case SEARCH_PRODUCT_VIDEOS_ERROR:
      return {
        ...state,
        productVideoSearch: payload,
        isLoading: false,
        success: false,
      };
    case SEARCH_PRODUCT_VIDEOS_PAGE_ERROR:
      return {
        ...state,
        productVideoSearchPage: payload.rows,
        productVideoSearchPageCount: payload.count,
        isLoading: false,
        success: false,
      };
    case UPDATE_VIDEO_PRODUCT_MAP:
    case DELETE_VIDEO_PRODUCT_ASSOCIATION:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    default:
      return state;
  }
}
