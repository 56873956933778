import {
    ADD_COURSE,
    ADD_COURSE_ERROR,
    GET_ALL_COURSES,
    GET_ALL_COURSES_ERROR,
    GET_PUBLISHED_COURSES,
    GET_PUBLISHED_COURSES_ERROR,
    GET_COURSES_PAGE,
    GET_COURSES_PAGE_ERROR,
    SEARCH_COURSES_PAGE,
    SEARCH_COURSES_PAGE_ERROR,
    SELECT_COURSE,
    GET_ALL_COURSE_COUNT,
    GET_ALL_COURSE_COUNT_ERROR,
    UPDATE_COURSE,
    UPDATE_COURSE_ERROR,
    DELETE_COURSE,
    DELETE_COURSE_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    courses: [],
    publishedCourses: [],
    coursesSearchPage: [],
    selectedCourse: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_COURSES:
        case GET_COURSES_PAGE:
            return {
                ...state,
                courses: payload,
                isLoading: false,
                success: true,
            };
        case GET_PUBLISHED_COURSES:
            return {
                ...state,
                publishedCourses: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_COURSE_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case GET_ALL_COURSE_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case SELECT_COURSE:
            return {
                ...state,
                selectedCourse: payload,
            };
        case SEARCH_COURSES_PAGE:
            return {
                ...state,
                coursesSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_COURSES_PAGE_ERROR:
            return {
                ...state,
                coursesSearchPage: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_COURSES_ERROR:
        case GET_COURSES_PAGE_ERROR:
            return {
                ...state,
                courses: payload,
                isLoading: false,
                success: false,
            };
        case GET_PUBLISHED_COURSES_ERROR:
            return {
                ...state,
                publishedCourses: [],
                isLoading: false,
                success: false,
            };
        case ADD_COURSE:
        case UPDATE_COURSE:
        case DELETE_COURSE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case ADD_COURSE_ERROR:
        case UPDATE_COURSE_ERROR:
        case DELETE_COURSE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
