import {
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_UPDATED,
  GET_ALL_CATEGORIES_PAGE,
  ALL_CATEGORIES_ERROR,
  GET_CATEGORY,
  GET_CATEGORY_ERROR,
  GET_CATEGORY_COUNT,
  GET_CATEGORY_COUNT_ERROR,
  ADD_CATEGORY,
  ADD_CATEGORY_ERROR,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY_ERROR,
  DELETE_CATEGORY_ERROR,
  GET_ALL_CATEGORIES_PAGE_ERROR,
  SELECT_CATEGORY,
  SET_FEATURED_CATEGORY,
  SET_FEATURED_CATEGORIES_FALSE,
  GET_FEATURED_CATEGORIES,
  SET_FEATURED_CATEGORY_ERROR,
  SET_FEATURED_CATEGORIES_FALSE_ERROR,
  GET_FEATURED_CATEGORIES_ERROR,
  CATEGORY_SEARCH,
  CATEGORY_SEARCH_ERROR,
  GET_ALL_FEATURED_CATEGORY_CONTENT,
  DELETE_FEATURED_CATEGORY_CONTENT,
  DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE,
  ADD_FEATURED_CATEGORY_CONTENT,
  GET_ALL_FEATURED_CATEGORY_CONTENT_ERROR,
  DELETE_FEATURED_CATEGORY_CONTENT_ERROR,
  DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE_ERROR,
  ADD_FEATURED_CATEGORY_CONTENT_ERROR,
} from "../actions/types";

const initialState = {
  categories: [],
  categoriesUpdated: [],
  featuredCategories: [],
  featuredCategoryContent: [],
  categorySearch: [],
  selectedCategory: null,
  category: null,
  isLoading: true,
  success: false,
  count: null
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_CATEGORIES_PAGE:
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_CATEGORIES_UPDATED:
      return {
        ...state,
        categoriesUpdated: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_FEATURED_CATEGORY_CONTENT:
      return {
        ...state,
        featuredCategoryContent: payload,
        isLoading: false,
        success: true,
      };
    case SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
        isLoading: false,
        success: true
      }
    case GET_CATEGORY:
      return {
        ...state,
        category: payload,
        isLoading: false,
        success: true,
      };
    case CATEGORY_SEARCH:
      return {
        ...state,
        categorySearch: payload,
        isLoading: false,
        success: true,
      };
    case GET_FEATURED_CATEGORIES:
      return {
        ...state,
        featuredCategories: payload,
        isLoading: false,
        success: true,
      };
    case GET_CATEGORY_COUNT:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      }
    case UPDATE_CATEGORY:
    case DELETE_CATEGORY:
    case ADD_CATEGORY:
    case SET_FEATURED_CATEGORY:
    case SET_FEATURED_CATEGORIES_FALSE:
    case DELETE_FEATURED_CATEGORY_CONTENT:
    case DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE:
    case ADD_FEATURED_CATEGORY_CONTENT:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case GET_ALL_CATEGORIES_PAGE_ERROR:
    case ALL_CATEGORIES_ERROR:
      return {
        ...state,
        categories: payload,
        isLoading: false,
        success: false,
      };
    case GET_ALL_FEATURED_CATEGORY_CONTENT_ERROR:
      return {
        ...state,
        featuredCategoryContent: payload,
        isLoading: false,
        success: false,
      };
    case CATEGORY_SEARCH_ERROR:
      return {
        ...state,
        categorySearch: payload,
        isLoading: false,
        success: false,
      };
    case DELETE_CATEGORY_ERROR:
    case UPDATE_CATEGORY_ERROR:
    case ADD_CATEGORY_ERROR:
    case SET_FEATURED_CATEGORY_ERROR:
    case SET_FEATURED_CATEGORIES_FALSE_ERROR:
    case DELETE_FEATURED_CATEGORY_CONTENT_ERROR:
    case DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE_ERROR:
    case ADD_FEATURED_CATEGORY_CONTENT_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case GET_CATEGORY_ERROR:
      return {
        ...state,
        category: payload,
        isLoading: false,
        success: false,
      };
    case GET_FEATURED_CATEGORIES_ERROR:
      return {
        ...state,
        featuredCategories: payload,
        isLoading: false,
        success: false,
      };
    case GET_CATEGORY_COUNT_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true
      }
    default:
      return state;
  }
}
