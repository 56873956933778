import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  authLayoutRoutes,
  protectedRoutes,
  buyersGuideRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import BuyersGuideLayout from "../layouts/BuyersGuide";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

const childRoutes = (Layout, routes) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment;
    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment;
        return (
          <Route
            key={index}
            path={element.path}
            exact={'true'}
            element={(
              <Guard>
                <Layout>
                  <element.component />
                </Layout>
              </Guard>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact={'true'}
        element={(
          <Guard>
            <Layout>
              <Component />
            </Layout>
          </Guard>
        )}
      />
    ) : null;
  });

const CMSRoutes = () => (
  <Router>
    <Routes>
      {childRoutes(DashboardLayout, protectedRoutes)}
      {childRoutes(BuyersGuideLayout, buyersGuideRoutes)}
      {childRoutes(AuthLayout, authLayoutRoutes)}

      <Route
        path='*'
        element={(
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Routes>
  </Router>
);

export default CMSRoutes;
