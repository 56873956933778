import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
    MenuItem,
    IconButton as MuiIconButton,
    Button,
    Dialog,
    Select,
    FormControl,
    InputLabel,
    FormGroup,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import { setAlert } from "../redux/actions/alerts";
import styles from './ClearCacheButton.module.css';

function ClearCacheButton({ setAlert }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [chosenSite, setChosenSite] = useState('')
    const [checkedOptions, setCheckedOptions] = useState([])
    const [disabledButton, setDisabledButton] = useState(false)
    const [stagingExpanded, setStagingExpanded] = useState({
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        28: 0
    })

    const [count, setCount] = useState(30)

    const sites = ['DPS', 'DXP']

    const dpsCacheOptions = [
        { id: 1, label: 'Clear server side cache' },
        { id: 2, label: 'Refresh All Cache' },
        { id: 3, label: 'Refresh Category Cache' },
        { id: 4, label: 'Refresh Product Cache' },
        { id: 5, label: 'Refresh Manufacturer Cache' },
    ]

    const dpsRefreshOptions = [
        { id: 29, label: 'Refresh Top Products' },
    ]

    const dxpCacheOptions = [
        { id: 6, label: 'Refresh Cache' },
        { id: 7, label: 'Refresh All Content Page Cache' },
        { id: 8, label: 'Refresh Article Cache' },
        { id: 28, label: 'Refresh Blog Cache' },
        { id: 9, label: 'Refresh Category Cache' },
        { id: 10, label: 'Refresh Course Cache' },
        { id: 11, label: 'Refresh Event Cache' },
        { id: 12, label: 'Refresh Homepage Cache' },
        { id: 13, label: 'Refresh Search Cache' },
        { id: 14, label: 'Refresh Video Cache' },
        { id: 15, label: 'Refresh Webinar Cache' },
        { id: 16, label: 'Refresh Xpert Caches' },
        { id: 17, label: 'Refresh Cataloge Sidebar' },
    ]

    const dxpRevalidateOptions = [
        { id: 19, label: 'Revalidate DXP' },
        { id: 20, label: 'Revalidate Home Page' },
        { id: 21, label: 'Revalidate Register Page' },
        { id: 22, label: 'Revalidate Category Pages' },
        { id: 23, label: 'Revalidate Events Page' },
        { id: 24, label: 'Revalidate Webinars Page' },
        { id: 25, label: 'Revalidate Free Content Page' },
        { id: 26, label: 'Revalidate Misc Page' },
        { id: 27, label: 'Revalidate All Content Page' },
    ]

    const duplicateRevalidation = {
        20: 12,
        21: 12,
        22: 9,
        23: 11,
        24: 15,
        25: 7
    }

    const getExpandedData = async () => {
        try {
            const data = await axios.get(`${dxpBaseUrl}/api/expanded/count/`)
            if (data?.data) {
                setStagingExpanded({
                    7: data.data.all,
                    8: data.data.articles,
                    9: data.data.sub_category,
                    10: data.data.courses,
                    11: data.data.events,
                    12: data.data.home,
                    13: data.data.search,
                    14: data.data.videos,
                    15: data.data.webinars,
                    16: data.data.xperts,
                    28: data.data.blogs,
                })
            }
        } catch (err) {
            console.error(err)
        }
    }
    useEffect(() => {
        getExpandedData()
    }, [])

    const handleClose = () => {
        setChosenSite('')
        setCheckedOptions([]);
        setDialogOpen(false);
    };

    const handleDialogOpen = (e) => {
        setDialogOpen(true);
    };

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dxpBaseUrl = process.env.REACT_APP_DXP_BASE_URL;

    const handleSiteChange = (site) => {
        setCheckedOptions([]);
        setChosenSite(site);
    }

    const handleCheck = (e, option) => {
        if (!e) return;
        if (e.target.checked === true) {
            if (option === 6 || option === 2 || option === 19) {
                if (option === 2 && checkedOptions.includes(1)) {
                    setCheckedOptions([1, 2])
                } else if (option === 6) {
                    setCheckedOptions([option])
                } else {
                    const dxpRevalidateIds = [20, 21, 22, 23, 24, 25, 26, 27]
                    const clearedOptions = checkedOptions.filter(checked => !dxpRevalidateIds.includes(checked))
                    setCheckedOptions([...clearedOptions, option])
                }
            } else {
                setCheckedOptions(prevCheckedOptions => [...prevCheckedOptions, option])
            }
        } else {
            const filteredOptions = checkedOptions.filter(el => el !== option);
            setCheckedOptions(filteredOptions)
        }
    }

    const chooseRoute = async (option, submitOption) => {

        switch (option) {
            case 1:
                await axios.get(`https://api.dentalproductshopper.com/api/cache/clear`);
                return
            case 2:
                await axios.get(`${baseUrl}/api/expanded/all`);
                return
            case 3:
                await axios.get(`${baseUrl}/api/expanded/category`);
                return
            case 4:
                await axios.get(`${baseUrl}/api/expanded/products`);
                return
            case 5:
                await axios.get(`${baseUrl}/api/expanded/manufacturers`);
                return
            case 6:
                await axios.get(`${dxpBaseUrl}/api/expanded/refresh-all/${submitOption}`);
                return
            case 7:
                await axios.get(`${dxpBaseUrl}/api/expanded/all/${submitOption}`);
                return
            case 8:
                await axios.get(`${dxpBaseUrl}/api/expanded/articles/${submitOption}`);
                return
            case 28:
                await axios.get(`${dxpBaseUrl}/api/expanded/blogs/${submitOption}`);
                return
            case 9:
                await axios.get(`${dxpBaseUrl}/api/expanded/category/${submitOption}`);
                return
            case 10:
                await axios.get(`${dxpBaseUrl}/api/expanded/courses/${submitOption}`);
                return
            case 11:
                await axios.get(`${dxpBaseUrl}/api/expanded/events/${submitOption}`);
                return
            case 12:
                await axios.get(`${dxpBaseUrl}/api/expanded/home/${submitOption}`);
                return
            case 13:
                await axios.get(`${dxpBaseUrl}/api/expanded/search/${submitOption}`);
                return
            case 14:
                await axios.get(`${dxpBaseUrl}/api/expanded/videos/${submitOption}`);
                return
            case 15:
                await axios.get(`${dxpBaseUrl}/api/expanded/webinars/${submitOption}`);
                return
            case 16:
                await axios.get(`${dxpBaseUrl}/api/expanded/xperts/${submitOption}`);
                return
            case 17:
                await axios.get(`${dxpBaseUrl}/api/expanded/json`);
                return
            case 18:
                await axios.get(`${dxpBaseUrl}/api/expanded/misc`);
                return
            case 19:
                await axios.get(`${dxpBaseUrl}/api/revalidate/refresh-all`);
                return
            case 20:
                await axios.get(`${dxpBaseUrl}/api/revalidate/home`);
                return
            case 21:
                await axios.get(`${dxpBaseUrl}/api/revalidate/register`);
                return
            case 22:
                await axios.get(`${dxpBaseUrl}/api/revalidate/category`);
                return
            case 23:
                await axios.get(`${dxpBaseUrl}/api/revalidate/events`);
                return
            case 24:
                await axios.get(`${dxpBaseUrl}/api/revalidate/webinars`);
                return
            case 25:
                await axios.get(`${dxpBaseUrl}/api/revalidate/free`);
                return
            case 26:
                await axios.get(`${dxpBaseUrl}/api/revalidate/faq`);
                return
            case 27:
                await axios.get(`${dxpBaseUrl}/api/revalidate/all`);
                return
            
            case 29:
                await axios.get(`${baseUrl}/api/topvieweds/newtopviewed`);
                return
        }


    }

    const disableButton = () => {
        setDisabledButton(true)
        let i = 30
        const countDown = setInterval(() => {
            i--
            if (i <= 0) {
                setDisabledButton(false)
                setCount(30)
                i = 30
                clearInterval(countDown)
            }
            setCount(prevCount => prevCount - 1);
        }, 500)
    }

    const handleSubmit = async (submitOption) => {
        disableButton()
        try {
            for (let option of checkedOptions) {
                if (checkedOptions.includes(duplicateRevalidation[option])) {
                    continue
                }
                await chooseRoute(option, submitOption);
            }
            setAlert('Cache updated', 'success')

        } catch (err) {
            console.log(err)
            setAlert('Error updating cache', 'danger')
        }
        handleClose();
        getExpandedData()
    }

    const revalidateSelected = dxpRevalidateOptions?.find(option => checkedOptions?.includes(option.id))
    const selectedEmptyStagingData = checkedOptions?.find(option => {
        if (option === 6) {
            return Object.values(stagingExpanded)?.includes(0)
        }
        return stagingExpanded[option] === 0
    })

    return (
        <>
            <Button color="primary" variant="outlined" onClick={(e) => handleDialogOpen(e)}>Refresh Website Caches</Button>

            <Dialog open={dialogOpen} onClose={handleClose}>
                <div style={{ padding: '2rem', minWidth: '500px', minHeight: '400px' }}>
                    <FormControl
                        variant="outlined"
                        style={{ width: "80%" }}
                    >
                        <InputLabel id="site-label">Select Site</InputLabel>
                        <Select
                            labelId="site-label"
                            id="site"
                            // value={language.language_name || ''}
                            name="site"
                            label='Select Site'
                            onChange={(e) => handleSiteChange(e.target.value)}
                        >
                            {sites.map((site, i) =>
                                <MenuItem key={i} value={site}>{site}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    {chosenSite === 'DPS' &&
                        <>
                        <div>
                            <FormGroup style={{ margin: '20px' }}>
                                {dpsCacheOptions.map((option, i) => (
                                    <FormControlLabel
                                        style={{ marginLeft: '10px' }}
                                        key={i}
                                        disabled={checkedOptions.includes(2) && (option.id !== 2 && option.id !== 1)}
                                        control={
                                            <Checkbox
                                                checked={checkedOptions.includes(option.id)}
                                                onChange={(e) => handleCheck(e, option.id)}
                                            />
                                        }
                                        label={option.label}
                                    />

                                ))}

                            </FormGroup>
                        </div>
                        <hr />
                        <div>
                            <FormGroup style={{ margin: '20px' }}>
                                {dpsRefreshOptions.map((option, i) => (
                                    <FormControlLabel
                                        style={{ marginLeft: '10px' }}
                                        key={i}
                                        disabled={checkedOptions.includes(2) && (option.id !== 2 && option.id !== 1)}
                                        control={
                                            <Checkbox
                                                checked={checkedOptions.includes(option.id)}
                                                onChange={(e) => handleCheck(e, option.id)}
                                            />
                                        }
                                        label={option.label}
                                    />

                                ))}

                            </FormGroup>
                        </div>

                        </>

                    }
                    {chosenSite === 'DXP' &&
                        <>
                            <div>
                                <FormGroup style={{ margin: '20px' }}>
                                    {dxpCacheOptions.map((option, i) => (
                                        <FormControlLabel
                                            style={{ marginLeft: '10px' }}
                                            key={i}
                                            disabled={checkedOptions.includes(6) && option.id !== 6}
                                            control={
                                                <Checkbox
                                                    checked={checkedOptions.includes(option.id)}
                                                    onChange={(e) => handleCheck(e, option.id)}
                                                />
                                            }
                                            label={option.label}
                                        />
                                    ))}

                                </FormGroup>
                            </div>
                            <hr />
                            <div>
                                <FormGroup style={{ margin: '20px' }}>
                                    {dxpRevalidateOptions.map((option, i) => (
                                        <FormControlLabel
                                            style={{ marginLeft: '10px' }}
                                            key={i}
                                            disabled={checkedOptions.includes(19) && option.id !== 19 || checkedOptions.includes(6)}
                                            control={
                                                <Checkbox
                                                    checked={checkedOptions.includes(option.id)}
                                                    onChange={(e) => handleCheck(e, option.id)}
                                                />
                                            }
                                            label={option.label}
                                        />
                                    ))}

                                </FormGroup>
                            </div>
                        </>
                    }
                    {chosenSite !== '' &&
                        <div style={{ display: 'flex' }}>
                            <Button
                                disabled={disabledButton}
                                variant='outlined'
                                color='primary'
                                style={{ margin: '10px' }}
                                onClick={() => handleSubmit('submit')}>
                                {disabledButton ? count : 'Submit'}
                            </Button>

                            {chosenSite === 'DXP' &&
                                <div className={revalidateSelected ? styles.disabled_button : ''} style={{ display: 'flex' }}>

                                    <Button
                                        disabled={disabledButton || revalidateSelected}
                                        variant='outlined'
                                        color='primary'
                                        style={{ margin: '10px' }}
                                        onClick={() => handleSubmit('test')}>
                                        {disabledButton ? count : 'Test'}
                                    </Button>

                                    <div className={selectedEmptyStagingData && !revalidateSelected ? styles.disabled_promote_button : ''} >
                                        <Button
                                            disabled={disabledButton || revalidateSelected || selectedEmptyStagingData}
                                            variant='outlined'
                                            color='primary'
                                            style={{ margin: '10px' }}
                                            onClick={() => handleSubmit('promote')}>
                                            {disabledButton ? count : 'Promote'}
                                        </Button>
                                    </div>

                                </div>
                            }
                        </div>
                    }
                </div>
            </Dialog >

        </>
    );
}



const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
    setAlert,

})(ClearCacheButton);
