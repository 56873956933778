import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import "../../vendor/perfect-scrollbar.css";

import { changeInputData } from "../../redux/actions/articleTemplates";

import {
    ListItemText,
    TextField as MuiTextField,
    InputLabel
} from "@mui/material";


const TextFieldColor = styled(MuiTextField)`
    margin: 0 16px;

    * {
        padding-left: 3px;
        height: 30px;
        border-bottom: none;
     
    }
   
`;

const TemplateOptions = ({
    changeInputData,
    articleTemplate: { inputtedData, hasTemplate, selectedSection, selectedInput },
}) => {
    const [currentOptions, setCurrentOptions] = useState([])

    useEffect(() => {
        if (!selectedInput.component || !selectedInput.index || !inputtedData[selectedInput.component] || !inputtedData[selectedInput.component][selectedInput.index]) {
            return
        }
        let possibleOptions

        if (selectedInput.childIndex) {
            possibleOptions = inputtedData[selectedInput.component][selectedInput.index].children[selectedInput.childIndex].options

        } else {
            possibleOptions = inputtedData[selectedInput.component][selectedInput.index].children[0].options

        }
        possibleOptions = Object.keys(possibleOptions).filter(key => key !== 'default')
        if (possibleOptions?.length > 0) {
            setCurrentOptions([...possibleOptions])
        }

    }, [selectedInput, inputtedData])

    const handleChangeOption = (option, e) => {
        if (!selectedInput || !selectedInput.component || !selectedInput.index || !inputtedData[selectedInput.component]) {
            return
        }
        let copiedInputData = inputtedData
        copiedInputData[selectedInput.component][selectedInput?.index].children[selectedInput?.childIndex ? selectedInput?.childIndex : 0].options[option] = +e.target.value

        changeInputData(copiedInputData)
    }

    return (
        <>

            <div style={{ margin: '10px 20px' }}>
                {currentOptions?.length > 0 && currentOptions.map((option, i) => {

                    return <div key={`${option}-${i}`} style={{ color: '#000', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                        <InputLabel style={{ color: '#eeeeee', width: '100%', marginBottom: '2px' }}>
                            {option + ':'}

                        </InputLabel>
                        <TextFieldColor
                            type={'number'}
                            style={{
                                width: '100px', backgroundColor: '#eeeeee', borderRadius: '5px'
                            }}
                            onChange={(e) => handleChangeOption(option, e)}
                        />
                    </div>
                })}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    articleTemplate: state.articleTemplate
});

export default connect(mapStateToProps, {
    changeInputData,
})(TemplateOptions);
// export default withRouter(connect(mapStateToProps, {
//     changeInputData,
// })(TemplateOptions));