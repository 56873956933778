import {
    GET_ALL_BLOGS,
    GET_ALL_BLOGS_ERROR,
    GET_BLOGS_PAGE,
    GET_BLOGS_PAGE_ERROR,
    ADD_BLOG,
    ADD_BLOG_ERROR,
    UPDATE_BLOG,
    UPDATE_BLOG_ERROR,
    DELETE_BLOG,
    DELETE_BLOG_ERROR,
    SELECT_BLOG,
    GET_BLOG_COUNT,
    GET_BLOG_COUNT_ERROR,
    GET_BLOGS_SEARCH_PAGE,
    GET_BLOGS_SEARCH_PAGE_ERROR,
    SEARCH_PUBLISHED_BLOGS,
    SEARCH_PUBLISHED_BLOGS_ERROR,
} from "../actions/types";

const initialState = {
    blogs: [],
    blogsSearchPage: [],
    blogsSearchCount: null,
    publishedBlogs: [],
    blog: null,
    count: null,
    selectedBlog: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_ALL_BLOGS:
        case GET_BLOGS_PAGE:
            return {
                ...state,
                blogs: payload,
                isLoading: false,
                success: true,
            };
        case GET_BLOGS_SEARCH_PAGE:
            return {
                ...state,
                blogsSearchPage: payload.rows,
                blogsSearchCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SEARCH_PUBLISHED_BLOGS:
            return {
                ...state,
                publishedBlogs: payload.rows,
                isLoading: false,
                success: true,
            };
        case ADD_BLOG:
        case DELETE_BLOG:
        case UPDATE_BLOG:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case GET_BLOG_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case SELECT_BLOG:
            return {
                ...state,
                selectedBlog: payload,
            };
        case GET_ALL_BLOGS_ERROR:
        case GET_BLOGS_PAGE_ERROR:
            return {
                ...state,
                blogs: payload,
                isLoading: false,
                success: false,
            };
        case GET_BLOGS_SEARCH_PAGE_ERROR:
            return {
                ...state,
                blogsSearchPage: payload.rows,
                blogsSearchCount: payload.count,
                isLoading: false,
                success: false,
            };
        case SEARCH_PUBLISHED_BLOGS_ERROR:
            return {
                ...state,
                publishedBlogs: [],
                isLoading: false,
                success: false,
            };
        case GET_BLOG_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false,
            };
        case ADD_BLOG_ERROR:
        case DELETE_BLOG_ERROR:
        case UPDATE_BLOG_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
