import { Pause } from "react-feather";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  GET_USER,
  GET_ALL_USERS,
  GET_ALL_USERS_PAGE,
  ADD_ROLE,
  ADD_PERMISSION,
  LOGIN_ERROR,
  LOGOUT_ERROR,
  REGISTER_ERROR,
  ADD_ROLE_ERROR,
  ADD_PERMISSION_ERROR,
  GET_USER_ERROR,
  GET_ALL_USERS_ERROR,
  GET_ALL_ADVERTISEMENTS_PAGE_ERROR,
  GET_ALL_USERS_PAGE_ERROR,
  UPDATE_IMAGE_ERROR,
} from "../actions/types";

const initialState = {
  user: null,
  token: localStorage.getItem("token"),
  users: null,
  isAuthenticated: null,
  roles: null,
  isLoading: true,
  success: false,
};

export default function (state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case LOGIN:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        user: {
          id: payload.user.id,
          email: payload.user.email,
          name: payload.user.firstName,
          title: payload.user.title,
          lastName: payload.user.lastName,
          bio: payload.user.bio,
          image: payload.user.image,
          userId: payload.user.userId,
          role_id: payload.user.role_id,
        },
        isAuthenticated: true,
        token: payload.token,
        roles: payload.user.Role,
        isLoading: false,
        success: true,
      };
    case GET_USER:
      return {
        ...state,
        user: {
          id: payload.id,
          email: payload.email,
          name: payload.firstName,
          title: payload.title,
          lastName: payload.lastName,
          bio: payload.bio,
          image: payload.image,
          userId: payload.userId,
          role_id: payload.role_id,
          permissions: payload.Role.Permissions,
          hasAccess: payload.Role.Permissions.map(permission => permission.permission)
        },
        isAuthenticated: true,
        roles: payload.Role,
        isLoading: false,
        success: true,
      };
    case GET_ALL_USERS_PAGE:
    case GET_ALL_USERS:
      return {
        ...state,
        users: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_USERS_PAGE_ERROR:
    case GET_ALL_USERS_ERROR:
      return {
        ...state,
        users: payload,
        isLoading: false,
        success: false,
      };
    case LOGOUT:
    case GET_USER_ERROR:
      localStorage.getItem("token");
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        roles: null,
        user: null,
        isAuthenticated: false,
        success: false,
        isLoading: false,
      };
    default:
      return state;
  }
}
