import {
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_UPDATED,
  GET_ALL_PRODUCTS_PAGE,
  GET_PRODUCT,
  SET_FEATURED_PRODUCT,
  SET_FEATURED_FALSE,
  GET_FEATURED_PRODUCTS,
  SELECT_PRODUCT,
  ALL_PRODUCTS_ERROR,
  GET_PRODUCT_ERROR,
  GET_PRODUCT_COUNT,
  GET_NEW_FEATURED_PRODUCTS,
  SET_NEW_FEATURED_PRODUCT,
  SET_NEW_FEATURED_FALSE,
  GET_PRODUCT_COUNT_ERROR,
  ADD_PRODUCT,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_PAGE,
  ADD_PRODUCT_ERROR,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
  GET_ALL_ADVERTISEMENTS_PAGE_ERROR,
  SEARCH_PRODUCTS_ERROR,
  SEARCH_PRODUCTS_PAGE_ERROR,
  GET_FEATURED_PRODUCTS_ERROR,
  SET_FEATURED_PRODUCT_ERROR,
  SET_FEATURED_FALSE_ERROR,
  GET_NEW_FEATURED_PRODUCTS_ERROR,
  SET_NEW_FEATURED_FALSE_ERROR,
  SET_NEW_FEATURED_PRODUCT_ERROR,
  GET_NEW_DEFAULT_PRODUCTS,
  GET_NEW_DEFAULT_PRODUCTS_ERROR,
  SET_NEW_DEFAULT_PRODUCT,
  SET_NEW_DEFAULT_PRODUCT_ERROR,
  SET_NEW_DEFAULT_FALSE,
} from "../actions/types";

const initialState = {
  products: [],
  productsUpdated: [],
  productSearch: [],
  productSearchPage: [],
  featuredNewProducts: [],
  featuredProductsNewProduct: [],
  defaultNewProducts: [],
  productSearchPageCount: null,
  product: null,
  selectedProduct: null,
  isLoading: true,
  success: false,
  count: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_PRODUCTS_PAGE:
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_PRODUCTS_UPDATED:
      return {
        ...state,
        productsUpdated: payload,
        isLoading: false,
        success: true,
      }
    case GET_PRODUCT:
      return {
        ...state,
        product: payload,
        isLoading: false,
        success: true,
      };
    case GET_FEATURED_PRODUCTS:
      return {
        ...state,
        featuredNewProducts: payload,
        isLoading: false,
        success: true,
      };
    case GET_NEW_FEATURED_PRODUCTS:
      return {
        ...state,
        featuredProductsNewProduct: payload,
        isLoading: false,
        success: true,
      };
    case GET_NEW_DEFAULT_PRODUCTS:
      return {
        ...state,
        defaultNewProducts: payload,
        isLoading: false,
        success: true,
      };
    case SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: payload,
      };
    case GET_PRODUCT_COUNT:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      };
    case SEARCH_PRODUCTS:
      return {
        ...state,
        productSearch: payload,
        isLoading: false,
        success: true,
      };
    case SEARCH_PRODUCTS_PAGE:
      return {
        ...state,
        productSearchPage: payload.rows,
        productSearchPageCount: payload.count,
        isLoading: false,
        success: true,
      };
    case UPDATE_PRODUCT:
    case DELETE_PRODUCT:
    case ADD_PRODUCT:
    case SET_FEATURED_PRODUCT:
    case SET_FEATURED_FALSE:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case GET_ALL_ADVERTISEMENTS_PAGE_ERROR:
    case ALL_PRODUCTS_ERROR:
      return {
        ...state,
        products: payload,
        isLoading: false,
        success: false,
      };
    case DELETE_PRODUCT_ERROR:
    case UPDATE_PRODUCT_ERROR:
    case ADD_PRODUCT_ERROR:
    case SET_FEATURED_PRODUCT_ERROR:
    case SET_FEATURED_FALSE_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case GET_PRODUCT_ERROR:
      return {
        ...state,
        product: payload,
        isLoading: false,
        success: false,
      };
    case GET_FEATURED_PRODUCTS_ERROR:
      return {
        ...state,
        featuredNewProducts: payload,
        isLoading: false,
        success: false,
      };
    case GET_NEW_FEATURED_PRODUCTS_ERROR:
      return {
        ...state,
        featuredProductsNewProduct: payload,
        isLoading: false,
        success: false,
      };
    case GET_NEW_DEFAULT_PRODUCTS_ERROR:
      return {
        ...state,
        defaultNewProducts: payload,
        isLoading: false,
        success: false,
      };
    case GET_PRODUCT_COUNT_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: false,
      };
    case SEARCH_PRODUCTS_ERROR:
      return {
        ...state,
        productSearch: payload,
        isLoading: false,
        success: false,
      };
    case SEARCH_PRODUCTS_PAGE_ERROR:
      return {
        ...state,
        productSearchPage: payload.rows,
        productSearchPageCount: payload.count,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
}
