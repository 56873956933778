import React from "react";
import { connect } from "react-redux";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { deleteArticle } from "../../redux/actions/articles";

const DeleteForm = ({
  deleteArticle,
  handleClose,
  page,
  article: { selectedArticle },
}) => {
  const handleDelete = (e) => {
    deleteArticle(selectedArticle.article_id, selectedArticle, page);
    handleClose();
  };
  return (
    <>
      <DialogTitle>Delete User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {selectedArticle.title}?
          <br />
          <br />
          <strong>This cannot be undone.</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={(e) => handleDelete(e)} color="primary">
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (state) => ({
  article: state.article,
});

export default connect(mapStateToProps, {
  deleteArticle,
})(DeleteForm);
