import {
	DXP_UPDATE_CONTENT_MAP,
	DXP_UPDATE_CONTENT_MAP_ERROR
} from "../actions/types";

const initialState = {
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_UPDATE_CONTENT_MAP:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_UPDATE_CONTENT_MAP_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}