import {
    DXP_ADD_PRODUCT_CATEGORY,
    DXP_ADD_PRODUCT_CATEGORY_ERROR,
    DXP_GET_PRODUCT_CATEGORIES,
    DXP_GET_PRODUCT_CATEGORIES_ERROR,
    DXP_GET_PRODUCT_CATEGORIES_PAGINATED,
    DXP_GET_PRODUCT_CATEGORIES_PAGINATED_ERROR,
    DXP_SELECT_PRODUCT_CATEGORY,
    DXP_GET_PRODUCT_CATEGORY_COUNT,
    DXP_GET_PRODUCT_CATEGORY_COUNT_ERROR,
    DXP_DELETE_PRODUCT_CATEGORY,
    DXP_DELETE_PRODUCT_CATEGORY_ERROR,
} from "../actions/types";

const initialState = {
    categoryCount: null,
    productCategories: [],
    allProductCategories: [],
    selectedProductCategory: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_PRODUCT_CATEGORIES:
            return {
                ...state,
                allProductCategories: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_PRODUCT_CATEGORIES_PAGINATED:
            return {
                ...state,
                productCategories: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_PRODUCT_CATEGORY_COUNT:
            return {
                ...state,
                categoryCount: payload,
                isLoading: false,
                success: true
            }
        case DXP_SELECT_PRODUCT_CATEGORY:
            return {
                ...state,
                selectedProductCategory: payload,
            };
        case DXP_ADD_PRODUCT_CATEGORY:
        case DXP_DELETE_PRODUCT_CATEGORY:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_PRODUCT_CATEGORIES_ERROR:
            return {
                ...state,
                allProductCategories: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_PRODUCT_CATEGORIES_PAGINATED_ERROR:
            return {
                ...state,
                productCategories: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_PRODUCT_CATEGORY_COUNT_ERROR:
            return {
                ...state,
                categoryCount: payload,
                isLoading: false,
                success: false
            }

        case DXP_ADD_PRODUCT_CATEGORY_ERROR:
        case DXP_DELETE_PRODUCT_CATEGORY_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
