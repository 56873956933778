import axios from "axios";
import {
    GET_ALL_ARTICLE_TYPES,
    GET_ALL_ARTICLE_TYPES_ERROR
} from "./types";

const baseUrl = process.env.REACT_APP_BASE_URL;

//gets all articles
export const getArticleTypes = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/api/articleTypes`);

        dispatch({
            type: GET_ALL_ARTICLE_TYPES,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ALL_ARTICLE_TYPES_ERROR,
            payload: { msg: "Could not fetch all article types" },
        });
    }
};