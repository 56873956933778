import {
    ADD_COURSETYPE,
    ADD_COURSETYPE_ERROR,
    GET_ALL_COURSETYPES,
    GET_ALL_COURSETYPES_ERROR,
    GET_COURSETYPES_PAGE,
    GET_COURSETYPES_PAGE_ERROR,
    SEARCH_COURSETYPES_PAGE,
    SEARCH_COURSETYPES_PAGE_ERROR,
    SELECT_COURSETYPE,
    GET_ALL_COURSETYPE_COUNT,
    GET_ALL_COURSETYPE_COUNT_ERROR,
    UPDATE_COURSETYPE,
    UPDATE_COURSETYPE_ERROR,
    DELETE_COURSETYPE,
    DELETE_COURSETYPE_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    courseTypes: [],
    courseTypesSearchPage: [],
    selectedCourseType: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_COURSETYPES:
        case GET_COURSETYPES_PAGE:
            return {
                ...state,
                courseTypes: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_COURSETYPE_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case GET_ALL_COURSETYPE_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case SELECT_COURSETYPE:
            return {
                ...state,
                selectedCourseType: payload,
            };
        case SEARCH_COURSETYPES_PAGE:
            return {
                ...state,
                courseTypesSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_COURSETYPES_PAGE_ERROR:
            return {
                ...state,
                courseTypesSearchPage: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_COURSETYPES_ERROR:
        case GET_COURSETYPES_PAGE_ERROR:
            return {
                ...state,
                courseTypes: payload,
                isLoading: false,
                success: false,
            };
        case ADD_COURSETYPE:
        case UPDATE_COURSETYPE:
        case DELETE_COURSETYPE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case ADD_COURSETYPE_ERROR:
        case UPDATE_COURSETYPE_ERROR:
        case DELETE_COURSETYPE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
