import {
    ADD_EVALUATION_CRITERIA,
    ADD_EVALUATION_CRITERIA_ERROR,
    GET_EVALUATION_CRITERIA,
    GET_EVALUATION_CRITERIA_ERROR,
    DELETE_EVALUATION_CRITERIA,
    DELETE_EVALUATION_CRITERIA_ERROR,
} from "../actions/types";

const initialState = {
    evalCriteria: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_EVALUATION_CRITERIA:
            return {
                ...state,
                evalCriteria: payload,
                isLoading: false,
                success: true,
            };
        case ADD_EVALUATION_CRITERIA:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DELETE_EVALUATION_CRITERIA:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DELETE_EVALUATION_CRITERIA_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case GET_EVALUATION_CRITERIA_ERROR:
            return {
                ...state,
                categorys: payload,
                isLoading: false,
                success: false,
            };
        case ADD_EVALUATION_CRITERIA_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
