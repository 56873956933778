import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import { updatePodcast } from "../../redux/actions/articles";


const EditForm = ({
  updatePodcast,
  handleClose,
  page,
  article: { selectedArticle },
}) => {
  const [post, setPost] = useState({
    podcast_id: "",
    modified_date: new Date(),
    description: "",
    name: "",
    published: ""
  });

  const [editPage, setPage] = useState(0);

  useEffect(() => {
    setPost({
      podcast_id: selectedArticle[0].podcast_id,
      description: selectedArticle[0].description,
      name: selectedArticle[0].name,
      published: selectedArticle[0].published
    });
  }, [selectedArticle]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updatePodcast(post.podcast_id, post, page);
    setPost({
      podcast_id: "",
      description: "",
      name: "",
      published: ""
    });
    handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPost({
      ...post,
      [name]: value,
    });
  };

  const { name, description, published } = post;
  return (
    <>
      <DialogTitle id="form-dialog-title">Edit Podcast</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          variant="outlined"
          name="name"
          type="text"
          label="Title"
          placeholder="Title"
          value={name}
          onChange={(e) => handleChange(e)}
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          placeholder="Description"
          label="Description"
          name="description"
          multiline
          rows={4}
          value={description}
          onChange={(e) => handleChange(e)}
          fullWidth
        />
        <FormControl
          variant="outlined"
          style={{
            width: "100%",
            paddingTop: ".5rem",
            paddingBottom: ".5rem",
            marginTop: ".5rem",
          }}
        >
          <InputLabel id="demo-simple-select-helper-label">
            Published
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={published}
            name="published"
            onChange={(e) => handleChange(e)}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

EditForm.propTypes = {
  updatePodcast: PropTypes.func.isRequired,
  article: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  article: state.article,
});

export default connect(mapStateToProps, { updatePodcast })(EditForm);
