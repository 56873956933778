import {
    ADD_AUDIENCE,
    ADD_AUDIENCE_ERROR,
    GET_ALL_AUDIENCES,
    GET_ALL_AUDIENCES_ERROR,
    GET_AUDIENCES_PAGE,
    GET_AUDIENCES_PAGE_ERROR,
    SEARCH_AUDIENCES_PAGE,
    SEARCH_AUDIENCES_PAGE_ERROR,
    SELECT_AUDIENCE,
    GET_ALL_AUDIENCE_COUNT,
    GET_ALL_AUDIENCE_COUNT_ERROR,
    UPDATE_AUDIENCE,
    UPDATE_AUDIENCE_ERROR,
    DELETE_AUDIENCE,
    DELETE_AUDIENCE_ERROR,
    ADD_AUDIENCE_ASSOCIATION,
    ADD_AUDIENCE_ASSOCIATION_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    targetAudiences: [],
    targetAudiencesSearchPage: [],
    selectedAudience: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_AUDIENCES:
        case GET_AUDIENCES_PAGE:
            return {
                ...state,
                targetAudiences: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_AUDIENCE_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case GET_ALL_AUDIENCE_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case SELECT_AUDIENCE:
            return {
                ...state,
                selectedAudience: payload,
            };
        case SEARCH_AUDIENCES_PAGE:
            return {
                ...state,
                targetAudiencesSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_AUDIENCES_PAGE_ERROR:
            return {
                ...state,
                targetAudiencesSearchPage: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_AUDIENCES_ERROR:
        case GET_AUDIENCES_PAGE_ERROR:
            return {
                ...state,
                targetAudiences: payload,
                isLoading: false,
                success: false,
            };
        case ADD_AUDIENCE:
        case UPDATE_AUDIENCE:
        case DELETE_AUDIENCE:
        case ADD_AUDIENCE_ASSOCIATION:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case ADD_AUDIENCE_ERROR:
        case UPDATE_AUDIENCE_ERROR:
        case DELETE_AUDIENCE_ERROR:
        case ADD_AUDIENCE_ASSOCIATION_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
