import {
    GET_SHORT_VIDEOS,
    GET_SHORT_VIDEOS_ERROR,
    SEARCH_SHORT_VIDEOS,
    SEARCH_SHORT_VIDEOS_ERROR,
    ADD_SHORT_VIDEOS,
    ADD_SHORT_VIDEOS_ERROR,
    UPDATE_SHORT_VIDEOS,
    UPDATE_SHORT_VIDEOS_ERROR,
    DELETE_SHORT_VIDEOS,
    DELETE_SHORT_VIDEOS_ERROR,
    SELECT_SHORT_VIDEO,
    GET_SHORT_VIDEOS_COUNT,
    GET_SHORT_VIDEOS_COUNT_ERROR
} from "../actions/types";

const initialState = {
    shortVideos: [],
    shortVideosSearch: [],
    selectedShortVideo: null,
    count: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SHORT_VIDEOS:
            return {
                ...state,
                shortVideos: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_SHORT_VIDEOS:
            return {
                ...state,
                shortVideosSearch: payload,
                isLoading: false,
                success: true,
            };
        case GET_SHORT_VIDEOS_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case SELECT_SHORT_VIDEO:
            return {
                ...state,
                selectedShortVideo: payload,
            };
        case ADD_SHORT_VIDEOS:
        case UPDATE_SHORT_VIDEOS:
        case DELETE_SHORT_VIDEOS:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case GET_SHORT_VIDEOS_ERROR:

            return {
                ...state,
                shortVideos: payload,
                isLoading: false,
                success: false,
            };
        case SEARCH_SHORT_VIDEOS_ERROR:

            return {
                ...state,
                shortVideosSearch: payload,
                isLoading: false,
                success: false,
            };
        case GET_SHORT_VIDEOS_COUNT_ERROR:

            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false,
            };

        case ADD_SHORT_VIDEOS_ERROR:
        case UPDATE_SHORT_VIDEOS_ERROR:
        case DELETE_SHORT_VIDEOS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
