import {
    DXP_GET_TRIAL_CODES_PAGINATED,
    DXP_GET_TRIAL_CODES_PAGINATED_ERROR,
    DXP_SELECT_TRIAL_CODE,
    DXP_GET_TRIAL_CODE_COUNT,
    DXP_GET_TRIAL_CODE_COUNT_ERROR,
    DXP_DELETE_TRIAL_CODE,
    DXP_DELETE_TRIAL_CODE_ERROR
} from "../actions/types";

const initialState = {
    count: null,
    trialCodes: [],
    selectedTrialCode: null,
    selectedCode: null,
    codeAdded: false,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_TRIAL_CODES_PAGINATED:
            return {
                ...state,
                trialCodes: payload,
                isLoading: false,
                success: true,
            };

        case DXP_GET_TRIAL_CODE_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }

        case DXP_SELECT_TRIAL_CODE:
            return {
                ...state,
                selectedTrialCode: payload,
            };

        case DXP_GET_TRIAL_CODES_PAGINATED_ERROR:
            return {
                ...state,
                trialCodes: [],
                isLoading: false,
                success: false,
            };

        case DXP_GET_TRIAL_CODE_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }

        case DXP_DELETE_TRIAL_CODE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_DELETE_TRIAL_CODE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}

