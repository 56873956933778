import {
	DXP_ADD_GROUP,
	DXP_ADD_GROUP_ERROR,
	DXP_GET_ALL_GROUPS,
	DXP_GET_ALL_GROUPS_ERROR,
	DXP_GET_ALL_GROUPS_COUNT,
	DXP_GET_ALL_GROUPS_COUNT_ERROR,
	DPX_GET_ALL_GROUPS_PAGINATED,
	DPX_GET_ALL_GROUPS_PAGINATED_ERROR,
	DXP_GET_GROUP_BY_ID,
	DXP_GET_GROUP_BY_ID_ERROR,
	DXP_UPDATE_GROUP,
	DXP_UPDATE_GROUP_ERROR,
	DXP_UPDATE_GROUP_PRICE,
	DXP_UPDATE_GROUP_PRICE_ERROR,
	DELETE_GROUP,
	DELETE_GROUP_ERROR,
	DXP_SEARCH_GROUPS_PAGINATED,
	DXP_SEARCH_GROUPS_PAGINATED_ERROR,
	DXP_GET_GROUPS_COUNT_BY_SEARCH,
	DXP_GET_GROUPS_COUNT_BY_SEARCH_ERROR,
	DXP_UPDATE_GROUP_ADD_BULK_MEMBERS,
	DXP_UPDATE_GROUP_ADD_BULK_MEMBERS_ERROR,
	DXP_UPDATE_GROUP_DELETE_BULK_MEMBERS,
	DXP_UPDATE_GROUP_DELETE_BULK_MEMBERS_ERROR,
	DXP_SELECT_GROUP,
	DXP_GET_GROUP_USERS,
	DXP_GET_GROUP_USERS_ERROR,
	DXP_SELECT_GROUP_USER,
	DXP_ADD_GROUP_USER,
	DXP_ADD_GROUP_USER_ERROR,
	DXP_GET_ALL_GROUP_USERS,
	DXP_GET_ALL_GROUP_USERS_ERROR,
	DXP_GET_GROUPS_COUNT_BY_DATE,
	DXP_GET_GROUPS_COUNT_BY_DATE_ERROR,
} from "../actions/types";

const initialState = {
	allGroups: [],
	allGroupsPaginated: [],
	allGroupsCount: null,
	allGroupsCountByDate: null,
	groupsBySearchPaginated: [],
	groupsBySearchCount: null,
	groupById: [],
	allGroupUsers: [],
	groupUsers: [],
	groupUserCount: null,
	selectedGroup: null,
	selectedGroupUser: null,
	isLoading: true,
	success: false,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case DXP_ADD_GROUP:
			return {
				...state,
				isLoading: false,
				success: true,
			};
		case DXP_GET_ALL_GROUPS:
			return {
				...state,
				allGroups: payload,
				isLoading: false,
				success: true,
			};
		case DXP_GET_ALL_GROUPS_COUNT:
			return {
				...state,
				allGroupsCount: payload,
				isLoading: false,
				success: true,
			};
		case DXP_GET_GROUPS_COUNT_BY_DATE:
			return {
				...state,
				allGroupsCountByDate: payload,
				isLoading: false,
				success: true,
			};
		case DPX_GET_ALL_GROUPS_PAGINATED:
			return {
				...state,
				allGroupsPaginated: payload,
				isLoading: false,
				success: true,
			};
		case DXP_SEARCH_GROUPS_PAGINATED:
			return {
				...state,
				groupsBySearchPaginated: payload,
				isLoading: false,
				success: true,
			};
		case DXP_GET_GROUPS_COUNT_BY_SEARCH:
			return {
				...state,
				groupsBySearchCount: payload,
				isLoading: false,
				success: true,
			};
		case DXP_GET_GROUP_BY_ID:
			return {
				...state,
				groupById: payload,
				isLoading: false,
				success: true,
			};
		case DXP_GET_GROUP_USERS:
			return {
				...state,
				groupUsers: payload.users,
				groupUserCount: payload.userCount,
				isLoading: false,
				success: true,
			};
		case DXP_GET_ALL_GROUP_USERS:
			return {
				...state,
				allGroupUsers: payload,
				isLoading: false,
				success: true,
			};
		case DXP_SELECT_GROUP:
			return {
				...state,
				selectedGroup: payload,
				isLoading: false,
				success: true,
			};
		case DXP_SELECT_GROUP_USER:
			return {
				...state,
				selectedGroupUser: payload,
				isLoading: false,
				success: true,
			};
		case DXP_UPDATE_GROUP:
		case DXP_UPDATE_GROUP_ADD_BULK_MEMBERS:
		case DXP_UPDATE_GROUP_DELETE_BULK_MEMBERS:
		case DXP_ADD_GROUP_USER:
		case DELETE_GROUP:
			return {
				...state,
				isLoading: false,
				success: true,
			}
		case DXP_ADD_GROUP_ERROR:
		case DXP_GET_ALL_GROUPS_ERROR:
		case DXP_GET_ALL_GROUPS_COUNT_ERROR:
		case DXP_GET_GROUPS_COUNT_BY_DATE_ERROR:
		case DPX_GET_ALL_GROUPS_PAGINATED_ERROR:
		case DXP_GET_GROUP_BY_ID_ERROR:
		case DXP_GET_GROUP_USERS_ERROR:
		case DXP_UPDATE_GROUP_ERROR:
		case DXP_UPDATE_GROUP_PRICE_ERROR:
		case DELETE_GROUP_ERROR:
		case DXP_SEARCH_GROUPS_PAGINATED_ERROR:
		case DXP_GET_GROUPS_COUNT_BY_SEARCH_ERROR:
		case DXP_UPDATE_GROUP_ADD_BULK_MEMBERS_ERROR:
		case DXP_UPDATE_GROUP_DELETE_BULK_MEMBERS_ERROR:
		case DXP_GET_ALL_GROUP_USERS_ERROR:
			return {
				...state,
				isLoading: false,
				success: false,
			};
		default:
			return state;
	}
}
