import {
    DXP_GET_ALL_PENDING_POSTS_PAGINATED,
    DXP_GET_ALL_PENDING_POSTS_PAGINATED_ERROR,
    DXP_GET_ALL_PENDING_POSTS_COMMENTS_PAGINATED,
    DXP_GET_ALL_PENDING_POSTS_COMMENTS_PAGINATED_ERROR,
    DXP_GET_ALL_PENDING_VIDEO_COMMENTS_PAGINATED,
    DXP_GET_ALL_PENDING_VIDEO_COMMENTS_PAGINATED_ERROR,
    DXP_APPROVE_REJECT_PENDING_POST,
    DXP_APPROVE_REJECT_PENDING_POST_ERROR,
    DXP_GET_SEARCH_POSTS_PAGINATED,
    DXP_GET_SEARCH_POSTS_PAGINATED_ERROR,
    DXP_APPROVE_REJECT_PENDING_POST_COMMENT,
    DXP_APPROVE_REJECT_PENDING_POST_COMMENT_ERROR,
    DXP_GET_SEARCH_POST_COMMENTS_PAGINATED,
    DXP_GET_SEARCH_POST_COMMENTS_PAGINATED_ERROR,
    DXP_APPROVE_REJECT_PENDING_VIDEO_COMMENT,
    DXP_APPROVE_REJECT_PENDING_VIDEO_COMMENT_ERROR,
    DXP_GET_SEARCH_VIDEO_COMMENTS_PAGINATED,
    DXP_GET_SEARCH_VIDEO_COMMENTS_PAGINATED_ERROR,
    DXP_GET_ALL_REJECTED_POSTS_PAGINATED,
    DXP_GET_ALL_REJECTED_POSTS_PAGINATED_ERROR,
    DXP_GET_ALL_REJECTED_POSTS_COMMENTS_PAGINATED,
    DXP_GET_ALL_REJECTED_POSTS_COMMENTS_PAGINATED_ERROR,
    DXP_GET_ALL_REJECTED_VIDEO_COMMENTS_PAGINATED,
    DXP_GET_ALL_REJECTED_VIDEO_COMMENTS_PAGINATED_ERROR,
    DXP_GET_ALL_FORUM_POSTS,
    DXP_GET_ALL_FORUM_POSTS_ERROR
} from "../actions/types";

const initialState = {
    allPendingPostsPaginated: [],
    allPendingPostsCount: null,
    allPendingPostCommentsPaginated: [],
    allPendingPostCommentsCount: null,
    allPendingVideoCommentsPaginated: [],
    allPendingVideoCommentsCount: null,
    searchPostsPaginated: [],
    searchPostsCount: null,
    searchPostCommentsPaginated: [],
    searchPostCommentsCount: null,
    searchVideoCommentsPaginated: [],
    searchVideoCommentsCount: null,
    allRejectedPostsPaginated: [],
    allRejectedPostsCount: null,
    allRejectedPostCommentsPaginated: [],
    allRejectedPostCommentsCount: null,
    allRejectedVideoCommentsPaginated: [],
    allRejectedVideoCommentsCount: null,
    allForumPosts: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_FORUM_POSTS:
            return {
                ...state,
                allForumPosts: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_REJECTED_POSTS_COMMENTS_PAGINATED:
            return {
                ...state,
                allRejectedPostCommentsPaginated: payload.rows,
                allRejectedPostCommentsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_REJECTED_VIDEO_COMMENTS_PAGINATED:
            return {
                ...state,
                allRejectedVideoCommentsPaginated: payload.rows,
                allRejectedVideoCommentsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_REJECTED_POSTS_PAGINATED:
            return {
                ...state,
                allRejectedPostsPaginated: payload.rows,
                allRejectedPostsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_PENDING_POSTS_PAGINATED:
            return {
                ...state,
                allPendingPostsPaginated: payload.rows,
                allPendingPostsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_SEARCH_POSTS_PAGINATED:
            return {
                ...state,
                searchPostsPaginated: payload.rows,
                searchPostsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_SEARCH_POST_COMMENTS_PAGINATED:
            return {
                ...state,
                searchPostCommentsPaginated: payload.rows,
                searchPostCommentsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_SEARCH_VIDEO_COMMENTS_PAGINATED:
            return {
                ...state,
                searchVideoCommentsPaginated: payload.rows,
                searchVideoCommentsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_PENDING_POSTS_COMMENTS_PAGINATED:
            return {
                ...state,
                allPendingPostCommentsPaginated: payload.rows,
                allPendingPostCommentsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_PENDING_VIDEO_COMMENTS_PAGINATED:
            return {
                ...state,
                allPendingVideoCommentsPaginated: payload.rows,
                allPendingVideoCommentsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_APPROVE_REJECT_PENDING_POST:
        case DXP_APPROVE_REJECT_PENDING_POST_COMMENT:
        case DXP_APPROVE_REJECT_PENDING_VIDEO_COMMENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_PENDING_POSTS_PAGINATED_ERROR:
        case DXP_GET_ALL_PENDING_POSTS_COMMENTS_PAGINATED_ERROR:
        case DXP_GET_ALL_PENDING_VIDEO_COMMENTS_PAGINATED_ERROR:
        case DXP_GET_SEARCH_POSTS_PAGINATED_ERROR:
        case DXP_APPROVE_REJECT_PENDING_POST_ERROR:
        case DXP_APPROVE_REJECT_PENDING_POST_COMMENT_ERROR:
        case DXP_GET_SEARCH_POST_COMMENTS_PAGINATED_ERROR:
        case DXP_APPROVE_REJECT_PENDING_VIDEO_COMMENT_ERROR:
        case DXP_GET_SEARCH_VIDEO_COMMENTS_PAGINATED_ERROR:
        case DXP_GET_ALL_REJECTED_POSTS_PAGINATED_ERROR:
        case DXP_GET_ALL_REJECTED_POSTS_COMMENTS_PAGINATED_ERROR:
        case DXP_GET_ALL_REJECTED_VIDEO_COMMENTS_PAGINATED_ERROR:
        case DXP_GET_ALL_FORUM_POSTS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}