import {
    DXP_ADD_EXTERNAL_EVENT,
    DXP_ADD_EXTERNAL_EVENT_ERROR,
    DXP_GET_ALL_EXTERNAL_EVENTS,
    DXP_GET_ALL_EXTERNAL_EVENTS_ERROR,
    DXP_GET_EXTERNAL_EVENTS_PAGE,
    DXP_GET_EXTERNAL_EVENTS_PAGE_ERROR,
    DXP_SEARCH_EXTERNAL_EVENTS_PAGE,
    DXP_SEARCH_EXTERNAL_EVENTS_PAGE_ERROR,
    DXP_SELECT_EXTERNAL_EVENT,
    DXP_GET_ALL_EXTERNAL_EVENT_COUNT,
    DXP_GET_ALL_EXTERNAL_EVENT_COUNT_ERROR,
    DXP_UPDATE_EXTERNAL_EVENT,
    DXP_UPDATE_EXTERNAL_EVENT_ERROR,
    DXP_DELETE_EXTERNAL_EVENT,
    DXP_DELETE_EXTERNAL_EVENT_ERROR,
    DXP_SEARCH_EXTERNAL_EVENTS_COUNT,
    DXP_SEARCH_EXTERNAL_EVENTS_COUNT_ERROR
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    externalEvents: [],
    publishedExternalEvents: [],
    externalEventsSearchPage: [],
    selectedExternalEvent: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case DXP_GET_ALL_EXTERNAL_EVENTS:
        case DXP_GET_EXTERNAL_EVENTS_PAGE:
            return {
                ...state,
                externalEvents: payload,
                publishedExternalEvents: payload.filter(event => event.published),
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_EXTERNAL_EVENT_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case DXP_SEARCH_EXTERNAL_EVENTS_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true
            }
        case DXP_SELECT_EXTERNAL_EVENT:
            return {
                ...state,
                selectedExternalEvent: payload,
            };
        case DXP_SEARCH_EXTERNAL_EVENTS_PAGE:
            return {
                ...state,
                externalEventsSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_EXTERNAL_EVENT:
        case DXP_UPDATE_EXTERNAL_EVENT:
        case DXP_DELETE_EXTERNAL_EVENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_EXTERNAL_EVENTS_ERROR:
        case DXP_GET_EXTERNAL_EVENTS_PAGE_ERROR:
            return {
                ...state,
                externalEvents: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_ALL_EXTERNAL_EVENT_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case DXP_SEARCH_EXTERNAL_EVENTS_COUNT_ERROR:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: false
            }

        case DXP_SEARCH_EXTERNAL_EVENTS_PAGE_ERROR:
            return {
                ...state,
                externalEventsSearchPage: payload,
                isLoading: false,
                success: false,
            };

        case DXP_ADD_EXTERNAL_EVENT_ERROR:
        case DXP_UPDATE_EXTERNAL_EVENT_ERROR:
        case DXP_DELETE_EXTERNAL_EVENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
