import {
    DL_AXIOM_LOADING,
    DL_GET_AXIOM_WEBHOOK,
    DL_GET_AXIOM_WEBHOOK_ERROR,
    DL_GET_AXIOM_WEBHOOK_COUNT,
    DL_GET_AXIOM_WEBHOOK_COUNT_ERROR,
    DL_SEARCH_AXIOM_WEBHOOK,
    DL_SEARCH_AXIOM_WEBHOOK_ERROR,
    DL_SEARCH_AXIOM_WEBHOOK_COUNT,
    DL_SEARCH_AXIOM_WEBHOOK_COUNT_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    webhooks: [],
    webhookSearch: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_AXIOM_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        case DL_GET_AXIOM_WEBHOOK:
            return {
                ...state,
                webhooks: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_AXIOM_WEBHOOK_COUNT:
            return {
                ...state,
                count: payload,
                success: true,
            };
        case DL_SEARCH_AXIOM_WEBHOOK:
            return {
                ...state,
                webhookSearch: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_AXIOM_WEBHOOK_COUNT:
            return {
                ...state,
                searchCount: payload,
                success: true,
            };


        case DL_GET_AXIOM_WEBHOOK_ERROR:
            return {
                ...state,
                webhooks: [],
                isLoading: false,
                success: false,
            };
        case DL_GET_AXIOM_WEBHOOK_COUNT_ERROR:
            return {
                ...state,
                count: null,
                isLoading: false,
                success: false,
            };
        case DL_SEARCH_AXIOM_WEBHOOK_ERROR:
            return {
                ...state,
                webhookSearch: [],
                isLoading: false,
                success: false,
            };
        case DL_SEARCH_AXIOM_WEBHOOK_COUNT_ERROR:
            return {
                ...state,
                searchCount: null,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}
