import {
    DXP_GET_FEATURED_COURSE,
    DXP_GET_FEATURED_COURSE_ERROR,
    DXP_GET_FEATURED_NEW_ALL,
    DXP_GET_FEATURED_NEW_ALL_ERROR,
    DXP_GET_FEATURED_CONTENT,
    DXP_GET_FEATURED_CONTENT_ERROR,
    DXP_GET_FEATURED_CATEGORIES_ALL,
    DXP_GET_FEATURED_CATEGORIES_ALL_ERROR,
    DXP_GET_FEATURED_WEBINARS_ALL,
    DXP_GET_FEATURED_WEBINARS_ALL_ERROR,
    DXP_GET_FEATURED_XPERT_ALL,
    DXP_GET_FEATURED_XPERT_ALL_ERROR,
    DXP_SELECT_WEBINARS_ALL,
    DXP_SELECT_XPERT_ALL,
    DXP_SELECT_NEW_CONTENT_ALL,
    DXP_ADD_FEATURED_CONTENT_ALL,
    DXP_ADD_FEATURED_CONTENT_ALL_ERROR,
    DXP_UPDATE_FEATURED_CONTENT_ALL,
    DXP_UPDATE_FEATURED_CONTENT_ALL_ERROR,
    DXP_DELETE_FEATURED_ALL,
    DXP_DELETE_FEATURED_ALL_ERROR,
    DXP_GET_FEATURED_AFFILIATE,
    DXP_GET_FEATURED_AFFILIATE_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    featuredCourse: [],
    featuredContent: [],
    featuredCategories: [],
    featuredXperts: [],
    selectedXpert: null,
    featuredWebinars: [],
    selectedWebinar: null,
    featuredNew: [],
    featuredAffiliateEvent: null,
    selectedNew: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_FEATURED_COURSE:
            return {
                ...state,
                featuredCourse: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_CONTENT:
            return {
                ...state,
                featuredContent: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_CATEGORIES_ALL:
            return {
                ...state,
                featuredCategories: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_WEBINARS_ALL:
            return {
                ...state,
                featuredWebinars: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_XPERT_ALL:
            return {
                ...state,
                featuredXperts: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_NEW_ALL:
            return {
                ...state,
                featuredNew: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_WEBINARS_ALL:
            return {
                ...state,
                selectedWebinar: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_XPERT_ALL:
            return {
                ...state,
                selectedXpert: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_NEW_CONTENT_ALL:
            return {
                ...state,
                selectedNew: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_AFFILIATE:
            return {
                ...state,
                featuredAffiliateEvent: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_FEATURED_CONTENT_ALL:
        case DXP_UPDATE_FEATURED_CONTENT_ALL:
        case DXP_DELETE_FEATURED_ALL:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_COURSE_ERROR:
            return {
                ...state,
                featuredCourse: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_FEATURED_CONTENT_ERROR:
            return {
                ...state,
                featuredContent: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_FEATURED_CATEGORIES_ALL_ERROR:
            return {
                ...state,
                featuredCategories: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_FEATURED_WEBINARS_ALL_ERROR:
            return {
                ...state,
                featuredWebinars: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_FEATURED_XPERT_ALL_ERROR:
            return {
                ...state,
                featuredXperts: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_FEATURED_NEW_ALL_ERROR:
            return {
                ...state,
                featuredNew: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_FEATURED_AFFILIATE_ERROR:
        case DXP_ADD_FEATURED_CONTENT_ALL_ERROR:
        case DXP_UPDATE_FEATURED_CONTENT_ALL_ERROR:
        case DXP_DELETE_FEATURED_ALL_ERROR:
            return {
                ...state,
                isLoading: false,
                success: true
            }
        default:
            return state;
    }
}
