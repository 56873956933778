import {
    DXP_GET_ALL_FACULTY_MEMBER_OPTIONS,
    DXP_GET_ALL_FACULTY_MEMBER_OPTIONS_ERROR

} from "../actions/types";


const initialState = {
    isLoadingFaculty: true,
    successFaculty: false,
    allMemberOptionsToSelect: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_FACULTY_MEMBER_OPTIONS:
            return {
                ...state,
                isLoadingFaculty: false,
                successFaculty: true,
                allMemberOptionsToSelect: payload,
            };
        case DXP_GET_ALL_FACULTY_MEMBER_OPTIONS_ERROR:
            return {
                ...state,
                isLoadingFaculty: false,
                successFaculty: false,
            };
        default:
            return state;
    }
}