import {
    DL_ADD_BUNDLE,
    DL_ADD_BUNDLE_ERROR,
    DL_GET_ALL_BUNDLES,
    DL_GET_ALL_BUNDLES_ERROR,
    DL_GET_ALL_PUBLISHED_BUNDLES,
    DL_GET_ALL_PUBLISHED_BUNDLES_ERROR,
    DL_GET_BUNDLES_PAGE,
    DL_GET_BUNDLES_PAGE_ERROR,
    DL_SEARCH_BUNDLES_PAGE,
    DL_SEARCH_BUNDLES_PAGE_ERROR,
    DL_SELECT_BUNDLE,
    DL_UPDATE_BUNDLE,
    DL_UPDATE_BUNDLE_ERROR,
    DL_DELETE_BUNDLE,
    DL_DELETE_BUNDLE_ERROR,
    DL_GET_ALL_BUNDLE_COUNT,
    DL_GET_ALL_BUNDLE_COUNT_ERROR,
    DL_SEARCH_BUNDLES_COUNT,
    DL_SEARCH_BUNDLES_COUNT_ERROR,
    DL_GET_BUNDLE_COURSES,
    DL_GET_BUNDLE_COURSES_ERROR,
    DL_GET_BUNDLED_COURSE_IDS,
    DL_GET_BUNDLED_COURSE_IDS_ERROR,
    DL_GET_PUBLISHED_BUNDLES_AND_LICENSE_COUNT,
    DL_GET_PUBLISHED_BUNDLES_AND_LICENSE_COUNT_ERROR,
    DL_SEARCH_BUNDLES_WITH_LICENSES,
    DL_SEARCH_BUNDLES_WITH_LICENSES_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    bundlesWithLicenseCount: null,
    searchBundlesWithLicenseCount: null,
    bundles: [],
    publishedBundles: [],
    bundlesWithLicense: [],
    searchBundlesWithLicense: [],
    bundleCourses: [],
    bundlesSearchPage: [],
    bundlesByLanguage: [],
    completedBundleData: [],
    bundledCourseIds: [],
    selectedBundle: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_ALL_BUNDLES:
        case DL_GET_BUNDLES_PAGE:
            return {
                ...state,
                bundles: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_PUBLISHED_BUNDLES:
            return {
                ...state,
                publishedBundles: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_BUNDLE_COURSES:
            return {
                ...state,
                bundleCourses: payload,
                isLoading: false,
                success: true,
            };

        case DL_GET_ALL_BUNDLE_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case DL_SEARCH_BUNDLES_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true
            }
        case DL_GET_BUNDLED_COURSE_IDS:
            return {
                ...state,
                bundledCourseIds: payload,
                isLoading: false,
                success: true
            }
        case DL_SELECT_BUNDLE:
            return {
                ...state,
                selectedBundle: payload,
            };
        case DL_SEARCH_BUNDLES_PAGE:
            return {
                ...state,
                bundlesSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_PUBLISHED_BUNDLES_AND_LICENSE_COUNT:
            return {
                ...state,
                bundlesWithLicense: payload.data,
                bundlesWithLicenseCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_BUNDLES_WITH_LICENSES:
            return {
                ...state,
                searchBundlesWithLicense: payload.data,
                searchBundlesWithLicenseCount: payload.count,
                isLoading: false,
                success: true,
            };


        case DL_ADD_BUNDLE:
        case DL_UPDATE_BUNDLE:
        case DL_DELETE_BUNDLE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_BUNDLES_ERROR:
        case DL_GET_BUNDLES_PAGE_ERROR:
            return {
                ...state,
                bundles: [],
                isLoading: false,
                success: false,
            };

        case DL_GET_ALL_PUBLISHED_BUNDLES_ERROR:
            return {
                ...state,
                publishedBundles: [],
                isLoading: false,
                success: false,
            };
        case DL_GET_BUNDLE_COURSES_ERROR:
            return {
                ...state,
                bundleCourses: [],
                isLoading: false,
                success: false,
            };

        case DL_GET_ALL_BUNDLE_COUNT_ERROR:
            return {
                ...state,
                count: null,
                isLoading: false,
                success: false
            }
        case DL_SEARCH_BUNDLES_COUNT_ERROR:
            return {
                ...state,
                searchCount: null,
                isLoading: false,
                success: false
            }
        case DL_SEARCH_BUNDLES_PAGE_ERROR:
            return {
                ...state,
                bundlesSearchPage: [],
                isLoading: false,
                success: false,
            };
        case DL_GET_BUNDLED_COURSE_IDS_ERROR:
            return {
                ...state,
                bundledCourseIds: [],
                isLoading: false,
                success: false,
            };
        case DL_GET_PUBLISHED_BUNDLES_AND_LICENSE_COUNT_ERROR:
            return {
                ...state,
                bundlesWithLicense: [],
                bundlesWithLicenseCount: null,
                isLoading: false,
                success: false,
            };
        case DL_SEARCH_BUNDLES_WITH_LICENSES_ERROR:
            return {
                ...state,
                searchBundlesWithLicense: [],
                searchBundlesWithLicenseCount: null,
                isLoading: false,
                success: false,
            };

        case DL_ADD_BUNDLE_ERROR:
        case DL_UPDATE_BUNDLE_ERROR:
        case DL_DELETE_BUNDLE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
