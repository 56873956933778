import {
    DXP_ADD_404_TO_301,
    DXP_ADD_404_TO_301_ERROR,
    DXP_GET_404_PAGES_PAGINATED,
    DXP_GET_404_PAGES_PAGINATED_ERROR,
    DXP_SELECT_404_PAGE,
    DXP_GET_404_PAGE_COUNT,
    DXP_GET_404_PAGE_COUNT_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    pages404: [],
    pages301: [],
    selected404Page: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_404_PAGES_PAGINATED:
            return {
                ...state,
                pages404: payload,
                isLoading: false,
                success: true,
            };

        case DXP_GET_404_PAGE_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }

        case DXP_SELECT_404_PAGE:
            return {
                ...state,
                selected404Page: payload,
            };

        case DXP_GET_404_PAGES_PAGINATED_ERROR:
            return {
                ...state,
                pages404: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_404_PAGE_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }

        case DXP_ADD_404_TO_301:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_404_TO_301_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}

