import {
  GET_ALL_EVALUATIONS,
  GET_ALL_EVALUATIONS_UPDATED,
  GET_ALL_EVALUATIONS_TAG,
  GET_ALL_EVALUATIONS_PAGE,
  ALL_EVALUATIONS_ERROR,
  GET_EVALUATION,
  GET_EVALUATION_ERROR,
  SELECT_EVALUATION,
  SEARCH_EVALUATIONS,
  SEARCH_EVALUATIONS_ERROR,
  GET_EVALUATION_COUNT,
  GET_EVALUATION_COUNT_ERROR,
  ADD_EVALUATION,
  ADD_EVALUATION_ERROR,
  UPDATE_EVALUATION,
  DELETE_EVALUATION,
  UPDATE_EVALUATION_ERROR,
  DELETE_EVALUATION_ERROR,
  GET_ALL_EVALUATIONS_PAGE_ERROR,
  SET_FEATURED_EVALUATION,
  SET_FEATURED_EVALUATIONS_FALSE,
  GET_FEATURED_EVALUATIONS,
  SET_FEATURED_EVALUATION_ERROR,
  SET_FEATURED_EVALUATIONS_FALSE_ERROR,
  GET_FEATURED_EVALUATIONS_ERROR,
  GET_REVIEW_PAGE_FEATURED,
  SET_REVIEW_PAGE_FEATURED,
  SET_REVIEW_PAGE_FEATURED_FALSE,
  GET_REVIEW_PAGE_DEFAULT,
  SET_REVIEW_PAGE_DEFAULT,
  SET_REVIEW_PAGE_DEFAULT_FALSE,
  GET_REVIEW_PAGE_FEATURED_ERROR,
  SET_REVIEW_PAGE_FEATURED_ERROR,
  SET_REVIEW_PAGE_FEATURED_FALSE_ERROR,
  GET_REVIEW_PAGE_DEFAULT_ERROR,
  SET_REVIEW_PAGE_DEFAULT_ERROR,
  SET_REVIEW_PAGE_DEFAULT_FALSE_ERROR,
  UNPUBLISH_EVALUATIONS,
  UNPUBLISH_EVALUATIONS_ERROR,
  GET_ALL_FEATURED_EVALS_DL,
  GET_ALL_FEATURED_EVALS_DL_ERROR,
  ADD_FEATURED_EVAL_DL,
  ADD_FEATURED_EVAL_DL_ERROR,
  DELETE_FEATURED_EVALS_DL,
  DELETE_FEATURED_EVALS_DL_ERROR,
} from "../actions/types";

const initialState = {
  evaluations: [],
  evaluationsUpdated: [],
  evaluationSearch: [],
  featuredEvaluations: [],
  featuredEvaluationsDL: [],
  reviewPageFeatured: [],
  reviewPageDefault: [],
  selectedEvaluation: null,
  evaluation: null,
  isLoading: true,
  success: false,
  count: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_EVALUATIONS:
      return {
        ...state,
        evaluations: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_EVALUATIONS_TAG:
      return {
        ...state,
        evaluations: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_EVALUATIONS_UPDATED:
      return {
        ...state,
        evaluationsUpdated: payload,
        isLoading: false,
        success: true,
      }
    case GET_ALL_EVALUATIONS_PAGE:
      return {
        ...state,
        evaluations: payload,
        isLoading: false,
        success: true,
      };
    case GET_EVALUATION:
      return {
        ...state,
        evaluation: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_FEATURED_EVALS_DL:
      return {
        ...state,
        featuredEvaluationsDL: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_FEATURED_EVALS_DL_ERROR:
      return {
        ...state,
        featuredEvaluationsDL: payload,
        isLoading: false,
        success: false,
      };
    case GET_REVIEW_PAGE_FEATURED:
      return {
        ...state,
        reviewPageFeatured: payload,
        isLoading: false,
        success: true,
      };
    case GET_REVIEW_PAGE_DEFAULT:
      return {
        ...state,
        reviewPageDefault: payload,
        isLoading: false,
        success: true,
      };
    case GET_FEATURED_EVALUATIONS:
      return {
        ...state,
        featuredEvaluations: payload,
        isLoading: false,
        success: true,
      };
    case SELECT_EVALUATION:
      return {
        ...state,
        selectedEvaluation: payload,
      };
    case SEARCH_EVALUATIONS:
      return {
        ...state,
        evaluationSearch: payload,
        isLoading: false,
        success: true,
      };
    case GET_EVALUATION_COUNT:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      };
    case UNPUBLISH_EVALUATIONS:
    case UPDATE_EVALUATION:
    case DELETE_EVALUATION:
    case ADD_EVALUATION:
    case SET_FEATURED_EVALUATION:
    case SET_FEATURED_EVALUATIONS_FALSE:
    case ADD_FEATURED_EVAL_DL:
    case DELETE_FEATURED_EVALS_DL:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case ALL_EVALUATIONS_ERROR:
      return {
        ...state,
        evaluations: payload,
        isLoading: false,
        success: false,
      };
    case GET_ALL_EVALUATIONS_PAGE_ERROR:
      return {
        ...state,
        evaluations: payload,
        isLoading: false,
        success: false,
      };
    case UNPUBLISH_EVALUATIONS_ERROR:
    case DELETE_EVALUATION_ERROR:
    case UPDATE_EVALUATION_ERROR:
    case ADD_EVALUATION_ERROR:
    case SET_FEATURED_EVALUATION_ERROR:
    case SET_FEATURED_EVALUATIONS_FALSE_ERROR:
    case ADD_FEATURED_EVAL_DL_ERROR:
    case DELETE_FEATURED_EVALS_DL_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case GET_EVALUATION_ERROR:
      return {
        ...state,
        evaluation: payload,
        isLoading: false,
        success: false,
      };
    case GET_FEATURED_EVALUATIONS_ERROR:
      return {
        ...state,
        featuredEvaluations: payload,
        isLoading: false,
        success: false,
      };
    case GET_REVIEW_PAGE_FEATURED_ERROR:
      return {
        ...state,
        reviewPageFeatured: payload,
        isLoading: false,
        success: false,
      };
    case GET_REVIEW_PAGE_DEFAULT_ERROR:
      return {
        ...state,
        reviewPageDefault: payload,
        isLoading: false,
        success: false,
      };
    case SEARCH_EVALUATIONS_ERROR:
      return {
        ...state,
        evaluationSearch: payload,
        isLoading: false,
        success: false,
      };
    case GET_EVALUATION_COUNT_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
}
