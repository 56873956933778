import React from "react";
import { connect } from "react-redux";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
} from "@mui/material";

import {
  deleteFeaturedMan,
  addFeaturedMan,
} from "../../../redux/actions/featuredManufacturers";

const ReplaceOrCancelDialog = ({
  deleteFeaturedMan,
  addFeaturedMan,
  newManu,
  replacementManufacturer,
  open,
  handleClose,
}) => {
  const handleSubmit = (e) => {
    deleteFeaturedMan(replacementManufacturer[0].feature_manufacturer_id);
    addFeaturedMan(newManu);
    handleClose(e);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
    >
      <DialogTitle>Replace Manufacturer Featured Products</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A set of featured products already exist for this manufacturer. Would
          you like to replace the set?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={(e) => handleSubmit(e)} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  featuredManufacturer: state.featuredManufacturer,
});

export default connect(mapStateToProps, { deleteFeaturedMan, addFeaturedMan })(
  ReplaceOrCancelDialog
);
