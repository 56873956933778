import {
  GET_ALL_FEATURED,
  GET_FEATURED,
  ALL_FEATURED_ERROR,
  GET_FEATURED_ERROR,
  GET_FEATURED_COUNT,
  GET_FEATURED_COUNT_ERROR,
  ADD_FEATURED,
  ADD_FEATURED_ERROR,
  SELECT_FEATURED,
  UPDATE_FEATURED,
  UPDATE_FEATURED_ERROR,
  DELETE_FEATURED,
  DELETE_FEATURED_ERROR,
} from "../actions/types";

const initialState = {
  featuredProducts: [],
  featuredProduct: null,
  selectedProduct: null,
  isLoading: true,
  success: false,
  count: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_FEATURED:
      return {
        ...state,
        featuredProducts: payload,
        isLoading: false,
        success: true,
      };
    case GET_FEATURED:
      return {
        ...state,
        featuredProduct: payload,
        isLoading: false,
        success: true,
      };
    case GET_FEATURED_COUNT:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      };
    case SELECT_FEATURED:
      return {
        ...state,
        selectedProduct: payload,
        isLoading: false,
        success: true,
      };
    case UPDATE_FEATURED:
    case DELETE_FEATURED:
    case ADD_FEATURED:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case ALL_FEATURED_ERROR:
      return {
        ...state,
        featuredProducts: payload,
        isLoading: false,
        success: false,
      };
    case DELETE_FEATURED_ERROR:
    case UPDATE_FEATURED_ERROR:
    case ADD_FEATURED_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case GET_FEATURED_ERROR:
      return {
        ...state,
        featuredProduct: payload,
        isLoading: false,
        success: false,
      };
    case GET_FEATURED_COUNT_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
}
