import {
    DXP_GET_EXTERNAL_CONTRIBUTORS,
    DXP_GET_EXTERNAL_CONTRIBUTORS_ERROR,
} from "../actions/types";

const initialState = {
    externalContributorOptions: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_EXTERNAL_CONTRIBUTORS:
            return {
                ...state,
                externalContributorOptions: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_EXTERNAL_CONTRIBUTORS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
