import {
    ADD_POP_UP,
    ADD_POP_UP_ERROR,
    GET_ALL_POP_UPS_PAGINATED,
    GET_ALL_POP_UPS_PAGINATED_ERROR,
    COUNT_POP_UPS,
    COUNT_POP_UPS_ERROR,
    UPDATE_POP_UP,
    UPDATE_POP_UP_ERROR,
    DELETE_POP_UP,
    DELETE_POP_UP_ERROR,
    SELECT_POPUP

} from "../actions/types";


const initialState = {
    isLoading: true,
    success: false,
    popups: [],
    count: 0,
    selectedPopup: {},
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_POP_UPS_PAGINATED:
            return {
                ...state,
                isLoading: false,
                success: true,
                popups: payload,
            };
        case COUNT_POP_UPS:
            return {
                ...state,
                isLoading: false,
                success: true,
                count: payload,
            };
        case SELECT_POPUP:
            return {
                ...state,
                isLoading: false,
                success: true,
                selectedPopup: payload,
            };
        case ADD_POP_UP:
        case UPDATE_POP_UP:
        case DELETE_POP_UP:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case GET_ALL_POP_UPS_PAGINATED_ERROR:
            return {
                ...state,
                popups: [],
                isLoading: false,
                success: false,
            };
        case COUNT_POP_UPS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case ADD_POP_UP_ERROR:
        case UPDATE_POP_UP_ERROR:
        case DELETE_POP_UP_ERROR:
            return {
                ...state,
                count: null,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}