import {
    DXP_GET_SUBSCRIPTION_CONFIG,
    DXP_GET_SUBSCRIPTION_CONFIG_ERROR,
} from "../actions/types";

const initialState = {
    subscriptionConfig: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_SUBSCRIPTION_CONFIG:
            return {
                ...state,
                subscriptionConfig: payload,
                isLoading: false,
                success: true,
            };

        case DXP_GET_SUBSCRIPTION_CONFIG_ERROR:
            return {
                ...state,
                subscriptionConfig: [],
                isLoading: false,
                success: false,
            };


        default:
            return state;
    }
}

