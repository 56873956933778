import React, { useEffect, useState } from "react";

import Loader from "../components/Loader";

import { connect } from "react-redux";


import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
  getPromotionPageFeatured,
  getPromotionPageDefault,
} from "../redux/actions/promotions";
import FeaturedPromotionForm from "../components/PromotionsContent/FeaturedPromotionForm";
import DefaultPromotionForm from "../components/PromotionsContent/DefaultPromotionForm";


const PromotionsPageContent = ({
  getPromotionPageFeatured,
  getPromotionPageDefault,
  promotion: { isLoading },
}) => {
  // const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getPromotionPageFeatured();
    getPromotionPageDefault();
  }, [getPromotionPageFeatured, getPromotionPageDefault]);

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <Paper>
        {/* <Paper className={classes.root}> */}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Set Featured Promotions" />
          <Tab label="Set Default Promotions" />
        </Tabs>
      </Paper>
      <br />
      <br />
      {value === 0 ? (
        <FeaturedPromotionForm />
      ) : value === 1 ? (
        <DefaultPromotionForm />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  promotion: state.promotion,
});

export default connect(mapStateToProps, {
  getPromotionPageFeatured,
  getPromotionPageDefault,
})(PromotionsPageContent);
