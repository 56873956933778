import {
    ADD_LOCATION,
    ADD_LOCATION_ERROR,
    GET_ALL_LOCATIONS,
    GET_ALL_LOCATIONS_ERROR,
    GET_LOCATIONS_PAGE,
    GET_LOCATIONS_PAGE_ERROR,
    SEARCH_LOCATIONS_PAGE,
    SEARCH_LOCATIONS_PAGE_ERROR,
    SELECT_LOCATION,
    GET_ALL_LOCATION_COUNT,
    GET_ALL_LOCATION_COUNT_ERROR,
    UPDATE_LOCATION,
    UPDATE_LOCATION_ERROR,
    DELETE_LOCATION,
    DELETE_LOCATION_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    locations: [],
    locationsSearchPage: [],
    selectedLocation: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_LOCATIONS:
        case GET_LOCATIONS_PAGE:
            return {
                ...state,
                locations: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_LOCATION_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case GET_ALL_LOCATION_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case SELECT_LOCATION:
            return {
                ...state,
                selectedLocation: payload,
            };
        case SEARCH_LOCATIONS_PAGE:
            return {
                ...state,
                locationsSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_LOCATIONS_PAGE_ERROR:
            return {
                ...state,
                locationsSearchPage: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_LOCATIONS_ERROR:
        case GET_LOCATIONS_PAGE_ERROR:
            return {
                ...state,
                locations: payload,
                isLoading: false,
                success: false,
            };
        case ADD_LOCATION:
        case UPDATE_LOCATION:
        case DELETE_LOCATION:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case ADD_LOCATION_ERROR:
        case UPDATE_LOCATION_ERROR:
        case DELETE_LOCATION_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
