import axios from "axios";
import {
  GET_ALL_FEATURED_MAN,
  GET_ALL_FEATURED_PAGE_MAN,
  ALL_FEATURED_MAN_ERROR,
  GET_FEATURED_MAN,
  GET_FEATURED_MAN_ERROR,
  GET_FEATURED_COUNT_MAN,
  GET_FEATURED_COUNT_MAN_ERROR,
  ADD_FEATURED_MAN,
  ADD_FEATURED_MAN_ERROR,
  UPDATE_FEATURED_MAN,
  DELETE_FEATURED_MAN,
  UPDATE_FEATURED_MAN_ERROR,
  DELETE_FEATURED_MAN_ERROR,
  GET_ALL_FEATURED_PAGE_MAN_ERROR,
  SELECT_FEATURED_MAN,
  SELECT_FEATURED_MAN_ERROR,
} from "./types";
import { setAlert } from "./alerts";

const baseUrl = process.env.REACT_APP_BASE_URL;

//gets all featured manufacturers
export const getFeatured = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/featuredmanufacturers`);

    dispatch({
      type: GET_ALL_FEATURED_MAN,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: ALL_FEATURED_MAN_ERROR,
      payload: { msg: "Could not fetch all featured manufacturers" },
    });
  }
};

//gets the total count of featured manufacturers
export const getFeaturedCount = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/featuredmanufacturers/count`
    );

    dispatch({
      type: GET_FEATURED_COUNT_MAN,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FEATURED_COUNT_MAN_ERROR,
      payload: { msg: "Could not fetch the featured manufacturer count" },
    });
  }
};

//gets all featured manufacturers paginated
export const getFeaturedPage = (page) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/featuredmanufacturers/page/${page}/25`
    );

    dispatch({
      type: GET_ALL_FEATURED_PAGE_MAN,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_FEATURED_PAGE_MAN_ERROR,
      payload: { msg: "Could not fetch all featured manufacturers" },
    });
  }
};

//gets featured product by id
export const getFeaturedMan = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/featuredmanufacturers/featuredmanufacturer/${id}`
    );

    dispatch({
      type: GET_FEATURED_MAN,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FEATURED_MAN_ERROR,
      payload: { msg: "Could not fetch featured product" },
    });
  }
};

//Add new featured product
export const addFeaturedMan = (featured) => async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/api/featuredmanufacturers/`, {
      featured,
    });

    dispatch({
      type: ADD_FEATURED_MAN,
      payload: response.data,
    });
    dispatch(getFeatured());
    dispatch(setAlert("Featured manufacturer added successfully!", "success"));
  } catch (err) {
    dispatch(setAlert("Failed to add featured manufacturer", "danger"));
    dispatch({
      type: ADD_FEATURED_MAN_ERROR,
      payload: { msg: "Could not add featured manufacturer" },
    });
  }
};

//update featured manufacturer by id
export const updateFeaturedMan = (id, featured) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/featuredmanufacturers/${id}`,
      {
        featured,
      }
    );

    dispatch({
      type: UPDATE_FEATURED_MAN,
      payload: response.data,
    });
    dispatch(getFeatured());
    dispatch(
      setAlert("Featured manufacturer successfully updated!", "success")
    );
  } catch (err) {
    dispatch(setAlert("Could not update featured manufacturer", "danger"));
    dispatch({
      type: UPDATE_FEATURED_MAN_ERROR,
      payload: { msg: "Could not update featured manufacturer" },
    });
  }
};

//delete featured manufacturer by id
export const deleteFeaturedMan = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/featuredmanufacturers/${id}`
    );

    dispatch({
      type: DELETE_FEATURED_MAN,
      payload: response.data,
    });
    dispatch(getFeatured());
    dispatch(
      setAlert("Featured manufacturer successfully deleted!", "success")
    );
  } catch (err) {
    dispatch(setAlert("Could not delete featured manufacturer", "danger"));
    dispatch({
      type: DELETE_FEATURED_MAN_ERROR,
      payload: { msg: "Could not delete featured manufacturer" },
    });
  }
};

//select a featured manufacturer from table
export const selectFeaturedMan = (manufacturer) => (dispatch) => {
  dispatch({
    type: SELECT_FEATURED_MAN,
    payload: manufacturer,
  });
};
