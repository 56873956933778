import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Dialog } from "@mui/material";

import DeleteForm from "./DeleteForm";
import EditForm from "./EditForm";

const OptionsDialog = ({
    type,
    open,
    handleClose,
    page,
    selectedAction,
    article: { selectedArticle },
}) => {

    useEffect(() => {
        if (!selectedArticle) {
            handleClose();
        }
    }, []);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
        >
            {selectedAction === "edit" && selectedArticle ? (
                <EditForm handleClose={handleClose} page={page} type={type} />
            )
                : selectedAction === "delete" && selectedArticle ? (
                    <DeleteForm handleClose={handleClose} page={page} />
                )
                    : handleClose()}
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    article: state.article,
});

export default connect(mapStateToProps, {})(OptionsDialog);
