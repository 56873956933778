import {
    DL_GET_POPUPS,
    DL_GET_POPUPS_ERROR,
    DL_GET_PUBLISHED_POPUPS,
    DL_GET_PUBLISHED_POPUPS_ERROR,
    DL_SEARCH_POPUPS,
    DL_SEARCH_POPUPS_ERROR,
    DL_GET_POPUPS_COUNT,
    DL_GET_POPUPS_COUNT_ERROR,
    DL_GET_POPUPS_SEARCH_COUNT,
    ADD_POPUP,
    ADD_POPUP_ERROR,
    UPDATE_POPUP,
    UPDATE_POPUP_ERROR,
    DELETE_POPUP,
    DELETE_POPUP_ERROR,
    DL_SELECT_POPUP,
} from "../actions/types";

const initialState = {
    publishedPopUps: [],
    popUps: [],
    searchedPopUps: [],
    selectedPopUp: null,
    count: null,
    searchCount: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_POPUPS:
            return {
                ...state,
                popUps: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_PUBLISHED_POPUPS:
            return {
                ...state,
                publishedPopUps: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_POPUPS:
            return {
                ...state,
                searchedPopUps: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_POPUPS_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_POPUPS_SEARCH_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_POPUP:
            return {
                ...state,
                selectedPopUp: payload,
            };
        case ADD_POPUP:
        case UPDATE_POPUP:
        case DELETE_POPUP:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case DL_GET_POPUPS_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DL_GET_PUBLISHED_POPUPS_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DL_SEARCH_POPUPS_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DL_GET_POPUPS_COUNT_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };

        case ADD_POPUP_ERROR:
        case UPDATE_POPUP_ERROR:
        case DELETE_POPUP_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
