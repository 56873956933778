import {
    DXP_FORUM_GET,
    DXP_FORUM_GET_ERROR,
    DXP_FORUM_SELECT,
    DXP_FORUM_ADD,
    DXP_FORUM_ADD_ERROR,
    DXP_FORUM_UPDATE,
    DXP_FORUM_UPDATE_ERROR,
    DXP_FORUM_DELETE,
    DXP_FORUM_DELETE_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    featuredContent: [],
    selectedContent: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_FORUM_GET:
            return {
                ...state,
                featuredContent: payload,
                isLoading: false,
                success: true,
            };
        case DXP_FORUM_SELECT:
            return {
                ...state,
                selectedContent: payload,
                isLoading: false,
                success: true,
            };
        case DXP_FORUM_ADD:
        case DXP_FORUM_UPDATE:
        case DXP_FORUM_DELETE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_FORUM_GET_ERROR:
            return {
                ...state,
                featuredContent: payload,
                isLoading: false,
                success: true
            }
        case DXP_FORUM_ADD_ERROR:
        case DXP_FORUM_UPDATE_ERROR:
        case DXP_FORUM_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: true
            }
        default:
            return state;
    }
}
