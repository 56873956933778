import {
    DXP_GET_UTM_METADATA,
    DXP_GET_UTM_METADATA_ERROR,

} from "../actions/types";

const initialState = {
    utmMetadata: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_UTM_METADATA:
            return {
                ...state,
                utmMetadata: payload,
                isLoading: false,
                success: true,
            };

        case DXP_GET_UTM_METADATA_ERROR:
            return {
                ...state,
                utmMetadata: null,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
