import {
    DXP_GET_COMPLETED_COURSE_PAGINATED,
    DXP_GET_COMPLETED_COURSE_PAGINATED_ERROR,
    DXP_SEARCH_COMPLETED_COURSE_PAGINATED,
    DXP_SEARCH_COMPLETED_COURSE_PAGINATED_ERROR,
    DXP_SELECT_COMPLETED_COURSE,
    DXP_GET_COMPLETED_COURSE_COUNT,
    DXP_GET_COMPLETED_COURSE_COUNT_ERROR,
    DXP_GET_COMPLETED_COURSE_STATS,
    DXP_GET_COMPLETED_COURSE_STATS_ERROR,

} from "../actions/types";

const initialState = {
    completedCourses: [],
    completedCoursesCount: null,
    completedCoursesSearch: [],
    completedCoursesSearchCount: null,
    selectedCompletedCourse: null,
    completedCourseTotalCount: {},
    courseStatData: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_COMPLETED_COURSE_PAGINATED:
            return {
                ...state,
                completedCourses: payload?.rows,
                completedCoursesCount: payload?.count,
                isLoading: false,
                success: true,
            };

        case DXP_SEARCH_COMPLETED_COURSE_PAGINATED:
            return {
                ...state,
                completedCoursesSearch: payload?.rows,
                completedCoursesSearchCount: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_COMPLETED_COURSE_COUNT:
            return {
                ...state,
                completedCourseTotalCount: payload,
                isLoading: false,
                success: true,
            };

        case DXP_SELECT_COMPLETED_COURSE:
            return {
                ...state,
                selectedCompletedCourse: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_COMPLETED_COURSE_STATS:
            return {
                ...state,
                courseStatData: payload,
                isLoading: false,
                success: true,
            };

        case DXP_GET_COMPLETED_COURSE_PAGINATED_ERROR:
        case DXP_SEARCH_COMPLETED_COURSE_PAGINATED_ERROR:
        case DXP_GET_COMPLETED_COURSE_COUNT_ERROR:
        case DXP_GET_COMPLETED_COURSE_STATS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}