import {
  GET_ALL_MANUFACTURERS,
  GET_ALL_MANUFACTURERS_UPDATED,
  ALL_MANUFACTURERS_ERROR,
  GET_ALL_MANUFACTURERS_PAGE,
  GET_ALL_MANUFACTURERS_PAGE_ERROR,
  GET_MANUFACTURER,
  GET_MANUFACTURER_ERROR,
  SELECT_MANUFACTURER,
  SEARCH_MANUFACTURERS,
  SEARCH_MANUFACTURERS_ERROR,
  GET_MANUFACTURERS_AD,
  ADD_MANUFACTURER,
  DELETE_ADVERTISEMENT,
  ADD_ADVERTISEMENT,
  ADD_MANUFACTURER_ERROR,
  UPDATE_MANUFACTURER,
  DELETE_MANUFACTURER,
  UPDATE_MANUFACTURER_ERROR,
  DELETE_MANUFACTURER_ERROR,
  GET_MANUFACTURERS_AD_ERROR,
  GET_MANUFACTURER_COUNT,
  GET_MANUFACTURER_COUNT_ERROR,
  GET_MANUFACTURERS_BY_CATEGORY,
  GET_MANUFACTURERS_BY_CATEGORY_ERROR
} from "../actions/types";

const initialState = {
  manufacturers: [],
  manufacturersUpdated: [],
  manufacturerSearch: [],
  manufacturerSearchCount: null,
  manufacturersAd: [],
  manufacturersByCategory: [],
  selectedManufacturer: null,
  manufacturer: null,
  isLoading: true,
  success: false,
  count: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_MANUFACTURERS:
      return {
        ...state,
        manufacturers: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_MANUFACTURERS_UPDATED:
      return {
        ...state,
        manufacturersUpdated: payload,
        isLoading: false,
        success: true,
      }
    case GET_ALL_MANUFACTURERS_PAGE:
      return {
        ...state,
        manufacturers: payload,
        isLoading: false,
        success: true,
      };
    case GET_MANUFACTURER:
      return {
        ...state,
        manufacturer: payload,
        isLoading: false,
        success: true,
      };
    case GET_MANUFACTURERS_BY_CATEGORY:
      return {
        ...state,
        manufacturersByCategory: payload,
        isLoading: false,
        success: true,
      };
    case SELECT_MANUFACTURER:
      return {
        ...state,
        selectedManufacturer: payload,
      };
    case SEARCH_MANUFACTURERS:
      return {
        ...state,
        manufacturerSearch: payload.rows,
        manufacturerSearchCount: payload.count,
        isLoading: false,
        success: true,
      };
    case GET_MANUFACTURER_COUNT:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      };
    case GET_MANUFACTURERS_AD:
      return {
        ...state,
        manufacturersAd: payload.filter(
          (item) => item.Advertisements.length > 0
        ),
        isLoading: false,
        success: true,
      };
    case UPDATE_MANUFACTURER:
    case DELETE_MANUFACTURER:
    case ADD_MANUFACTURER:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case ADD_ADVERTISEMENT:
      return {
        ...state,
        manufacturersAd: [payload, ...state.manufacturersAd],
        isLoading: false,
        success: true,
      };

    case DELETE_ADVERTISEMENT:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case ALL_MANUFACTURERS_ERROR:
      return {
        ...state,
        manufacturers: payload,
        isLoading: false,
        success: false,
      };
    case GET_ALL_MANUFACTURERS_PAGE_ERROR:
      return {
        ...state,
        manufacturers: payload,
        isLoading: false,
        success: false,
      };
    case GET_MANUFACTURERS_AD_ERROR:
      return {
        ...state,
        manufacturersAd: payload,
        isLoading: false,
        success: false,
      };
    case DELETE_MANUFACTURER_ERROR:
    case UPDATE_MANUFACTURER_ERROR:
    case ADD_MANUFACTURER_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case GET_MANUFACTURER_ERROR:
      return {
        ...state,
        manufacturer: payload,
        isLoading: false,
        success: false,
      };
    case GET_MANUFACTURERS_BY_CATEGORY_ERROR:
      return {
        ...state,
        manufacturersByCategory: payload,
        isLoading: false,
        success: false,
      };
    case SEARCH_MANUFACTURERS_ERROR:
      return {
        ...state,
        manufacturerSearch: payload.rows,
        manufacturerSearchCount: payload.count,
        isLoading: false,
        success: false,
      };
    case GET_MANUFACTURER_COUNT_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
}
