import {
    DXP_GET_ALL_TRANSACTIONS_PAGINATED,
    DXP_GET_ALL_TRANSACTIONS_PAGINATED_ERROR,
    DXP_GET_TRANSACTIONS_BY_MEMBERSHIP_PAGINATED,
    DXP_GET_TRANSACTIONS_BY_MEMBERSHIP_PAGINATED_ERROR,
    DXP_GET_SEARCH_TRANSACTIONS_PAGINATED,
    DXP_GET_SEARCH_TRANSACTIONS_PAGINATED_ERROR,
    DXP_GET_STRIPE_INVOICES_TO_SELECT,
    DXP_GET_STRIPE_INVOICES_TO_SELECT_ERROR,
    DXP_CREATE_NEW_STRIPE_TRANSACTION_ENTRY,
    DXP_CREATE_NEW_STRIPE_TRANSACTION_ENTRY_ERROR,
    DXP_RESET_RETURNING_STRIPE_CHARGES_STATE,
    DXP_CREATE_NEW_AUTH_NET_TRANSACTION_ENTRY,
    DXP_CREATE_NEW_AUTH_NET_TRANSACTION_ENTRY_ERROR,
    DXP_SEARCH_USER_FOR_AUTH_NET_TRANSACTION_ENTRY,
    DXP_SEARCH_USER_FOR_AUTH_NET_TRANSACTION_ENTRY_ERROR,
    DXP_GET_PAYPAL_TRANSACTIONS_TO_SELECT,
    DXP_GET_PAYPAL_TRANSACTIONS_TO_SELECT_ERROR,
    DXP_CREATE_NEW_PAYPAL_TRANSACTION_ENTRY,
    DXP_CREATE_NEW_PAYPAL_TRANSACTION_ENTRY_ERROR,
    DXP_RESET_RETURNING_PAYPAL_TRANSACTIONS_STATE,


    DXP_GET_ALL_TRANSACTIONS_BY_USERID,
    DXP_GET_ALL_TRANSACTIONS_BY_USERID_ERROR,
    DXP_REFUND_ANY_TRANSACTION,
    DXP_REFUND_ANY_TRANSACTION_ERROR,
    DXP_GET_TRANSACTION_BY_TRANSACTION_ID,
    DXP_GET_TRANSACTION_BY_TRANSACTION_ID_ERROR,
    DXP_UPDATE_TRANSACTION_BY_ENTRY_ID,
    DXP_UPDATE_TRANSACTION_BY_ENTRY_ID_ERROR,
    DXP_ADD_NEW_TRANSACTION_ENTRY,
    DXP_ADD_NEW_TRANSACTION_ENTRY_ERROR,
} from "../actions/types";

const initialState = {
    allTransactionsByUserId: [],
    allTransactionsPaginated: [],
    allTransactionsCount: null,
    transactionsByMembershipTypePaginated: [],
    transactionsByMembershipTypeCount: null,
    transactionsBySearchTermPaginated: [],
    transactionsBySearchTermCount: null,
    returningStripeTransactions: null,
    authNetUsersSearch: [],
    returningPayPalTransactions: null,
    transactionByTransactionId: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_TRANSACTION_BY_TRANSACTION_ID:
            return {
                ...state,
                transactionByTransactionId: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_TRANSACTIONS_BY_USERID:
            return {
                ...state,
                allTransactionsByUserId: payload,
                isLoading: false,
                success: true,
            };
        case DXP_RESET_RETURNING_PAYPAL_TRANSACTIONS_STATE:
            return {
                ...state,
                returningPayPalTransactions: null,
                isLoading: false,
                success: true,
            };
        case DXP_GET_PAYPAL_TRANSACTIONS_TO_SELECT:
            return {
                ...state,
                returningPayPalTransactions: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_USER_FOR_AUTH_NET_TRANSACTION_ENTRY:
            return {
                ...state,
                authNetUsersSearch: payload,
                isLoading: false,
                success: true,
            };
        case DXP_RESET_RETURNING_STRIPE_CHARGES_STATE:
            return {
                ...state,
                returningStripeTransactions: null,
                isLoading: false,
                success: true,
            };
        case DXP_GET_STRIPE_INVOICES_TO_SELECT:
            return {
                ...state,
                returningStripeTransactions: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_TRANSACTIONS_PAGINATED:
            return {
                ...state,
                allTransactionsPaginated: payload.rows,
                allTransactionsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_TRANSACTIONS_BY_MEMBERSHIP_PAGINATED:
            return {
                ...state,
                transactionsByMembershipTypePaginated: payload.rows,
                transactionsByMembershipTypeCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_SEARCH_TRANSACTIONS_PAGINATED:
            return {
                ...state,
                transactionsBySearchTermPaginated: payload.rows,
                transactionsBySearchTermCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DXP_CREATE_NEW_STRIPE_TRANSACTION_ENTRY:
        case DXP_CREATE_NEW_AUTH_NET_TRANSACTION_ENTRY:
        case DXP_CREATE_NEW_PAYPAL_TRANSACTION_ENTRY:
        case DXP_REFUND_ANY_TRANSACTION:
        case DXP_UPDATE_TRANSACTION_BY_ENTRY_ID:
        case DXP_ADD_NEW_TRANSACTION_ENTRY:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_TRANSACTIONS_PAGINATED_ERROR:
        case DXP_GET_TRANSACTIONS_BY_MEMBERSHIP_PAGINATED_ERROR:
        case DXP_GET_SEARCH_TRANSACTIONS_PAGINATED_ERROR:
        case DXP_GET_STRIPE_INVOICES_TO_SELECT_ERROR:
        case DXP_CREATE_NEW_STRIPE_TRANSACTION_ENTRY_ERROR:
        case DXP_CREATE_NEW_AUTH_NET_TRANSACTION_ENTRY_ERROR:
        case DXP_SEARCH_USER_FOR_AUTH_NET_TRANSACTION_ENTRY_ERROR:
        case DXP_GET_PAYPAL_TRANSACTIONS_TO_SELECT_ERROR:
        case DXP_CREATE_NEW_PAYPAL_TRANSACTION_ENTRY_ERROR:
        case DXP_GET_ALL_TRANSACTIONS_BY_USERID_ERROR:
        case DXP_REFUND_ANY_TRANSACTION_ERROR:
        case DXP_GET_TRANSACTION_BY_TRANSACTION_ID_ERROR:
        case DXP_UPDATE_TRANSACTION_BY_ENTRY_ID_ERROR:
        case DXP_ADD_NEW_TRANSACTION_ENTRY_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}