import {
    GET_TEMPLATES,
    GET_TEMPLATES_ERROR,
    SET_HAS_TEMPLATE,
    SET_HAS_TEMPLATE_ERROR,
    SELECT_ARTICLE_TEMPLATES,
    SELECT_ARTICLE_FOR_TEMPLATE,
    SELECT_BLOG_FOR_TEMPLATE,
    SELECT_EVALUATION_FOR_TEMPLATE,
    SET_HAS_TEMPLATE_SECTION,
    SELECT_ARTICLE_TEMPLATE_SECTION,
    CHANGE_INPUT_DATA,
    GET_ARTICLE_TITLE,
    GET_ARTICLE_TITLE_ERROR,
    SAVE_ARTICLE,
    SAVE_ARTICLE_ERROR,
    SAVE_TEMPLATE,
    SAVE_TEMPLATE_ERROR,
    SET_TEMPLATE_RETURN_URL,
    DELETE_TEMPLATE,
    DELETE_TEMPLATE_ERROR,
    SELECT_ARTICLE_TEMPLATE_INPUT,
    CHANGE_TEMPLATE_HISTORY,
    CLEAR_TEMPLATE_HISTORY,
    ADD_TEMPLATE_HISTORY,
    CLEAR_TEMPLATE,
    SHOW_PREVIEW,
    SHOW_PREVIEW_ERROR,
} from "../actions/types";

const initialState = {
    templates: [],
    hasTemplate: false,
    selectedTemplate: false,
    selectedArticle: null,
    selectedBlog: null,
    selectedEvaluation: null,
    articleTitle: null,
    selectedSection: null,
    selectedInput: null,
    sections: [],
    inputtedData: {},
    returnUrl: null,
    templateHistory: [],
    historyIndex: null,
    templatePreview: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_TEMPLATES:
            return {
                ...state,
                templates: payload,
                isLoading: false,
                success: true,
            };
        case SET_HAS_TEMPLATE:
            return {
                ...state,
                hasTemplate: payload,
                isLoading: false,
                success: true,
            };
        case GET_ARTICLE_TITLE:
            return {
                ...state,
                articleTitle: payload,
                isLoading: false,
                success: true,
            };
        case SHOW_PREVIEW:
            return {
                ...state,
                templatePreview: payload,
                isLoading: false,
                success: true,
            };
        case CHANGE_INPUT_DATA:
            return {
                ...state,
                inputtedData: payload.data,
                templateHistory: payload.newHistory,
                historyIndex: null
            };
        case CHANGE_TEMPLATE_HISTORY:
            return {
                ...state,
                inputtedData: state.templateHistory[payload].inputData,
                sections: state.templateHistory[payload].sections,
                historyIndex: payload
            };
        case CLEAR_TEMPLATE:
            return {
                ...state,
                inputtedData: {},
                sections: [],
                templateHistory: [{ inputData: {}, sections: [] }],
                historyIndex: null
            };
        case ADD_TEMPLATE_HISTORY:
            return {
                ...state,
                inputtedData: payload.data,
                sections: payload.section,
                templateHistory: payload.newHistory,
                historyIndex: null
            };
        case CLEAR_TEMPLATE_HISTORY:
            return {
                ...state,
                templateHistory: [],
                historyIndex: null
            };
        case SELECT_ARTICLE_TEMPLATES:
            return {
                ...state,
                selectedTemplate: payload,
            };
        case SELECT_ARTICLE_FOR_TEMPLATE:
            return {
                ...state,
                selectedArticle: payload,
            };
        case SELECT_BLOG_FOR_TEMPLATE:
            return {
                ...state,
                selectedBlog: payload,
            };
        case SELECT_EVALUATION_FOR_TEMPLATE:
            return {
                ...state,
                selectedEvaluation: payload,
            };
        case SET_HAS_TEMPLATE_SECTION:
            return {
                ...state,
                sections: payload.section,
                templateHistory: payload.newHistory,
                historyIndex: null
            };
        case SELECT_ARTICLE_TEMPLATE_SECTION:
            return {
                ...state,
                selectedSection: payload,
            };
        case SET_TEMPLATE_RETURN_URL:
            return {
                ...state,
                returnUrl: payload,
            };
        case SELECT_ARTICLE_TEMPLATE_INPUT:
            return {
                ...state,
                selectedInput: payload,
            };
        case SAVE_ARTICLE:
        case SAVE_TEMPLATE:
        case DELETE_TEMPLATE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case GET_TEMPLATES_ERROR:
            return {
                ...state,
                templates: payload,
                isLoading: false,
                success: false,
            };
        case SET_HAS_TEMPLATE_ERROR:
            return {
                ...state,
                hasTemplates: payload,
                isLoading: false,
                success: false,
            };
        case GET_ARTICLE_TITLE_ERROR:
            return {
                ...state,
                articleTitle: payload,
                isLoading: false,
                success: false,
            };
        case SHOW_PREVIEW_ERROR:
            return {
                ...state,
                templatePreview: payload,
                isLoading: false,
                success: false,
            };
        case SAVE_ARTICLE_ERROR:
        case SAVE_TEMPLATE_ERROR:
        case DELETE_TEMPLATE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
