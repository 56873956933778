import {
    GET_LANGUAGE,
    GET_LANGUAGE_ERROR,
    DXP_SELECT_LANGUAGE,
    ADD_LANGUAGE,
    ADD_LANGUAGE_ERROR,
    UPDATE_LANGUAGE,
    UPDATE_LANGUAGE_ERROR,
    DXP_GET_ALL_LANGUAGES,
    DXP_GET_ALL_LANGUAGES_ERROR
} from "../actions/types";

const initialState = {
    allLanguages: [],
    languageById: [],
    selectedLanguage: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_LANGUAGES:
            return {
                ...state,
                allLanguages: payload,
                isLoading: false,
                success: true,
            };
        case GET_LANGUAGE:
            return {
                ...state,
                languageById: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_LANGUAGE:
            return {
                ...state,
                selectedLanguage: payload,
                isLoading: false,
                success: true,
            };
        case ADD_LANGUAGE:
        case UPDATE_LANGUAGE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_LANGUAGES_ERROR:
            return {
                ...state,
                allLanguages: payload,
                isLoading: false,
                success: false,
            };
        case GET_LANGUAGE_ERROR:
            return {
                ...state,
                languageById: payload,
                isLoading: false,
                success: false,
            };
        case ADD_LANGUAGE_ERROR:
        case UPDATE_LANGUAGE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
