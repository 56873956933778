import {
    DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED,
    DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_ERROR,
    DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_BY_FILTER_VALUE,
    DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_BY_FILTER_VALUE_ERROR,
    DXP_POST_CREATE_FELLOWSHIP_MATERIALS,
    DXP_POST_CREATE_FELLOWSHIP_MATERIALS_ERROR,
    DXP_GET_ALL_FELLOWSHIP_MATERIALS_BY_SEARCH_TERM_PAGINATED,
    DXP_GET_ALL_FELLOWSHIP_MATERIALS_BY_SEARCH_TERM_PAGINATED_ERROR,
    DXP_DELETE_FELLOWSHIP_MATERIALS,
    DXP_DELETE_FELLOWSHIP_MATERIALS_ERROR,
    DXP_UPDATE_FELLOWSHIP_MATERIALS,
    DXP_UPDATE_FELLOWSHIP_MATERIALS_ERROR
} from "../actions/types";

const initialState = {
    allFellowshipMaterialsPaginated: [],
    allFellowshipMaterialsPaginatedCount: null,
    allMaterialsByFellowshipIdPaginated: [],
    allMaterialsByFellowshipIdPaginatedCount: null,
    allMaterialsBySearchTermPaginated: [],
    allMaterialsBySearchTermPaginatedCount: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_FELLOWSHIP_MATERIALS_BY_SEARCH_TERM_PAGINATED:
            return {
                ...state,
                allMaterialsBySearchTermPaginated: payload?.rows,
                allMaterialsBySearchTermPaginatedCount: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED:
            return {
                ...state,
                allFellowshipMaterialsPaginated: payload?.rows,
                allFellowshipMaterialsPaginatedCount: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_BY_FILTER_VALUE:
            return {
                ...state,
                allMaterialsByFellowshipIdPaginated: payload?.rows,
                allMaterialsByFellowshipIdPaginatedCount: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_POST_CREATE_FELLOWSHIP_MATERIALS:
        case DXP_DELETE_FELLOWSHIP_MATERIALS:
        case DXP_UPDATE_FELLOWSHIP_MATERIALS:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_ERROR:
        case DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_BY_FILTER_VALUE_ERROR:
        case DXP_POST_CREATE_FELLOWSHIP_MATERIALS_ERROR:
        case DXP_GET_ALL_FELLOWSHIP_MATERIALS_BY_SEARCH_TERM_PAGINATED_ERROR:
        case DXP_DELETE_FELLOWSHIP_MATERIALS_ERROR:
        case DXP_UPDATE_FELLOWSHIP_MATERIALS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}