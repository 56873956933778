const defaultImage = '/static/img/brands/author-image-placeholder.jpg';

const config = {
    element_key: [
        {
            component_id: '1',
            component_version: '1',
            component_name: 'Header',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'header', value: '', options: { default: 'Please Enter Header Text' } },
            ],
            style_options: ['backgroundColor', 'color', 'marginTop', 'marginBottom', 'textAlign'],
            children_styles_options: [
                { name: 'header', styles: [''] }
            ],
            component: 'HeaderI1V1',
        },
        {
            component_id: '2',
            component_version: '1',
            component_name: 'Left Image Text Block',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'image', value: '', options: { default: defaultImage } },
                { name: 'body', value: '', options: { default: 'Please Enter Text' } },
                { name: 'imageLink', value: '', options: { default: 'Please Enter Image Link' } },
            ],
            style_options: ['backgroundColor', 'marginTop', 'marginBottom',],
            children_styles_options: [
                { name: 'image', styles: ['borderRadius', 'objectFit', 'height', 'width'] },
                { name: 'body', styles: ['color', 'textAlign'] },
                { name: 'imageLink', styles: [''] }

            ],
            component: 'LeftImageTextBlockI2V1',
        },
        {
            component_id: '3',
            component_version: '1',
            component_name: 'Text Block',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'body', value: '', options: { default: 'Please Enter Text' } },
            ],
            style_options: ['backgroundColor', 'color', 'marginTop', 'marginBottom', 'textAlign'],
            children_styles_options: [
                { name: 'body', styles: [''] }
            ],
            component: 'TextBlockI3V1',
        },
        {
            component_id: '4',
            component_version: '1',
            component_name: 'Two Images',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'leftImage', value: '', options: { default: defaultImage } },
                { name: 'rightImage', value: '', options: { default: defaultImage } },
                { name: 'leftImageLink', value: '', options: { default: 'Please Enter Image Link' } },
                { name: 'rightImageLink', value: '', options: { default: 'Please Enter Image Link' } },
            ],
            style_options: ['backgroundColor', 'marginTop', 'marginBottom',],
            children_styles_options: [
                { name: 'leftImage', styles: ['borderRadius', 'objectFit', 'height'] },
                { name: 'rightImage', styles: ['borderRadius', 'objectFit', 'height'] },
                { name: 'leftImageLink', styles: [''] },
                { name: 'rightImageLink', styles: [''] },
            ],
            component: 'TwoImageI4V1',
        },
        {
            component_id: '5',
            component_version: '1',
            component_name: 'Text Block w/ Header',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'header', value: '', options: { default: 'Please Enter Text' } },
                { name: 'body', value: '', options: { default: 'Please Enter Text' } },
            ],
            style_options: ['backgroundColor', 'marginTop', 'marginBottom', 'textAlign'],
            children_styles_options: [
                { name: 'header', styles: ['color'] },
                { name: 'body', styles: ['color'] }
            ],
            component: 'HeaderTextBlockI5V1',
        },
        {
            component_id: '6',
            component_version: '1',
            component_name: 'Author Block',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'image', value: '', options: { default: defaultImage } },
                { name: 'name', value: '', options: { default: 'Please Enter Name' } },
                { name: 'text', value: '', options: { default: 'Please Enter Text' } },
                { name: 'imageLink', value: '', options: { default: 'Please Enter Image Link' } },
            ],
            style_options: ['backgroundColor', 'marginTop', 'marginBottom', 'textAlign'],
            children_styles_options: [
                { name: 'image', styles: ['borderRadius', 'objectFit'] },
                { name: 'name', styles: ['color'] },
                { name: 'text', styles: ['color'] },
                { name: 'imageLink', styles: [''] }

            ],
            component: 'AuthorBlockI6V1',
        },
        {
            component_id: '7',
            component_version: '1',
            component_name: 'Right Image Text Block',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'body', value: '', options: { default: 'Please Enter Text', } },
                { name: 'image', value: '', options: { default: defaultImage } },
                { name: 'imageLink', value: '', options: { default: 'Please Enter Image Link' } },
            ],
            style_options: ['backgroundColor', 'marginTop', 'marginBottom',],
            children_styles_options: [
                { name: 'body', styles: ['color', 'textAlign'] },
                { name: 'image', styles: ['borderRadius', 'objectFit', 'height', 'width'] },
                { name: 'imageLink', styles: [''] }
            ],
            component: 'RightImageTextBlockI7V1',
        },
        {
            component_id: '8',
            component_version: '1',
            component_name: 'Dynamic Image Block',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'images', value: [''], options: { default: defaultImage, imagesPerRow: 4 } },
                { name: 'imageLink', value: [''], options: { default: 'Please Enter Image Link' } },
            ],
            style_options: ['backgroundColor', 'marginTop', 'marginBottom', 'gap', 'justifyContent',],
            children_styles_options: [
                { name: 'image', styles: ['borderRadius', 'objectFit', 'height'] },
                { name: 'imageLink', styles: [''] }
            ],
            component: 'DynamicImageBlockI8V1',
        },
        {
            component_id: '9',
            component_version: '1',
            component_name: 'Full Image Block',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'image', value: '', options: { default: defaultImage } },
                { name: 'imageLink', value: '', options: { default: 'Please Enter Image Link' } },
            ],
            style_options: ['backgroundColor', 'marginTop', 'marginBottom',],
            children_styles_options: [
                { name: 'image', styles: ['borderRadius', 'objectFit', 'height'] },
                { name: 'imageLink', styles: [''] }
            ],
            component: 'FullImageBlockI9V1',
        },
        {
            component_id: '10',
            component_version: '1',
            component_name: 'Quote Block',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'image', value: '', options: { default: defaultImage } },
                { name: 'text', value: '', options: { default: 'Please Enter Quote' } },
                { name: 'name', value: '', options: { default: 'Please Enter Name' } },
                { name: 'imageLink', value: '', options: { default: 'Please Enter Image Link' } },
            ],
            style_options: ['backgroundColor', 'marginTop', 'marginBottom', 'textAlign'],
            children_styles_options: [
                { name: 'image', styles: ['borderRadius', 'objectFit'] },
                { name: 'text', styles: ['color'] },
                { name: 'name', styles: ['color'] },
                { name: 'imageLink', styles: [''] }
            ],
            component: 'QuoteBlockI10V1',
        },
        {
            component_id: '11',
            component_version: '1',
            component_name: 'Bulleted List Block',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'text', value: '', options: { default: 'Please Enter Header Text' } },
                { name: 'listItem', value: [''], options: { default: 'Please Enter Text' } },
            ],
            style_options: ['backgroundColor', 'marginTop', 'marginBottom', 'textAlign'],
            children_styles_options: [
                { name: 'text', styles: ['color', 'fontWeight'] },
                { name: 'listItem', styles: [''] },
            ],
            component: 'BulletListI11V1',
        },
        {
            component_id: '12',
            component_version: '1',
            component_name: 'Evaluator Block',
            templates_categories_available: ['article', 'evaluation', 'blog'],
            children: [
                { name: 'image', value: [''], options: { default: defaultImage } },
                { name: 'quote', value: [''], options: { default: 'Please Enter Quote' } },
                { name: 'name', value: [''], options: { default: 'Please Enter Name' } },
                { name: 'address', value: [''], options: { default: 'Please Enter Address ( City, State )' } },
                { name: 'imageLink', value: [''], options: { default: 'Enter Image Link' } },
            ],
            style_options: ['backgroundColor', 'marginTop', 'marginBottom', 'textAlign'],
            children_styles_options: [
                { name: 'image', styles: ['borderRadius', 'objectFit'] },
                { name: 'quote', styles: ['color'] },
                { name: 'name', styles: ['color'] },
                { name: 'address', styles: ['color'] },
                { name: 'imageLink', styles: [''] }
            ],
            component: 'EvaluatorBlockI12V1',
        },
    ]
};

export default config;



// inputtedData = {
// TwoImageI4V1: [
//     {
//         component_id: '',
//         component_version: 1,
//         order: 0,
//         children: [
//           { name: '', value: '', options: {} }
//         ],
//         styles: { ''}
//      children- styles: [
//         { name: '', styles: '' }
//       ]
//    }
// ]
// }