import {
    DXP_ADD_PRODUCT_MANUFACTURER,
    DXP_ADD_PRODUCT_MANUFACTURER_ERROR,
    DXP_GET_PRODUCT_MANUFACTURERS,
    DXP_GET_PRODUCT_MANUFACTURERS_ERROR,
    DXP_UPDATE_PRODUCT_MANUFACTURER,
    DXP_UPDATE_PRODUCT_MANUFACTURER_ERROR,
} from "../actions/types";

const initialState = {
    manufacturerCount: null,
    productManufacturers: [],
    selectedProductManufacturer: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_PRODUCT_MANUFACTURERS:
            return {
                ...state,
                productManufacturers: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_PRODUCT_MANUFACTURER:
        case DXP_UPDATE_PRODUCT_MANUFACTURER:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_PRODUCT_MANUFACTURERS_ERROR:
            return {
                ...state,
                productManufacturers: payload,
                isLoading: false,
                success: false,
            };

        case DXP_ADD_PRODUCT_MANUFACTURER_ERROR:
        case DXP_UPDATE_PRODUCT_MANUFACTURER_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
