import axios from "axios";
import {
  GET_ALL_MANUFACTURERS,
  GET_ALL_MANUFACTURERS_UPDATED,
  GET_ALL_MANUFACTURERS_PAGE,
  ALL_MANUFACTURERS_ERROR,
  GET_MANUFACTURER,
  GET_MANUFACTURER_ERROR,
  SELECT_MANUFACTURER,
  SEARCH_MANUFACTURERS,
  SEARCH_MANUFACTURERS_ERROR,
  GET_MANUFACTURER_COUNT,
  GET_MANUFACTURER_COUNT_ERROR,
  GET_MANUFACTURERS_AD,
  ADD_MANUFACTURER,
  ADD_MANUFACTURER_ERROR,
  UPDATE_MANUFACTURER,
  DELETE_MANUFACTURER,
  UPDATE_MANUFACTURER_ERROR,
  DELETE_MANUFACTURER_ERROR,
  GET_MANUFACTURERS_AD_ERROR,
  GET_ALL_MANUFACTURERS_PAGE_ERROR,
  UPDATE_MANUFACTURER_TAGS,
  UPDATE_MANUFACTURER_TAG_ERROR,
  GET_MANUFACTURERS_BY_CATEGORY,
  GET_MANUFACTURERS_BY_CATEGORY_ERROR,
} from "./types";
import { setAlert } from "./alerts";

const baseUrl = process.env.REACT_APP_BASE_URL;

//gets all manufacturers
export const getManufacturers = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/manufacturers`);

    dispatch({
      type: GET_ALL_MANUFACTURERS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: ALL_MANUFACTURERS_ERROR,
      payload: { msg: "Could not fetch all manufacturers" },
    });
  }
};

//gets all manufacturers
export const getManufacturersPublished = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/manufacturers/published`);

    dispatch({
      type: GET_ALL_MANUFACTURERS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: ALL_MANUFACTURERS_ERROR,
      payload: { msg: "Could not fetch all published manufacturers" },
    });
  }
};

//gets all updated manufacturers
export const getManufacturersUpdated = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/manufacturers/recent`);

    dispatch({
      type: GET_ALL_MANUFACTURERS_UPDATED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: ALL_MANUFACTURERS_ERROR,
      payload: { msg: "Could not fetch all manufacturers" },
    });
  }
};

//gets the total count of manufacturers
export const getManufacturerCount = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/manufacturers/count`);

    dispatch({
      type: GET_MANUFACTURER_COUNT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MANUFACTURER_COUNT_ERROR,
      payload: { msg: "Could not fetch the manufacturer count" },
    });
  }
};

//gets all manufacturers paginated
export const getManufacturersPage = (page) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/manufacturers/page/${page}/15`
    );

    dispatch({
      type: GET_ALL_MANUFACTURERS_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_MANUFACTURERS_PAGE_ERROR,
      payload: { msg: "Could not fetch all manufacturers" },
    });
  }
};

//gets all manufacturers with ads
export const getManufacturersAds = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/manufacturers/advertisements`
    );

    dispatch({
      type: GET_MANUFACTURERS_AD,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MANUFACTURERS_AD_ERROR,
      payload: { msg: "Could not fetch all manufacturers with ads" },
    });
  }
};

//gets manufacturer by id
export const getManufacturer = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/manufacturers/manufacturer/${id}`
    );

    dispatch({
      type: GET_MANUFACTURER,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MANUFACTURER_ERROR,
      payload: { msg: "Could not fetch manufacturer" },
    });
  }
};

//gets manufacturer by id with published products
export const getManufacturer2 = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/manufacturers/publishedmanufacturer/${id}`
    );

    dispatch({
      type: GET_MANUFACTURER,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MANUFACTURER_ERROR,
      payload: { msg: "Could not fetch manufacturer" },
    });
  }
};

//gets manufacturer by id with published products
export const getManufacturersByCategory = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/manufacturers/category/${id}`
    );
    dispatch({
      type: GET_MANUFACTURERS_BY_CATEGORY,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MANUFACTURERS_BY_CATEGORY_ERROR,
      payload: { msg: "Could not fetch manufacturer" },
    });
  }
};

//gets manufacturer by id with published products
export const getAllManufacturers = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/manufacturers/category/all`
    );
    dispatch({
      type: GET_MANUFACTURERS_BY_CATEGORY,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MANUFACTURERS_BY_CATEGORY_ERROR,
      payload: { msg: "Could not fetch manufacturer" },
    });
  }
};

//Add new manufacturer
export const addManufacturer = (manufacturer, page) => async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/api/manufacturers/`, {
      manufacturer,
    });

    dispatch({
      type: ADD_MANUFACTURER,
      payload: response.data,
    });
    dispatch(setAlert("Manufacturer successfully added!", "success"));
    dispatch(getManufacturersPage(page));
  } catch (err) {
    dispatch(setAlert("Could not add manufacturer", "danger"));
    dispatch({
      type: ADD_MANUFACTURER_ERROR,
      payload: { msg: "Could not add manufacturer" },
    });
  }
};

//update manufacturer by id
export const updateManufacturer = (id, manufacturer, page, search) => async (
  dispatch
) => {
  try {
    const response = await axios.put(`${baseUrl}/api/manufacturers/${id}`, {
      manufacturer,
    });

    if (manufacturer.published === false) {
      const unpublishRelatedProducts = await axios.put(`${baseUrl}/api/products/manufacturer-cascade/${id}`);
    }

    dispatch({
      type: UPDATE_MANUFACTURER,
      payload: response.data,
    });

    dispatch(setAlert("Manufacturer successfully updated!", "success"));
    if (search) {
      dispatch(searchManufacturers(search, page));
    } else {
      dispatch(getManufacturersPage(page));

    }
  } catch (err) {
    dispatch(setAlert("Could not update manufacturer", "danger"));
    dispatch({
      type: UPDATE_MANUFACTURER_ERROR,
      payload: { msg: "Could not update manufacturer" },
    });
  }
};

//update manufacturer by id for Tags
export const updateManufacturerTag = (id, manufacturer) => async (dispatch) => {
  try {
    const response = await axios.put(`${baseUrl}/api/manufacturers/${id}`, {
      manufacturer,
    });

    dispatch({
      type: UPDATE_MANUFACTURER_TAGS,
      payload: response.data,
    });

    dispatch(setAlert("Manufacturer successfully updated!", "success"));
    dispatch(getManufacturers());
  } catch (err) {
    dispatch(setAlert("Could not update manufacturer", "danger"));
    dispatch({
      type: UPDATE_MANUFACTURER_TAG_ERROR,
      payload: { msg: "Could not update manufacturer" },
    });
  }
};

//delete manufacturer by id
export const deleteManufacturer = (id, page) => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/manufacturers/${id}`);

    dispatch({
      type: DELETE_MANUFACTURER,
      payload: response.data,
    });
    dispatch(setAlert("Manufacturer successfully deleted!", "success"));
    dispatch(getManufacturersPage(page));
  } catch (err) {
    dispatch(setAlert("Could not delete manufacturer", "danger"));
    dispatch({
      type: DELETE_MANUFACTURER_ERROR,
      payload: { msg: "Could not delete manufacturer" },
    });
  }
};

//search manufactueres
export const searchManufacturers = (manufacturer, page) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/manufacturers/manufacturersearchpage/${manufacturer}/${page}/25`
    );
    dispatch({
      type: SEARCH_MANUFACTURERS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_MANUFACTURERS_ERROR,
      payload: [],
    });
  }

  // }
};


//selects a single manufacturer
export const selectManufacturer = (manufacturer) => (dispatch) => {
  dispatch({
    type: SELECT_MANUFACTURER,
    payload: manufacturer,
  });
};
