import {
	DXP_ADD_USER_EXTERNAL_CONTENT,
	DXP_ADD_USER_EXTERNAL_CONTENT_ERROR,
	DXP_UPDATE_USER_EXTERNAL_CONTENT,
	DXP_UPDATE_USER_EXTERNAL_CONTENT_ERROR,
	DXP_DELETE_USER_EXTERNAL_CONTENT,
	DXP_DELETE_USER_EXTERNAL_CONTENT_ERROR,
	DXP_PUBLISH_UNPUBLISH_USER_EXTERNAL_CONTENT,
	DXP_PUBLISH_UNPUBLISH_USER_EXTERNAL_CONTENT_ERROR

} from "../actions/types";

const initialState = {
	isLoading: true,
	success: false,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case DXP_ADD_USER_EXTERNAL_CONTENT:
		case DXP_UPDATE_USER_EXTERNAL_CONTENT:
		case DXP_DELETE_USER_EXTERNAL_CONTENT:
		case DXP_PUBLISH_UNPUBLISH_USER_EXTERNAL_CONTENT:
			return {
				...state,
				isLoading: false,
				success: true,
			};

		case DXP_ADD_USER_EXTERNAL_CONTENT_ERROR:
		case DXP_UPDATE_USER_EXTERNAL_CONTENT_ERROR:
		case DXP_DELETE_USER_EXTERNAL_CONTENT_ERROR:
		case DXP_PUBLISH_UNPUBLISH_USER_EXTERNAL_CONTENT_ERROR:
			return {
				...state,
				isLoading: false,
				success: false,
			};
		default:
			return state;
	}
}
