import {
    DXP_GET_REGISTRANT_USERS,
    DXP_GET_REGISTRANT_USERS_ERROR,
    DXP_GET_REGISTRANT_USERS_COUNT,
    DXP_GET_REGISTRANT_USERS_COUNT_ERROR,
    DXP_SEARCH_REGISTRANT_USERS,
    DXP_SEARCH_REGISTRANT_USERS_ERROR,
    DXP_SEARCH_REGISTRANT_USERS_COUNT,
    DXP_SEARCH_REGISTRANT_USERS_COUNT_ERROR,
    DXP_SELECT_REGISTRANT_USER
} from "../actions/types";

const initialState = {
    registrants: [],
    searchRegistrants: [],
    selectedRegistrant: null,
    count: null,
    searchCount: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_REGISTRANT_USERS:
            return {
                ...state,
                registrants: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_REGISTRANT_USERS_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_REGISTRANT_USERS:
            return {
                ...state,
                searchRegistrants: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_REGISTRANT_USERS_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_REGISTRANT_USER:
            return {
                ...state,
                selectedRegistrant: payload,
            };

        case DXP_GET_REGISTRANT_USERS_ERROR:
            return {
                ...state,
                registrants: [],
                isLoading: false,
                success: false,
            };
        case DXP_GET_REGISTRANT_USERS_COUNT_ERROR:
            return {
                ...state,
                count: null,
                isLoading: false,
                success: false,
            };

        case DXP_SEARCH_REGISTRANT_USERS_ERROR:
            return {
                ...state,
                searchRegistrants: [],
                isLoading: false,
                success: false,
            };
        case DXP_SEARCH_REGISTRANT_USERS_COUNT_ERROR:
            return {
                ...state,
                searchCount: null,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}