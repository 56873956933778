import {
    DXP_CREATE_FELLOWSHIP_TEST,
    DXP_CREATE_FELLOWSHIP_TEST_ERROR,
    DXP_GET_ALL_FELLOWSHIP_TESTS_PAGINATED,
    DXP_GET_ALL_FELLOWSHIP_TESTS_PAGINATED_ERROR,
    DXP_GET_FELLOWSHIP_TEST_BY_ID,
    DXP_GET_FELLOWSHIP_TEST_BY_ID_ERROR,
    DXP_UPDATE_FELLOWSHIP_TEST_BY_ID,
    DXP_UPDATE_FELLOWSHIP_TEST_BY_ID_ERROR,
    DXP_DELETE_FELLOWSHIP_TEST_BY_ID,
    DXP_DELETE_FELLOWSHIP_TEST_BY_ID_ERROR,
    DXP_SEARCH_FELLOWSHIP_TESTS_PAGINATED,
    DXP_SEARCH_FELLOWSHIP_TESTS_PAGINATED_ERROR

} from "../actions/types";


const initialState = {
    isLoadingFellowshipTest: true,
    successFellowshipTest: false,
    allFellowshipTestsPaginated: [],
    allFellowshipTestsPaginatedCount: 0,
    fellowshipTestById: {},
    searchReturnTestsPaginated: [],
    searchReturnTestsCount: 0
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_CREATE_FELLOWSHIP_TEST:
        case DXP_UPDATE_FELLOWSHIP_TEST_BY_ID:
        case DXP_DELETE_FELLOWSHIP_TEST_BY_ID:
            return {
                ...state,
                isLoadingFellowshipTest: false,
                successFellowshipTest: true,
            };
        case DXP_SEARCH_FELLOWSHIP_TESTS_PAGINATED:
            return {
                ...state,
                isLoadingFellowshipTest: false,
                successFellowshipTest: true,
                searchReturnTestsPaginated: payload.rows,
                searchReturnTestsCount: payload.count,
            };
        case DXP_GET_FELLOWSHIP_TEST_BY_ID:
            return {
                ...state,
                isLoadingFellowshipTest: false,
                successFellowshipTest: true,
                fellowshipTestById: payload,
            };
        case DXP_GET_ALL_FELLOWSHIP_TESTS_PAGINATED:
            return {
                ...state,
                isLoadingFellowshipTest: false,
                successFellowshipTest: true,
                allFellowshipTestsPaginated: payload.rows,
                allFellowshipTestsPaginatedCount: payload.count,
            };
        case DXP_CREATE_FELLOWSHIP_TEST_ERROR:
        case DXP_GET_ALL_FELLOWSHIP_TESTS_PAGINATED_ERROR:
        case DXP_GET_FELLOWSHIP_TEST_BY_ID_ERROR:
        case DXP_UPDATE_FELLOWSHIP_TEST_BY_ID_ERROR:
        case DXP_DELETE_FELLOWSHIP_TEST_BY_ID_ERROR:
        case DXP_SEARCH_FELLOWSHIP_TESTS_PAGINATED_ERROR:
            return {
                ...state,
                isLoadingFellowshipTest: false,
                successFellowshipTest: false,
            };
        default:
            return state;
    }
}