import {
    DL_GET_ALL_SUBSCRIPTIONS_PAGINATED,
    DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_ERROR,
    DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_BY_SEARCH_TERM,
    DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_BY_SEARCH_TERM_ERROR,
    DL_GET_ALL_USERS_SUBSCRIPTIONS_BY_USER_ID,
    DL_GET_ALL_USERS_SUBSCRIPTIONS_BY_USER_ID_ERROR,
    DL_SUBSCRIPTION_CANCEL_AUTO_RENEW,
    DL_SUBSCRIPTION_CANCEL_AUTO_RENEW_ERROR,
    DL_GET_SUBSCRIPTION_COUPONS,
    DL_GET_SUBSCRIPTION_COUPONS_ERROR,
} from "../actions/types";

const initialState = {
    allSubscriptionsPaginatedCount: null,
    allSubscriptionsPaginated: [],
    allSearchSubscriptionsPaginatedCount: null,
    allSearchSubscriptionsPaginated: [],
    allUserSubscriptionsByUserId: [],
    subscriptionCoupons: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_ALL_USERS_SUBSCRIPTIONS_BY_USER_ID:
            return {
                ...state,
                allUserSubscriptionsByUserId: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_BY_SEARCH_TERM:
            return {
                ...state,
                allSearchSubscriptionsPaginated: payload.rows,
                allSearchSubscriptionsPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_SUBSCRIPTIONS_PAGINATED:
            return {
                ...state,
                allSubscriptionsPaginated: payload.rows,
                allSubscriptionsPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DL_GET_SUBSCRIPTION_COUPONS:
            return {
                ...state,
                subscriptionCoupons: payload,
                isLoading: false,
                success: true,
            };
        case DL_SUBSCRIPTION_CANCEL_AUTO_RENEW:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_ERROR:
        case DL_GET_ALL_SUBSCRIPTIONS_PAGINATED_BY_SEARCH_TERM_ERROR:
        case DL_GET_ALL_USERS_SUBSCRIPTIONS_BY_USER_ID_ERROR:
        case DL_SUBSCRIPTION_CANCEL_AUTO_RENEW_ERROR:
        case DL_GET_SUBSCRIPTION_COUPONS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
