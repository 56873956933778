import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { uploadFile } from "./../../lib/ReactS3";

import config from "../../utils/awsConfig";

import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { DropzoneArea } from '../DropzoneArea';


import { searchProducts } from "../../redux/actions/products";
import { setAlert } from "../../redux/actions/alerts";
import { addPodcast } from "../../redux/actions/articles";

const classes = {
  dropZone: {
    height: "232px",
    minHeight: "68px",
    marginRight: "10px",
    padding: "0 5px",
  },
};

const NewDialog = ({
  setAlert,
  page,
  searchProducts,
  addPodcast,
  handleClose,
  setSearchString,
  open,
  product: { productSearch },
}) => {
  // const classes = useStyles();
  const prodName = useRef();
  const [post, setPost] = useState({
    name: "",
    description: "",
    product_id: "",
    productName: "",
    path: "",
    image_url: "",
    published: "",
    urlTitle: ""
  });
  const [dropName, setDropName] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    addPodcast(post, page);
    setPost({
      name: "",
      description: "",
      product_id: "",
      productName: "",
      path: "",
      image_url: "",
      published: "",
      urlTitle: ""
    });
    // setSearchString("");
    handleClose();
  };

  const handleProductSelect = (e, prod) => {
    const { product_id, name } = prod;

    setPost({
      ...post,
      product_id: product_id,
      productName: name,
    });
  };

  const searchForProduct = (e) => {
    setTimeout(() => {
      if (e.target.value.length < 2) {
        setAlert("Query must be 2 characters or longer", "danger");
      } else {
        searchProducts(e.target.value, 0);
      }
    }, 1000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPost({
      ...post,
      [name]: value,
    });
  };

  const handleClick = (e) => {
    setDropName(e.currentTarget.getAttribute("name"));
  };

  const handleHover = (e) => {
    if (e.currentTarget.getAttribute("name")) {
      setDropName(e.currentTarget.getAttribute("name"));
    }
  };

  const handleDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      uploadFile(file, config)
        .then((data) => {
          setPost({
            ...post,
            [dropName]: file.path,
          });
        })
        .catch((err) => console.error(err));
    });
  };

  const handleDelete = () => {
    setPost({
      ...post,
      [dropName]: "",
    });
  };

  const {
    name,
    description,
    productId,
    productName,
    path,
    image_url,
    published,
    urlTitle
  } = post;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">New Podcast</DialogTitle>
      <DialogContent>
        <DialogContentText>Add new podcast</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          variant="outlined"
          name="name"
          label="Name"
          placeholder={name}
          value={name}
          onChange={(e) => handleChange(e)}
          fullWidth
          style={{ marginBottom: "10px" }}
        />
        <TextField
          margin="dense"
          variant="outlined"
          name="urlTitle"
          label="URL Title"
          placeholder={name}
          value={urlTitle}
          onChange={(e) => handleChange(e)}
          fullWidth
          style={{ marginBottom: "10px" }}
        />
        <form noValidate autoComplete="off" style={{ marginBottom: "8px" }}>
          <Autocomplete
            options={productSearch ? productSearch : []}
            ref={prodName}
            getOptionLabel={(option) => option.name}
            onKeyUp={(e) => searchForProduct(e)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search and Select Product"
                variant="outlined"
                value={productName}
              />
            )}
            onChange={(e, prod) => handleProductSelect(e, prod)}
          />
        </form>
        <h4 style={{ textAlign: "left" }}>Thumbnail Image</h4>
        <div
          style={{ display: "flex", marginBottom: "2rem" }}
          onClick={(e) => handleClick(e)}
          onMouseOver={(e) => handleHover(e)}
          onDragOver={(e) => handleHover(e)}
          name="image_url"
        >
          <DropzoneArea
            dropzoneClass={classes.dropZone}
            showPreviewsInDropzone={true}
            filesLimit={1}
            onChange={(files) => handleDrop(files)}
            onDelete={handleDelete}
          />
          <TextField
            id="outlined-multiline-static"
            label="Thumbnail Image URL"
            multiline
            placeholder={image_url ? image_url : "Add Thumbnail Image"}
            value={image_url ? image_url : "Add Thumbnail Image"}
            rows={13}
            variant="outlined"
            style={{ width: "100%" }}
            disabled
          />
        </div>
        <h4 style={{ textAlign: "left" }}>Product Video Upload</h4>
        <div
          style={{ display: "flex", marginBottom: "2rem" }}
          onClick={(e) => handleClick(e)}
          onMouseOver={(e) => handleHover(e)}
          onDragOver={(e) => handleHover(e)}
          name="path"
        >
          <DropzoneArea
            dropzoneClass={classes.dropZone}
            showPreviewsInDropzone={true}
            filesLimit={1}
            maxFileSize={90000000}
            onChange={(files) => handleDrop(files)}
            onDelete={handleDelete}
          />
          <TextField
            id="outlined-multiline-static"
            label="Video Link"
            multiline
            placeholder={path ? path : "Add Video"}
            value={path ? path : "Add Video"}
            rows={13}
            variant="outlined"
            style={{ width: "100%" }}
            disabled
          />
        </div>
        <TextField
          margin="dense"
          variant="outlined"
          name="description"
          label="Description"
          value={description}
          onChange={(e) => handleChange(e)}
          multiline
          rows={6}
          fullWidth
          style={{ marginBottom: "10px" }}
        />
        <FormControl
          variant="outlined"
          style={{ width: "100%", paddingTop: ".5rem", paddingBottom: ".5rem" }}
        >
          <InputLabel id="demo-simple-select-helper-label">
            Published
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={published}
            name="published"
            onChange={(e) => handleChange(e)}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  category: state.category,
  product: state.product,
  productVideo: state.productVideo,
});

export default connect(mapStateToProps, {
  searchProducts,
  addPodcast,
  setAlert,
})(NewDialog);
