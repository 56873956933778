import {
    DXP_VIDEO_GET,
    DXP_VIDEO_GET_ERROR,
    DXP_VIDEO_SELECT,
    DXP_VIDEO_ADD,
    DXP_VIDEO_ADD_ERROR,
    DXP_VIDEO_UPDATE,
    DXP_VIDEO_UPDATE_ERROR,
    DXP_VIDEO_DELETE,
    DXP_VIDEO_DELETE_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    featuredVideos: [],
    selectedVideo: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_VIDEO_GET:
            return {
                ...state,
                featuredVideos: payload,
                isLoading: false,
                success: true,
            };
        case DXP_VIDEO_SELECT:
            return {
                ...state,
                selectedVideo: payload,
                isLoading: false,
                success: true,
            };
        case DXP_VIDEO_ADD:
        case DXP_VIDEO_UPDATE:
        case DXP_VIDEO_DELETE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_VIDEO_GET_ERROR:
            return {
                ...state,
                featuredVideos: payload,
                isLoading: false,
                success: true
            }
        case DXP_VIDEO_ADD_ERROR:
        case DXP_VIDEO_UPDATE_ERROR:
        case DXP_VIDEO_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: true
            }
        default:
            return state;
    }
}
