import {
    DXP_GET_ALL_FELLOWSHIPS,
    DXP_GET_ALL_FELLOWSHIPS_ERROR,
    DXP_SEARCH_FELLOWSHIPS,
    DXP_SEARCH_FELLOWSHIPS_ERROR,
    DXP_GET_ALL_FELLOWSHIPS_PAGINATED,
    DXP_GET_ALL_FELLOWSHIPS_PAGINATED_ERROR,
    DXP_GET_FELLOWSHIP_BY_ID,
    DXP_GET_FELLOWSHIP_BY_ID_ERROR,
    DXP_UPDATE_FELLOWSHIP,
    DXP_UPDATE_FELLOWSHIP_ERROR,
    DXP_CREATE_NEW_FELLOWSHIP,
    DXP_CREATE_NEW_FELLOWSHIP_ERROR,
    DXP_SEARCH_FELLOWSHIPS_PAGINATED,
    DXP_SEARCH_FELLOWSHIPS_PAGINATED_ERROR,
    DXP_DELETE_FELLOWSHIP,
    DXP_DELETE_FELLOWSHIP_ERROR

} from "../actions/types";


const initialState = {
    isLoadingFellowships: true,
    successFellowships: false,
    allFellowships: [],
    searchReturnFellowships: [],
    allFellowshipsPaginated: [],
    allFellowshipsPaginatedCount: 0,
    fellowshipById: {},
    searchReturnFellowshipsPaginated: [],
    searchReturnFellowshipsPaginatedCount: 0,
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_SEARCH_FELLOWSHIPS_PAGINATED:
            return {
                ...state,
                isLoadingFellowships: false,
                successFellowships: true,
                searchReturnFellowshipsPaginated: payload.rows,
                searchReturnFellowshipsPaginatedCount: payload.count,
            };
        case DXP_GET_FELLOWSHIP_BY_ID:
            return {
                ...state,
                isLoadingFellowships: false,
                successFellowships: true,
                fellowshipById: payload,
            };
        case DXP_GET_ALL_FELLOWSHIPS_PAGINATED:
            return {
                ...state,
                isLoadingFellowships: false,
                successFellowships: true,
                allFellowshipsPaginated: payload.rows,
                allFellowshipsPaginatedCount: payload.count,
            };
        case DXP_SEARCH_FELLOWSHIPS:
            return {
                ...state,
                isLoadingFellowships: false,
                successFellowships: true,
                searchReturnFellowships: payload,
            };
        case DXP_GET_ALL_FELLOWSHIPS:
            return {
                ...state,
                isLoadingFellowships: false,
                successFellowships: true,
                allFellowships: payload,
            };
        case DXP_UPDATE_FELLOWSHIP:
        case DXP_CREATE_NEW_FELLOWSHIP:
        case DXP_DELETE_FELLOWSHIP:
            return {
                ...state,
                isLoadingFellowships: false,
                successFellowships: true,
            };
        case DXP_GET_ALL_FELLOWSHIPS_ERROR:
        case DXP_SEARCH_FELLOWSHIPS_ERROR:
        case DXP_GET_ALL_FELLOWSHIPS_PAGINATED_ERROR:
        case DXP_GET_FELLOWSHIP_BY_ID_ERROR:
        case DXP_UPDATE_FELLOWSHIP_ERROR:
        case DXP_CREATE_NEW_FELLOWSHIP_ERROR:
        case DXP_SEARCH_FELLOWSHIPS_PAGINATED_ERROR:
        case DXP_DELETE_FELLOWSHIP_ERROR:
            return {
                ...state,
                isLoadingFellowships: false,
                successFellowships: false,
            };
        default:
            return state;
    }
}