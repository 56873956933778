import {
    DXP_GET_ALL_USER_COMPLETIONS_PAGINATED,
    DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_ERROR,
    DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_FILTER_VALUE,
    DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_FILTER_VALUE_ERROR,
    DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_SEARCH_TERM,
    DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_SEARCH_TERM_ERROR,
    DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_PAGINATED,
    DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_PAGINATED_ERROR,
    DXP_GET_ALL_SUBSCRIPTIONS_BY_FELLOWSHIP_ID_PAGINATED,
    DXP_GET_ALL_SUBSCRIPTIONS_BY_FELLOWSHIP_ID_PAGINATED_ERROR,
    DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED,
    DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED_ERROR,
    DXP_REFUND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS,
    DXP_REFUND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR,
    DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_USER_ID,
    DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_USER_ID_ERROR,
    DXP_GET_FELLOWSHIP_SUBCRIPTION_CHARGE_RECIEPT_URL,
    DXP_GET_FELLOWSHIP_SUBCRIPTION_CHARGE_RECIEPT_URL_ERROR,
    DXP_POST_CREATE_COMPLIMENTARY_SUBSCRIPTION,
    DXP_POST_CREATE_COMPLIMENTARY_SUBSCRIPTION_ERROR,
    DXP_REVOKE_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS,
    DXP_REVOKE_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR,
    DXP_EXTEND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS,
    DXP_EXTEND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR,
    DXP_DELETE_USER_COMPLETIONS,
    DXP_DELETE_USER_COMPLETIONS_ERROR,
} from "../actions/types";

const initialState = {
    allUserCompletionsPaginated: [],
    allUserCompletionsPaginatedCount: null,
    allUserCompletionsPaginatedByFilterValue: [],
    allUserCompletionsPaginatedByFilterValueCount: null,
    allUserCompletionsPaginatedBySearchTerm: [],
    allUserCompletionsPaginatedBySearchTermCount: null,
    allFellowshipSubscriptionsPaginated: [],
    allFellowshipSubscriptionsPaginatedCount: null,
    allSubscriptionsByFellowshipIdPaginated: [],
    allSubscriptionsByFellowshipIdPaginatedCount: null,
    allFellowshipSubscriptionsPaginatedBySearchTerm: [],
    allFellowshipSubscriptionsPaginatedBySearchTermCount: null,
    allFellowshipSubscriptionById: [],
    fellowshipSubscriptionChargeReceiptUrl: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_FELLOWSHIP_SUBCRIPTION_CHARGE_RECIEPT_URL:
            return {
                ...state,
                fellowshipSubscriptionChargeReceiptUrl: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_USER_ID:
            return {
                ...state,
                allFellowshipSubscriptionById: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_USER_COMPLETIONS_PAGINATED:
            return {
                ...state,
                allUserCompletionsPaginated: payload?.rows,
                allUserCompletionsPaginatedCount: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_FILTER_VALUE:
            return {
                ...state,
                allUserCompletionsPaginatedByFilterValue: payload?.rows,
                allUserCompletionsPaginatedByFilterValueCount: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_SEARCH_TERM:
            return {
                ...state,
                allUserCompletionsPaginatedBySearchTerm: payload?.rows,
                allUserCompletionsPaginatedBySearchTermCount: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_PAGINATED:
            return {
                ...state,
                allFellowshipSubscriptionsPaginated: payload?.rows,
                allFellowshipSubscriptionsPaginatedCount: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_SUBSCRIPTIONS_BY_FELLOWSHIP_ID_PAGINATED:
            return {
                ...state,
                allSubscriptionsByFellowshipIdPaginated: payload?.rows,
                allSubscriptionsByFellowshipIdPaginatedCount: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED:
            return {
                ...state,
                allFellowshipSubscriptionsPaginatedBySearchTerm: payload?.rows,
                allFellowshipSubscriptionsPaginatedBySearchTermCount: payload?.count,
                isLoading: false,
                success: true,
            };
        case DXP_REFUND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS:
        case DXP_POST_CREATE_COMPLIMENTARY_SUBSCRIPTION:
        case DXP_REVOKE_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS:
        case DXP_EXTEND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS:
        case DXP_DELETE_USER_COMPLETIONS:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_ERROR:
        case DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_FILTER_VALUE_ERROR:
        case DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_SEARCH_TERM_ERROR:
        case DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_PAGINATED_ERROR:
        case DXP_GET_ALL_SUBSCRIPTIONS_BY_FELLOWSHIP_ID_PAGINATED_ERROR:
        case DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED_ERROR:
        case DXP_REFUND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR:
        case DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_USER_ID_ERROR:
        case DXP_GET_FELLOWSHIP_SUBCRIPTION_CHARGE_RECIEPT_URL_ERROR:
        case DXP_POST_CREATE_COMPLIMENTARY_SUBSCRIPTION_ERROR:
        case DXP_REVOKE_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR:
        case DXP_EXTEND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR:
        case DXP_DELETE_USER_COMPLETIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}