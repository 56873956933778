import axios from "axios";
import {
  GET_ALL_PROMOTIONS,
  GET_ALL_PROMOTIONS_PAGE,
  ALL_PROMOTIONS_ERROR,
  GET_PROMOTION,
  GET_PROMOTION_ERROR,
  GET_PROMOTION_COUNT,
  GET_PROMOTION_COUNT_ERROR,
  SEARCH_PROMOTIONS,
  ADD_PROMOTION,
  ADD_PROMOTION_ERROR,
  ADD_PROMOTION_PRODUCTS,
  ADD_PROMOTION_PRODUCTS_ERROR,
  UPDATE_PROMOTION,
  DELETE_PROMOTION,
  UPDATE_PROMOTION_ERROR,
  DELETE_PROMOTION_ERROR,
  GET_FEATURED_COUNT_ERROR,
  GET_ALL_PROMOTIONS_PAGE_ERROR,
  SEARCH_PROMOTIONS_ERROR,
  SELECT_PROMOTION,
  GET_NEW_PRODUCT_PROMOTION,
  GET_NEW_PRODUCT_PROMOTION_ERROR,
  SET_NEW_PRODUCT_PROMOTION,
  SET_NEW_PRODUCT_PROMOTION_ERROR,
  SET_NEW_PRODUCT_PROMOTION_FALSE,
  GET_PROMOTION_PAGE_FEATURED,
  SET_PROMOTION_PAGE_FEATURED,
  SET_PROMOTION_PAGE_FEATURED_FALSE,
  GET_PROMOTION_PAGE_DEFAULT,
  SET_PROMOTION_PAGE_DEFAULT,
  SET_PROMOTION_PAGE_DEFAULT_FALSE,
  GET_PROMOTION_PAGE_FEATURED_ERROR,
  SET_PROMOTION_PAGE_FEATURED_ERROR,
  SET_PROMOTION_PAGE_FEATURED_FALSE_ERROR,
  GET_PROMOTION_PAGE_DEFAULT_ERROR,
  SET_PROMOTION_PAGE_DEFAULT_ERROR,
  SET_PROMOTION_PAGE_DEFAULT_FALSE_ERROR,
  GET_ASSOCIATED_PRODUCTS,
  GET_ASSOCIATED_PRODUCTS_ERROR,
  DELETE_PRODUCT_ASSOCIATION,
  DELETE_PRODUCT_ASSOCIATION_ERROR,
} from "./types";
import { setAlert } from "./alerts";

const baseUrl = process.env.REACT_APP_BASE_URL;

//gets all promotions
export const getPromotions = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/promotions`);

    dispatch({
      type: GET_ALL_PROMOTIONS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: ALL_PROMOTIONS_ERROR,
      payload: { msg: "Could not fetch all promotions" },
    });
  }
};

export const getPromotionCount = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/promotions/count`);

    dispatch({
      type: GET_PROMOTION_COUNT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROMOTION_COUNT_ERROR,
      payload: { msg: "Could not fetch the promotion count" },
    });
  }
};

//gets all promotions paginated
export const getPromotionsPage = (page) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/promotions/page/${page}/25`
    );

    dispatch({
      type: GET_ALL_PROMOTIONS_PAGE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_PROMOTIONS_PAGE_ERROR,
      payload: { msg: "Could not fetch all promotions" },
    });
  }
};

//gets promotion by id
export const getPromotion = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/promotions/promotion/${id}`
    );

    dispatch({
      type: GET_PROMOTION,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROMOTION_ERROR,
      payload: { msg: "Could not fetch promotion" },
    });
  }
};

//gets promotion by id
export const getAssociatedProducts = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${baseUrl}/api/promotions/products/${id}`);

    dispatch({
      type: GET_ASSOCIATED_PRODUCTS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ASSOCIATED_PRODUCTS_ERROR,
      payload: { msg: "Could not fetch promotion" },
    });
  }
};

//Add new promotions
export const addPromotion = (promotion) => async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/api/promotions/`, {
      promotion,
    });

    dispatch({
      type: ADD_PROMOTION,
      payload: response.data,
    });
    dispatch(setAlert("Promotion added successfully!", "success"));
    dispatch(getPromotions());
  } catch (err) {
    dispatch(setAlert("Could not add promotion", "danger"));
    dispatch({
      type: ADD_PROMOTION_ERROR,
      payload: { msg: "Could not add promotion" },
    });
  }
};

//Batch add products and promotion to map array
export const addPromotionProducts = (promotion) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${baseUrl}/api/promotions/product-promotion/`,
      {
        promotion,
      }
    );

    dispatch({
      type: ADD_PROMOTION_PRODUCTS,
      payload: response.data,
    });
    dispatch(
      setAlert(
        "Promotion added to each selected product successfully!",
        "success"
      )
    );
    dispatch(getPromotionsPage(0));
  } catch (err) {
    dispatch(setAlert("Could not add promotion for those products", "danger"));
    dispatch({
      type: ADD_PROMOTION_PRODUCTS_ERROR,
      payload: { msg: "Could not add promotion products" },
    });
  }
};

//update promotion by id
export const updatePromotion = (id, promotion, page) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/promotions/promotion/${id}`,
      {
        promotion,
      }
    );

    dispatch({
      type: UPDATE_PROMOTION,
      payload: response.data,
    });
    dispatch(setAlert("Promotion updated successfully!", "success"));
    dispatch(getPromotionsPage(page));
  } catch (err) {
    dispatch(setAlert("Could not update promotion", "danger"));
    dispatch({
      type: UPDATE_PROMOTION_ERROR,
      payload: { msg: "Could not update promotion" },
    });
  }
};

//delete promotion by id
export const deletePromotion = (id, page) => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/promotions/${id}`);

    dispatch({
      type: DELETE_PROMOTION,
      payload: response.data,
    });
    dispatch(setAlert("Promotion deleted.", "success"));
    dispatch(getPromotionsPage(page));
  } catch (err) {
    dispatch(setAlert("Could not delete promotion.", "danger"));
    dispatch({
      type: DELETE_PROMOTION_ERROR,
      payload: { msg: "Could not delete promotion" },
    });
  }
};

export const deleteProductAssociations = (productIdArray) => async (dispatch) => {
  try {
    const response = await axios.put(`${baseUrl}/api/promotions/product-association-delete/`, {
      productIdArray
    });

    dispatch({
      type: DELETE_PRODUCT_ASSOCIATION,
      payload: response.data,
    });
    // dispatch(setAlert("Product association deleted successfully!", "success"));
  } catch (err) {
    // dispatch(setAlert("Could not delete product association", "danger"));
    dispatch({
      type: DELETE_PRODUCT_ASSOCIATION_ERROR,
      payload: { msg: "Could not delete product association" }
    })
  }
}

//search promotions with manufacturer
export const searchPromotions = (manufacturer) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/promotions/promotionsearch/${manufacturer}`
    );
    dispatch({
      type: SEARCH_PROMOTIONS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_PROMOTIONS_ERROR,
      payload: [],
    });
  }
};

//search promotions with manufacturer
export const searchUnpublishedPromotions = (manufacturer) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/promotions/unpublishedpromotionsearch/${manufacturer}`
    );
    dispatch({
      type: SEARCH_PROMOTIONS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_PROMOTIONS_ERROR,
      payload: [],
    });
  }
};

//selects a single Promotion
export const selectPromotion = (promotion) => (dispatch) => {
  dispatch({
    type: SELECT_PROMOTION,
    payload: promotion,
  });
};

//gets the promotion currently displayued on new product view
export const getNewProductsPromotion = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/promotions/new-product-featured`
    );

    dispatch({
      type: GET_NEW_PRODUCT_PROMOTION,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_NEW_PRODUCT_PROMOTION_ERROR,
      payload: { msg: "Could not fetch featured new product promotion" },
    });
  }
};

//set promotion for new product page
export const setPromotionNewProduct = (id) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/promotions/set-new-product-featured/${id}`
    );

    dispatch({
      type: SET_NEW_PRODUCT_PROMOTION,
      payload: response.data,
    });
    dispatch(setAlert("Promotion added as featured succesfully!", "success"));
    dispatch(getNewProductsPromotion());
  } catch (err) {
    dispatch(setAlert("Could not set promotion as featured", "danger"));
    dispatch({
      type: SET_NEW_PRODUCT_PROMOTION_ERROR,
      payload: { msg: "Could not set product as featured" },
    });
  }
};

//set all previously featured promotions as false for new promotion
export const setFeaturedNewFalse = () => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/promotions/set-new-product-featured-false`
    );

    dispatch({
      type: SET_NEW_PRODUCT_PROMOTION_FALSE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: SET_NEW_PRODUCT_PROMOTION_FALSE,
      payload: { msg: "Could not remove promotion as featured" },
    });
  }
};

//promotion page featured stuff
export const getPromotionPageFeatured = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/promotions/featured-promotion-promotion-page`
    );

    dispatch({
      type: GET_PROMOTION_PAGE_FEATURED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROMOTION_PAGE_FEATURED_ERROR,
      payload: { msg: "Could not get promotion page featured promotions" },
    });
  }
};

export const setPromotionPageFeatured = (id, featuredPromotion) => async (
  dispatch
) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/promotions/set-new-featured-promotion-page/${id}`,
      {
        featuredPromotion,
      }
    );

    dispatch({
      type: SET_PROMOTION_PAGE_FEATURED,
      payload: response.data,
    });
    dispatch(getPromotionPageFeatured());
    dispatch(setAlert("Featured promotions set successfully!", "success"));
  } catch (err) {
    dispatch({
      type: SET_PROMOTION_PAGE_FEATURED_ERROR,
      payload: { msg: "Could not set promotion page featured promotion" },
    });
  }
};

export const setPromotionPageFeaturedFalse = () => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/promotions/set-featured-promotion-page-false`
    );

    dispatch({
      type: SET_PROMOTION_PAGE_FEATURED_FALSE,
      payload: response.data,
    });
  } catch (err) {
    dispatch(setAlert("Featured promotions failed to change", "danger"));
    dispatch({
      type: SET_PROMOTION_PAGE_FEATURED_FALSE_ERROR,
      payload: {
        msg: "Could not set promotion page featured promotions false",
      },
    });
  }
};

export const getPromotionPageDefault = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/promotions/default-featured-promotion-page`
    );

    dispatch({
      type: GET_PROMOTION_PAGE_DEFAULT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROMOTION_PAGE_DEFAULT_ERROR,
      payload: { msg: "Could not get promotions page default promotions" },
    });
  }
};

export const setPromotionsPageDefaultFalse = () => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/promotions/default-featured-promotion-page-false`
    );

    dispatch({
      type: SET_PROMOTION_PAGE_DEFAULT_FALSE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: SET_PROMOTION_PAGE_DEFAULT_FALSE_ERROR,
      payload: { msg: "Could not set promotion page default promotions false" },
    });
  }
};

export const setPromotionsPageDefault = (id) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/promotions/set-featured-promotion-page-default/${id}`
    );

    dispatch({
      type: SET_PROMOTION_PAGE_DEFAULT,
      payload: response.data,
    });
    dispatch(getPromotionPageDefault());
    dispatch(setAlert("Default promotions set successfully!", "success"));
  } catch (err) {
    dispatch(setAlert("Default promotions failed to change", "danger"));
    dispatch({
      type: SET_PROMOTION_PAGE_DEFAULT_ERROR,
      payload: { msg: "Could not set promotion page default promotion" },
    });
  }
};
