import {
    DL_GET_CONTENT,
    DL_GET_CONTENT_ERROR,
    DL_ADD_FEATURED,
    DL_ADD_FEATURED_ERROR,
    DL_GET_FEATURED_CATEGORY,
    DL_GET_FEATURED_SPONSOR,
    DL_GET_FEATURED_GENERAL,
    DL_GET_FEATURED_TAKEOVER,
    DL_GET_FEATURED_COURSE,
    DL_GET_FEATURED_EVENT,
    DL_GET_FEATURED_ERROR,
    DL_SELECT_CATEGORY,
    DL_SELECT_COURSE,
    DL_SELECT_EVENT,
    DL_DELETE_FEATURED,
    DL_DELETE_FEATURED_ERROR,
    DL_SELECT_FEATURED_MANUFACTURER,
} from "../actions/types";

const initialState = {
    dlContentTypes: [{ type: 'Courses' }, { type: 'Events' }, { type: 'Take 5 Videos' }],
    contentTypes: [{ type: 'Courses' }, { type: 'Events' }, { type: 'Take 5 Videos' }, { type: 'DPS EBooks' }, { type: 'DPS Evaluations' }, { type: 'DPS Products' }],
    content: Array.from({ length: 32 }, (_, i) => i).reduce((obj, num) => ({ ...obj, [num]: [] }), {}),
    featuredCategory: [],
    featuredSponsor: [],
    featuredGeneral: [],
    featuredTakeover: [],
    featuredCourse: [],
    featuredEvent: [],
    selectedCategory: null,
    selectedManufacturer: null,
    selectedCourse: null,
    selectedEvent: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_CONTENT:
            return {
                ...state,
                content: {
                    ...state.content,
                    [payload[0]]: payload[1]
                },
                isLoading: false,
                success: true,
            };
        case DL_GET_FEATURED_CATEGORY:
            return {
                ...state,
                featuredCategory: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_FEATURED_SPONSOR:
            return {
                ...state,
                featuredSponsor: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_FEATURED_GENERAL:
            return {
                ...state,
                featuredGeneral: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_FEATURED_TAKEOVER:
            return {
                ...state,
                featuredTakeover: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_FEATURED_COURSE:
            return {
                ...state,
                featuredCourse: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_FEATURED_EVENT:
            return {
                ...state,
                featuredEvent: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_CATEGORY:
            return {
                ...state,
                selectedCategory: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_FEATURED_MANUFACTURER:
            return {
                ...state,
                selectedManufacturer: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_COURSE:
            return {
                ...state,
                selectedCourse: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_EVENT:
            return {
                ...state,
                selectedEvent: payload,
                isLoading: false,
                success: true,
            };
        case DL_ADD_FEATURED:
        case DL_DELETE_FEATURED:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case DL_GET_CONTENT_ERROR:
            return {
                ...state,
                content: payload,
                isLoading: false,
                success: false,
            };
        case DL_ADD_FEATURED_ERROR:
        case DL_DELETE_FEATURED_ERROR:
        case DL_GET_FEATURED_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };

        default:
            return state;
    }
}
