import {
    GET_MEMBERS_PAGINATED,
    GET_MEMBERS_PAGINATED_ERROR,
    GET_TEAM_MEMBER_BY_ID,
    GET_TEAM_MEMBER_BY_ID_ERROR,
    CREATE_TEAM_MEMBER,
    CREATE_TEAM_MEMBER_ERROR,
    DELETE_TEAM_MEMBER,
    DELETE_TEAM_MEMBER_ERROR,
    UPDATE_TEAM_MEMBER,
    UPDATE_TEAM_MEMBER_ERROR
} from "../actions/types";

const initialState = {
    membersPaginated: [],
    membersPaginatedCount: null,
    memberbyId: {},
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_TEAM_MEMBER_BY_ID:
            return {
                ...state,
                memberbyId: payload,
                isLoading: false,
                success: true,
            };
        case GET_MEMBERS_PAGINATED:
            return {
                ...state,
                membersPaginated: payload.rows,
                membersPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case CREATE_TEAM_MEMBER:
        case DELETE_TEAM_MEMBER:
        case UPDATE_TEAM_MEMBER:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case GET_MEMBERS_PAGINATED_ERROR:
        case GET_TEAM_MEMBER_BY_ID_ERROR:
        case CREATE_TEAM_MEMBER_ERROR:
        case DELETE_TEAM_MEMBER_ERROR:
        case UPDATE_TEAM_MEMBER_ERROR:
            return {
                ...state,
                isLoading: true,
                success: false,
            };
        default:
        return state;
    }
}
