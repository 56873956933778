import {
    DL_GET_PODCAST_EPISODES,
    DL_GET_PODCAST_EPISODES_ERROR,
    DL_GET_PUBLISHED_PODCAST_EPISODES,
    DL_GET_PUBLISHED_PODCAST_EPISODES_ERROR,
    DL_SEARCH_PODCAST_EPISODES,
    DL_SEARCH_PODCAST_EPISODES_ERROR,
    DL_GET_PODCAST_EPISODES_COUNT,
    DL_GET_PODCAST_EPISODES_COUNT_ERROR,
    DL_GET_PODCAST_EPISODES_SEARCH_COUNT,
    ADD_PODCAST_EPISODE,
    ADD_PODCAST_EPISODE_ERROR,
    UPDATE_PODCAST_EPISODE,
    UPDATE_PODCAST_EPISODE_ERROR,
    DELETE_PODCAST_EPISODE,
    DELETE_PODCAST_EPISODE_ERROR,
    DL_SELECT_PODCAST_EPISODE,
} from "../actions/types";

const initialState = {
    publishedPodcastEpisodes: [],
    podcastEpisodes: [],
    searchedPodcastEpisodes: [],
    selectedPodcastEpisode: null,
    count: null,
    searchCount: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_PODCAST_EPISODES:
            return {
                ...state,
                podcastEpisodes: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_PUBLISHED_PODCAST_EPISODES:
            return {
                ...state,
                publishedPodcastEpisodes: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_PODCAST_EPISODES:
            return {
                ...state,
                searchedPodcastEpisodes: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_PODCAST_EPISODES_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_PODCAST_EPISODES_SEARCH_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_PODCAST_EPISODE:
            return {
                ...state,
                selectedPodcastEpisode: payload,
            };
        case ADD_PODCAST_EPISODE:
        case UPDATE_PODCAST_EPISODE:
        case DELETE_PODCAST_EPISODE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case DL_GET_PODCAST_EPISODES_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DL_GET_PUBLISHED_PODCAST_EPISODES_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DL_SEARCH_PODCAST_EPISODES_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DL_GET_PODCAST_EPISODES_COUNT_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };

        case ADD_PODCAST_EPISODE_ERROR:
        case UPDATE_PODCAST_EPISODE_ERROR:
        case DELETE_PODCAST_EPISODE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
