import {
  GET_ALL_PROMOTIONS,
  ALL_PROMOTIONS_ERROR,
  GET_ALL_PROMOTIONS_PAGE,
  ALL_PROMOTIONS_PAGE_ERROR,
  GET_PROMOTION,
  GET_PROMOTION_ERROR,
  SELECT_PROMOTION,
  SEARCH_PROMOTIONS,
  GET_PROMOTION_COUNT,
  GET_PROMOTION_COUNT_ERROR,
  ADD_PROMOTION,
  ADD_PROMOTION_ERROR,
  ADD_PROMOTION_PRODUCTS,
  ADD_PROMOTION_PRODUCTS_ERROR,
  UPDATE_PROMOTION,
  DELETE_PROMOTION,
  UPDATE_PROMOTION_ERROR,
  DELETE_PROMOTION_ERROR,
  SEARCH_PROMOTIONS_ERROR,
  GET_NEW_PRODUCT_PROMOTION,
  GET_NEW_PRODUCT_PROMOTION_ERROR,
  GET_PROMOTION_PAGE_FEATURED,
  GET_PROMOTION_PAGE_DEFAULT,
  GET_PROMOTION_PAGE_FEATURED_ERROR,
  GET_PROMOTION_PAGE_DEFAULT_ERROR,
  GET_ASSOCIATED_PRODUCTS,
  GET_ASSOCIATED_PRODUCTS_ERROR,
  DELETE_PRODUCT_ASSOCIATION,
  DELETE_PRODUCT_ASSOCIATION_ERROR,
} from "../actions/types";

const initialState = {
  promotions: [],
  promotionSearchPage: [],
  promotionPageFeatured: [],
  promotionPageDefault: [],
  associatedProducts: [],
  promotionProduct: null,
  promotionSearchPageCount: null,
  newProductPromotion: null,
  promotion: null,
  newPromotion: null,
  isLoading: true,
  success: false,
  count: null,
  selectedPromotion: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_PROMOTIONS:
    case GET_ALL_PROMOTIONS_PAGE:
      return {
        ...state,
        promotions: payload,
        isLoading: false,
        success: true,
      };
    case GET_PROMOTION:
      return {
        ...state,
        promotion: payload,
        isLoading: false,
        success: true,
      };
    case GET_ASSOCIATED_PRODUCTS:
      return {
        ...state,
        associatedProducts: payload,
        isLoading: false,
        success: true
      }
    case SELECT_PROMOTION:
      return {
        ...state,
        selectedPromotion: payload,
        isLoading: false,
        success: true,
      };
    case SEARCH_PROMOTIONS:
      return {
        ...state,
        promotionSearchPage: payload,
        isLoading: false,
        success: true,
      };
    case GET_PROMOTION_COUNT:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      };
    case GET_PROMOTION_PAGE_FEATURED:
      return {
        ...state,
        promotionPageFeatured: payload,
        isLoading: false,
        success: true,
      };
    case GET_PROMOTION_PAGE_DEFAULT:
      return {
        ...state,
        promotionPageDefault: payload,
        isLoading: false,
        success: true,
      };
    case UPDATE_PROMOTION:
    case DELETE_PROMOTION:
    case ADD_PROMOTION:
    case DELETE_PRODUCT_ASSOCIATION:
      return {
        ...state,
        newPromotion: payload,
        isLoading: false,
        success: true,
      };
    case ADD_PROMOTION_PRODUCTS:
      return {
        ...state,
        promotionProduct: payload,
        isLoading: false,
        success: true,
      };
    case GET_NEW_PRODUCT_PROMOTION:
      return {
        ...state,
        newProductPromotion: payload,
        isLoading: false,
        success: true,
      };
    case ALL_PROMOTIONS_ERROR:
    case ALL_PROMOTIONS_PAGE_ERROR:
      return {
        ...state,
        promotions: payload,
        isLoading: false,
        success: false,
      };
    case DELETE_PROMOTION_ERROR:
    case UPDATE_PROMOTION_ERROR:
    case ADD_PROMOTION_ERROR:
    case DELETE_PRODUCT_ASSOCIATION_ERROR:
      return {
        ...state,
        newPromotion: payload,
        isLoading: false,
        success: false,
      };
    case GET_PROMOTION_ERROR:
      return {
        ...state,
        promotion: payload,
        isLoading: false,
        success: false,
      };
    case GET_ASSOCIATED_PRODUCTS_ERROR:
      return {
        ...state,
        associatedProducts: payload,
        isLoading: false,
        success: false
      }
    case GET_PROMOTION_COUNT_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: false,
      };
    case SEARCH_PROMOTIONS_ERROR:
      return {
        ...state,
        promotionSearchPage: payload,
        isLoading: false,
        success: false,
      };
    case ADD_PROMOTION_PRODUCTS_ERROR:
      return {
        ...state,
        promotionProduct: payload,
        isLoading: false,
        success: false,
      };
    case GET_NEW_PRODUCT_PROMOTION_ERROR:
      return {
        ...state,
        newProductPromotion: payload,
        isLoading: false,
        success: false,
      };
    case GET_PROMOTION_PAGE_FEATURED_ERROR:
      return {
        ...state,
        promotionPageFeatured: payload,
        isLoading: false,
        success: false,
      };
    case GET_PROMOTION_PAGE_DEFAULT_ERROR:
      return {
        ...state,
        promotionPageDefault: payload,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
}
