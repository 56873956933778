import {
    DL_GET_FREE_MEMBERSHIPS,
    DL_GET_FREE_MEMBERSHIPS_ERROR,
    DL_GET_FREE_MEMBERSHIP_COUNT,
    DL_GET_FREE_MEMBERSHIP_COUNT_ERROR,
    DL_SEARCH_FREE_MEMBERSHIPS,
    DL_SEARCH_FREE_MEMBERSHIPS_ERROR,
    DL_SEARCH_FREE_MEMBERSHIP_COUNT,
    DL_SEARCH_FREE_MEMBERSHIP_COUNT_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    freeMemberships: [],
    searchedFreeMembership: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_FREE_MEMBERSHIPS:
            return {
                ...state,
                freeMemberships: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_FREE_MEMBERSHIP_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_FREE_MEMBERSHIPS:
            return {
                ...state,
                searchedFreeMembership: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_FREE_MEMBERSHIP_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_FREE_MEMBERSHIPS_ERROR:
            return {
                ...state,
                freeMemberships: [],
                isLoading: false,
                success: false
            }
        case DL_GET_FREE_MEMBERSHIP_COUNT_ERROR:
            return {
                ...state,
                count: null,
                isLoading: false,
                success: false
            }
        case DL_SEARCH_FREE_MEMBERSHIPS_ERROR:
            return {
                ...state,
                searchedFreeMembership: [],
                isLoading: false,
                success: false
            }
        case DL_SEARCH_FREE_MEMBERSHIP_COUNT_ERROR:
            return {
                ...state,
                searchCount: null,
                isLoading: false,
                success: false
            }


        default:
            return state;
    }
}
