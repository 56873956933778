import {
  GET_ALL_FEATURED_MAN,
  GET_ALL_FEATURED_PAGE_MAN,
  ALL_FEATURED_MAN_ERROR,
  GET_FEATURED_MAN,
  GET_FEATURED_MAN_ERROR,
  GET_FEATURED_COUNT_MAN,
  GET_FEATURED_COUNT_MAN_ERROR,
  ADD_FEATURED_MAN,
  ADD_FEATURED_MAN_ERROR,
  UPDATE_FEATURED_MAN,
  DELETE_FEATURED_MAN,
  UPDATE_FEATURED_MAN_ERROR,
  DELETE_FEATURED_MAN_ERROR,
  GET_ALL_FEATURED_PAGE_MAN_ERROR,
  SELECT_FEATURED_MAN,
  SELECT_FEATURED_MAN_ERROR,
} from "../actions/types";

const initialState = {
  featuredManufacturers: [],
  featuredManufacturer: null,
  selectedManufacturer: null,
  isLoading: true,
  success: false,
  count: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_FEATURED_MAN:
      return {
        ...state,
        featuredManufacturers: payload,
        isLoading: false,
        success: true,
      };
    case GET_FEATURED_MAN:
      return {
        ...state,
        featuredManufacturer: payload,
        isLoading: false,
        success: true,
      };
    case GET_FEATURED_COUNT_MAN:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      };
    case SELECT_FEATURED_MAN:
      return {
        ...state,
        selectedManufacturer: payload,
        isLoading: false,
        success: true,
      };
    case UPDATE_FEATURED_MAN:
    case DELETE_FEATURED_MAN:
    case ADD_FEATURED_MAN:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case ALL_FEATURED_MAN_ERROR:
      return {
        ...state,
        featuredManufacturers: payload,
        isLoading: false,
        success: false,
      };
    case DELETE_FEATURED_MAN_ERROR:
    case UPDATE_FEATURED_MAN_ERROR:
    case ADD_FEATURED_MAN_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case GET_FEATURED_MAN_ERROR:
      return {
        ...state,
        featuredManufacturer: payload,
        isLoading: false,
        success: false,
      };
    case GET_FEATURED_COUNT_MAN_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
}
