import {
    DXP_GET_ALL_MANUFACTURERS,
    DXP_GET_ALL_MANUFACTURERS_ERROR,
    DXP_GET_MANUFACTURER,
    DXP_GET_MANUFACTURER_ERROR,
    DXP_UPDATE_MANUFACTURER,
    DXP_UPDATE_MANUFACTURER_ERROR,
    // ADD_MANUFACTURER,
    // ADD_MANUFACTURER_ERROR,
} from "../actions/types";

const initialState = {
    allManufacturers: [],
    publishedManufacturers: [],
    manufacturerById: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_MANUFACTURERS:
            return {
                ...state,
                allManufacturers: payload,
                publishedManufacturers: payload.filter(manufacturer => manufacturer.published),
                isLoading: false,
                success: true,
            };
        case DXP_GET_MANUFACTURER:
            return {
                ...state,
                manufacturerById: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_MANUFACTURERS_ERROR:
            return {
                ...state,
                allManufacturers: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_MANUFACTURER_ERROR:
            return {
                ...state,
                manufacturerById: payload,
                isLoading: false,
                success: false,
            };
        case DXP_UPDATE_MANUFACTURER:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_UPDATE_MANUFACTURER_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}