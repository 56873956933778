import {
    DXP_ADD_VIDEO,
    DXP_ADD_VIDEO_ERROR,
    DXP_GET_ALL_VIDEOS,
    DXP_GET_ALL_VIDEOS_ERROR,
    DXP_GET_VIDEOS_PAGE,
    DXP_GET_VIDEOS_PAGE_ERROR,
    DXP_SEARCH_VIDEOS_PAGE,
    DXP_SEARCH_VIDEOS_PAGE_ERROR,
    DXP_SELECT_VIDEO,
    DXP_GET_ALL_VIDEO_COUNT,
    DXP_GET_ALL_VIDEO_COUNT_ERROR,
    DXP_UPDATE_VIDEO,
    DXP_UPDATE_VIDEO_ERROR,
    DXP_DELETE_VIDEO,
    DXP_DELETE_VIDEO_ERROR,
    DXP_SEARCH_VIDEOS_COUNT,
    DXP_SEARCH_VIDEOS_COUNT_ERROR,
    DXP_GET_ALL_VIDEOS_BY_LANGUAGE,
    DXP_GET_ALL_VIDEOS_BY_LANGUAGE_ERROR,
    DXP_GET_VIDEO,
    DXP_GET_VIDEO_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    videos: [],
    publishedVideos: [],
    videosSearchPage: [],
    selectedVideo: null,
    isLoading: true,
    success: false,
    videosByLanguage: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_VIDEOS_BY_LANGUAGE:
            return {
                ...state,
                videosByLanguage: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_VIDEOS:
        case DXP_GET_VIDEOS_PAGE:
            return {
                ...state,
                videos: payload,
                publishedVideos: payload.filter(video => video.published),
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_VIDEO_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case DXP_SEARCH_VIDEOS_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true
            }

        case DXP_SELECT_VIDEO:
        case DXP_GET_VIDEO:
            return {
                ...state,
                selectedVideo: payload,
            };
        case DXP_SEARCH_VIDEOS_PAGE:
            return {
                ...state,
                videosSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_VIDEO:
        case DXP_UPDATE_VIDEO:
        case DXP_DELETE_VIDEO:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_VIDEOS_ERROR:
        case DXP_GET_VIDEOS_PAGE_ERROR:
            return {
                ...state,
                videos: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_ALL_VIDEO_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case DXP_SEARCH_VIDEOS_COUNT_ERROR:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: false
            }

        case DXP_SEARCH_VIDEOS_PAGE_ERROR:
            return {
                ...state,
                videosSearchPage: payload,
                isLoading: false,
                success: false,
            };

        case DXP_ADD_VIDEO_ERROR:
        case DXP_UPDATE_VIDEO_ERROR:
        case DXP_DELETE_VIDEO_ERROR:
        case DXP_GET_ALL_VIDEOS_BY_LANGUAGE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_GET_VIDEO_ERROR:
            return {
                ...state,
                selectedVideo: null,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
