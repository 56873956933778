import {
    GET_ALL_MANUFACTURER_REVIEWS_PAGE,
    GET_ALL_MANUFACTURER_REVIEWS_PAGE_ERROR,
    GET_MANUFACTURER_REVIEW_COUNT,
    GET_MANUFACTURER_REVIEW_COUNT_ERROR,
    SEARCH_MANUFACTURER_REVIEWS,
    SELECT_MANUFACTURER_REVIEW,
    ADD_MANUFACTURER_REVIEW,
    UPDATE_MANUFACTURER_REVIEW,
    DELETE_MANUFACTURER_REVIEW,
    GET_FILTERED_MANUFACTURER_REVIEWS,
    GET_FILTERED_MANUFACTURER_REVIEWS_ERROR,
} from "../actions/types";

const initialState = {
    manufacturerReviews: [],
    manufacturerReviewSearch: [],
    manufacturerReviewSearchCount: null,
    manufacturer: null,
    selectedManufacturerReview: null,
    isLoading: true,
    success: false,
    count: null,
    filteredManufacturerReviews: [],
    filteredManufacturerReviewsCount: null,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_FILTERED_MANUFACTURER_REVIEWS:
            return {
                ...state,
                filteredManufacturerReviews: payload.rows,
                filteredManufacturerReviewsCount: payload.count,
                isLoading: false,
                success: true
            };
        case GET_FILTERED_MANUFACTURER_REVIEWS_ERROR:
            return {
                ...state,
                filteredManufacturerReviews: null,
                filteredManufacturerReviewsCount: null,
                isLoading: false,
                success: false
            };
        case GET_ALL_MANUFACTURER_REVIEWS_PAGE:
            return {
                ...state,
                manufacturerReviews: payload,
                isLoading: false,
                success: true
            };
        case GET_MANUFACTURER_REVIEW_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case SELECT_MANUFACTURER_REVIEW:
            return {
                ...state,
                selectedManufacturerReview: payload
            }
        case SEARCH_MANUFACTURER_REVIEWS:
            return {
                ...state,
                manufacturerReviewSearch: payload
            }
        case UPDATE_MANUFACTURER_REVIEW:
        case DELETE_MANUFACTURER_REVIEW:
        case ADD_MANUFACTURER_REVIEW:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case GET_ALL_MANUFACTURER_REVIEWS_PAGE_ERROR:
            return {
                ...state,
                manufacturerReviews: payload,
                isLoading: false,
                success: true
            };
        case GET_ALL_MANUFACTURER_REVIEWS_PAGE_ERROR:
            return {
                ...state,
                productReviews: payload,
                isLoading: false,
                success: true,
            };
        case GET_MANUFACTURER_REVIEW_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false,
            }
        default:
            return state;
    }
}