import {
    DXP_GET_ALL_FELLOWSHIP_SESSIONS_PAGINATED,
    DXP_GET_ALL_FELLOWSHIP_SESSIONS_PAGINATED_ERROR,
    DXP_SEARCH_FELLOWSHIP_SESSIONS_PAGINATED,
    DXP_SEARCH_FELLOWSHIP_SESSIONS_PAGINATED_ERROR,
    DXP_GET_SESSION_BY_ID,
    DXP_GET_SESSION_BY_ID_ERROR,
    DXP_CREATE_NEW_SESSION,
    DXP_CREATE_NEW_SESSION_ERROR,
    DXP_UPDATE_FELLOWSHIP_SESSION,
    DXP_UPDATE_FELLOWSHIP_SESSION_ERROR,
    DXP_GET_FILTERED_SESSIONS_BY_FELLOWSHIP_ID_PAGINATED,
    DXP_GET_FILTERED_SESSIONS_BY_FELLOWSHIP_ID_PAGINATED_ERROR,
    DXP_DELETE_FELLOWSHIP_SESSION_BY_ID,
    DXP_DELETE_FELLOWSHIP_SESSION_BY_ID_ERROR,
    DXP_GET_ALL_SESSION_COURSES_NAME_ID_ONLY,
    DXP_GET_ALL_SESSION_COURSES_NAME_ID_ONLY_ERROR,
} from "../actions/types";


const initialState = {
    isLoadinFellowshipSessions: true,
    successFellowshipSessions: false,
    fellowshipSessionsPaginated: [],
    fellowshipSessionsPaginatedCount: 0,
    searchReturnSessionsPaginated: [],
    searchReturnSessionsPaginatedCount: 0,
    sessionById: {},
    sessionsByFellowshipIdPaginated: [],
    sessionsByFellowshipIdPaginatedCount: 0,
    sessionCourses: [],
    sessionCourseById: {},
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_SESSION_COURSES_NAME_ID_ONLY:
            return {
                ...state,
                isLoadinFellowshipSessions: false,
                successFellowshipSessions: true,
                sessionCourses: payload,
            };
        case DXP_GET_SESSION_BY_ID:
            return {
                ...state,
                isLoadinFellowshipSessions: false,
                successFellowshipSessions: true,
                sessionById: payload,
            };
        case DXP_GET_FILTERED_SESSIONS_BY_FELLOWSHIP_ID_PAGINATED:
            return {
                ...state,
                isLoadinFellowshipSessions: false,
                successFellowshipSessions: true,
                sessionsByFellowshipIdPaginated: payload.rows,
                sessionsByFellowshipIdPaginatedCount: payload.count,
            };
        case DXP_SEARCH_FELLOWSHIP_SESSIONS_PAGINATED:
            return {
                ...state,
                isLoadinFellowshipSessions: false,
                successFellowshipSessions: true,
                searchReturnSessionsPaginated: payload.rows,
                searchReturnSessionsPaginatedCount: payload.count,
            };
        case DXP_GET_ALL_FELLOWSHIP_SESSIONS_PAGINATED:
            return {
                ...state,
                isLoadinFellowshipSessions: false,
                successFellowshipSessions: true,
                fellowshipSessionsPaginated: payload.rows,
                fellowshipSessionsPaginatedCount: payload.count,
            };
        case DXP_CREATE_NEW_SESSION:
        case DXP_UPDATE_FELLOWSHIP_SESSION:
        case DXP_DELETE_FELLOWSHIP_SESSION_BY_ID:
            return {
                ...state,
                isLoadinFellowshipSessions: false,
                successFellowshipSessions: true,
            };
        case DXP_GET_ALL_FELLOWSHIP_SESSIONS_PAGINATED_ERROR:
        case DXP_SEARCH_FELLOWSHIP_SESSIONS_PAGINATED_ERROR:
        case DXP_GET_SESSION_BY_ID_ERROR:
        case DXP_CREATE_NEW_SESSION_ERROR:
        case DXP_UPDATE_FELLOWSHIP_SESSION_ERROR:
        case DXP_GET_FILTERED_SESSIONS_BY_FELLOWSHIP_ID_PAGINATED_ERROR:
        case DXP_DELETE_FELLOWSHIP_SESSION_BY_ID_ERROR:
        case DXP_GET_ALL_SESSION_COURSES_NAME_ID_ONLY_ERROR:
            return {
                ...state,
                isLoadinFellowshipSessions: false,
                successFellowshipSessions: false,
            };
        default:
            return state;
    }
}