import React, { useState } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Collapse,
  Box,
  Button,
} from "@mui/material";
import { red } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";


const FeaturedManufacturerTableRows = ({
  manu,
  handleClick,
  handleClose,
  anchorEl,
}) => {
  const [open, setOpen] = useState(false);
  // const classes = useRowStyles();

  return (
    <>
      <TableRow >
        {/* <TableRow className={classes.root}> */}

        <TableCell
          component="th"
          scope="row"
          align="left"
          style={{ fontWeight: "bold" }}
        >
          {manu.Manufacturer.name}
        </TableCell>
        <TableCell>

          <IconButton
            aria-label="expand row"
            size="large"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Featured Products
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Expiration Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {manu.product1 && (
                      <TableRow key={manu.product1.product_id}>
                        <TableCell component="th" scope="row">
                          {manu.product1.name}
                        </TableCell>
                        <TableCell>
                          <Moment format="MM/DD/YYYY">
                            {manu.expiration_date_1}
                          </Moment>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                  <>
                    {manu.product2 && (
                      <TableRow key={manu.product2.product_id}>
                        <TableCell component="th" scope="row">
                          {manu.product2.name}
                        </TableCell>
                        <TableCell>
                          <Moment format="MM/DD/YYYY">
                            {manu.expiration_date_2}
                          </Moment>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                  <>
                    {manu.product3 && (
                      <TableRow key={manu.product3.product_id}>
                        <TableCell component="th" scope="row">
                          {manu.product3.name}
                        </TableCell>
                        <TableCell>
                          <Moment format="MM/DD/YYYY">
                            {manu.expiration_date_3}
                          </Moment>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                  <TableRow>
                    <TableCell>
                      <Button
                        name="delete"
                        variant="contained"
                        onClick={(e) => handleClick(e)}
                        id={manu.feature_manufacturer_id}
                        style={{
                          marginTop: "2rem",
                          marginBottom: "2rem",
                          background: red[500],
                          color: "white",
                        }}
                      >
                        Delete
                      </Button>

                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default FeaturedManufacturerTableRows;
