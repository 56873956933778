import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client';
import App from "./App";
import "./mocks";

import { Provider } from "react-redux";
import HttpsRedirect from 'react-https-redirect';
import store from "./redux/store/index";
import { StyledEngineProvider, CssBaseline } from '@mui/material';
const root = createRoot(document.getElementById("root"));

root.render(
  <HttpsRedirect>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <App />
      </StyledEngineProvider>
    </Provider>
  </HttpsRedirect>
);
