import React from "react";
import { connect } from "react-redux";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { deleteFeaturedMan } from "../../../redux/actions/featuredManufacturers";

const DeleteDialog = ({
  deleteFeaturedMan,
  handleClose,
  featuredManufacturer: { selectedManufacturer },
}) => {
  const handleDelete = (e) => {
    e.preventDefault();
    deleteFeaturedMan(selectedManufacturer.feature_manufacturer_id);
    handleClose();
  };
  return (
    <>
      <DialogTitle>Delete Manufacturer Featured Product</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete{" "}
          {selectedManufacturer.Manufacturer.name}'s featured products? This
          cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={(e) => handleDelete(e)} color="primary">
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (state) => ({
  featuredManufacturer: state.featuredManufacturer,
});

export default connect(mapStateToProps, { deleteFeaturedMan })(DeleteDialog);
