import {
    GET_ALL_DISTRIBUTORS,
    ALL_DISTRIBUTORS_ERROR,
    GET_DISTRIBUTORS_PAGINATED,
    SEARCH_DISTRIBUTORS_PAGINATED,
    GET_DISTRIBUTORS_COUNT,
    GET_DISTRIBUTORS_SEARCH_COUNT,
    SELECT_DISTRIBUTOR,
    ADD_DISTRIBUTOR,
    UPDATE_DISTRIBUTOR,
    DISTRIBUTORS_ERROR,
} from "../actions/types";

const initialState = {
    distributors: [],
    paginatedDistributors: [],
    distributorSearchPage: [],
    selectedDistributor: null,
    count: null,
    searchCount: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_ALL_DISTRIBUTORS:
            return {
                ...state,
                distributors: payload,
                isLoading: false,
                success: true,
            };
        case GET_DISTRIBUTORS_PAGINATED:
            return {
                ...state,
                paginatedDistributors: payload,
                isLoading: false,
                success: true,
            };
        case GET_DISTRIBUTORS_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_DISTRIBUTORS_PAGINATED:
            return {
                ...state,
                distributorSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case GET_DISTRIBUTORS_SEARCH_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case SELECT_DISTRIBUTOR:
            return {
                ...state,
                selectedDistributor: payload,
                isLoading: false,
                success: true,
            };
        case ADD_DISTRIBUTOR:
        case UPDATE_DISTRIBUTOR:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case ALL_DISTRIBUTORS_ERROR:
            return {
                ...state,
                distributors: payload,
                isLoading: false,
                success: false,
            };
        case DISTRIBUTORS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
