import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Grid,
} from "@mui/material";

import { updateFeatured } from "../../../redux/actions/featured";

const EditDialog = ({
  updateFeatured,
  handleClose,
  featured: { selectedProduct },
}) => {
  const [product, setProduct] = useState({
    feature_product_id: null,
    create_date: null,
    modified_date: null,
    category_id: null,
    product_id: null,
    featured: null,
    expiration_date: null,
  });

  useEffect(() => {
    setProduct({
      feature_product_id: selectedProduct[0].feature_product_id,
      create_date: selectedProduct[0].create_date,
      modified_date: selectedProduct[0].modified_date,
      category_id: selectedProduct[0].category_id,
      product_id: selectedProduct[0].product_id,
      featured: selectedProduct[0].featured,
      expiration_date: selectedProduct[0].expiration_date,
    });
  }, [selectedProduct]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFeatured(product.feature_product_id, product);
    setProduct({
      feature_product_id: null,
      create_date: null,
      modified_date: null,
      category_id: null,
      product_id: null,
      featured: null,
      expiration_date: null,
    });
    handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setProduct({
      ...product,
      expiration_date: date,
    });
  };

  const { featured, expiration_date } = product;
  return (
    <>
      <DialogTitle id="form-dialog-title">Edit Featured Product</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Edit {selectedProduct[0].Product.name}'s feature status
        </DialogContentText>
        <Grid container direction="row">
          <Typography variant="caption">Featured</Typography>
        </Grid>

        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={featured}
            name="featured"
            placeholder={featured}
            defaultValue={featured}
            onChange={handleChange}
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
          <TextField
            id="date-picker-inline"
            label="Expiration Date"
            value={expiration_date}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            name="expiration1"
            onChange={handleDateChange}
            style={{ marginTop: "8px" }}
          />

        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = (state) => ({
  featured: state.featured,
});

export default connect(mapStateToProps, { updateFeatured })(EditDialog);
