import {
    DXP_GET_TOWER_EVENTS,
    DXP_GET_TOWER_EVENTS_ERROR,
    DXP_GET_TOP_EVENTS,
    DXP_GET_TOP_EVENTS_ERROR,
    DXP_SELECT_TOP_EVENT,
    DXP_SELECT_TOWER_EVENT,
    DXP_ADD_FEATURED_EVENT,
    DXP_ADD_FEATURED_EVENT_ERROR,
    DXP_UPDATE_FEATURED_EVENT,
    DXP_UPDATE_FEATURED_EVENT_ERROR,
    DXP_DELETE_FEATURED_EVENT,
    DXP_DELETE_FEATURED_EVENT_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    topEvents: [],
    towerEvents: [],
    selectedTop: null,
    selectedTower: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_TOWER_EVENTS:
            return {
                ...state,
                towerEvents: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_TOP_EVENTS:
            return {
                ...state,
                topEvents: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_FEATURED_EVENT:
        case DXP_UPDATE_FEATURED_EVENT:
        case DXP_DELETE_FEATURED_EVENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_TOP_EVENT:
            return {
                ...state,
                selectedTop: payload,
            };
        case DXP_SELECT_TOWER_EVENT:
            return {
                ...state,
                selectedTower: payload,
            };
        case DXP_GET_TOWER_EVENTS_ERROR:
            return {
                ...state,
                towerEvents: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_TOP_EVENTS_ERROR:
            return {
                ...state,
                topEvents: payload,
                isLoading: false,
                success: true
            }
        case DXP_ADD_FEATURED_EVENT_ERROR:
        case DXP_UPDATE_FEATURED_EVENT_ERROR:
        case DXP_DELETE_FEATURED_EVENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: true
            }
        default:
            return state;
    }
}
