import {
    DXP_ADD_WEBINAR,
    DXP_ADD_WEBINAR_ERROR,
    DXP_GET_ALL_WEBINARS,
    DXP_GET_ALL_WEBINARS_ERROR,
    DXP_GET_WEBINARS_PAGE,
    DXP_GET_WEBINARS_PAGE_ERROR,
    DXP_SEARCH_WEBINARS_PAGE,
    DXP_SEARCH_WEBINARS_PAGE_ERROR,
    DXP_SELECT_WEBINAR,
    DXP_GET_ALL_WEBINAR_COUNT,
    DXP_GET_ALL_WEBINAR_COUNT_ERROR,
    DXP_UPDATE_WEBINAR,
    DXP_UPDATE_WEBINAR_ERROR,
    DXP_DELETE_WEBINAR,
    DXP_DELETE_WEBINAR_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    webinars: [],
    publishedWebinars: [],
    webinarsSearchPage: [],
    selectedWebinar: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_WEBINARS:
        case DXP_GET_WEBINARS_PAGE:
            return {
                ...state,
                webinars: payload,
                publishedWebinars: payload.filter(webinar => webinar.published),
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_WEBINAR_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }

        case DXP_SELECT_WEBINAR:
            return {
                ...state,
                selectedWebinar: payload,
            };
        case DXP_SEARCH_WEBINARS_PAGE:
            return {
                ...state,
                webinarsSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_WEBINAR:
        case DXP_UPDATE_WEBINAR:
        case DXP_DELETE_WEBINAR:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_WEBINARS_ERROR:
        case DXP_GET_WEBINARS_PAGE_ERROR:
            return {
                ...state,
                webinars: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_ALL_WEBINAR_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }

        case DXP_SEARCH_WEBINARS_PAGE_ERROR:
            return {
                ...state,
                webinarsSearchPage: payload,
                isLoading: false,
                success: false,
            };

        case DXP_ADD_WEBINAR_ERROR:
        case DXP_UPDATE_WEBINAR_ERROR:
        case DXP_DELETE_WEBINAR_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
