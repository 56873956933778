import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import config from "../../utils/awsConfig";
import { uploadFile } from "./../../lib/ReactS3";
import { Editor } from '@tinymce/tinymce-react';

import {
    Button,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Dialog,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { DropzoneArea } from '../DropzoneArea';

import { addArticle } from "../../redux/actions/articles";
import { searchProducts } from "../../redux/actions/products";
import { getAllUrlTitles } from "../../redux/actions/articles";
import { searchManufacturers } from "../../redux/actions/manufacturers";
import { getAllUsers } from "../../redux/actions/auth";
import { setAlert } from "../../redux/actions/alerts";
import { useNavigate } from "react-router-dom";
import { setReturnUrl } from "../../redux/actions/articleTemplates";
import { getArticleTypes } from "../../redux/actions/articleTypes";

import "./NewDialog.css";

const editorApiKey = process.env.REACT_APP_TINY_KEY

const custom_image_upload_handler = (file, success) => {
    uploadFile(file.blob(), config)
        .then((data) => {
            success('https://dh1bsjhakq06i.cloudfront.net/' + file.filename())
        })
        .catch((err) => console.error(err));
}

const classes = {
    dropZone: {
        height: "232px",
        minHeight: "68px",
        width: '20%',
        marginRight: "10px",
        padding: "0 5px",
    },
    dropZoneImage: {
        height: "232px",
        minHeight: "68px",
        width: '200px',
        marginRight: "10px",
        marginBottom: '10px',
        padding: "0 5px",
    },
};

const NewDialog = ({
    setAlert,
    getAllUsers,
    page,
    searchProducts,
    addArticle,
    handleClose,
    open,
    type,
    type_id,
    getAllUrlTitles,
    setReturnUrl,
    getArticleTypes,
    product: { productSearch },
    article: { allUrlTitles },
    articleType: { articleTypes },
    auth: { user, users },
}) => {
    const prodName = useRef();
    const editorRef = useRef(null);
    // const classes = useStyles();
    const [article, setArticle] = useState({
        title: null,
        content: null,
        publish: null,
        // article_type: type,
        // author: user.userId,
        // article_type_id: type_id,
        product_id: null,
        urlTitle: null,
        ebookExternalLink: null,
        short_description: null,
        thumbnail: null,
        thumbnail_path: null,
        call_to_action: null,
        pdf_link: null,
        hubspot_form_id: null,
        is_templated: false,
        exclude_newsfeed: false,
    });
    const navigate = useNavigate()
    const [editPage, setPage] = useState(0);
    const [productList, setProductList] = useState([]);
    const [impersonate, setImpersonate] = useState(false);

    const typeFilter = ['Blogs', 'Case Study', 'Research', 'Marketing Materials', 'CE', 'Viva', 'More Information']

    useEffect(() => {
        setArticle(prevArticle => ({
            ...prevArticle,
            article_type: type,
            article_type_id: type_id,
            author: user.userId,
        }));
    }, [type, type_id, user]);

    useEffect(() => {
        if (user.permissions.length > 0) {
            user.permissions.forEach(permission => {
                if (permission.permission === 'impersonate') {
                    setImpersonate(true)
                }
            })
        }
        getAllUsers()
    }, [user, getAllUsers])

    useEffect(() => {
        getAllUrlTitles()
        getArticleTypes()
    }, [getAllUrlTitles, getArticleTypes])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (productList.length > 0 && title && (urlTitle && !allUrlTitles.some(el => el.urlTitle === urlTitle)) && (content || is_templated) && short_description) {
            await addArticle(article, page, productList);
            if (is_templated) {
                navigate(`/onsite-content/template`)
            }
            setArticle({
                title: null,
                content: null,
                publish: null,
                article_type: type,
                article_type_id: type_id,
                author: user.userId,
                product_id: null,
                urlTitle: null,
                ebookExternalLink: null,
                short_description: null,
                thumbnail: null,
                thumbnail_path: null,
                call_to_action: null,
                pdf_link: null,
                hubspot_form_id: null,
                is_templated: false,
                exclude_newsfeed: false
            });
            setProductList([])
            handleDialogClose();
        } else {
            if (allUrlTitles.some(el => el.urlTitle === urlTitle)) {
                setAlert('Duplicate Url Title in article database - make it unique', 'danger')
            } else {
                setAlert(`Please fill out required fields: ${!title ? 'Title, ' : ''} ${!urlTitle ? 'Url Title, ' : ''} ${!content && !is_templated ? 'Article Body, ' : ''} ${!short_description ? 'Short Description, ' : ''}& pick at least 1 product`, 'danger')
            }
        }
    };

    const searchForProducts = (e) => {
        setTimeout(() => {
            if (e.target.value.length < 2) {
                setAlert("Query must be 2 characters or longer", "danger");
            } else {
                searchProducts(e.target.value);
            }
        }, 1000);
    };

    const handleProductSelect = (e, product) => {
        let updatedProductList = [...productList];
        if (product) {
            setArticle({
                ...article,
                product_id: product.product_id
            })
            updatedProductList.push({ product_id: product.product_id, productName: product.name })
            setProductList(updatedProductList)
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setArticle({
            ...article,
            [name]: value,
        });

        if (name === 'is_templated' && value) {
            setReturnUrl(`/onsite-content/${article_type?.replaceAll(' ', '-')}`)
        }
        if (name === 'title') {
            setArticle({
                ...article,
                title: value,
                urlTitle: value.toLowerCase().replace(/[^a-zA-Z0-9-_]/g, '-')
            })
        }
    };

    const handleEditorChange = (data) => {
        setArticle({
            ...article,
            content: editorRef.current.getContent()
        })
    };

    const handleDialogClose = () => {
        handleClose();
        setPage(0);
    };

    //handles the drop event, sets the returned value from s3 bucket to the dropName value in state
    const handleDrop = (acceptedFiles, property) => {
        acceptedFiles.forEach((file) => {
            uploadFile(file, config)
                .then((data) => {
                    if (property === 'thumbnail') {
                        setArticle({
                            ...article,
                            [property]: data.location,
                            thumbnail_path: data.filename
                        });

                    } else {
                        setArticle({
                            ...article,
                            [property]: data.location,
                        });

                    }
                })
                .catch((err) => console.error(err));
        });
    };

    const handleDelete = (property) => {
        if (property === 'thumbnail') {
            setArticle({
                ...article,
                [property]: null,
                thumbnail_path: null
            });

        } else {
            setArticle({
                ...article,
                [property]: null,
            });

        }
    };

    const {
        title,
        content,
        publish,
        author,
        urlTitle,
        article_type,
        ebookExternalLink,
        short_description,
        thumbnail,
        thumbnail_path,
        call_to_action,
        pdf_link,
        hubspot_form_id,
        is_templated,
        exclude_newsfeed
    } = article;

    return (
        <Dialog
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            disableEnforceFocus={true}
        >
            <DialogTitle id="form-dialog-title">New {type} Article</DialogTitle>
            {editPage === 0 &&
                <DialogContent
                >
                    <DialogContentText>Add New Article</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        name="title"
                        label="Title"
                        placeholder={title}
                        value={title ? title : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                    />
                    <form noValidate autoComplete="off" style={{ width: "100%" }}>
                        <Autocomplete
                            options={productSearch ? productSearch : []}
                            ref={prodName}
                            getOptionLabel={(option) => option.name}
                            onKeyUp={(e) => searchForProducts(e)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Product"
                                    variant="outlined"
                                />
                            )}
                            onChange={(e, prod) => handleProductSelect(e, prod)}
                        />
                    </form>
                    <div>
                        <h4 style={{ marginBottom: 5 }}>Associated Products:</h4>
                        {
                            productList.length > 0 &&
                            productList.map((product, i) =>
                                <div key={`product-${i}`}>{product.productName}</div>
                            )
                        }
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <FormControl
                            variant="outlined"
                            style={{ width: "calc((100% - 10px)/2)", paddingTop: ".5rem", paddingBottom: ".5rem", marginTop: 12 }}
                        >
                            <InputLabel id="demo-simple-select-helper-label">Publish</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={publish ? publish : false}
                                name="publish"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl
                            variant="outlined"
                            style={{ width: "calc((100% - 10px)/2)", paddingTop: ".5rem", paddingBottom: ".5rem", marginTop: 12 }}
                        >
                            <InputLabel id="exclude-newsfeed-label">Hide From Newsfeed</InputLabel>
                            <Select
                                labelId="exclude-newsfeed-label"
                                id="exclude-newsfeed"
                                value={exclude_newsfeed ? exclude_newsfeed : false}
                                name="exclude_newsfeed"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {
                        type && (type === 'CE' || type === 'Viva') ?
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%", paddingTop: ".5rem", paddingBottom: ".5rem", marginTop: 12 }}
                            >
                                <InputLabel style={{ fontSize: 17 }}>Dental Learning Or Viva Learning</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={article_type ? article_type : ''}
                                    name="article_type"
                                    onChange={(e) => handleChange(e)}
                                >
                                    <MenuItem value={"CE"}>Dental Learning</MenuItem>
                                    <MenuItem value={"Viva"}>Viva Learning</MenuItem>
                                </Select>
                            </FormControl>
                            : type && type === 'other' ?
                                <FormControl>
                                    <InputLabel
                                        id="demo-simple-select-label"
                                        style={{ marginLeft: ".5rem" }}
                                    >
                                        Article Type
                                    </InputLabel>
                                    <Select
                                        variant="outlined"
                                        label="Article Type"
                                        name="article_type"
                                        value={article_type ? article_type : ''}
                                        onChange={(e) => handleChange(e)}
                                        fullWidth
                                        sx={{ width: '250px' }}
                                    >
                                        {articleTypes?.length > 0 ?
                                            articleTypes?.filter(type => !typeFilter.includes(type.name)).map((type, i) => (
                                                <MenuItem key={`article-type-${i}`} value={type.name}>{type.name === 'CE' ? 'Continuing Ed. (Dental Learning)' : type.name === 'Viva' ? 'Continuing Ed. (Viva Learning)' : type.name}</MenuItem>
                                            ))
                                            : null}

                                    </Select>
                                </FormControl>
                                : null}
                    {
                        impersonate === true ?
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%", paddingTop: ".5rem", paddingBottom: ".5rem", marginTop: 12 }}
                            >
                                <InputLabel id="demo-simple-select-helper-label">Author</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={author ? author : ''}
                                    name="author"
                                    onChange={(e) => handleChange(e)}
                                >
                                    {users && users.length > 0 &&
                                        users.map((user, i) =>
                                            <MenuItem key={`user-${i}`} value={user.userId}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                            :
                            <TextField
                                autoFocus
                                margin="dense"
                                variant="outlined"
                                name="author"
                                label="Author ID"
                                placeholder={author}
                                value={author ? author : ''}
                                onChange={(e) => handleChange(e)}
                                fullWidth
                                disabled
                                style={{ marginBottom: "10px" }}
                            />
                    }
                    <TextField
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        name="ebookExternalLink"
                        label="eBook External Link"
                        placeholder={ebookExternalLink}
                        value={ebookExternalLink ? ebookExternalLink : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        name="short_description"
                        label="Short Description"
                        placeholder={short_description}
                        value={short_description ? short_description : ''}
                        onChange={(e) => handleChange(e)}
                        multiline
                        rows={2}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                    />

                    <h4 style={{ textAlign: "left" }}>Image Upload</h4>
                    <DropzoneArea
                        acceptedFiles={['image/*']}
                        dropzoneClass={classes.dropZoneImage}
                        dropzoneText={thumbnail_path ? thumbnail_path : 'Add Image'}
                        showPreviewsInDropzone={true}
                        filesLimit={1}
                        onChange={(e) => handleDrop(e, 'thumbnail')}
                        onDelete={() => handleDelete('thumbnail')}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        name="urlTitle"
                        label="URL Title"
                        placeholder={urlTitle}
                        value={urlTitle ? urlTitle.toLowerCase().replace(/[^a-zA-Z0-9-_]/g, '-') : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                        required
                    />
                </DialogContent>
            }
            {
                editPage === 1 &&
                <DialogContent>
                    <DialogContentText>Add New Article</DialogContentText>
                    <FormControl
                        variant="outlined"
                        style={{ width: "100%", paddingTop: ".5rem", paddingBottom: ".5rem", marginTop: 12 }}
                    >
                        <InputLabel id="demo-simple-select-helper-label">Template</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={is_templated ? is_templated : false}
                            name="is_templated"
                            margin='dense'
                            onChange={(e) => handleChange(e)}
                            style={{ minWidth: '800px' }}
                        >
                            <MenuItem value={false}>Custom</MenuItem>
                            <MenuItem value={true}>Template</MenuItem>
                        </Select>
                    </FormControl>
                    {!is_templated && <Editor
                        value={content ? content : ''}
                        onInit={(evt, editor) => editorRef.current = editor}
                        apiKey={editorApiKey}
                        onEditorChange={(newText) => handleEditorChange(newText)}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste imagetools code help wordcount'
                            ],
                            paste_as_text: true,
                            menubar: 'file edit view insert format tools table tc help',
                            toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | link image | code | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            images_upload_handler: custom_image_upload_handler,
                        }}
                    />}
                    <h4 style={{ marginBottom: 8 }}>For Ebooks - Hubspot Form Id</h4>
                    <TextField
                        style={{ marginTop: 0 }}
                        margin="dense"
                        variant="outlined"
                        placeholder="Hubspot Form Id"
                        label="Hubspot Form Id"
                        name="hubspot_form_id"
                        value={hubspot_form_id ? hubspot_form_id : ''}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        name="call_to_action"
                        label='Call to Action Ex.) "Download PDF Here!"'
                        placeholder={call_to_action}
                        value={call_to_action ? call_to_action : ''}
                        onChange={(e) => handleChange(e)}
                        multiline
                        rows={2}
                        fullWidth
                        style={{ marginBottom: "10px", marginTop: "20px" }}
                    />
                    <h3>Upload PDF Below:</h3>
                    <div style={{ display: 'flex' }}>
                        <DropzoneArea
                            acceptedFiles={['application/pdf']}
                            dropzoneClass={classes.dropZone}
                            showPreviewsInDropzone={true}
                            filesLimit={1}
                            onChange={(e) => handleDrop(e, 'pdf_link')}
                            onDelete={() => handleDelete('pdf_link')}
                        />
                        <TextField
                            id="outlined-multiline-static"
                            // label="PDF Link"
                            multiline
                            // name="topArchBannerLink"
                            placeholder={article ? article.pdf_link : "Add PDF"}
                            value={article ? article.pdf_link : "Add PDF"}
                            rows={13}
                            variant="outlined"
                            // style={{ width: "100%" }}
                            disabled
                        />
                    </div>
                    <h4>OR paste in PDF link below</h4>
                    <div>
                        <TextField
                            autoFocus
                            margin="dense"
                            variant="outlined"
                            name="pdf_link"
                            label='PDF Url'
                            placeholder={pdf_link}
                            value={pdf_link ? pdf_link : ''}
                            onChange={(e) => handleChange(e)}
                            multiline
                            rows={2}
                            fullWidth
                            style={{ marginBottom: "10px", marginTop: "5px" }}
                        />
                    </div>
                </DialogContent>
            }
            <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                    Cancel
                </Button>
                {editPage === 0 &&
                    <Button color="primary" onClick={(e) => setPage(1)}>
                        Next
                    </Button>
                }
                {editPage === 1 &&
                    <Button color="primary" onClick={(e) => setPage(0)}>
                        Back
                    </Button>
                }
                {editPage === 1 &&
                    <Button color="primary" onClick={(e) => handleSubmit(e)}>
                        Submit
                    </Button>
                }
            </DialogActions>
        </Dialog >
    );
};

NewDialog.propTypes = {
    searchManufacturers: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    category: state.category,
    product: state.product,
    auth: state.auth,
    article: state.article,
    articleType: state.articleType,
});

export default connect(mapStateToProps, {
    searchManufacturers,
    searchProducts,
    addArticle,
    setAlert,
    getAllUsers,
    getAllUrlTitles,
    setReturnUrl,
    getArticleTypes
})(NewDialog);
