import {
    GET_DYNAMIC_PERSONA_LISTS,
    GET_CATEGORY_PERSONA_LISTS,
    GET_MANUFACTURER_PERSONA_LISTS,
    SELECT_DYNAMIC_PERSONA,
    ADD_DYNAMIC_PERSONA_LIST,
    ADD_DYNAMIC_PERSONA_LIST_ERROR,
    UPDATE_DYNAMIC_PERSONA_LIST,
    DELETE_DYNAMIC_PERSONA_LIST,
    DYNAMIC_PERSONA_LISTS_ERROR,
} from "../actions/types";

const initialState = {
    categoryPersonas: [],
    manufacturerPersonas: [],
    customPersonas: [],
    selectedPersona: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_DYNAMIC_PERSONA_LISTS:
            return {
                ...state,
                customPersonas: payload,
                isLoading: false,
                success: true,
            };
        case GET_CATEGORY_PERSONA_LISTS:
            return {
                ...state,
                categoryPersonas: payload,
                isLoading: false,
                success: true,
            };
        case GET_MANUFACTURER_PERSONA_LISTS:
            return {
                ...state,
                manufacturerPersonas: payload,
                isLoading: false,
                success: true,
            };
        case SELECT_DYNAMIC_PERSONA:
            return {
                ...state,
                selectedPersona: payload,
                isLoading: false,
                success: true,
            };

        case ADD_DYNAMIC_PERSONA_LIST:
        case UPDATE_DYNAMIC_PERSONA_LIST:
        case DELETE_DYNAMIC_PERSONA_LIST:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case ADD_DYNAMIC_PERSONA_LIST_ERROR:
        case DYNAMIC_PERSONA_LISTS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
