import {
    DXP_EXTERNAL_EVENT_GET,
    DXP_EXTERNAL_EVENT_GET_ERROR,
    DXP_EXTERNAL_EVENT_SELECT,
    DXP_EXTERNAL_EVENT_ADD,
    DXP_EXTERNAL_EVENT_ADD_ERROR,
    DXP_EXTERNAL_EVENT_UPDATE,
    DXP_EXTERNAL_EVENT_UPDATE_ERROR,
    DXP_EXTERNAL_EVENT_DELETE,
    DXP_EXTERNAL_EVENT_DELETE_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    featuredEvents: [],
    selectedEvent: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_EXTERNAL_EVENT_GET:
            return {
                ...state,
                featuredEvents: payload,
                isLoading: false,
                success: true,
            };
        case DXP_EXTERNAL_EVENT_SELECT:
            return {
                ...state,
                selectedEvent: payload,
                isLoading: false,
                success: true,
            };
        case DXP_EXTERNAL_EVENT_ADD:
        case DXP_EXTERNAL_EVENT_UPDATE:
        case DXP_EXTERNAL_EVENT_DELETE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_EXTERNAL_EVENT_GET_ERROR:
            return {
                ...state,
                featuredEvents: payload,
                isLoading: false,
                success: true
            }
        case DXP_EXTERNAL_EVENT_ADD_ERROR:
        case DXP_EXTERNAL_EVENT_UPDATE_ERROR:
        case DXP_EXTERNAL_EVENT_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: true
            }
        default:
            return state;
    }
}
