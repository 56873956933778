import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import {
    Button
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useNavigate } from "react-router-dom";

import { handleSectionChange } from '../../redux/actions/buyersGuide'
import { getBuyersGuideStageUrl } from '../../helpers/getBuyersGuideStageUrl'
import { Facebook } from '@mui/icons-material';


const NextSectionButton = ({ handleSectionChange, buyersGuide: { isLoading, success, specificBuyersGuideData } }) => {
    const navigate = useNavigate();
    const [prompt, setPrompt] = useState({ display: false, message: '' })

    const handleNext = () => {
        let stage = specificBuyersGuideData.curr_stage
        confirmStageChange(stage)
    }

    const setNextStage = (isNew, newStage) => {
        let stage = specificBuyersGuideData.curr_stage

        if (stage < 6) {
            handleSectionChange(stage + 1, specificBuyersGuideData, { loadNew: isNew, stage: newStage, buyers_guide_id: specificBuyersGuideData.buyers_guide_id })
            navigate(`/buyers-guide/${getBuyersGuideStageUrl(stage + 1)}`)
        }
    }

    const confirmStageChange = (stage) => {
        switch (stage) {
            case 4:
                setPrompt({ display: true, heading: 'Finished Editing', message: 'After clicking accept you will not be able to modify product data, until after the manufacturers have had a chance to review.', stage: 2 })
                break;
            case 5:
                setPrompt({ display: true, heading: 'Complete Manufacturer Review', message: 'After clicking accept all manufacturers will lose access to the portals where they can edit their products, you will be able to reopen portal individually.', stage: 3 })
                break;

            default:
                setPrompt({ display: false, heading: '', message: '', stage: 1 })
                setNextStage(false, null)
                break;
        }
    }


    return (
        <>
            <Button color="primary" variant="contained" onClick={() => { handleNext() }}>Next</Button>


            <Dialog
                open={prompt.display}
                onClose={() => { setPrompt({ ...prompt, display: false }) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {prompt.heading}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {prompt.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setPrompt({ ...prompt, display: false }) }}>Disagree</Button>
                    <Button onClick={() => {
                        setNextStage(prompt.display, prompt.stage)
                        setPrompt({ ...prompt, display: false })
                    }} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = state => ({
    buyersGuide: state.buyersGuide
});

export default connect(mapStateToProps, {
    handleSectionChange
}
)(NextSectionButton);
