import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";


import OptionsDialog from "./Dialogs/OptionsDialog";
import { selectFeaturedMan } from "../../redux/actions/featuredManufacturers";
import FeaturedManufacturerTableRows from "./FeaturedManufacturerTableRows";


const FeaturedManufacturerTable = ({
  selectFeaturedMan,
  featuredManufacturer: {
    featuredManufacturers,
    selectedManufacturer,
    featuredManufacturer,
    isLoading,
    success,
    count,
  },
}) => {
  // const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = (event) => {
    const featured = featuredManufacturers.filter(
      (manu) => manu.feature_manufacturer_id === event.currentTarget.id
    );
    setSelectedAction(event.currentTarget.name);
    setDialogOpen(true);
    selectFeaturedMan(featured[0]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <TableContainer
        component={Paper}
        variant="outlined"
        style={{ marginTop: "3rem", boxShadow: "5px 5px 5px grey" }}
      >
        <Typography variant="h5" style={{ padding: "1rem" }}>
          Featured Manufacturer Products
        </Typography>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Manufacturer</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {featuredManufacturers.map((manu) => (
              <FeaturedManufacturerTableRows
                key={manu.feature_manufacturer_id}
                manu={manu}
                handleClick={handleClick}
                handleClose={handleClose}
                anchorEl={anchorEl}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <OptionsDialog
        selectedAction={selectedAction}
        open={dialogOpen}
        handleClose={handleDialogClose}
        selected={selectedManufacturer}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  featuredManufacturer: state.featuredManufacturer,
});

export default connect(mapStateToProps, { selectFeaturedMan })(
  FeaturedManufacturerTable
);
