import {
    DXP_GET_ALL_ADDITIONAL_PROGRAMS_PAGINATED,
    DXP_GET_ALL_ADDITIONAL_PROGRAMS_PAGINATED_ERROR,
    DXP_SEARCH_ADDITIONAL_PROGRAMS_BY_TERM_PAGINATED,
    DXP_SEARCH_ADDITIONAL_PROGRAMS_BY_TERM_PAGINATED_ERROR,
    DXP_GET_FILTERED_ADDITIONAL_PROGRAMS_BY_FELLOWSHIP_ID_PAGINATED,
    DXP_GET_FILTERED_ADDITIONAL_PROGRAMS_BY_FELLOWSHIP_ID_PAGINATED_ERROR,
    DXP_GET_ADDITIONAL_PROGRAM_BY_ID,
    DXP_GET_ADDITIONAL_PROGRAM_BY_ID_ERROR,
    DXP_CREATE_NEW_ADDITIONAL_PROGRAM,
    DXP_CREATE_NEW_ADDITIONAL_PROGRAM_ERROR,
    DXP_UPDATE_ADDITIONAL_PROGRAM,
    DXP_UPDATE_ADDITIONAL_PROGRAM_ERROR,
    DXP_DELETE_ADDITIONAL_PROGRAM,
    DXP_DELETE_ADDITIONAL_PROGRAM_ERROR
} from "../actions/types";


const initialState = {
    isLoadingAdditionalPrograms: true,
    successAdditionalPrograms: false,
    allAdditionalProgramsPaginated: [],
    allAdditionalProgramsPaginatedCount: 0,
    searchReturnAdditionalProgramsPaginated: [],
    searchReturnAdditionalProgramsPaginatedCount: 0,
    additionalProgramsByFellowshipIdPaginated: [],
    additionalProgramsByFellowshipIdPaginatedCount: 0,
    additionalProgramById: {},
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ADDITIONAL_PROGRAM_BY_ID:
            return {
                ...state,
                isLoadingAdditionalPrograms: false,
                successAdditionalPrograms: true,
                additionalProgramById: payload,
            };
        case DXP_GET_FILTERED_ADDITIONAL_PROGRAMS_BY_FELLOWSHIP_ID_PAGINATED:
            return {
                ...state,
                isLoadingAdditionalPrograms: false,
                successAdditionalPrograms: true,
                additionalProgramsByFellowshipIdPaginated: payload.rows,
                additionalProgramsByFellowshipIdPaginatedCount: payload.count
            };
        case DXP_SEARCH_ADDITIONAL_PROGRAMS_BY_TERM_PAGINATED:
            return {
                ...state,
                isLoadingAdditionalPrograms: false,
                successAdditionalPrograms: true,
                searchReturnAdditionalProgramsPaginated: payload.rows,
                searchReturnAdditionalProgramsPaginatedCount: payload.count
            };
        case DXP_GET_ALL_ADDITIONAL_PROGRAMS_PAGINATED:
            return {
                ...state,
                isLoadingAdditionalPrograms: false,
                successAdditionalPrograms: true,
                allAdditionalProgramsPaginated: payload.rows,
                allAdditionalProgramsPaginatedCount: payload.count
            };
        case DXP_CREATE_NEW_ADDITIONAL_PROGRAM:
        case DXP_UPDATE_ADDITIONAL_PROGRAM:
        case DXP_DELETE_ADDITIONAL_PROGRAM:
            return {
                ...state,
                isLoadingAdditionalPrograms: false,
                successAdditionalPrograms: true,
            };
        case DXP_GET_ALL_ADDITIONAL_PROGRAMS_PAGINATED_ERROR:
        case DXP_SEARCH_ADDITIONAL_PROGRAMS_BY_TERM_PAGINATED_ERROR:
        case DXP_GET_FILTERED_ADDITIONAL_PROGRAMS_BY_FELLOWSHIP_ID_PAGINATED_ERROR:
        case DXP_GET_ADDITIONAL_PROGRAM_BY_ID_ERROR:
        case DXP_CREATE_NEW_ADDITIONAL_PROGRAM_ERROR:
        case DXP_UPDATE_ADDITIONAL_PROGRAM_ERROR:
        case DXP_DELETE_ADDITIONAL_PROGRAM_ERROR:
            return {
                ...state,
                isLoadingAdditionalPrograms: false,
                successAdditionalPrograms: false,
            };
        default:
            return state;
    }
}