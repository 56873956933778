import {
    GET_ALL_PRODUCT_REVIEWS_PAGE,
    GET_PRODUCT_REVIEW_COUNT,
    GET_PRODUCT_REVIEW_COUNT_ERROR,
    GET_ALL_PRODUCT_REVIEWS_PAGE_ERROR,
    SELECT_PRODUCT_REVIEW,
    SEARCH_PRODUCT_REVIEWS,
    SEARCH_PRODUCT_REVIEWS_ERROR,
    UPDATE_PRODUCT_REVIEW,
    DELETE_PRODUCT_REVIEW,
    ADD_PRODUCT_REVIEW,
    GET_PRODUCT_REVIEWS,
    SET_FEATURED_REVIEW,
    SET_FEATURED_REVIEWS_FALSE,
    GET_FEATURED_REVIEW,
    GET_PRODUCT_REVIEWS_ERROR,
    SET_FEATURED_REVIEW_ERROR,
    SET_FEATURED_REVIEWS_FALSE_ERROR,
    GET_FEATURED_REVIEW_ERROR,
    UPDATE_PRODUCT_REVIEW_ERROR,
    DELETE_PRODUCT_REVIEW_ERROR,
    ADD_PRODUCT_REVIEW_ERROR,
    GET_PRODUCT_REVIEWS_BY_MANUFACTURER_ID_PAGE,
    GET_PRODUCT_REVIEWS_BY_MANUFACTURER_ID_PAGE_ERROR
} from "../actions/types";

const initialState = {
    productReviews: [],
    productReviewSearch: [],
    featuredReview: [],
    // review: null,
    productReviewSearchCount: null,
    product: null,
    selectedProductReview: null,
    filteredProductReviews: [],
    filteredProductReviewsCount: null,
    isLoading: true,
    success: false,
    count: null,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_PRODUCT_REVIEWS_BY_MANUFACTURER_ID_PAGE:
            return {
                ...state,
                filteredProductReviews: payload.rows,
                filteredProductReviewsCount: payload.count,
                isLoading: false,
                success: true,
            };
        case GET_ALL_PRODUCT_REVIEWS_PAGE:
            return {
                ...state,
                productReviews: payload,
                isLoading: false,
                success: true,
            };
        case GET_PRODUCT_REVIEWS:
            return {
                ...state,
                productReviews: payload,
                isLoading: false,
                success: true,
            };
        case GET_FEATURED_REVIEW:
            return {
                ...state,
                featuredReview: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_PRODUCT_REVIEWS:
            return {
                ...state,
                productReviewSearch: payload.rows,
                productReviewSearchCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SELECT_PRODUCT_REVIEW:
            return {
                ...state,
                selectedProductReview: payload,
            };
        case GET_PRODUCT_REVIEW_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case SET_FEATURED_REVIEWS_FALSE:
        case SET_FEATURED_REVIEW:
        case UPDATE_PRODUCT_REVIEW:
        case DELETE_PRODUCT_REVIEW:
        case ADD_PRODUCT_REVIEW:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case GET_ALL_PRODUCT_REVIEWS_PAGE_ERROR:
            return {
                ...state,
                productReviews: payload,
                isLoading: false,
                success: false,
            };
        case GET_PRODUCT_REVIEWS_ERROR:
            return {
                ...state,
                productReviews: payload,
                isLoading: false,
                success: false,
            };
        case GET_PRODUCT_REVIEW_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false,
            };
        case SEARCH_PRODUCT_REVIEWS_ERROR:
            return {
                ...state,
                productReviewSearch: payload.rows,
                productReviewCount: payload.count,
                isLoading: false,
                success: false,
            };
        case SET_FEATURED_REVIEWS_FALSE_ERROR:
        case SET_FEATURED_REVIEW_ERROR:
        case UPDATE_PRODUCT_REVIEW_ERROR:
        case DELETE_PRODUCT_REVIEW_ERROR:
        case ADD_PRODUCT_REVIEW_ERROR:
        case GET_PRODUCT_REVIEWS_BY_MANUFACTURER_ID_PAGE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case GET_FEATURED_REVIEW_ERROR:
            return {
                ...state,
                featuredReview: payload,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
