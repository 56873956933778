import React, { useEffect, useState } from "react";

import FeaturedProductForm from "../components/NewProductsContent/FeaturedProductForm";
import Loader from "../components/Loader";

import { connect } from "react-redux";


import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
  getFeaturedNewProducts,
  getNewDefaultProducts,
} from "../redux/actions/products";
import { getNewProductsPromotion } from "../redux/actions/promotions";
import FeaturedPromotionForm from "../components/NewProductsContent/FeaturedPromotionForm";
import DefaultProductForm from "../components/NewProductsContent/DefaultProductForm";



const NewProductsPageContent = ({
  getFeaturedNewProducts,
  getNewProductsPromotion,
  getNewDefaultProducts,
  product: { isLoading },
}) => {
  // const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getFeaturedNewProducts();
    getNewProductsPromotion();
    getNewDefaultProducts();
  }, [getFeaturedNewProducts, getNewProductsPromotion, getNewDefaultProducts]);

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <Paper >
        {/* <Paper className={classes.root}> */}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Set Featured Products" />
          {/* <Tab label="Set Default Products" /> */}
          <Tab label="Set Promotion Featured Products" />
        </Tabs>
      </Paper>
      <br />
      <br />
      {value === 0 ? (
        <FeaturedProductForm />
      ) : value === 1 ? (
        <DefaultProductForm />
      ) : value === 2 ? (
        <FeaturedPromotionForm />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
});

export default connect(mapStateToProps, {
  getFeaturedNewProducts,
  getNewProductsPromotion,
  getNewDefaultProducts,
})(NewProductsPageContent);
