import {
    DXP_AUDIT_ALL_SUBSCRIPTIONS,
    DXP_AUDIT_ALL_SUBSCRIPTIONS_ERROR
} from "../actions/types";

const initialState = {
    subscriptionsToAudit: [],
    isLoading: true,
    success: false,
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_AUDIT_ALL_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptionsToAudit: payload,
                isLoading: false,
                success: true,
            };
        case DXP_AUDIT_ALL_SUBSCRIPTIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}