import {
    DXP_GET_FEATURED_PROFILES,
    DXP_GET_FEATURED_PROFILES_ERROR,
    DXP_GET_FEATURED_CATEGORIES,
    DXP_GET_FEATURED_CATEGORIES_ERROR,
    DXP_GET_FEATURED_FREE,
    DXP_GET_FEATURED_FREE_ERROR,
    DXP_GET_FEATURED_EVENT,
    DXP_GET_FEATURED_EVENT_ERROR,
    DXP_GET_STATS,
    DXP_GET_STATS_ERROR,
    DXP_SELECT_TOP,
    DXP_SELECT_FREE,
    DXP_ADD_FEATURED_CONTENT,
    DXP_ADD_FEATURED_CONTENT_ERROR,
    DXP_UPDATE_CONTENT,
    DXP_UPDATE_CONTENT_ERROR,
    DXP_DELETE_CONTENT,
    DXP_DELETE_CONTENT_ERROR,
    DXP_SELECT_HOME_EVENT,
    DXP_SELECT_HOME_FORUM_POST,
    DXP_GET_FEATURED_FORUM_POST,
    DXP_GET_FEATURED_FORUM_POST_ERROR,
    DXP_GET_FEATURED_LIVE_EVENTS,
    DXP_GET_FEATURED_LIVE_EVENTS_ERROR
} from "../actions/types";

const initialState = {
    count: null,
    featuredContent: [],
    freeContent: [],
    featuredCategories: [],
    featuredEvent: [],
    featuredLiveEvents: [],
    featuredForumPost: [],
    stats: [],
    selectedContent: null,
    selectedFree: null,
    selectedEvent: null,
    selectedForumPost: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_FEATURED_LIVE_EVENTS:
            return {
                ...state,
                featuredLiveEvents: payload,
            };
        case DXP_SELECT_HOME_FORUM_POST:
            return {
                ...state,
                selectedForumPost: payload,
            };
        case DXP_GET_FEATURED_PROFILES:
            return {
                ...state,
                featuredContent: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_FREE:
            return {
                ...state,
                freeContent: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_EVENT:
            return {
                ...state,
                featuredEvent: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_FORUM_POST:
            return {
                ...state,
                featuredForumPost: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_FEATURED_CATEGORIES:
            return {
                ...state,
                featuredCategories: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_STATS:
            return {
                ...state,
                stats: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_FEATURED_CONTENT:
        case DXP_UPDATE_CONTENT:
        case DXP_DELETE_CONTENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_TOP:
            return {
                ...state,
                selectedContent: payload,
            };
        case DXP_SELECT_FREE:
            return {
                ...state,
                selectedFree: payload,
            };
        case DXP_SELECT_HOME_EVENT:
            return {
                ...state,
                selectedEvent: payload,
            };
        case DXP_GET_FEATURED_PROFILES_ERROR:
            return {
                ...state,
                featuredContent: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_FEATURED_FREE_ERROR:
            return {
                ...state,
                freeContent: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_FEATURED_EVENT_ERROR:
            return {
                ...state,
                featuredEvent: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_FEATURED_CATEGORIES_ERROR:
            return {
                ...state,
                featuredCategories: payload,
                isLoading: false,
                success: true
            }
        case DXP_GET_STATS_ERROR:
            return {
                ...state,
                stats: payload,
                isLoading: false,
                success: true
            }
        case DXP_ADD_FEATURED_CONTENT_ERROR:
        case DXP_UPDATE_CONTENT_ERROR:
        case DXP_DELETE_CONTENT_ERROR:
        case DXP_GET_FEATURED_FORUM_POST_ERROR:
        case DXP_GET_FEATURED_LIVE_EVENTS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: true
            }
        default:
            return state;
    }
}
