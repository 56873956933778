import {
    DL_GET_ALL_REGISTRATIONS_PAGINATED,
    DL_GET_ALL_REGISTRATIONS_PAGINATED_ERROR,
    DL_GET_SEARCH_ALL_REGISTRATIONS_PAGINATED_BY_SEARCH_TERM,
    DL_GET_SEARCH_ALL_REGISTRATIONS_PAGINATED_BY_SEARCH_TERM_ERROR,
    DL_GET_ALL_REGISTRATIONS_BY_COMPLETION_STATUS_PAGINATED,
    DL_GET_ALL_REGISTRATIONS_BY_COMPLETION_STATUS_PAGINATED_ERROR,
    DL_GET_ALL_REGISTRANTS_TO_ADD_USER_IDS,
    DL_GET_ALL_REGISTRANTS_TO_ADD_USER_IDS_ERROR,
    DL_POST_SAVE_NEW_REGISTRANTS_ON_DB,
    DL_POST_SAVE_NEW_REGISTRANTS_ON_DB_ERROR,
    DL_POST_CREATE_NEW_EVALUATIONS_ON_DB,
    DL_POST_CREATE_NEW_EVALUATIONS_ON_DB_ERROR,
} from "../actions/types";

const initialState = {
    allRegistrationsPaginatedCount: null,
    allRegistrationsPaginated: [],
    allSearchRegistrationsPaginatedCount: null,
    allSearchRegistrationsPaginated: [],
    allRegistrationsByCompletionStatusPaginatedCount: null,
    allRegistrationsByCompletionStatusPaginated: [],
    allRegistrantsToAddUserIds: [],
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_ALL_REGISTRANTS_TO_ADD_USER_IDS:
            return {
                ...state,
                allRegistrantsToAddUserIds: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_REGISTRATIONS_BY_COMPLETION_STATUS_PAGINATED:
            return {
                ...state,
                allRegistrationsByCompletionStatusPaginated: payload.rows,
                allRegistrationsByCompletionStatusPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DL_GET_SEARCH_ALL_REGISTRATIONS_PAGINATED_BY_SEARCH_TERM:
            return {
                ...state,
                allSearchRegistrationsPaginated: payload.rows,
                allSearchRegistrationsPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_REGISTRATIONS_PAGINATED:
            return {
                ...state,
                allRegistrationsPaginated: payload.rows,
                allRegistrationsPaginatedCount: payload.count,
                isLoading: false,
                success: true,
            };
        case DL_POST_SAVE_NEW_REGISTRANTS_ON_DB:
        case DL_POST_CREATE_NEW_EVALUATIONS_ON_DB:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_REGISTRATIONS_PAGINATED_ERROR:
        case DL_GET_SEARCH_ALL_REGISTRATIONS_PAGINATED_BY_SEARCH_TERM_ERROR:
        case DL_GET_ALL_REGISTRATIONS_BY_COMPLETION_STATUS_PAGINATED_ERROR:
        case DL_GET_ALL_REGISTRANTS_TO_ADD_USER_IDS_ERROR:
        case DL_POST_SAVE_NEW_REGISTRANTS_ON_DB_ERROR:
        case DL_POST_CREATE_NEW_EVALUATIONS_ON_DB_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
