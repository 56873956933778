import {
    GET_FEATURED_HOME_CONTENT,
    GET_FEATURED_HOME_CONTENT_ERROR,
    DL_GET_CONTENT_TYPE,
    DL_GET_CONTENT_TYPE_ERROR,
    DL_ADD_FEATURED_CONTENT,
    DL_ADD_FEATURED_CONTENT_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    featuredContent: {},
    content: {},
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_FEATURED_HOME_CONTENT:
            return {
                ...state,
                featuredContent: {
                    ...state.featured,
                    [payload.section_id]: payload.content
                },
                isLoading: false,
                success: true,
            };
        case DL_GET_CONTENT_TYPE:
            return {
                ...state,
                content: {
                    ...state.content,
                    [payload.contentType]: payload.content
                },
                isLoading: false,
                success: true,
            };
        case DL_ADD_FEATURED_CONTENT:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case DL_GET_CONTENT_TYPE_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case GET_FEATURED_HOME_CONTENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false
            }
        case DL_ADD_FEATURED_CONTENT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false
            }

        default:
            return state;
    }
}
