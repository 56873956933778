import Signature from "./Signature";
import Policy from "./Policy";
import axios from 'axios'
import { dateISOString, xAmzDate, dateYMD } from "./Date";
import { throwError } from './ErrorThrower';
import { setAlert } from "../redux/actions/alerts";

class S3FileUpload {
    static async uploadFile(file, config, dxp) {

        throwError(config, file);
        const url = `http://${config.bucketName}.s3.amazonaws.com/`;

        const data = new FormData();
        data.append("image", file);

        const baseUrl = dxp ? process.env.REACT_APP_DXP_BASE_URL : process.env.REACT_APP_BASE_URL
        try {
            let res = await axios.post(`${baseUrl}/api/imageUpload/upload`, data)

            if (res.status !== 200) return Promise.reject(res);

            return Promise.resolve({
                bucket: config.bucketName,
                key: `${config.dirName ? config.dirName + "/" : ""}${res?.data?.data?.filename}`,
                location: `${url}${config.dirName ? config.dirName + "/" : ""}${res?.data?.data?.filename}`,
                filename: res?.data?.data?.filename,
                message: res?.data?.data?.message,
                result: res,
            });
        } catch (error) {
            console.error(error)
        }
    }
    static async deleteFile(fileName, config) {
        const fd = new FormData();
        const url = `https://${config.bucketName}.s3-${config.region}.amazonaws.com/${config.dirName ? config.dirName + "/" : ""}${fileName}`;
        fd.append("Date", xAmzDate);
        fd.append("X-Amz-Date", xAmzDate);
        fd.append("Authorization", Signature.getSignature(config, dateYMD, Policy.getPolicy(config)));
        fd.append("Content-Type", "text/plain");

        const params = {
            method: "delete",
            headers: {
                fd
            }
        };

        const deleteResult = await fetch(url, params);
        if (!deleteResult.ok) return Promise.reject(deleteResult);
        return Promise.resolve({
            ok: deleteResult.ok,
            status: deleteResult.status,
            message: "File Deleted",
            fileName: fileName
        });
    }
}
const { uploadFile, deleteFile } = S3FileUpload;
export { uploadFile, deleteFile };
export default S3FileUpload;