import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  CardContent,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Button,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { spacing } from '@mui/system';
import Loader from "../../components/Loader";

import {
  searchPromotions,
  getPromotions,
  setPromotionNewProduct,
  setFeaturedNewFalse,
} from "../../redux/actions/promotions";
import { setAlert } from "../../redux/actions/alerts";

import styled from "styled-components";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)`
  width: 270px;
`;

const FeaturedPromotionForm = ({
  searchPromotions,
  setPromotionNewProduct,
  setFeaturedNewFalse,
  setAlert,
  promotion: { newProductPromotion, promotionSearchPage, isLoading },
}) => {
  const [newPromotion, setNewPromotion] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (newPromotion) {
      setIsDisabled(false);
    }
  }, [isDisabled, newPromotion]);

  const searchForPromotions = (e) => {
    setTimeout(() => {
      if (e.target.value.length < 3) {
        setAlert("Query must be 3 characters or longer", "danger");
      } else {
        searchPromotions(e.target.value);
      }
    }, 1000);
  };

  const handlePromotionSelect = (e, promotion) => {
    if (promotion) {
      setNewPromotion(promotion.promotion_id);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setFeaturedNewFalse();
      setNewPromotion(null);
      setPromotionNewProduct(newPromotion);
    } catch (err) {
      console.log(err);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card mb={6} variant="outlined" style={{ boxShadow: "5px 5px 5px grey" }}>
      <CardContent>
        <Typography
          variant="h2"
          gutterBottom
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Featured Products - New Product Page
        </Typography>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12} style={{ padding: "2rem" }}>
            <Typography variant="h4" style={{ marginBottom: "2rem" }}>
              Currently Featured Promotion
            </Typography>
            <TextField
              value={newProductPromotion[0] ? newProductPromotion[0].title : ''}
              variant="outlined"
              disabled
            />
          </Grid>
          <Divider />
          <Grid item xs={12} style={{ padding: "2rem" }}>
            <Typography variant="h4" style={{ marginBottom: "1rem" }}>
              Set New Promotion
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ marginBottom: "2rem" }}
            >
              <Autocomplete
                name="promotions"
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, promo) => handlePromotionSelect(e, promo)}
              />
            </form>
          </Grid>
        </Grid>

        <Divider />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
          onClick={(e) => handleSubmit(e)}
          disabled={isDisabled}
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );
};

FeaturedPromotionForm.propTypes = {};

const mapStateToProps = (state) => ({
  promotion: state.promotion,
});

export default connect(mapStateToProps, {
  searchPromotions,
  getPromotions,
  setFeaturedNewFalse,
  setPromotionNewProduct,
  setAlert,
})(FeaturedPromotionForm);
