import {
    DL_GET_PODCASTS,
    DL_GET_PODCASTS_ERROR,
    DL_GET_PUBLISHED_PODCASTS,
    DL_GET_PUBLISHED_PODCASTS_ERROR,
    DL_SEARCH_PODCASTS,
    DL_SEARCH_PODCASTS_ERROR,
    DL_GET_PODCASTS_COUNT,
    DL_GET_PODCASTS_COUNT_ERROR,
    DL_GET_PODCASTS_SEARCH_COUNT,
    ADD_PODCAST,
    ADD_PODCAST_ERROR,
    UPDATE_PODCAST,
    UPDATE_PODCAST_ERROR,
    DELETE_PODCAST,
    DELETE_PODCAST_ERROR,
    DL_SELECT_PODCAST,
} from "../actions/types";

const initialState = {
    publishedPodcasts: [],
    podcasts: [],
    searchedPodcasts: [],
    selectedPodcast: null,
    count: null,
    searchCount: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_PODCASTS:
            return {
                ...state,
                podcasts: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_PUBLISHED_PODCASTS:
            return {
                ...state,
                publishedPodcasts: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_PODCASTS:
            return {
                ...state,
                searchedPodcasts: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_PODCASTS_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_PODCASTS_SEARCH_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_PODCAST:
            return {
                ...state,
                selectedPodcast: payload,
            };
        case ADD_PODCAST:
        case UPDATE_PODCAST:
        case DELETE_PODCAST:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case DL_GET_PODCASTS_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DL_GET_PUBLISHED_PODCASTS_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DL_SEARCH_PODCASTS_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DL_GET_PODCASTS_COUNT_ERROR:

            return {
                ...state,
                isLoading: false,
                success: false,
            };

        case ADD_PODCAST_ERROR:
        case UPDATE_PODCAST_ERROR:
        case DELETE_PODCAST_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
