import {
    DXP_GET_RELATED_CONTENT,
    DXP_GET_RELATED_CONTENT_ERROR,
    DXP_SET_SELECTED_TYPE
} from "../actions/types";

const initialState = {
    relatedContent: [],
    parentPropertyKey: { Courses: 'Related', Videos: 'RelatedVideo', Articles: 'RelatedArticle', Webinars: 'RelatedWebinar', Events: 'RelatedEvent', Xperts: 'RelatedXpert' },
    propertyKey: { Courses: 'course_id', Videos: 'video_id', Articles: 'article_id', Webinars: 'webinar_id', Events: 'event_id', Xperts: 'username' },
    selectedRelatedType: { type: 'Courses' },
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_RELATED_CONTENT:
            return {
                ...state,
                relatedContent: payload,
                isLoading: false,
                success: true
            }

        case DXP_SET_SELECTED_TYPE:
            return {
                ...state,
                selectedRelatedType: payload,
                isLoading: false,
                success: true
            }

        case DXP_GET_RELATED_CONTENT_ERROR:
            return {
                ...state,
                relatedContent: payload,
                isLoading: false,
                success: false
            }

        default:
            return state;
    }
}
