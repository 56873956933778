import {
    DXP_GET_CATEGORY,
    DXP_GET_CATEGORY_ERROR,
    DXP_GET_SUB_CATEGORY,
    DXP_GET_SUB_CATEGORY_ERROR,
    DXP_GET_ALL_CATEGORIES,
    DXP_GET_ALL_CATEGORIES_ERROR,
    DXP_GET_ALL_SUB_CATEGORIES,
    DXP_GET_ALL_SUB_CATEGORIES_ERROR,
    DXP_GET_SUB_CATEGORIES,
    DXP_GET_SUB_CATEGORIES_ERROR,
    DXP_ADD_CATEGORY,
    DXP_ADD_CATEGORY_ERROR,
    DXP_ADD_SUB_CATEGORY,
    DXP_ADD_SUB_CATEGORY_ERROR,
    DXP_DELETE_CATEGORY,
    DXP_DELETE_CATEGORY_ERROR,
    DXP_DELETE_SUB_CATEGORY,
    DXP_DELETE_SUB_CATEGORY_ERROR,
    DXP_UPDATE_SUB_CATEGORY,
    DXP_UPDATE_SUB_CATEGORY_ERROR,
    DXP_UPDATE_CATEGORY,
    DXP_UPDATE_CATEGORY_ERROR,
    DXP_GET_NULL_SUB_CATEGORIES,
    DXP_GET_NULL_SUB_CATEGORIES_ERROR
} from "../actions/types";

const initialState = {
    categories: [],
    categoryById: null,
    subCategories: [],
    publishedSubCategories: [],
    subCategoryById: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_CATEGORY:
            return {
                ...state,
                categoryById: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_SUB_CATEGORY:
            return {
                ...state,
                subCategoryById: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_CATEGORIES:
            return {
                ...state,
                categories: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_SUB_CATEGORIES:
            return {
                ...state,
                subCategories: payload,
                publishedSubCategories: payload.filter(sub => sub.published),
                isLoading: false,
                success: true,
            };
        case DXP_GET_SUB_CATEGORIES:
        case DXP_GET_NULL_SUB_CATEGORIES:
            return {
                ...state,
                subCategories: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_CATEGORIES_ERROR:
        case DXP_ADD_CATEGORY_ERROR:
            return {
                ...state,
                categories: payload,
                isLoading: false,
                success: false,
            };
        case DXP_GET_SUB_CATEGORIES_ERROR:
        case DXP_GET_NULL_SUB_CATEGORIES:
        case DXP_GET_ALL_SUB_CATEGORIES_ERROR:
        case DXP_ADD_SUB_CATEGORY_ERROR:
        case DXP_DELETE_CATEGORY_ERROR:
        case DXP_UPDATE_SUB_CATEGORY_ERROR:
        case DXP_UPDATE_CATEGORY_ERROR:
        case DXP_DELETE_SUB_CATEGORY_ERROR:
        case DXP_GET_CATEGORY_ERROR:
        case DXP_GET_SUB_CATEGORY_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_DELETE_CATEGORY:
        case DXP_DELETE_SUB_CATEGORY:
        case DXP_UPDATE_SUB_CATEGORY:
        case DXP_UPDATE_CATEGORY:
        case DXP_ADD_CATEGORY:
        case DXP_ADD_SUB_CATEGORY:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        default:
            return state;
    }
}


