import {
    DXP_GET_ALL_CONTENT_TYPES,
    DXP_GET_ALL_CONTENT_TYPES_ERROR
} from "../actions/types";

const initialState = {
    contentTypes: [{ type: 'Courses' }, { type: 'Videos' }, { type: 'Articles' }, { type: 'Webinars' }, { type: 'Events' }, { type: 'Xperts' }],
    isLoading: true,
    success: false,
};

// , { type: 'Xperts' }

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_ALL_CONTENT_TYPES:
            return {
                ...state,
                contentTypes: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_CONTENT_TYPES_ERROR:
            return {
                ...state,
                contentTypes: payload,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
