import {
    GET_ALL_VIDEOS,
    SET_FEATURED_VIDEO,
    SET_FEATURED_VIDEOS_FALSE,
    GET_FEATURED_VIDEOS,
    GET_ALL_VIDEOS_ERROR,
    SET_FEATURED_VIDEO_ERROR,
    SET_FEATURED_VIDEOS_FALSE_ERROR,
    GET_FEATURED_VIDEOS_ERROR,
} from "../actions/types";

const initialState = {
    productVideos: [],
    featuredVideos: [],
    isLoading: true,
    success: false,
};


export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_ALL_VIDEOS:
            return {
                ...state,
                productVideos: payload,
                isLoading: false,
                success: true,
            };
        case GET_FEATURED_VIDEOS:
            return {
                ...state,
                featuredVideos: payload,
                isLoading: false,
                success: true,
            };
        case SET_FEATURED_VIDEO:
        case SET_FEATURED_VIDEOS_FALSE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case GET_ALL_VIDEOS_ERROR:
            return {
                ...state,
                productVideos: payload,
                isLoading: false,
                success: false,
            };
        case GET_FEATURED_VIDEOS_ERROR:
            return {
                ...state,
                featuredVideos: payload,
                isLoading: false,
                success: false,
            };
        case SET_FEATURED_VIDEO_ERROR:
        case SET_FEATURED_VIDEOS_FALSE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
