import {
    DXP_GET_AFFILIATES_COUNT,
    DXP_GET_AFFILIATES_COUNT_ERROR,
    DXP_GET_ALL_AFFILIATES,
    DXP_GET_ALL_AFFILIATES_ERROR,
    DXP_GET_AFFILIATES_PAGINATED,
    DXP_GET_AFFILIATES_PAGINATED_ERROR,
    DXP_SEARCH_AFFILIATES_PAGINATED,
    DXP_SEARCH_AFFILIATES_PAGINATED_ERROR,
    DXP_SEARCH_COUNT_PAGINATED,
    DXP_SEARCH_AFFILIATES_COUNT_ERROR,
    DXP_SELECT_AFFILIATE,
    DXP_ADD_AFFILIATES,
    DXP_ADD_AFFILIATES_ERROR,
    DXP_UPDATE_AFFILIATES,
    DXP_UPDATE_AFFILIATES_ERROR,
    DXP_DELETE_AFFILIATES,
    DXP_DELETE_AFFILIATES_ERROR,
    DXP_GET_ALL_AFFILIATE_NAMES,
    DXP_GET_ALL_AFFILIATE_NAMES_ERROR,
    DXP_GET_AFFILIATE_EVENTS,
    DXP_GET_AFFILIATE_EVENTS_ERROR,
} from "../actions/types";

const initialState = {
    affiliates: [],
    affiliatesSearch: [],
    affiliateNames: [],
    affiliateEvents: [],
    selectedAffiliate: null,
    count: 0,
    searchCount: 0,
    isLoading: true,
    success: false,
};

// , { type: 'Xperts' }

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DXP_GET_AFFILIATES_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_AFFILIATES:
            return {
                ...state,
                affiliates: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_AFFILIATE_NAMES:
            return {
                ...state,
                affiliateNames: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_AFFILIATE_EVENTS:
            return {
                ...state,
                affiliateEvents: payload,
                isLoading: false,
                success: true,
            };
        case DXP_GET_AFFILIATES_PAGINATED:
            return {
                ...state,
                affiliates: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_AFFILIATES_PAGINATED:
            return {
                ...state,
                affiliatesSearch: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SEARCH_COUNT_PAGINATED:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DXP_SELECT_AFFILIATE:
            return {
                ...state,
                selectedAffiliate: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_AFFILIATES:
        case DXP_UPDATE_AFFILIATES:
        case DXP_DELETE_AFFILIATES:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_AFFILIATES_COUNT_ERROR:
        case DXP_GET_ALL_AFFILIATES_ERROR:
        case DXP_GET_ALL_AFFILIATE_NAMES_ERROR:
        case DXP_GET_AFFILIATE_EVENTS_ERROR:
        case DXP_GET_AFFILIATES_PAGINATED_ERROR:
        case DXP_SEARCH_AFFILIATES_PAGINATED_ERROR:
        case DXP_SEARCH_AFFILIATES_COUNT_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case DXP_ADD_AFFILIATES_ERROR:
        case DXP_UPDATE_AFFILIATES_ERROR:
        case DXP_DELETE_AFFILIATES_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
