import {
    DXP_ADD_ARTICLE,
    DXP_ADD_ARTICLE_ERROR,
    DXP_GET_ALL_ARTICLES,
    DXP_GET_ALL_ARTICLES_ERROR,
    DXP_GET_ARTICLES_PAGE,
    DXP_GET_ARTICLES_PAGE_ERROR,
    DXP_SEARCH_ARTICLES_PAGE,
    DXP_SEARCH_ARTICLES_PAGE_ERROR,
    DXP_SELECT_ARTICLE,
    DXP_GET_ALL_ARTICLE_COUNT,
    DXP_GET_ALL_ARTICLE_COUNT_ERROR,
    DXP_UPDATE_ARTICLE,
    DXP_UPDATE_ARTICLE_ERROR,
    DXP_DELETE_ARTICLE,
    DXP_DELETE_ARTICLE_ERROR,
    DXP_SEARCH_ARTICLES_COUNT,
    DXP_SEARCH_ARTICLES_COUNT_ERROR,
    DXP_GET_ARTICLE_DOWNLOAD_COUNT,
    DXP_GET_ARTICLE_DOWNLOAD_COUNT_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    articles: [],
    publishedArticles: [],
    articlesSearchPage: [],
    selectedArticle: null,
    articleDownloadCount: 0,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case DXP_GET_ALL_ARTICLES:
        case DXP_GET_ARTICLES_PAGE:
            return {
                ...state,
                articles: payload,
                publishedArticles: payload.filter(article => article.published),
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_ARTICLE_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case DXP_SEARCH_ARTICLES_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true
            }

        case DXP_SELECT_ARTICLE:
            return {
                ...state,
                selectedArticle: payload,
            };
        case DXP_SEARCH_ARTICLES_PAGE:
            return {
                ...state,
                articlesSearchPage: payload,
                isLoading: false,
                success: true,
            };

        case DXP_GET_ARTICLE_DOWNLOAD_COUNT:
            return {
                ...state,
                articleDownloadCount: payload,
                isLoading: false,
                success: true,
            };
        case DXP_ADD_ARTICLE:
        case DXP_UPDATE_ARTICLE:
        case DXP_DELETE_ARTICLE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DXP_GET_ALL_ARTICLES_ERROR:
        case DXP_GET_ARTICLES_PAGE_ERROR:
            return {
                ...state,
                articles: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_ALL_ARTICLE_COUNT_ERROR:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: false
            }
        case DXP_SEARCH_ARTICLES_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }

        case DXP_SEARCH_ARTICLES_PAGE_ERROR:
            return {
                ...state,
                articlesSearchPage: payload,
                isLoading: false,
                success: false,
            };

        case DXP_GET_ARTICLE_DOWNLOAD_COUNT_ERROR:
            return {
                ...state,
                articleDownloadCount: 0,
                isLoading: false,
                success: false,
            };

        case DXP_ADD_ARTICLE_ERROR:
        case DXP_UPDATE_ARTICLE_ERROR:
        case DXP_DELETE_ARTICLE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
