import {
    DL_GET_ALL_BLOGS_PAGINATED,
    DL_GET_ALL_BLOGS_PAGINATED_ERROR,
    DL_CREATE_NEW_BLOG,
    DL_CREATE_NEW_BLOG_ERROR,
    DL_UPDATE_BLOG,
    DL_UPDATE_BLOG_ERROR,
    DL_GET_SPECIFIC_BLOG_BY_ID,
    DL_GET_SPECIFIC_BLOG_BY_ID_ERROR,
    DL_SEARCH_BLOGS_PAGINATED,
    DL_SEARCH_BLOGS_PAGINATED_ERROR,
    DL_DELETE_BLOG_BY_ID,
    DL_DELETE_BLOG_BY_ID_ERROR
} from "../actions/types";

const initialState = {
    allBlogsPaginated: [],
    allBlogsPaginatedCount: 0,
    searchedBlogsPaginated: [],
    searchedBlogsPaginatedCount: 0,
    blogById: null,
    isLoadingBlogs: true,
    success: false,
};


export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_SEARCH_BLOGS_PAGINATED:
            return {
                ...state,
                searchedBlogsPaginated: payload.rows,
                searchedBlogsPaginatedCount: payload.count,
                isLoadingBlogs: false,
                success: true,
            };
        case DL_GET_SPECIFIC_BLOG_BY_ID:
            return {
                ...state,
                blogById: payload,
                isLoadingBlogs: false,
                success: true,
            };
        case DL_GET_ALL_BLOGS_PAGINATED:
            return {
                ...state,
                allBlogsPaginated: payload.rows,
                allBlogsPaginatedCount: payload.count,
                isLoadingBlogs: false,
                success: true,
            };
        case DL_GET_ALL_BLOGS_PAGINATED_ERROR:
        case DL_CREATE_NEW_BLOG_ERROR:
        case DL_UPDATE_BLOG_ERROR:
        case DL_GET_SPECIFIC_BLOG_BY_ID_ERROR:
        case DL_SEARCH_BLOGS_PAGINATED_ERROR:
        case DL_DELETE_BLOG_BY_ID_ERROR:
            return {
                ...state,
                isLoadingBlogs: false,
                success: false,
            };
        case DL_CREATE_NEW_BLOG:
        case DL_UPDATE_BLOG:
        case DL_DELETE_BLOG_BY_ID:
            return {
                ...state,
                isLoadingBlogs: false,
                success: true,
            };
        default:
            return state;
    }
}
