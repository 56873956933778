import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function ToastAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Alert = ({ alerts }) => {
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });

  const { vertical, horizontal, open } = state;

  useEffect(() => {
    alerts.length > 0 && alerts !== null
      ? setState({
        ...state,
        open: true,
      })
      : setState({
        ...state,
        open: false,
      });
  }, [alerts]);

  return (
    <div>
      {alerts.map((alert) =>
        alert.alertType === "success" ? (
          <Snackbar
            key={alert.id}
            anchorOrigin={{ vertical, horizontal }}
            open={open}
          >
            <div>
              <ToastAlert
                severity="success"
                sx={{
                  padding: ".5rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  marginTop: "3rem",
                }}
              >
                {alert.msg}
              </ToastAlert>
            </div>
          </Snackbar>
        ) : (
          <Snackbar
            key={alert.id}
            anchorOrigin={{ vertical, horizontal }}
            open={open}
          >
            <div>
              <ToastAlert
                severity="warning"
                sx={{
                  backgroundColor: "#B20000",
                  padding: ".5rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  marginTop: "3rem",
                }}
              >
                {alert.msg}
              </ToastAlert>
            </div>
          </Snackbar>
        )
      )}
    </div>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
